import React from 'react';
import { TextField, MenuItem  } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    icon: {
      color: 'red',
    },
  });

export const DobFieldForSm = ({months,month,handleChange}) => {

    const classes = useStyles();

  return (
    <React.Fragment>
      
      <div className="form-group mt-2">

<div className="col-6 col-md-4 col-lg-2 col-xl-2 col-sm-6 mx-auto dob-filed">

  <label className="form-label px-2">Date of Birth: </label>  

  </div>

    </div>

  <div className="field-div d-flex mt-2">

<div className="col-6 col-md-4 col-lg-4 col-sm-6 mx-auto month-filed text-end">

<TextField
id="month"
select
label="Month"
value={month}
onChange={handleChange}
variant="outlined"
className='month'
sx={{
"& .MuiSvgIcon-root": {
 color: (theme) => theme.palette.primary.main
}
}}
InputLabelProps={{
classes: {
root: 'input-label',
focused: 'input-label-focused',
}
}}
InputProps={{
classes: {
root: 'input-root',
focused: 'input-root-focused',
notchedOutline: 'input-notched-outline',
input: 'input-text',
}
}}
>
{months.map((option) => (
<MenuItem key={option.value} value={option.value}>
{option.label}
</MenuItem>
))}
</TextField>

</div> 

<div className="col-3 col-md-2 col-lg-2 col-sm-3 mx-auto day-filed text-center">

<TextField
id="day"
label="Day"
variant="outlined"
className='day'
InputLabelProps={{
classes: {
root: 'input-label',
focused: 'input-label-focused',
}
}}
InputProps={{
classes: {
root: 'input-root-2',
focused: 'input-root-focused',
notchedOutline: 'input-notched-outline',
input: 'input-text',
}
}}
/>

</div>

<div className="col-3 col-md-6 col-lg-6 col-sm-3 mx-auto year-filed text-start">

<TextField
id="year"
label="Year"
variant="outlined"
className='year'
InputLabelProps={{
classes: {
root: 'input-label',
focused: 'input-label-focused',
}
}}
InputProps={{
classes: {
root: 'input-root-3',
focused: 'input-root-focused',
notchedOutline: 'input-notched-outline',
input: 'input-text',
}
}}
/>
</div>


</div>  

    </React.Fragment>
  )
}
