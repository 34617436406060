import axios from 'axios';
import React, { useState,useEffect } from 'react'
import styledComponents from 'styled-components'
import { ImageSm } from './ImageSm';
import { ImageLoading } from '../loading/ImageLoading';
import {ImgModal} from './ImgModal';
import { Pagination } from '../pagination/Pagination';
import { Helmet } from 'react-helmet';

export const TagImages = ({tag}) => {

  const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Browse Celebrity MILF Images - Xcelebs`);
  const [pageDescription, setPageDescription] = useState(`Browse Celebrity MILF Images at Xcelebs. Every day growing universe of celebrity nude`);

  const [nextPageUrl,setNextPageUrl]=useState('');
  const [prevPageUrl,setPrevPageUrl]=useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const pageParam = urlParams.get('page');

  const pageNum =  (pageParam) ? parseInt(pageParam, 10) : 1;

  const [images,setImages] = useState([]);
  const [pages,setPages]=useState(0);
  const [currentPage,setCurrentPage]=useState(pageNum);
  const [currentBtn,setCurrentBtn]=useState(pageNum);
  const [loading,setLoading]=useState(true);
  const [pageLink,setPageLink]=useState(null);

  const itemPerPage=30;

  const [activeIndex,setActiveIndex]=useState(0);
  const [imgModal,setImgModal]=useState(false);
  const [imgId,setImgId]=useState('');

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const openImgModal=(flag)=>{
    setImgModal(flag);
  }

  const viewImage=(index,id)=>{
      setActiveIndex(index);
      openImgModal(true);
      setImgId(id);
  }

  useEffect(() => {

    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };

  }, []);

useEffect(()=>{
  setCurrentBtn(pageNum);
},[tag,pageNum]);

const currentPageHandler=page=>{
setCurrentPage(page);
}

function capitalizeFirstLetter(string) {
  if(string.toUpperCase()==="MILF" || string.toUpperCase()==="BDSM" || string.toUpperCase()==="BTS" || string.toUpperCase()==="MOM")
   return string.toUpperCase();
 return string.charAt(0).toUpperCase() + string.slice(1);
}

useEffect(()=>{

document.body.style.zoom="100%";
setPageLink(`/browse/tags/images/${tag}`);
setCurrentPage(pageNum);

if(pageNum==1){
const title=`Browse Celebrity ${(tag && tag.replaceAll("-", " ").split(" ").map(capitalizeFirstLetter).join(" "))} Images - Xcelebs`;
const description = `Browse Celebrity ${(tag && tag.replaceAll("-", " ").split(" ").map(capitalizeFirstLetter).join(" "))} Images at Xcelebs.`;

setPageTitle(title);
setPageDescription(description);

document.title = title;

setNextPageUrl(`https://xcelebs.org/browse/tags/images/${tag.replace("-"," ").toLowerCase()}?page=${2}`);

}else{
const title=`Browse Celebrity ${(tag && tag.replaceAll("-", " ").split(" ").map(capitalizeFirstLetter).join(" "))} Images - Page ${pageNum} - Xcelebs`;
const description = `Page ${pageNum}: Browse Celebrity ${(tag && tag.replaceAll("-", " ").split(" ").map(capitalizeFirstLetter).join(" "))} Images at Xcelebs.`;

setPageTitle(title);
setPageDescription(description);

document.title = title;

setNextPageUrl(`https://xcelebs.org/browse/tags/images/${tag.replace("-"," ").toLowerCase()}?page=${pageNum+1}`);

  if(pageNum==2)
  setPrevPageUrl(`https://xcelebs.org/browse/tags/images/${tag.replace("-"," ").toLowerCase()}`);
  else
  setPrevPageUrl(`https://xcelebs.org/browse/tags/images/${tag.replace("-"," ").toLowerCase()}?page=${pageNum-1}`);

}


},[tag,pageNum]);


useEffect(() => {

  const fetchData = async () => {

      try {
          if (isNaN(currentBtn))  
              return;

              if (currentBtn === 1) {
                window.scrollTo(0, 0);
            } else {
                if (window.innerWidth >= 1300) { 
                    window.scrollTo(0, 170);
                }  else if (window.innerWidth >= 700) {
                    window.scrollTo(0, 80);
                } else
                    window.scrollTo(0, 120);
            }
          
          setLoading(true);
          
          const tag_url = process.env.REACT_APP_CELEB_IMAGE;
          
          const tag_name = (tag) && tag.replaceAll('-', " ");
          
          if (!isOnline)
              return;
          
          const response = await axios.get(`${tag_url}/tag-list?tag=${encodeURIComponent(tag_name)}&page=${currentPage}`);
          
          setImages(response.data.celebImageList);
          setPageHandler(response.data.numOfItems);
          setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
  };

  fetchData();

}, [currentBtn,tag,isOnline]);


const setPageHandler =length=>{
 
  const numOfItems=length;     
  let page = parseInt(numOfItems/itemPerPage);

  if(parseInt(numOfItems%30)!==0)
   page+=1;

   setPages(page);
 };

  return (
     <TagImgContainer>
        <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
        { (prevPageUrl) && <link rel="prev" href={prevPageUrl} />}
        { (nextPageUrl && pages !==1 && currentBtn !==pages) && <link rel="next" href={nextPageUrl} />}
      </Helmet>

                 {/* { (!loading) && <div className="img-list my-3">
                     
                     {
                       (images) && images.map((img,index)=> <Image item={img} index={index} key={img.imgId} viewImageHandler={viewImage}/>)
                     }
 
                       </div> }  */}
       
                     { (!loading) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 img-list">
                     <div className="row">
                    {
                      (images) && images.map((img,index)=> <ImageSm item={img} index={index} key={img.imgId} viewImageHandler={viewImage} tag={tag}/>)
                    }

                        </div>
                      </div> }


                      {
                        (loading) && <ImageLoading/>
                      }

                      { (images && images.length>=1 && !loading) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 pages mt-3">

<Pagination pages={pages} currentPageHandler={currentPageHandler}
         link={pageLink} currentBtn={currentBtn} setCurrentBtn={setCurrentBtn}/> 
                  
                    </div>   }  

                   
        {
          (imgModal && imgId) && <ImgModal images={images} a_index={activeIndex} openImgModalHandler={openImgModal} currentImgId={imgId} tagName={tag} currentPage={currentPage}/>
        }


{/* {
(imgModal) && <ImgModal2 images={images} a_index={activeIndex} openImgModalHandler={openImgModal}/>
} */}
        
     </TagImgContainer>
  )
}

const TagImgContainer=styledComponents.div`


background: var(--lightBlack);
position: relative;


.card{
  background: transparent;
}

.pics{
  transform: scale(1);
  background: #403d3d47;
}

.pics-sm{
  transform: scale(0.95);
  background: #403d3d47;
  border-radius:0.4rem;
}

@media (max-width: 692px) {
  .pics-sm{
    transform: scale(0.96);
  }
}

.img-list .image-sm{
  padding:0 !important;
  margin:0 !important;
}

.img-list .image{
  
  width: auto;
  padding: 0.2rem 0.4rem;
  background: #403d3d47;
  margin: 0.3rem 0.3rem;
  text-align: center;
  display:inline-block;
  border-radius:0.3rem;
  
}

.img-div{

  border: 3px solid transparent;
  cursor:pointer;
  transition: 0.5s all;
  height:14rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
  border: 3px solid var(--lightOrange);
  transition: 0.5s all;
  }

}

.img-sm-div{

  border: 3px solid transparent;
  cursor:pointer;
  transition: 0.5s all;
  //max-height:11rem;
  height:11rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
  border: 3px solid var(--lightOrange);
  transition: 0.5s all;
  }

}

.img-div img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.img-sm-div img{
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

`;