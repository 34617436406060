import React,{useEffect,useState} from 'react';
import axios from 'axios';
import styledComponents  from 'styled-components';
import { Footer } from '../footer/Footer';
import { TopBar } from '../Index/TopBar/TopBar';
import { TopBarList } from '../Index/TopBar/TopBarList';
import { ConnectionStatus } from '../internet-connection/ConnectionStatus';
import { VideoLoadingList } from '../loading/VideoLoadingList';
import { Helmet } from 'react-helmet';
import { VRLiveCam } from './VRLiveCam';

export const VRLivePage = () => {

  // const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Free Live VR Sex Cams, Live Sex and Free Sex Chat with Naked Girls, Watch Live VR Sex Cams with Quest 3, Quest 2, Apple Vision Pro, Oculus Go, and PlayStation VR | Xcelebs Live VR`);
  const [pageDescription, setPageDescription] = useState(`Watch Naked Girls in our Adult Live VR Sex Cams Community. ❤️ No Registration Needed. 500+ LIVE VR Cam Girls and Couples are Ready to Chat.`);

    const [lives,setLives] = useState([]);

    const [loading,setLoading] = useState(true);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const [isShow,setIsShow]=useState(false);

      useEffect(() => {

        const handleOnline = () => {
          setIsOnline(true);
        };
    
        const handleOffline = () => {
          setIsOnline(false);
        };
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
        };

      }, []);

      useEffect(() => {

        const fetchData = async () => {
          
          const live_api=`https://bss.dreamcamtrue.com/api/clients/v1/broadcasts?partnerId=vrporn&limit=84&offset=0&show-offline=true&stream-types=video2D,video3D&include-tags=false&include-tip-menu=false`;

          setLoading(true);
      
          if (!isOnline)
            return;
      
          try {
            const response = await axios.get(`${live_api}`);
            setLives(response.data.pageItems);
            setLoading(false);
          } catch (error) {
            console.log(error);
            setLoading(false);
          }
        };
      
        fetchData();
      }, [isOnline]);
      

      useEffect(()=>{


        var check = document.getElementById('checkMenu');
        check.checked = false;
  
        window.scrollTo(0, 0);
        const title = `Free Live VR Sex Cams, Live Sex and Free Sex Chat with Naked Girls, Watch Live VR Sex Cams with Quest 3, Quest 2, Apple Vision Pro, Oculus Go, and PlayStation VR | Xcelebs Live VR`;
        const desc = `Watch Naked Girls in our Adult Live VR Sex Cams Community. ❤️ No Registration Needed. 500+ LIVE VR Cam Girls and Couples are Ready to Chat.`;

        setPageTitle(title);
        setPageDescription(desc);
        document.title = title;
        document.body.style.zoom="100%";
       
      },[]);


      const changeCheckHandler=(flag)=>{
        setIsShow(flag);
        var check = document.getElementById('checkMenu');
        check.checked = flag;
    }

  return (
      <LiveComponent>
         <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        {/* <link rel="canonical" href={canonicalUrl} /> */}
      </Helmet>
      <div className="container-fluid">
          <div className="row">

          <input type="checkbox" id="checkMenu"/>

          <div className='top-bar'>
       <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
       </div>

       <div className='top-bar-list'>
                             <TopBarList/>
                             </div>

          <div className="col-12 col-lg-12 col-sm-12 col-md-12 mx-auto live-title text-center">
              <h1 className='title'>Live <span className='vr-cam'>VR</span> Sex Cams</h1>
              <h6 className='sub-title px-2 px-md-4'>Cam girls now live in VR. Have fun with real people right next to you. Chat for free, send tips, get into a private chat with any girl you like. 100% real.</h6>
            </div>


            <div className="col-12 col-lg-12 col-sm-12 col-md-12 mx-auto lives px-4 d-none d-lg-block">
              <div className="row">
                {
                 (!loading && lives) && lives.map(item=><VRLiveCam key={item.username} item={item}/>)
                }
             
              </div>
           </div> {/*lives*/}

           <div className="col-12 col-lg-12 col-sm-12 col-md-12 mx-auto lives px-3 d-lg-none">
              <div className="row">
                {
                 (!loading && lives) && lives.map(item=><VRLiveCam key={item.username} item={item}/>)
                }
              </div>
           </div> {/*lives-sm*/}

           {
        (loading) && <VideoLoadingList/>
          }

           <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div mt-5'>
                  <Footer/>
       </div>

          </div>
      </div>

      <ConnectionStatus/>

      </LiveComponent>
  )
}

const LiveComponent=styledComponents.div`

background: var(--lightBlack);

.live-title .title{
    color: var(--mainWhite);
    font-size: 1.6rem;
    font-family: 'Ubuntu', sans-serif;
    margin: 1rem 0 1rem 0;

    .vr-cam{
      color: var(--lightOrange);
    }
  }

  .live-title .sub-title{
    color: var(--mainWhite);
    font-size: 0.8rem;
    font-family: 'Open Sans', sans-serif; 
    margin-bottom: 2rem;
  }

  .lives .live{
    position:relative;
    padding:0 !important;
    margin:0 !important;
    transform: scale(0.97);
    cursor:pointer;
  }

  @media (min-width: 1024px) {
    .live-title .title{
      font-size: 1.8rem;
    }
    .live-title .sub-title{
      font-size: 1rem;
    }
  }
  
  
  .live .card{
    background: transparent;
  }
  
  .live .status{
    position: absolute;
    top:-0.5%;
  
    .now{
      background: var(--lightRed);
      padding: 0.1rem 0.4rem 0.1rem 0.4rem;
      font-family: 'Karla', sans-serif;
      color: var(--mainWhite);
      border-radius:0.2rem;
      font-size:0.8rem;
    }
  }
  
  .live .name-flag{
    position: absolute;
    top:85%;
  
    .name{
      color: var(--mainWhite);
      font-family: 'Karla', sans-serif;
      cursor:pointer;
    }
  
    .age{
      color: var(--mainWhite);
      font-family: 'Karla', sans-serif;
      background: rgba(0,0,0,0.7);
      padding: 0.1rem 0.2rem 0rem 0.2rem;
      font-size:0.8rem;
      cursor:pointer;
    }
  
  }

  .select-cam{
    padding:0 !important;
   // margin:0 !important;
  }
  
  .type{

    width: auto;
    //background: var(--lightDark2);
    background-color: #272727;
    padding:0.2rem 1.3rem;
    transform: scale(0.8);
    cursor:pointer;
    display:inline-block;
    border-radius:0.3rem;
    transition: 0.5s all;
    //  margin: 0.3rem 1.2rem;
     text-align: center;
  
  }

  .selected-type{
    background: var(--lightGreen);
  }
  
  .type .type-name{
   // color: var(--mainWhite);
    color: #fff;
    //font-family: 'Open Sans', sans-serif; 
    font-family: 'Karla', sans-serif;
    //font-family: 'Josefin Sans', sans-serif;
    font-weight:bold;
    font-size:1rem;
  }

  #checkMenu{
    display:none;
  }

  @media (min-width: 1024px) {
    .top-bar {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  .top-bar{
    // padding:0 !important;
    // margin:0 !important;
  }

  .top-bar-list{
    padding:0 !important;
    margin:0 !important;
    overflow: hidden;
    position:relative;
    max-height: 0;
    transition: max-height 0.5s ease;
  }
  
  #checkMenu:checked ~ .top-bar-list{
    max-height: 300px;
    transition: max-height 0.5s ease;
  }

  .footer-div{
    padding:0 !important;
  //  margin:0 !important;
  }

  .hoverable-image {
    position: relative;
  }
  
  .hoverable-image::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 28px; /* Adjust the height as needed */
    background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for transparency */
  }

`;