import styledComponents from "styled-components";

export const Signup=styledComponents.button`

font-size:1rem;
padding: 0.4rem 2rem;
// font-family: 'Karla', sans-serif;
background: var(--lightOrange);
color: var(--mainWhite) !important;
border-radius:0.3rem;
transition: 0.5s all;
border:none;
//text-transform: capitalize;
font-family: 'Merriweather', serif;

&:hover{
    //font-weight:bold;
    color: var(--lightOrange) !important;
    background: var(--mainWhite);
    transition: 0.5s all;
}

`;