import axios from 'axios';
import React from 'react'
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import styledComponents from 'styled-components'
import { TagList } from './TagList';

export const Header = ({stream}) => {

    const navigate = useNavigate();

    // const anime = (stream.tags && stream.tags.split(',')[0].toLowerCase()=='anime') ? true : false;

    const anime = ((stream.celebName && stream.celebName.toLowerCase()=='hentai') || (stream.celebName && stream.celebName.toLowerCase()=='amateur') || (stream.celebName && stream.celebName.toLowerCase()=='fashion')) ? true : false;

   // const title_1 = (stream.title) && stream.title.split("in")[0].substring(stream.celebName.length);
   
   var title_1 = (stream.title && stream.celebName) && stream.title.substring(stream.celebName.length);

    if(stream.filmName && stream.celebType && stream.celebType.toLowerCase()==='celeb')
         title_1 = (title_1) && title_1.substring(0,(title_1.length-stream.filmName.length)); 
     

    var tags=[];

    var tag_list =(stream.tags) && stream.tags.replaceAll(".",",").split(",");

    if(tag_list!==undefined){
        for(var i=0;i<tag_list.length;i++)
          tags[i] = tag_list[i];
    }else if(stream.tags)
       tags[0] = stream.tags;


    const getCeleb = async (name, type) => {
        try {
          const celeb_url = process.env.REACT_APP_CELEB;
          const response = await axios.get(`${celeb_url}?name=${encodeURIComponent(name)}`);
          viewCeleb(response.data.celebId, response.data.celebName, type);
          // setIsLoading(false);
        } catch (error) {
          // setIsLoading(false);
          console.log(error);
        }
      };
      

   const viewCeleb=(id,name,type)=>{
    return (id && name && type && type.toLowerCase()==='celeb') ? navigate(`/view/celeb/${id}/${name.toLowerCase().replaceAll(/[-\s]+/g, '-')}`) :
    navigate(`/view/pornstar/${id}/${name.toLowerCase().replaceAll(/[-\s]+/g, '-')}`);
   } 
   
   const viewFilm=(name)=>{
    return (name) && `/view/movie/${encodeURIComponent(name.toLowerCase().replaceAll(/[-\s]+/g, '-'))}`;
   }

  return (
      <HeaderContainer>
                         <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto header mt-3 mb-2'>
                             
                              { (stream.celebName && title_1 && !anime) && <h1 className='title text-start'><span className='star text-capitalize' onClick={()=>getCeleb(stream.celebName,stream.celebType)}>{stream.celebName}</span>{title_1} { (stream.filmName) && <React.Fragment> <Link className='film' to={viewFilm(stream.filmName)}>{stream.filmName}</Link> </React.Fragment> }</h1> }
                             
                              { (stream.title && anime) && <h1 className='title text-start'>{stream.title}</h1> }
                             
                              { (stream.celebName_2) && <h3 className='title-sub'>Other celeb in this video: <span className='star-sub text-capitalize' onClick={()=>getCeleb(stream.celebName_2,stream.celebType)}>{stream.celebName_2}</span> { (stream.celebName_3) && <React.Fragment><span> and </span><span className='star-sub text-capitalize' onClick={()=>getCeleb(stream.celebName_3,stream.celebType)}>{stream.celebName_3}</span> </React.Fragment>}.</h3>}
                         </div>
                         <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto tag-header mb-2'>

                              {/* {
                                 <div className='row'>
                                     { (tags) && tags.map(t=> (t.length<=10) && <Tag name={t}/>)}
                                  </div>   
                             } */}

                             { (tags) && <TagList tags={tags}/>}

                          </div>
                  
      </HeaderContainer>
  )
}

const HeaderContainer=styledComponents.div`

background: var(--lightBlack);

.title{
    color: var(--mainWhite);
    font-weight:bold;
    // font-family: 'Karla', sans-serif;
    font-family: 'Open Sans', sans-serif; 
    font-size:1.45rem;
    letter-spacing:0.03em;
 
    .star{
        color: var(--lightOrange);
        cursor:pointer;
        transition: 0.5s all;

        &:hover{
            text-decoration: underline;
            transition: 0.5s all;
        }
    }
    
    .film{
        color: var(--lightOrange);
        cursor:pointer;
        transition: 0.5s all;
        text-decoration:none;

        &:hover{
            text-decoration: underline;
            transition: 0.5s all;
        }
    }
 }

 @media (min-width: 900px) {
    .title{
        font-size:1.55rem;
    }
  }
  

 .title-sub{
    color: var(--mainWhite);
    //  font-family: 'Karla', sans-serif;
    font-family: 'Open Sans', sans-serif; 
    font-weight:bold;
    font-size:0.86rem;
    letter-spacing:0.04em;

     .star-sub{
        color: var(--lightOrange);
        cursor:pointer;
        transition: 0.5s all;

        &:hover{
            text-decoration: underline;
            transition: 0.5s all;
        }
    }
 }

 @media (min-width: 900px) {
    .title-sub{
        font-size:0.95rem;
    }
  }

 .tag{
    color: var(--mainWhite);
    border-radius:0.3rem;
    font-family: 'Karla', sans-serif;
    background: linear-gradient(0deg,#343434,#4b4b4b);
    font-size:0.9rem;
    transition: 0.5s all;

    &:hover{
       cursor:pointer;
       transition: 0.5s all;
       background:  var(--lightOrange);
    }
 }

 .tag-div{
     padding:0 !important;
     margin:0 !important;
 }

 .header{
    padding:0 !important;
    //  margin:0 !important;
 }

 .tag-header{
     padding:0 !important;
   //  margin:0 !important;
     
 }

`;
