import styledComponents from "styled-components";


export const Category=styledComponents.button`

font-size:1rem;
padding:  ${props=>props.large ? '0.4rem 1.5rem' : '0.4rem 1.1rem'};
font-family: 'Merriweather', serif;
background: var(--lightDark2);
color: var(--mainWhite) !important;
border-radius:0.3rem;
transition: 0.5s all;
border:none;

&:hover{
    background: var(--lightOrange);
   // font-weight:bold;
    transition: 0.5s all;
}

`;