import React from 'react'
import styledComponents from 'styled-components'

export default function Title() {
  return (
     <TitleContainer>
        <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 main-title text-center">
             <h2 className='title'>Most Popular Celebs & Videos</h2>
        </div>
     </TitleContainer>
  )
}

const TitleContainer=styledComponents.div`

.main-title{
   .title{
       color: var(--mainWhite);
       font-size:2rem;
       font-family: 'Josefin Sans', sans-serif;
       font-weight:bold;
   }
}

`;