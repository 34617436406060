import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';

export const Celebs = ({cast}) => {

      // const navigate = useNavigate();

      const redirectToCeleb=(id,name)=>{
            return (id && name) && `/view/celeb/${id}/${name.toLowerCase().replaceAll(/[-\s]+/g, '-')}`;
      }

  return (

     <React.Fragment>

<Link to={redirectToCeleb(cast.celebId,cast.celebName)} className="col-6 col-lg-2 col-md-3 col-sm-4 mx-auto item"> 

           {/* <div className="col-6 col-lg-2 col-md-3 col-sm-4 mx-auto item"> */}

           <div className="card">

           <div className="img-container">
           <img src={cast.imgUrl} alt={`${cast.celebName} Nude`} title={`${cast.celebName} Nude`} className="card-img-top" style={{width:'100%',height:'17rem'}} loading="lazy"/> 
           </div>

           <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 views-img text-end">

           <div className="view d-none d-lg-block">
      {  (cast.imgUrl) && <span className='total-views-img'> <span>{cast.views}K</span> <FontAwesomeIcon className="fa-views icon" icon={faEye}/></span> }
            </div>

            <div className="view-sm d-lg-none">
      {  (cast.imgUrl) && <span className='total-views-img'> <span>{cast.views}K</span> <FontAwesomeIcon className="fa-views icon" icon={faEye}/></span> }
            </div>

            </div>

           </div> {/*card*/}

             <div className="card-footer">
                {(cast.celebName) && <h4 className='cast-name text-center px-2 text-uppercase'>{cast.celebName}</h4> }
            </div>

           {/* </div> */}

           </Link> 

     </React.Fragment>
  )
}
