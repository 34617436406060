import React,{useEffect,useState} from 'react'
import styledComponents from 'styled-components'
import { Alphabat } from '../../category/Alphabat';
import { RecommendedList } from '../../Detail/RecommendedList';
import { TopBar } from '../../Index/TopBar/TopBar';
import { Tags } from '../../stars/Tags';
import { Footer } from '../Footer';
import { DMCA } from './DMCA';
import { Alph } from '../../test/Alph';
import { TopBarList } from 'src/components/Index/TopBar/TopBarList';
import { ConnectionStatus } from 'src/components/internet-connection/ConnectionStatus';
import { Helmet } from 'react-helmet';

export const DMCAPage = () => {

  // const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`DMCA Policy - Xcelebs`);
  const [pageDescription, setPageDescription] = useState(`At Xcelebs, we are committed to upholding the principles of copyright protection as outlined in the Digital Millennium Copyright Act (DMCA). This page provides information on how we handle copyright complaints, respect intellectual property rights, and promptly respond to valid DMCA notifications.`);

  const [isShow,setIsShow]=useState(false);

  useEffect(()=>{
    var check = document.getElementById('checkMenu');
    check.checked = false;
    window.scrollTo(0, 0);
    const title='DMCA Policy - Xcelebs';
    const desc ='At Xcelebs, we are committed to upholding the principles of copyright protection as outlined in the Digital Millennium Copyright Act (DMCA). This page provides information on how we handle copyright complaints, respect intellectual property rights, and promptly respond to valid DMCA notifications.';
   
    setPageTitle(title);
    setPageDescription(desc);
    document.title = title;
    document.body.style.zoom="100%";
},[]);

const changeCheckHandler=(flag)=>{
  setIsShow(flag);
  var check = document.getElementById('checkMenu');
  check.checked = flag;
}

  return (
     <Container>
        <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        {/* <link rel="canonical" href={canonicalUrl} /> */}
      </Helmet>
          <div className='container-fluid'>
            <div className='row'>

            <input type="checkbox" id="checkMenu"/>

            <div className='top-bar'>
            <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
                   </div>

                   <div className='top-bar-list'>
                             <TopBarList/>
                             </div>

                   <div className='alph-header mb-2 mt-1 d-none d-md-block'>
                          <Alph/>
                     </div>

                     <div className='alph-header mb-2 mt-1 d-md-none'>
                         <Alphabat/>
                     </div>
            
                <div className='col-12 col-md-11 col-lg-10 col-sm-12 mx-auto policy-div my-3'>
                        <DMCA/>
                 </div>

                 <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto recommended-div mt-5'>
                              <RecommendedList celebName={'Emily Blunt'}/>
                          </div>
                          
                          <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto trending-tags mb-5'>
                            <Tags/>
                          </div>
                          
                          <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                        <Footer/>
                         </div>      
          </div>
          </div>

         <ConnectionStatus/>

     </Container>
  )
}

const Container = styledComponents.div`

background: var(--lightBlack);

@media (min-width: 1024px) {
  .top-bar {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
    // padding:0 !important;
    // margin:0 !important;
  } 

  .recommended-div{
    padding:0 !important;
    //margin:0 !important;
   }
   
//    .trending-tags{
//      padding:0 !important;
//      margin:0 !important;
//    }
   
   .footer-div{
     padding:0 !important;
     margin:0 !important;
   }

   #checkMenu{
    display:none;
  }
  
  .top-bar-list{
    padding:0 !important;
    margin:0 !important;
    overflow: hidden;
    position:relative;
    max-height: 0;
    transition: max-height 0.5s ease;
  }
  
  #checkMenu:checked ~ .top-bar-list{
    max-height: 300px;
    transition: max-height 0.5s ease;
  }


`;