import React from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Tag = ({name,more,setMoreHandler}) => {

  // const navigate = useNavigate();

  const redirectToTag=(tag)=>{
    return (tag) && `/browse/tags/videos/${tag.replaceAll(" ","-")}`;
  }
  return (
    //  <div className='col-3 col-md-2 col-sm-3 col-lg-1 text-center text-capitalize mx-auto tag-div' onClick={()=>redirectToTag(name)}>
    //         <label className='tag py-1 px-2 my-1'>{name}</label>
    //  </div>
    <React.Fragment>
       { (name!=="more") && <Link className="tag text-capitalize" to={redirectToTag(name)}>{name}</Link> }
       { (!more && name==="more") && <label className="tag text-capitalize" onClick={()=>setMoreHandler(true)}><FontAwesomeIcon className='fa-plus icon' icon={faPlus}/></label>}
    </React.Fragment>
  )
}
