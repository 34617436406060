import axios from 'axios';
import React, { useState,useEffect } from 'react'
import styledComponents from 'styled-components'
import { Title } from '../Detail/Title';
import { ImgLoading } from '../loading/ImgLoading';
import { Film } from '../stars/Film';

export const RecommendedMovieList = ({filmName}) => {

    const [movies,setMovies] = useState([]);
    const [loading,setLoading]=useState(true);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {

        const handleOnline = () => {
          setIsOnline(true);
        };
    
        const handleOffline = () => {
          setIsOnline(false);
        };
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
        };

      }, []);

     useEffect(() => {

        const fetchData = async () => {

          // const token = from local storage
      
          const recommend_url = process.env.REACT_APP_FILM;
      
          setLoading(true);
      
          if (!isOnline)
            return;

          const name = (filmName) && filmName.replaceAll("-", " ");
      
          try {
            const response = await axios.get(`${recommend_url}/recommend?name=${encodeURIComponent(name)}`, {
              headers: {
                // 'Authorization':'bearer '.concat(token)
              }
            });
            setMovies(response.data);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            console.log(error);
          }
        };
      
        fetchData();
      }, [isOnline,filmName]);
      

  return (
     <RecommendListContainer>
          <div className="container-fluid">
            <div className="row">

            <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 title">
                <Title title='RECOMMENDED MOVIES'/>
            </div>

           { (!loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 item-list px-4 d-md-none d-lg-block">

            <div className="row">
            { (movies) && movies.map((film)=><Film key={film.filmId} film={film}/>)}
             </div>

            </div> }

            { (!loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 item-list px-4 d-none d-md-block d-lg-none">

            <div className="row">
            { (movies) && movies.slice(0,4).map((film)=><Film key={film.filmId} film={film}/>)}
             </div>

            </div> }

            {
                (loading) && <ImgLoading/>
            }


            </div>
            </div>
     </RecommendListContainer>
  )
}

const RecommendListContainer=styledComponents.div`

background: var(--lightBlack);

.item-list .item{
    position:relative;
    padding:0 !important;
    margin:0 !important;
    transform: scale(0.77);
    cursor:pointer;
    transition:0.5s all;
    text-decoration:none;

    &:hover{
        .cast-name{
        color: var(--lightOrange);
        transition:0.5s all;
        }
        .img-container{
            border: 3px solid var(--lightOrange);
            transition:0.5s all;
        }
    }
}

.img-container{
    cursor:pointer;
    border: 3px solid transparent;
    transition:0.5s all;

    &:hover{
        border: 3px solid var(--lightOrange);
        transition:0.5s all;
    }
}

.card{
    background: transparent;
  }
 

.cast-name{
    // font-family: 'Radio Canada', sans-serif;
    font-family: 'Montserrat', sans-serif;
    color: var(--mainWhite);
    font-weight:bold;
    font-size:1.25rem;
    // letter-spacing:0.02em;
    margin: 0.8rem 0;
}


`;
