import React from 'react';
import { useState } from 'react';
import styledComponents from 'styled-components';
import {Item} from './Item';


export const TopBarList = () => {

    const [lgTopList,setLgTopList]=useState<string[]>(['Romantic','Live Sex','Nude','Bikini','Brunette','Butt','MILF','Fashion','Underwear','Sexy','Other']);
    const [smTopList,setSmTopList]=useState<string[]>(['Romantic','Live Sex','Nude','Bikini','Butt','MILF','Fashion','Other']);

  return (
      <TopBarListContainer>
    <div className="container-fluid mb-1">
          <div className="row">
              <div className="item-list text-center d-none d-lg-block">
                    {
                      lgTopList.map((item,index)=><Item key={index} item={item}/>)
                    }
              </div>
              <div className="item-list text-center d-lg-none">
                    {
                      smTopList.map((item,index)=><Item key={index} item={item}/>)
                    }
              </div>
          </div>
    </div>
    </TopBarListContainer>
  )
}

const TopBarListContainer=styledComponents.div`

background: #333;


.item-list .item{
  width: auto;
  padding: 0.1rem 0.5rem;
  color: var(--mainWhite);
  font-family: 'Karla', sans-serif;
  margin: 0.3rem 0.3rem;
  font-size:0.9rem;
  text-align: center;
  display:inline-block;
  //border-radius:0.3rem;
  //font-weight:bold;
  cursor:pointer;
  transition: 0.5s all;
  border-bottom: 3px solid transparent;
  text-decoration:none;

  &:hover{
     
    cursor:pointer;
    transition: 0.5s all;
    color:  var(--lightOrange);
    border-bottom: 3px solid var(--lightOrange);
 }

}

.fa-circle {
  color: #ffa400;
  font-size: 0.5rem;
  margin-bottom: 0.1rem;
  // margin-right: 0.1rem;
  border-radius: 50%;
  // border: 1px solid transparent; 
  animation: pulse 1.2s infinite alternate;
  transition: background-color 0.5s ease, border-color 0.5s ease; /* Add border-color transition */
}

@keyframes pulse {
  0% {
      transform: scale(1);
      background-color: #ffcc00;
      border-color: transparent; /* Transparent border at the start */
      opacity: 1;
  }
  100% {
      transform: scale(0.8);
      background-color: #ffcc00;
      border-color: #f2f2f2; /* Light border color */
      opacity: 0;
  }
}

`;