import React from 'react'
import styledComponents from 'styled-components'
import { Film } from '../stars/Film';

export const MovieResults = ({movies}) => {

  return (
     <MovieResultContainer>
          
                  <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto celeb-header text-center mt-2'>
                               <h4 className='c-header'>Movie Results</h4>
                   </div>

                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto item-list'>
                   <div className='row'>
                   { (movies) && movies.map((film)=><Film key={film.filmId} film={film}/>)}
                  </div>
                   </div>
                
     </MovieResultContainer>
  )
}

const MovieResultContainer=styledComponents.div`

.c-header{
    color: var(--mainWhite);
    font-weight:bold;
    font-family: 'Karla', sans-serif;
    font-size:1.8rem;
}

.item-list .item{
    position:relative;
    padding:0 !important;
    margin:0 !important;
    transform: scale(0.77);
    cursor:pointer;
    transition:0.5s all;
    text-decoration:none;

    &:hover{
        .cast-name{
        color: var(--lightOrange);
        transition:0.5s all;
        }
        .img-container{
            border: 3px solid var(--lightOrange);
            transition:0.5s all;
        }
    }
}

.img-container{
    cursor:pointer;
    border: 3px solid transparent;
    transition:0.5s all;

    &:hover{
        border: 3px solid var(--lightOrange);
        transition:0.5s all;
    }
}

.card{
    background: transparent;
  }
 

.cast-name{
    // font-family: 'Radio Canada', sans-serif;
    font-family: 'Montserrat', sans-serif;
    color: var(--mainWhite);
    font-weight:bold;
    font-size:1.25rem;
    letter-spacing:0.02em;
    margin: 0.8rem 0;
}


`;