import React, { useState,useEffect } from 'react'
import styledComponents from 'styled-components'
import axios from 'axios';
import { Pagination } from '../Detail/Pagination';
import { ImageLoading } from '../loading/ImageLoading';
import { ImageSm } from '../common/ImageSm';
import { ImgModal } from '../test/ImgModal';

export const FilmImgList = ({filmName}) => {

    const [images,setImages] = useState([])
    const [pages,setPages]=useState(0);
    const [currentPage,setCurrentPage]=useState(1);
    const [currentBtn,setCurrentBtn]=useState(1);
  
    const itemPerPage=30;
  
    const [activeIndex,setActiveIndex]=useState(0);
    const [imgModal,setImgModal]=useState(false);
    const [loading,setLoading]=useState(true);
    const [imgId,setImgId]=useState('');

    const [isOnline, setIsOnline] = useState(navigator.onLine);
  
    const closeImgModal=(flag)=>{
      setImgModal(flag);
    }
  
    const viewImage=(index,id)=>{
        setActiveIndex(index);
        closeImgModal(true);
        setImgId(id);
    }
  
    useEffect(() => {

      const handleOnline = () => {
        setIsOnline(true);
      };
  
      const handleOffline = () => {
        setIsOnline(false);
      };
  
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
  
      return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
      };

    }, []);
  
  useEffect(()=>{
    setCurrentBtn(1);
  },[filmName]);
  
  const currentPageHandler=page=>{
  setCurrentPage(page);
  }
  
  useEffect(()=>{
  // document.title=`Search and Download Free ${getCtg(ctg.toLowerCase())} Movies with Subtitle- Voyage Movies`;
  document.body.style.zoom="100%";
  // setPageLink(`/movie/list${ctg}`);
  setCurrentPage(1);
  // setSearch(false);
  },[filmName]);
  
  useEffect(() => {

    const fetchData = async () => {

      if (isNaN(currentBtn))  
        return;   
      
      window.scrollTo(0, 0);
    
      setLoading(true);
  
      if (!isOnline)
        return;
  
      const img_url = process.env.REACT_APP_CELEB_IMAGE;
  
      const name = (filmName) && filmName.replaceAll("-", " ");
    
      try {
        const response = await axios.get(`${img_url}/film-list?name=${name}&page=${currentPage}`);
        setImages(response.data.celebImageList);
        setPageHandler(response.data.numOfItems);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
  
    fetchData();
  }, [currentBtn,filmName,currentPage,isOnline]);
  
  
  const setPageHandler =length=>{
   
    const numOfItems=length;     
    let page = parseInt(numOfItems/itemPerPage);
  
    if(parseInt(numOfItems%30)!==0)
     page+=1;
  
     setPages(page);
   };

   
  return (
    <FilmImgListContainer>
        
                     { (!loading) &&  <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 img-list">
                     <div className="row">
                    {
                     (images) && images.map((img,index)=> <ImageSm item={img} index={index} viewImageHandler={viewImage}/>)
                    }

                        </div>
                      </div> }

                      {
                        (loading) && <ImageLoading/>
                      }
                      

{ (images && images.length>=1 && !loading) &&  <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 pages mt-3">

<Pagination pages={pages} currentPageHandler={currentPageHandler}
       currentBtn={currentBtn} setCurrentBtn={setCurrentBtn}/> 
                  
                    </div>  }    
                     
        {
          (imgModal) && <ImgModal images={images} a_index={activeIndex} closeImgModalHandler={closeImgModal} currentImgId={imgId}/>
        }
    </FilmImgListContainer>
  )
}

const FilmImgListContainer=styledComponents.div`


background: var(--lightBlack);
position: relative;

.card{
  background: transparent;
}

.pics{
  transform: scale(1);
  background: #403d3d47;
}

.pics-sm{
  transform: scale(0.95);
  background: #403d3d47;
  border-radius:0.4rem;
}

@media (max-width: 692px) {
  .pics-sm{
    transform: scale(0.96);
  }
}

.img-list .image-sm{
  padding:0 !important;
  margin:0 !important;
}

.img-list .image{
  
  width: auto;
  padding: 0.2rem 0.4rem;
  background: #403d3d47;
  margin: 0.3rem 0.3rem;
  text-align: center;
  display:inline-block;
  border-radius:0.3rem;
  
}

.img-div{

  border: 3px solid transparent;
  cursor:pointer;
  transition: 0.5s all;
  height:14rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
  border: 3px solid var(--lightOrange);
  transition: 0.5s all;
  }

}

.img-sm-div{

  border: 3px solid transparent;
  cursor:pointer;
  transition: 0.5s all;
  //max-height:11rem;
  height:11rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
  border: 3px solid var(--lightOrange);
  transition: 0.5s all;
  }

}

.img-div img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.img-sm-div img{
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

`;