import React, { useEffect, useState, useRef } from 'react'
import styledComponents from 'styled-components'
import {SearchBar} from './SearchBar';
import {Category} from '../CtgButton';
import { Signup } from './Signup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons';
import { SearchBarSm } from './SearchBarSm';
import { Link, useNavigate } from 'react-router-dom';
import { AuthModal } from '../../user-auth/AuthModal';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';


export const TopBar = ({isShow,changeCheckHandler}) => {

  const authDivRef = useRef(null);

  const urlParams = new URLSearchParams(window.location.search);
  const searchParam = urlParams.get('q');

  const search_key = (searchParam) ? searchParam : '';

      const navigate=useNavigate();

      const [openSignup,setOpenSignup]=useState(false);
      const [searchClicked,setSearchClicked]=useState(false);
      const [name,setName]=useState('');

      const redirectToHome=()=>{
        return navigate('/');
      }

      const redirectToSignup=()=>{
        return navigate('/signup');
      }

      const redirectToLogin=()=>{
        return navigate('/login');
      }

      const openSignupHandler=()=>{
        setOpenSignup(!openSignup);
      }

      const serachClickedHandler=(flag)=>{
        setSearchClicked(flag);
      }

      useEffect(()=>{
           if(search_key)
          setName(search_key);
      },[search_key]);

      const handleOutsideClick = (event) => {
        if (authDivRef.current && !authDivRef.current.contains(event.target)) {
          setOpenSignup(false);
        }
      };
    
    
      useEffect(() => {
        
        // Attach event listener when the component mounts
        window.addEventListener('click', handleOutsideClick);
    
        // Detach event listener when the component unmounts
        return () => {
          window.removeEventListener('click', handleOutsideClick);
        };
      }, []);

  return (
   
    <TopbarContainer>
          <div className='container-fluid my-1'>
                      <div className='row'>
                            <div className='col-xl-12 col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-bar d-flex'>

                            <div className='col-4 col-md-2 col-lg-2 col-xl-2 col-sm-2 mx-auto logo text-center my-2'>
                               <p className='site pe-1'>
                               {/* <img src='https://i.ibb.co/0Kf3vFx/xcelebs.png' alt="logo" width={'100rem'} height={'40rem'} className="pe-2"/> */}
                                   <Link to={'/'} className='x text-uppercase'>x</Link><Link to={'/'} className='c'>celebs<span className='org'>.org</span></Link>
                                </p>
                            </div> {/*logo*/}

                            <div className='col-8 col-md-10 col-lg-10 col-xl-7 col-sm-10 mx-auto search-ctg d-flex my-2'>

                            <div className='col-0 col-md-8 col-lg-8 col-xl-9 col-sm-7 mx-auto search text-start my-1 d-none d-sm-block'>
                            <SearchBar serachClickedHandler={serachClickedHandler} searchClicked={searchClicked} name={name} setName={setName}/>
                            </div>

                            <div className='col-lg-3 col-xl-3 mx-auto ctg my-1 text-start d-none d-lg-block'>
                                <Category large onClick={()=>changeCheckHandler(!isShow)}>Categories <FontAwesomeIcon className="fa-angle icon px-2" icon={faAngleDown}/></Category>
                           </div>

                            <div className='col-10 col-md-3 col-sm-4 mx-auto ctg my-1 d-lg-none text-end'>
                                <Category onClick={()=>changeCheckHandler(!isShow)}>Categories <FontAwesomeIcon className="fa-angle icon px-2" icon={faAngleDown}/></Category>
                           </div>

                           <div ref={authDivRef} className='col-2 col-md-1 col-lg-1 col-sm-1 mx-auto bars d-xl-none ps-3'>
                             <FontAwesomeIcon className="fa-bars" icon={faBars} onClick={()=>openSignupHandler()}/>
                           </div>  
                                
                            </div> {/*search-ctg*/}

                            <div className='col-xl-3 mx-auto user-login d-flex my-2'>

                            <div className='col-xl-6 mx-auto signup text-end px-2 my-1 d-none d-xl-block'>
                             <Signup onClick={()=>redirectToSignup()}>Sign up</Signup>
                             </div>   

                             <div className='col-xl-6 mx-auto login my-1 d-none d-xl-block'>
                             <Signup onClick={()=>redirectToLogin()}>Log in</Signup>
                             </div>   

                           {/* <div className='bars my-2 ps-1'>
                             <FontAwesomeIcon className="fa-bars icon d-xl-none" icon={faBars} onClick={()=>openSignupHandler()}/>
                           </div>   */}


                            </div> {/*user-login*/}

                            </div> {/*top-bar*/}

                            <div className='col-12 mx-auto top-search d-sm-none'>
                             <SearchBarSm  serachClickedHandler={serachClickedHandler} searchClicked={searchClicked} name={name} setName={setName}/>
                            </div>

                            {/* <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-ctg d-xl-none d-sm-block d-none text-center'>
                            <Category onClick={()=>changeCheckHandler(!isShow)}>Categories</Category>
                             </div> */}

                            
                            <div className='bar-div d-xl-none'>
                            { (openSignup) && <AuthModal redirectToSignup={redirectToSignup} redirectToLogin={redirectToLogin}/> }
                             </div> 
                            
                      </div>
          </div>
    </TopbarContainer>


  )
}

const TopbarContainer=styledComponents.div`

position:relative;
background: var(--lightBlack);


.top-bar{
    margin:0;
    padding:0;
}

// .ctg{
//      transform: scale(0.9);
// }

.logo{
    // color: var(--mainWhite);
    font-size: 1.5rem;
   // font-family: 'Oswald', sans-serif;
   font-family: 'Roboto', sans-serif;
    position:relative;

    .x{
        color: var(--lightOrange);
       // letter-spacing:0.1rem;
       font-size: 1.7rem;
       font-weight:bold;
       cursor:pointer;
       text-decoration:none;
    }

    .c{
       color: var(--mainWhite);
        font-weight:bold;
        cursor:pointer;
        text-decoration:none;
    }

    .org{
        font-size: 0.8rem;
        color: var(--lightGrey);
        cursor:pointer;
        text-decoration:none;
    }
}

// .search-ctg{
//     border:1px solid red;
// }

.user-login{
    //border:1px solid red;
}

.bars{
  margin-top:0.75rem;
  color: var(--mainWhite);
  cursor:pointer;
  transition:0.3s all;

  &:hover{
    color: var(--lightGrey);
    transition:0.3s all;
  }
}



//.top-ctg{
  //position:absolute;
  //top:4rem;
 // z-index:-1111;
//}

.icon{
  color: var(--mainWhite) !important;
  font-size:1rem;
  font-weight: bold;
}

.fa-bars{
  color: var(--mainWhite) !important;
  font-size:1.3rem;
  font-weight: bold;
}

`;