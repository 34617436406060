import React, { useState } from 'react'
import styledComponents from 'styled-components'
import { Recommend } from '../common/Recommend';
import { Footer } from '../footer/Footer';
import { TopBar } from '../Index/TopBar/TopBar';
import { Alphabat } from './Alphabat';
import Header from './Header';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import {TagLoadingList} from '../loading/TagLoadingList';
import { Alph } from '../test/Alph';
import { Tags } from '../stars/Tags';
import { TopBarList } from '../Index/TopBar/TopBarList';
import { LiveBoard } from '../live/LiveBoard';
import { RecommendedList } from '../common/RecommendedList';
import { CtgAds } from '../ads/CtgAds';
import { ConnectionStatus } from '../internet-connection/ConnectionStatus';
import { Helmet } from 'react-helmet';

export const Categories = () => {

  // const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Browse Nude Celeb Video and Image Tags - Xcelebs`);
  const [pageDescription, setPageDescription] = useState(`Browse Nude Celeb Video and Image Tags on Xcelebs ... TAGS. RACE. 5 video. 15 images. TITLE. 16 videos. 25 images. TV. 331 videos. 896 images ...`);

    // const navigate = useNavigate();

    const [tags,setTags]=useState([]);
    const [loading,setLoading]=useState(true);
    const [isShow,setIsShow]=useState(false);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {

      const handleOnline = () => {
        setIsOnline(true);
      };
  
      const handleOffline = () => {
        setIsOnline(false);
      };
  
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
  
      return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
      };

    }, []);


    useEffect(()=>{

      window.scrollTo(0, 0);

      const title=`Browse Nude Celeb Video and Image Tags - Xcelebs`;

      const description = `Browse Nude Celeb Video and Image Tags on Xcelebs ... TAGS. RACE. 5 video. 15 images. TITLE. 16 videos. 25 images. TV. 331 videos. 896 images ...`;

      setPageTitle(title);
      setPageDescription(description);

      document.title = title;
      document.body.style.zoom="100%";
     
    },[]);

    const changeCheckHandler=(flag)=>{
      setIsShow(flag);
      var check = document.getElementById('checkMenu');
      check.checked = flag;
  }

  useEffect(() => {

    const fetchData = async () => {
        try {

            // const token = from local storage

            const tag_url = process.env.REACT_APP_TAG;

            setLoading(true);

            if (!isOnline)
                return;

            const response = await axios.get(`${tag_url}/all`, {
                headers: {
                    // 'Authorization':'bearer '.concat(token)
                }
            });

            setTags(response.data.tags);
            setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
    };

    fetchData();

}, [isOnline]);

     
    
//     const tags=[{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898}
// ,{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},
// {id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},
// {id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898}
// ,{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},
// {id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},
// {id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898}
// ,{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},
// {id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898},{id:'',tag:'STRIPPING',videos:23,images:898}];

const redirectToTag=(tag)=>{
     return (tag) && `/browse/tags/videos/${tag.toLowerCase().replaceAll(' ','-')}`;
}

  return (
     <CategoryContainer>
       <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        {/* <link rel="canonical" href={canonicalUrl} /> */}
      </Helmet>
               <div className='container-fluid'>
                        <div className='row'>

                        <input type="checkbox" id="checkMenu"/>

                            <div className='top-bar'>
                            <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
                            </div>

                            <div className='top-bar-list'>
                             <TopBarList/>
                             </div>


                            <div className='alph-header mb-4 mt-1 d-none d-md-block'>
                          <Alph/>
                            </div>

                     <div className='alph-header mb-4 mt-1 d-md-none'>
                         <Alphabat/>
                     </div>

                            <div className='header-tag'>
                                <Header/>
                          </div>
 
                      <CtgAds/>

                            { (!loading) && <div className='col-12 col-md-12 col-sm-12 col-lg-12 mx-auto catgs px-4 px-xl-5'>
                                <div className='row'>
                                    { (tags) && tags.map((tag,index)=>{
                                    return (<React.Fragment key={index}>
                                <div className='col-6 col-md-3 col-sm-6 col-lg-3 mx-auto ctg my-1'>
                                    <Link className='name text-start text-uppercase' to={redirectToTag(tag.tagName)}>{tag.tagName}</Link>

                                    <div className='col-12 col-md-12 col-sm-12 col-lg-12 mx-auto video-img d-flex'>

                                    <div className='col-6 col-md-6 col-sm-6 col-lg-6 mx-auto videos text-center'>
                                         <span className='video'>{tag.numOfVideos} videos</span>
                                    </div>    

                                    <div className='col-6 col-md-6 col-sm-6 col-lg-6 mx-auto imgs text-center'>
                                         <span className='img'>{tag.numOfImages} photos</span>
                                    </div>    
                                         
                                     </div>   
                                    
                                </div>
                                   </React.Fragment>);    })
                                   }
                                </div>
                           </div> }

                           {
                             (loading) && <TagLoadingList/>
                           }

                           <div className='recommended-div mt-5'>
                           {/* <Recommend/> */}
                          <RecommendedList celebName={'Kelly Brook'}/>
                           </div>

                           <div className='live-div'>
                                   <LiveBoard/>
                         </div>

                           <div className='tags mb-5'>
                           <Tags/>
                           </div>

                           <div className='footer-div'>
                             <Footer/>
                           </div>
                        </div>
               </div>

                 <ConnectionStatus/>

     </CategoryContainer>
  )
}

const CategoryContainer=styledComponents.div`

background: var(--lightBlack);

.ctg .name{
    color: var(--mainWhite);
    // font-family: 'Josefin Sans', sans-serif;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.02rem;
    font-weight:bold;
    font-size:0.8rem;
    transition: 0.5s all;
    cursor:pointer;
    text-decoration:none;
    &:hover{
        color: var(--lightOrange);
        text-decoration: underline;
    }
}

.catgs .ctg{
  // padding:0 !important;
   margin:0.3rem 0 !important;
}

.video-img{
    
    .video{
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif; 
        font-size:0.6rem;
        opacity:0.8;
    }
    .img{
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif; 
        font-size:0.6rem;
        opacity:0.8;
    }
}

@media (min-width: 1024px) {
  .top-bar {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
    // padding:0 !important;
    // margin:0 !important;
}

.footer-div{
    padding:0 !important;
  //  margin:0 !important;
}


.recommended-div{
  padding:0 !important;
 // margin:0 !important;
}

#checkMenu{
  display:none;
}

.top-bar-list{
  padding:0 !important;
  margin:0 !important;
  overflow: hidden;
  position:relative;
  max-height: 0;
  transition: max-height 0.5s ease;
}

#checkMenu:checked ~ .top-bar-list{
  max-height: 300px;
  transition: max-height 0.5s ease;
}



`;