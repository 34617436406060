import React,{useEffect} from 'react'
import styledComponents from 'styled-components'
import { useState } from 'react';
import axios from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';

export const Bio = ({filmName}) => {

       const [film,setFilm]=useState({});

       const [isOnline, setIsOnline] = useState(navigator.onLine);
       const [loading,setLoading]=useState(true);

       useEffect(() => {

        const handleOnline = () => {
          setIsOnline(true);
        };
    
        const handleOffline = () => {
          setIsOnline(false);
        };
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
        };

      }, []);

      
           useEffect(() => {

            const fetchData = async () => {

              const film_url = process.env.REACT_APP_FILM;
          
              setLoading(true);
          
              const name = filmName.replaceAll("-", " ");
          
              try {
                const response = await axios.get(`${film_url}?name=${name}`);
                setFilm(response.data);
                setLoading(false);
              } catch (error) {
                setLoading(false);
                setFilm({
                  filmId: 'N0057',
                  name: filmName.replaceAll("-", " "),
                  imgUrl: 'https://i.ibb.co/QmFF2M2/movie.png',
                  desc: `We don't have an overview of ${filmName.replaceAll("-", " ")}`
                });
                console.log(error);
              }
            };
          
            fetchData();
          }, [filmName,isOnline]);
          

  return (
    <BioContainer>
        
                     <div className='col-12 col-md-12 col-sm-12 col-lg-12 mx-auto bio d-flex my-3'>

                     <div className='col-3 col-md-3 col-sm-3 col-lg-3 mx-auto profile text-end'>

              <div className='img-container d-none d-md-block py-3'>
       { (!loading && film.imgUrl) && <img src={film.imgUrl} alt={`${film.name} Nude Scenes - Xcelebs`} title={`${film.name} Nude Scenes - Xcelebs`} className="img" style={{width:'45%',height:'10rem'}} loading="lazy"/>  }
           </div>

           <div className='img-container d-md-none px-1 py-3'>
         { (!loading && film.imgUrl) && <img src={film.imgUrl} alt={`${film.name} Nude Scenes - Xcelebs`} title={`${film.name} Nude Scenes - Xcelebs`} className="img" style={{width:'100%',height:'10rem'}} loading="lazy"/> }
           </div>

                         </div> {/*profile*/}

                         <div className='col-9 col-md-9 col-sm-9 col-lg-9 mx-auto info py-3'>

                          {  (!loading && film.name) && <h1 className='d-none d-md-block'><label className='name ps-5 text-capitalize'>{film.name}</label> <label className='tag px-2'>#nude</label></h1> }

                          {  (!loading && film.name) &&  <h1 className='d-md-none'><label className='name-sm px-2 text-capitalize'>{film.name}</label> <label className='tag-sm px-2'>#nude</label></h1> }

                            { (loading) && <Skeleton variant="rect" width={500} height={18} className="skeleton d-none d-lg-block"/>}
                            { (loading) && <Skeleton variant="rect" width={400} height={17} className="skeleton my-3 d-none d-lg-block"/>}
                            { (loading) && <Skeleton variant="rect" width={300} height={16} className="skeleton d-none d-lg-block"/>}

                            { (loading) && <Skeleton variant="rect" width={230} height={16} className="skeleton d-lg-none"/>}
                            { (loading) && <Skeleton variant="rect" width={200} height={15} className="skeleton my-3 d-lg-none"/>}

                              <p className='detail px-4 d-none d-md-block'>
                             { (!loading && film.desc) && film.desc}
                              </p>

                              <p className='detail-sm px-2 d-md-none'>
                              { (!loading && film.desc) && film.desc}
                              </p>

                        </div> {/*info*/}

                     </div>
             
    </BioContainer>
  )
}

const BioContainer=styledComponents.div`

background: var(--lightBlack);


.bio{
    background: var(--darkBlue);
    border:1px solid var(--lightGrey);
    border-radius:0.2rem;
    padding:0 !important;
  //  margin:0 !important;
}

.info .name{
    color: var(--mainWhite);
    // font-family: 'Karla', sans-serif;
    font-family: 'Open Sans', sans-serif; 
    font-weight:bold;
    font-size:1.65rem;
    letter-spacing:0.04em;
}

.info .name-sm{
    color: var(--mainWhite);
    // font-family: 'Karla', sans-serif;
    font-family: 'Open Sans', sans-serif; 
    font-weight:bold;
    font-size:1.3rem;
    letter-spacing:0.04em;
}

.info .tag{
    color: var(--lightOrange);
    font-family: 'Josefin Sans', sans-serif;
    font-size:1.7rem;
}

.info .tag-sm{
    color: var(--lightOrange);
    font-family: 'Josefin Sans', sans-serif;
    font-size:1.4rem;
}

.info .detail{
    color: var(--lightGrey);
    font-family: 'Open Sans', sans-serif; 
    font-size:0.9rem;
}

.info .detail-sm{
    color: var(--lightGrey);
    font-family: 'Open Sans', sans-serif; 
    font-size:0.8rem;
}

.skeleton{
  background: rgb(73, 73, 73);
  border-radius:0.4rem;
}

`;
