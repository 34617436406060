import React from 'react'
import styledComponents from 'styled-components';
import { Tag } from './Tag';

export const TagLoadingList = ()=> {

  const tags=[1,2,3,4,5,6,7,8];

  return (
      <LoadContainer>
         <div className="col-12 col-md-12 col-lg-12 col-sm-12 load-list my-3 px-2">

        <div className="row mb-3">

             {tags.map((tag,index)=><Tag key={index}/>)}

             </div>
             <div className="row">

{tags.map((tag,index)=><Tag key={index}/>)}

          </div>
</div>
    </LoadContainer>
  )
}


const LoadContainer=styledComponents.div`

background: var(--lightBlack);

.tag-load-div{
  background: var(--lightDark2);
  padding:0.5rem 0;
  transform: scale(0.9);
  cursor:pointer;
  border-radius:0.3rem;
}


`;