import axios from 'axios';
import React, { useState,useEffect } from 'react'
import styledComponents from 'styled-components'
import { Title } from '../Detail/Title';
import { ImgLoading } from '../loading/ImgLoading';
import { Celeb } from '../stars/Celeb';

export const RecommendedList = ({celebName}) => {

    const [celebs,setCelebs] = useState([]);
    const [loading,setLoading]=useState(false);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {

        const handleOnline = () => {
          setIsOnline(true);
        };
    
        const handleOffline = () => {
          setIsOnline(false);
        };
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
        };

      }, []);

    
    useEffect(()=>{

        // const token = from local storage
    
         const recommend_url=process.env.REACT_APP_CELEB;

         setLoading(true);

         if(!isOnline)
         return;
    
         axios.get(`${recommend_url}/celeb/recommend/${celebName}`,{
             headers:{
             //    'Authorization':'bearer '.concat(token)
               }
         }).then(res=>{
             setCelebs(res.data);
             setLoading(false);
         }).catch(error=>{
            setLoading(false);
             console.log(error);
         });
         
     },[isOnline,celebName]);

  return (
     <RecommendListContainer>
          <div className="container-fluid">
            <div className="row">

            <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 title">
                <Title title='RECOMMENDED CELEBRITIES'/>
            </div>
            
           { (!loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 item-list">

            <div className="row">
            { (celebs) && celebs.map((celeb)=><Celeb key={celeb.castId} cast={celeb}/>)}
             </div>

            </div> }

            {
                (loading) && <ImgLoading/>
            }


            </div>
            </div>
     </RecommendListContainer>
  )
}

const RecommendListContainer=styledComponents.div`

background: var(--lightBlack);

.item-list .item{
    position:relative;
    padding:0 !important;
    margin:0 !important;
    transform: scale(0.77);
    cursor:pointer;
    transition:0.5s all;
    text-decoration:none;

    &:hover{
        .cast-name{
        color: var(--lightOrange);
        transition:0.5s all;
        }
        .img-container{
            border: 3px solid var(--lightOrange);
            transition:0.5s all;
        }
    }
}

.img-container{
    cursor:pointer;
    border: 3px solid transparent;
    transition:0.5s all;

    &:hover{
        border: 3px solid var(--lightOrange);
        transition:0.5s all;
    }
}

.card{
    background: transparent;
  }
 

.cast-name{
  // font-family: 'Radio Canada', sans-serif;
  font-family: 'Montserrat', sans-serif;
  color: var(--mainWhite);
  font-weight:bold;
  font-size:1.1rem;
  margin: 0.8rem 0;
}


`;
