import React from 'react';
import { Button } from '../Button';
import { ButtonSm } from '../ButtonSm';
import {isMobile} from 'react-device-detect';
import {isBrowser} from 'react-device-detect';
import axios from 'axios';

export const Description = () => {

  const live_sex_cams = 'https://go.rmhfrtnd.com?campaignId=xcelebs-live-sign-up&sourceId=xcelebs.org&path=%2Fsignup%2Fuser&userId=11d347350c44824625b893d1b5e6f2eeef888388b45ff2095b02b8dfc212bf84';
  const sex_dating = 'https://t.affenhance.com/226438/4299/15217';

  const redirectToLiveSex=(link)=>{

    axios.get('https://ipapi.co/json/')
    .then((response) => {
      const data = response.data;
      const country = data.country_name;

      trackAffiliateOffer('CHL101','LIVE-SEX-PPS','PPS','stripchat',link,country);
      return (link) && window.open(link);
    })
    .catch((error) => {
      console.error('Error fetching country:', error);

      trackAffiliateOffer('CHL101','LIVE-SEX-PPS','PPS','stripchat',link,'');
      return (link) && window.open(link);
    });

  }

  const redirectToDating=(link)=>{

    axios.get('https://ipapi.co/json/')
    .then((response) => {
      const data = response.data;
      const country = data.country_name;

      trackAffiliateOffer('CRD102','SEX-DATING','PPL','crakrevenue',link,country);
      return (link) && window.open(link);
    })
    .catch((error) => {
      console.error('Error fetching country:', error);

      trackAffiliateOffer('CRD102','SEX-DATING','PPL','crakrevenue',link,'');
      return (link) && window.open(link);
    });

  }


  const trackAffiliateOffer= async (id,name,type,provider,link,country)=>{

    const res = axios.get(`https://hutils.loxal.net/whois`);
    const ip = (await res).data.ip;

    let device="undetected";

    if (isMobile)
     device="mobile";

    if (isBrowser)
     device="desktop";
    

    const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
      affiliateLink:link,deviceType:device,ipAddress:ip,country:country};

      const save_url=process.env.REACT_APP_AFFILIATE;

      axios.post(`${save_url}`,detail,{
          headers:{
              'Content-Type':'application/json',
           //   'Authorization':'bearer '.concat(token)
          }
      });

  }

  return (
     <React.Fragment>
         <div className='container'>
              <div className='row'>
              <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 desc px-3">
                  
                  <h1 className='header text-uppercase d-none d-lg-block'>ANNOUNCING <label className='x'>X</label>celebs platform</h1>
                  <h1 className='header-md text-uppercase d-none d-lg-none d-md-block'>ANNOUNCING <label className='x'>X</label>celebs platform</h1>
                  <h1 className='header-sm text-uppercase d-md-none d-sm-block'>ANNOUNCING <label className='x'>X</label>celebs platform</h1>

                  <p className='des my-3 d-none d-md-block'>
                    Xcelebs is a Celebrity Platform that provides access to Nude Celebrity Videos and Photos,<br/> Sex Dating, Live Sex Cams, and the ability to Chat with Models. 
                  </p>

                  <p className='des-sm my-2 d-md-none'>
                  Xcelebs is a Celebrity Platform that provides access to Nude Celebrity Videos and Photos, Sex Dating, Live Sex Cams, and the ability to Chat with Models. 
                  </p>

              </div>

              <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 live-dating">

              <div className="cus-btn live">

               <Button className='d-none d-md-block' onClick={()=>redirectToLiveSex(live_sex_cams)}>Live Sex Cams</Button>
              
               <ButtonSm className='d-md-none' onClick={()=>redirectToLiveSex(live_sex_cams)}>Live Sex Cams</ButtonSm>
             
              </div> {/*live*/}

              <div className="cus-btn dating">

              <Button className='d-none d-md-block' onClick={()=>redirectToDating(sex_dating)}>Sex Dating</Button>
              
              <ButtonSm className='d-md-none' onClick={()=>redirectToDating(sex_dating)}>Sex Dating</ButtonSm>

              </div>  {/*dating*/}

              </div>  {/*live-dating*/}

              </div>
         </div>
     </React.Fragment>
  )
}

