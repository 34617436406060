import axios from 'axios';
import React,{useState} from 'react'
import { useEffect } from 'react';
import styledComponents from 'styled-components'
import { Alphabat } from '../category/Alphabat';
import { Footer } from '../footer/Footer';
import { TopBar } from '../Index/TopBar/TopBar';
import { VideoLoadingList } from '../loading/VideoLoadingList';
import { Tags } from '../stars/Tags';
import { VideoResults } from './VideoResults';
import { Alph } from '../test/Alph';
import { TopBarList } from '../Index/TopBar/TopBarList';
import { LiveBoard } from '../live/LiveBoard';
import { ConnectionStatus } from '../internet-connection/ConnectionStatus';
import { Helmet } from 'react-helmet';

export const TopCelebVideoList = () => {

  // const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Top 100 Nude Celeb Videos of 2023 - Xcelebs`);
  const [pageDescription, setPageDescription] = useState(`Watch Top 100 Nude Celeb Videos of 2023 on Xcelebs`);

    const [videos,setVideos]=useState([]);
    const [loading,setLoading]=useState(true);
    const [isShow,setIsShow]=useState(false);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {

      const handleOnline = () => {
        setIsOnline(true);
      };
  
      const handleOffline = () => {
        setIsOnline(false);
      };
  
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
  
      return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
      };

    }, []);

    useEffect(()=>{

      var check = document.getElementById('checkMenu');
      check.checked = false;

      window.scrollTo(0, 0);
      const title=`Top 100 Nude Celeb Videos of 2023 - Xcelebs`;
      const desc = `Watch Top 100 Nude Celeb Videos of 2023 on Xcelebs`;

      setPageTitle(title);
      setPageDescription(desc);
      document.title = title;
      document.body.style.zoom="100%";
     
    },[]);

    const changeCheckHandler=(flag)=>{
      setIsShow(flag);
      var check = document.getElementById('checkMenu');
      check.checked = flag;
  }
   

    useEffect(() => {

      const fetchData = async () => {

        const video_url = process.env.REACT_APP_CELEB_VIDEO;

        setLoading(true);
    
        if (!isOnline)
          return;
    
        try {
          const response = await axios.get(`${video_url}/top-videos`);
          setVideos(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      };
    
      fetchData();
    }, [isOnline]);
    

  return (
     <TopCelebContainer>
        <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        {/* <link rel="canonical" href={canonicalUrl} /> */}
      </Helmet>
          <div className='container-fluid'>
             <div className='row'>

             <input type="checkbox" id="checkMenu"/>

             <div className='top-bar'>
             <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
             </div>

             <div className='top-bar-list'>
                             <TopBarList/>
                             </div>
                             

             <div className='alph-header mb-2 mt-1 d-none d-md-block'>
                          <Alph/>
                     </div>

                     <div className='alph-header mb-2 mt-1 d-md-none'>
                         <Alphabat/>
                     </div>

                     <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto celeb-header text-center mt-4 mb-3'>
                               <h1 className='c-header'>TOP 100 VIDEOS <span className='high'>OF 2023</span></h1>
                   </div>

                   { (!loading) && <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto video-div mb-4'>
                           { (videos && videos.length>=1) && <VideoResults videos={videos}/> }
                   </div> }
                   {
                     (loading) && <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto video-div mb-4'> <VideoLoadingList/> </div>
                   }

<div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto live-div'>
                <LiveBoard/>
            </div>

            <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto trending-tags mb-5'>
               <Tags/>
             </div>
             
             <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                        <Footer/>
             </div>

             </div>
             </div>

          <ConnectionStatus/>

     </TopCelebContainer>
  )
}


const TopCelebContainer=styledComponents.div`

background: var(--lightBlack);


.video-div{
  //padding:0 !important;
 // margin:0 !important;
} 

@media (min-width: 1024px) {
  .top-bar {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
    // padding:0 !important;
    // margin:0 !important;
  }

  .trending-tags{
    // padding:0 !important;
    // margin:0 !important;
  }
  
  .footer-div{
    padding:0 !important;
    margin:0 !important;
  }

#checkMenu{
  display:none;
}

.top-bar-list{
  padding:0 !important;
  margin:0 !important;
  overflow: hidden;
  position:relative;
  max-height: 0;
  transition: max-height 0.5s ease;
}

#checkMenu:checked ~ .top-bar-list{
  max-height: 300px;
  transition: max-height 0.5s ease;
}

.c-header{
  color: var(--mainWhite);
  font-weight:bold;
  font-family: 'Karla', sans-serif;
  font-size:2.2rem;

  .high{
    color: var(--lightOrange);
  }
}

`;