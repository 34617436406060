import React,{useState,useEffect} from 'react';
import styledComponents from 'styled-components';
import { Footer } from '../footer/Footer';
import { TopBar } from '../Index/TopBar/TopBar';
import { TopBarList } from '../Index/TopBar/TopBarList';
import { ZoomImgList } from './ZoomImgList';
import axios from 'axios';
import { CircularLoading } from '../loading/CircularLoading';
import { Helmet } from 'react-helmet';
import { ImageRemovedMessage } from '../common/ImageRemovedMessage';

export const ZoomInImage = () => {

  const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Nude, Naked, Topless, Breasts, Tits, Sex, Sexy, Butt, Boobs, Bush, Photos - Xcelebs`);
  const [pageDescription, setPageDescription] = useState(`Nude, Naked, Topless, Breasts, Tits, Sex, Sexy, Butt, Boobs, Bush, Photos at Xcelebs`);

  const id = (window.location.pathname.length>3) && window.location.pathname.split("/")[4];

  const [isShow,setIsShow]=useState(false);
  const [item,setItem]=useState({imgId:'',imgUrl:'',celebName:'',filmName:''});
  const [loading,setLoading]=useState(false);
  const [imgId,setImgId] = useState(id);
  const [photos,setPhotos]= useState([]);
  const [imageRemoved,setImageRemoved] = useState(false);

    useEffect(()=>{
        
        var check = document.getElementById('checkMenu');
        check.checked = false;

    },[]);

    useEffect(()=>{

      const title=`${(imgId && item.celebName) && item.celebName} Nude, Naked, Topless, Breasts, Tits, Sex, Sexy, Butt, Boobs, Bush, Photos - ${imgId} - Xcelebs`;
      const desc=`${(imgId && item.celebName) && item.celebName} Nude, Naked, Topless, Breasts, Tits, Sex, Sexy, Butt, Boobs, Bush, Photos at Xcelebs - ${imgId}`;
  
      setPageTitle(title);
      setPageDescription(desc);
      document.title = title;
      document.body.style.zoom="100%";
  
     },[imgId,item.celebName]);

   useEffect(() => {

    const fetchData = async () => {

      window.scrollTo(0, 0);
  
      const selectedImage = photos.find(image => image.imgId === id);
  
      if (selectedImage) {
        setItem({
          imgId: selectedImage.imgId,
          imgUrl: selectedImage.imgUrl,
          celebName: selectedImage.celebName,
          filmName: selectedImage
        });
        return;
      }
  
      setLoading(true);
  
      const img_url = process.env.REACT_APP_CELEB_IMAGE;
  
      try {
        const response = await axios.get(`${img_url}/${imgId}`);
        setPhotos([...photos, response.data]);
        setItem({
          imgId: response.data.imgId,
          imgUrl: response.data.imgUrl,
          celebName: response.data.celebName,
          filmName: response.data.filmName
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        setImageRemoved(true);
      }
      };
  
    fetchData();
  }, [imgId]);
  

   useEffect(() => {
    // Preload all images when the component mounts
    if (photos.length > 0) {
      photos.forEach((photo) => {
        const img = new Image();
        img.src = photo.imgUrl;
      });
    }
  }, [photos]);

    const changeCheckHandler=(flag)=>{
        setIsShow(flag);
        var check = document.getElementById('checkMenu');
        check.checked = flag;
    }


  return (
      <ZoomContainer>
        <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
           <div className='container-fluid'>
        <div className='row'>

        <input type="checkbox" id="checkMenu"/>

<div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-bar'>
           <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
</div>

<div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-bar-list'>
    <TopBarList/>
</div>

           <div className="image-modal">
         { (!loading && !imageRemoved && item.imgUrl) && <img
            key={item.imgId}
            src={item.imgUrl}
            alt={(item.celebName) && `${item.celebName} Nude Picture - ${(item.imgId) && item.imgId}`}
            title={(item.celebName) && `${item.celebName} Nude Picture - ${(item.imgId) && item.imgId}`}
            className="full-size-image"
            data-orig={item.imgUrl}
            loading="lazy"
          /> }
          </div>

          {
                            (loading) && <div className='c-loading my-5'> <CircularLoading/>  </div>
                 }

                 {
                   (imageRemoved) && <ImageRemovedMessage/>
                 }

          <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto celeb-img-div my-4'>
               { (item.celebName) && <ZoomImgList celebName={item.celebName} setImgIdHandler={setImgId}/> }
            </div>

          <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                <Footer/>
             </div> {/*footer*/}

          </div>

          </div>
        </ZoomContainer>
  )
}

const ZoomContainer=styledComponents.div`

background: var(--lightBlack);

#checkMenu{
  display:none;
}

@media (min-width: 1024px) {
  .top-bar {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
  // padding:0 !important;
  // margin:0 !important;
}

.image-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--lightBlack);
  }
  
  .full-size-image {
    max-width: 100%; 
    height: auto;
    margin: auto;
  }

  .top-bar-list{
    padding:0 !important;
    margin:0 !important;
    overflow: hidden;
    position:relative;
    max-height: 0;
    transition: max-height 0.5s ease;
  }
  
  #checkMenu:checked ~ .top-bar-list{
    max-height: 300px;
    transition: max-height 0.5s ease;
  }

  .footer-div{
    padding:0 !important;
    margin:0 !important;
  }

`;