import React from 'react';
import styledComponents from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const CircularLoading = ()  => {
  return (
     <CircularContainer>
         <div className="container">
            <div className="row">
                 <div className="col-12 col-md-12 col-sm-12 col-lg-12 mx-auto text-center load">
                 <CircularProgress className="load-icon"/>
                 </div>
            </div>
        </div>
     </CircularContainer>
  )
}

const CircularContainer=styledComponents.div`

//background: var(--lightBlack);

.load-icon{
    color: var(--mainWhite);
    position: relative;
   // top:800%;
}


`;
