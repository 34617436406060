import React, { useEffect } from 'react'
import styledComponents from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import ReactCountryFlag from "react-country-flag";
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useState } from 'react';
import axios from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';
import { faFireAlt} from '@fortawesome/free-solid-svg-icons';

export const Bio = ({celebId,celebName}) => {

    const[celeb,setCeleb]=useState({});

    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [loading,setLoading]=useState(true);

    const redirectToInsta=(link)=>{
        return window.open(link);
    } 

    useEffect(() => {

        const handleOnline = () => {
          setIsOnline(true);
        };
    
        const handleOffline = () => {
          setIsOnline(false);
        };
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
        };

      }, []);

    useEffect(() => {

        const fetchData = async () => {
          const celeb_url = process.env.REACT_APP_CELEB;
      
          setLoading(true);
      
          try {
            const response = await axios.get(`${celeb_url}/id/${celebId}`);
            setCeleb(response.data);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            setCeleb({
              celebId: 'N0056',
              celebName: celebName.replaceAll("-", " "),
              desc: `We don't have a biography for ${celebName.replaceAll("-", " ")}.`,
              country: '',
              age: 0,
              views: 0.0,
              img_url: 'https://i.ibb.co/0BXMM2d/celebs.png',
              insta_id: ''
            });
            console.log(error);
          }
        };
      
        fetchData();
      }, [celebId,celebName,isOnline]);
      

    const redirectToExclusive=(link)=>{
        return (link) && window.open(link);
      }


  return (
    <BioContainer>
       
                     <div className='col-12 col-md-12 col-sm-12 col-lg-12 mx-auto bio d-flex my-3'>

                     <div className='col-3 col-md-3 col-sm-3 col-lg-3 mx-auto profile text-end'>

              <div className='img-container d-none d-md-block py-3'>
       { (!loading && celeb.img_url) && <img src={celeb.img_url} alt={`${celeb.celebName} Nude - Xcelebs`} title={`${celeb.celebName} Nude - Xcelebs`} className="img" style={{width:'45%',height:'10rem'}} loading="lazy"/> }

           </div>

           <div className='img-container d-md-none px-1 py-3'>
         { (!loading && celeb.img_url) && <img src={celeb.img_url}  alt={`${celeb.celebName} Nude - Xcelebs`} title={`${celeb.celebName} Nude - Xcelebs`} className="img" style={{width:'100%',height:'10rem'}} loading="lazy"/> }

           </div>

                         </div> {/*profile*/}

                         <div className='col-9 col-md-9 col-sm-9 col-lg-9 mx-auto info py-3'>

                           { (!loading && celeb.celebName) && <h1 className='d-none d-md-block'><label className='name ps-5 text-capitalize'>{celeb.celebName}</label> <label className='tag px-2'>#nude</label></h1> }

                           { (!loading && celeb.celebName) && <h1 className='d-md-none'><label className='name-sm ps-2 text-capitalize'>{celeb.celebName}</label> <label className='tag-sm px-2'>#nude</label></h1> }

                            { (loading) && <Skeleton variant="rect" width={500} height={18} className="skeleton d-none d-lg-block"/>}
                            { (loading) && <Skeleton variant="rect" width={400} height={17} className="skeleton my-3 d-none d-lg-block"/>}
                            { (loading) && <Skeleton variant="rect" width={300} height={16} className="skeleton d-none d-lg-block"/>}

                            { (loading) && <Skeleton variant="rect" width={230} height={16} className="skeleton d-lg-none"/>}
                            { (loading) && <Skeleton variant="rect" width={200} height={15} className="skeleton my-3 d-lg-none"/>}

                              <p className='detail px-4 d-none d-md-block'>
                             {(!loading && celeb.desc) && celeb.desc}
                              </p>

                              <p className='detail-sm px-2 d-md-none'>
                              {(!loading && celeb.desc) && celeb.desc}
                              </p>

                              <div className='col-12 col-md-12 col-sm-12 col-lg-12 mx-auto personal d-flex'>

                              <div className='col-3 col-md-4 col-sm-3 col-lg-4 mx-auto country text-center'>
                             {/* { (!loading && celeb.country) &&  <span><FontAwesomeIcon className="fa-globe icon" icon={faGlobe}/> <span className='region text-uppercase'>{celeb.country}</span></span> } */}
                             {  (!loading && celeb.country) && <span className='flag-img'>  <ReactCountryFlag
     countryCode={celeb.country}
     svg
     style={{
         width: '2em',
         height: '1em',
     }}
     title={celeb.country}
 />
 </span> }
                             </div> {/*country*/}

                             <div className='col-4 col-md-4 col-sm-4 col-lg-4 mx-auto age text-center'>
                                { (!loading && celeb.age) && <React.Fragment> <span className='born'>Age:</span> <span className='years'>{celeb.age} years</span> </React.Fragment> }
                            </div> {/*age*/}

                            <div className='col-5 col-md-4 col-sm-5 col-lg-4 mx-auto insta text-center'>
                            { (!loading && celeb.insta_id) && <span className='insta_id' onClick={()=>redirectToInsta(celeb.insta_id)}><FontAwesomeIcon className="fa-instagram icon" icon={faInstagram}/> <span className='instagram'>Instagram</span></span> }
                             </div> {/*insta*/}
                                     
                              </div>

                            { (!loading && celeb.exclusiveUrl) && <div className="exclusive text-center">
                <span onClick={()=>redirectToExclusive(celeb.exclusiveUrl)} className="content">{`${celeb.celebName}`} {`${(celeb.exclusiveUrl.includes("onlyfans")) ? ` OnlyFans ` : ' Exclusive '}`} <FontAwesomeIcon className="fa-fire icon" icon={faFireAlt}/></span>
                         </div> }

                        </div> {/*info*/}

                     </div> {/*bio*/}

              
    </BioContainer>
  )
}

const BioContainer=styledComponents.div`

background: var(--lightBlack);


.bio{
    background: var(--darkBlue);
    border:1px solid var(--lightGrey);
    border-radius:0.2rem;
    padding:0 !important;
  //  margin:0 !important;
}

.info .name{
    color: var(--mainWhite);
    // font-family: 'Karla', sans-serif;
    font-family: 'Open Sans', sans-serif; 
    font-weight:bold;
    font-size:1.65rem;
    letter-spacing:0.04em;
}

.info .name-sm{
    color: var(--mainWhite);
    // font-family: 'Karla', sans-serif;
    font-family: 'Open Sans', sans-serif; 
    font-weight:bold;
    font-size:1.3rem;
    letter-spacing:0.04em;
}

.info .tag{
    color: var(--lightOrange);
    font-family: 'Josefin Sans', sans-serif;
    font-size:1.7rem;
}

.info .tag-sm{
    color: var(--lightOrange);
    font-family: 'Josefin Sans', sans-serif;
    font-size:1.4rem;
}

.info .detail{
    color: var(--lightGrey);
    font-family: 'Open Sans', sans-serif; 
    font-size:0.9rem;
}

.info .detail-sm{
    color: var(--lightGrey);
    font-family: 'Open Sans', sans-serif; 
    font-size:0.8rem;
}

.personal{

    font-family: 'Open Sans', sans-serif; 
    position: relative;
    top:-0.4rem;

    .country{
       .region{
           color: var(--lightGrey);
           font-size:0.8rem;
       }
       .fa-globe{
        color: var(--lightBlue);
        font-size:0.9rem;
       }
    }

    .age{
        .born{
            color: var(--mainWhite);
            font-size:0.9rem;
        }
        .years{
            color: var(--lightGrey);
            font-size:0.8rem;
        }
    }

    .insta_id{
        cursor:pointer;
    }

    .insta{
        .instagram{
            color: var(--lightGrey);
            font-size:0.8rem;
            transition: 0.5s all;
        }
        .fa-instagram{
            color: var(--lightBlue);
            font-size:0.9rem; 
            transition: 0.5s all;
        }

        &:hover{

          .instagram{
               color: var(--mainWhite);
            }

            .fa-instagram{
               color: var(--lightBlue3);
            }

          }
    }
}

.skeleton{
    background: rgb(73, 73, 73);
    border-radius:0.4rem;
}



.exclusive .content{
   position: relative;
   top:-0.15rem;
   color: var(--mainWhite);
   text-decoration:none;
   font-family: 'Karla', sans-serif;
   font-size:0.8rem;
   cursor:pointer;

   .icon{
    color: var(--lightOrange);
    margin-left:0.1rem;
   }
}

`;
