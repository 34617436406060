import React from 'react';
import { Item } from './LiveBoard';
import axios from 'axios';
import {isMobile,isBrowser} from 'react-device-detect';

type ItemProps={
  item:Item;
}

export const Live = ({item}:ItemProps) => {


  const openLiveSex = async (url: string) => {
    
    try {
      const response = await axios.get('https://ipapi.co/json/');
      const data = response.data;
      const country = data.country_name;
  
      await trackAffiliateOffer('CHL103', 'LIVE-SEX-RevShare', 'RevShare', 'chaturbate', url, country);

      return (url) && window.open(url);
  
    } catch (error) {
      console.error('Error fetching country:', error);
  
      await trackAffiliateOffer('CHL103', 'LIVE-SEX-RevShare', 'RevShare', 'chaturbate', url, '');
  
      return (url) && window.open(url);
    }
  };
  

  const trackAffiliateOffer= async (id:string,name:string,type:string,provider:string,link:string,country:string)=>{

    const res = axios.get(`https://hutils.loxal.net/whois`);
    const ip = (await res).data.ip;
  
    let device="undetected";
  
    if (isMobile)
     device="mobile";
  
    if (isBrowser)
     device="desktop";
    
  
    const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
      affiliateLink:link,deviceType:device,ipAddress:ip,country:country};
  
      const save_url=process.env.REACT_APP_AFFILIATE;
  
      axios.post(`${save_url}`,detail,{
          headers:{
              'Content-Type':'application/json',
           //   'Authorization':'bearer '.concat(token)
          }
      });
  
  }

  return (
    <React.Fragment>
    <div className="col-6 col-lg-2 col-sm-2 col-md-3 mx-auto live" onClick={()=>openLiveSex(item.chat_room_url_revshare)}>

    <div className="card">

    <div className="img-container hoverable-image">
   { (item.image_url) && <img src={item.image_url} alt={`${item.display_name} - Live Sex Cams | Chaturbate`} className="card-img-top" style={{width:'100%',height:'11rem'}}/> }
      </div>

      <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 status text-start px-1">
       <span className='now'>Live</span>
       </div>

       <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 name-flag d-flex px-2">

       <div className="col-8 mx-auto col-md-8 col-lg-8 col-sm-8 name-div text-start">
         { (item.display_name && item.display_name.length<=14) && <label className='name'>{item.display_name.substring(0,14)}</label> }
         { (item.display_name && item.display_name.length>14) && <label className='name'>{`${item.display_name.substring(0,14)}...`}</label> }
       </div>

       <div className="col-4 mx-auto col-md-4 col-lg-4 col-sm-4 flag-div text-end">
       {/* <img src={item.country_flag} alt={item.name} className="card-img-top" style={{width:'25%',height:'1rem'}}/>  */}
        { (item.age) && <label className='age'>{item.age}</label> }
       </div>

       </div>
          
      </div>
    </div>
    </React.Fragment>
  )
}