import styledComponents from "styled-components";

export const RefreshButton=styledComponents.button`

border:none;
border-radius:0.3rem;
padding: 0.4rem 1.5rem;
font-size:1rem;
outline:none;
background: var(--lightBlue2);
color: var(--mainWhite);
border: 1px solid var(--mainWhite);
cursor:pointer;
transition: 1s all;
font-family: 'Karla', sans-serif;
text-transform: capitalize;

&:hover{
    background: var(--lightBlue);
    //color: var(--lightOrange);
    transition: 1s all;
}

`;