import axios from 'axios';
import React, { useState,useEffect } from 'react'
import styledComponents from 'styled-components'
import { ImgLoading } from '../loading/ImgLoading';
import { Star } from '../stars/Star';
import { Title } from './Title';

export const StarRecommendedList = ({celebName}) => {

    const [celebs,setCelebs] = useState([]);
    const [loading,setLoading]=useState(true);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {

        const handleOnline = () => {
          setIsOnline(true);
        };
    
        const handleOffline = () => {
          setIsOnline(false);
        };
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
        };

      }, []);

     useEffect(() => {

      const fetchData = async () => {
        // const token = from local storage
    
        const recommend_url = process.env.REACT_APP_CELEB;
    
        setLoading(true);
    
        if (!isOnline)
          return;

        const name = (celebName) && celebName.replaceAll("-", " ");
    
        try {
          const response = await axios.get(`${recommend_url}/model/recommend?name=${encodeURIComponent(name)}`, {
            headers: {
              // 'Authorization':'bearer '.concat(token)
            }
          });
          setCelebs(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      };
    
      fetchData();
    }, [isOnline,celebName]);
    

  return (
     <RecommendListContainer>
          <div className="container-fluid">
            <div className="row">

            <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 title">
                <Title title='RECOMMENDED PORNSTARS'/>
            </div>

           { (!loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 item-list px-4 d-md-none d-lg-block">

            <div className="row">
            { (celebs) && celebs.map((celeb)=><Star key={celeb.castId} cast={celeb}/>)}
             </div>

            </div> }

            { (!loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 item-list px-4 d-none d-md-block d-lg-none">

            <div className="row">
            { (celebs) && celebs.slice(0,4).map((celeb)=><Star key={celeb.castId} cast={celeb}/>)}
             </div>

            </div> }

            {
                (loading) && <ImgLoading/>
            }


            </div>
            </div>
     </RecommendListContainer>
  )
}

const RecommendListContainer=styledComponents.div`

background: var(--lightBlack);

.item-list .item{
    position:relative;
    padding:0 !important;
    margin:0 !important;
    transform: scale(0.77);
    cursor:pointer;
    transition:0.5s all;
    text-decoration:none;

    &:hover{
        .cast-name{
        color: var(--lightOrange);
        transition:0.5s all;
        }
        .img-container{
            border: 3px solid var(--lightOrange);
            transition:0.5s all;
        }
    }
}

.img-container{
    cursor:pointer;
    border: 3px solid transparent;
    transition:0.5s all;

    &:hover{
        border: 3px solid var(--lightOrange);
        transition:0.5s all;
    }
}

.card{
    background: transparent;
  }
 

.cast-name{
    // font-family: 'Radio Canada', sans-serif;
    font-family: 'Montserrat', sans-serif;
    color: var(--mainWhite);
    font-weight:bold;
    font-size:1.1rem;
    // letter-spacing:0.02em;
    margin: 0.8rem 0;
}

@media (min-width: 1050px) {
  .cast-name{
    font-size:1.2rem;
  }
}


`;