import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router';
import styledComponents from 'styled-components'
import { Alphabat } from '../category/Alphabat';
import { RecommendedList } from '../Detail/RecommendedList';
import { Footer } from '../footer/Footer';
import { TopBar } from '../Index/TopBar/TopBar';
import { CircularLoading } from '../loading/CircularLoading';
import { Tags } from '../stars/Tags';
import { Header } from './Header';
import { MoreVideos } from './MoreVideos';
import { Alph } from '../test/Alph';
import { TopBarList } from '../Index/TopBar/TopBarList';
import { StarRecommendedList } from '../Detail/StarRecommendedList';
import { LiveBoard } from '../live/LiveBoard';
import { StreamTopAd } from '../ads/StreamTopAd';
import { ConnectionStatus } from '../internet-connection/ConnectionStatus';
import { Helmet } from 'react-helmet'
import { VideoJs } from '../player/VideoJs';
import { VideoRemovedMessage } from '../common/VideoRemovedMessage';

export const Stream = () => {

  const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(``);
  const [pageDescription, setPageDescription] = useState(``);
  const [title, setTitle] = useState('Watch Nude Scenes');
  const [videoRemoved,setVideoRemoved]=useState(false);

   const [stream,setStream]=useState({});
   const [loading,setLoading]=useState(true);

   const [isShow,setIsShow]=useState(false);

   const [isOnline, setIsOnline] = useState(navigator.onLine);

   const location = useLocation();

   useEffect(() => {

    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };

  }, []);

   useEffect(()=>{
     
     const stream_title=`${title} - Xcelebs`;
     const desc = (stream.title) && `Watch ${stream.title} on Xcelebs for free`;

     setPageTitle(stream_title);
     setPageDescription(desc);
     document.title = title;
     document.body.style.zoom="100%";
   
  },[stream]);


 useEffect(() => {

  const fetchData = async () => {

    window.scrollTo(0, 0);

    if (window.location.href.split("/").length > 4) {
      const id = window.location.href.split("/")[4];
      const video_url = process.env.REACT_APP_CELEB_VIDEO;

      setLoading(true);

      if (!isOnline)
        return;

      try {
        const response = await axios.get(`${video_url}/id/${id}`);
        setStream(response.data);
        setLoading(false);
        setTitle(response.data.title);
        // (response.data.videoUrl) && setPlayer(response.data.videoUrl);
      } catch (error) {
        setLoading(false);
        console.log(error);
        setVideoRemoved(true);
      }

    }
  };

  fetchData();
}, [location.pathname,isOnline]);


 const changeCheckHandler=(flag)=>{
  setIsShow(flag);
  var check = document.getElementById('checkMenu');
  check.checked = flag;
}

 useEffect(()=>{

  var check = document.getElementById('checkMenu');
  check.checked = false;

 },[]);

  return (
      <StreamContainer>
        <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
          <div className='container-fluid'>
                     <div className='row'>

                     <input type="checkbox" id="checkMenu"/>

                     <div className='top-bar'>
                     <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
                   </div>

                   <div className='top-bar-list'>
                             <TopBarList/>
                             </div>

                   <div className='alph-header mb-2 mt-1 d-none d-md-block'>
                          <Alph/>
                     </div>

                     <div className='alph-header mb-2 mt-1 d-md-none'>
                         <Alphabat/>
                     </div>
                     
                     {  <React.Fragment>

                     <div className='col-12 col-md-12 col-lg-11 col-sm-12 mx-auto header-div mb-3'>
                       { (stream) && <Header stream={stream}/> }
                     </div>
                     
<div className='col-12 col-md-12 col-lg-11 col-sm-12 mx-auto stream-div text-center d-none d-lg-block'>

 { (!loading && !videoRemoved && stream) && <React.Fragment>
{ (stream.hlsUrl || stream.embedUrl) ? <VideoJs embedVideo={stream}/>
:
 <iframe className="player-warpper" src={stream.videoUrl_02} 
frameborder="0" marginwidth="0"  marginheight="0" scrolling="no" width="100%" height="600" allowfullscreen=""/> 
}
</React.Fragment> }

{
   (videoRemoved) && <React.Fragment>  <VideoRemovedMessage/> </React.Fragment>
}

{ (loading) && <div className='loading-player'></div> }

  </div> 
  
<div className='col-12 col-md-12 col-lg-11 col-sm-12 mx-auto stream-div text-center d-lg-none'>

{ (!loading && !videoRemoved && stream) && <React.Fragment>
{ (stream.hlsUrl || stream.embedUrl) ? <iframe className='player-wrapper' src={`https://xcelebs.org/embed/${encodeURIComponent(JSON.stringify(stream))}`}
                        frameborder="0" width="100%" height="315" scrolling="no" allowfullscreen=""/>
 :
<iframe className="player-warpper" src={stream.videoUrl_02} 
frameborder="0" marginwidth="0"  marginheight="0" scrolling="no" width="100%" height="315" allowfullscreen=""/> 
} 
</React.Fragment> }

{
   (videoRemoved) && <React.Fragment>  <VideoRemovedMessage/> </React.Fragment>
}

{ (loading) && <div className='loading-player'></div> }
</div>
                     </React.Fragment>  }

                          {/* {
                            (loading) && <div className='c-loading mt-5'> <CircularLoading/>  </div>
                          } */}

                         { <StreamTopAd/> }

                          <div className='col-md-12 col-lg-12 col-sm-12 col-12 mx-auto more-div'>
                               {  (stream && !videoRemoved) && <MoreVideos stream={stream}/> }
                          </div>

                          <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto recommended-div mt-5'>
                              { (stream.celebName && stream.celebType && stream.celebType.toLowerCase()==='celeb') && <RecommendedList celebName={stream.celebName}/> }
                              { (stream.celebName && stream.celebType && stream.celebType.toLowerCase()==='model') && <StarRecommendedList celebName={stream.celebName}/> }
                          </div>

                          <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto live-div'>
                               <LiveBoard/>
                        </div>

                          <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto trending-tags mb-5'>
                            <Tags/>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                        <Footer/>
                         </div>          

                     </div>
          </div>

         <ConnectionStatus/>

      </StreamContainer>
  )
}

const StreamContainer=styledComponents.div`

background: var(--lightBlack);

@media (min-width: 1024px) {
  .top-bar {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
  // padding:0 !important;
  // margin:0 !important;
} 


.more-div{
  // padding:0 !important;
  // margin:0 !important;
}

.recommended-div{
 padding:0 !important;
 //margin:0 !important;
}

.trending-tags{
 // padding:0 !important;
 // margin:0 !important;
}

.footer-div{
  padding:0 !important;
  margin:0 !important;
}

#player {
  width: 80%;
  margin: 0 auto;
  display: table;
    // width: 100%;
    // height: 600px;
    // object-fit: cover;
  //  z-index: -100;
}

#player-sm{
  width: 100%;
  margin: 0 auto;
  display: table;
  // width: 100%;
  // height: 315px;
  // object-fit: cover;
//  z-index: -100;
}

.player-div {
  min-width: 100%;
  min-height: 100%;
}

#checkMenu{
  display:none;
}

.top-bar-list{
  padding:0 !important;
  margin:0 !important;
  overflow: hidden;
  position:relative;
  max-height: 0;
  transition: max-height 0.5s ease;
}

#checkMenu:checked ~ .top-bar-list{
  max-height: 300px;
  transition: max-height 0.5s ease;
}

.player-warpper {
  background: var(--lightBlack) !important;
}


.stream-offer {
  margin-top:0.7rem;
}


.loading-player {
  position: relative;
  width: 100%;
  height: 315px;
  overflow: hidden;
  background: var(--pureBlack);
}

@media (min-width: 1024px) {
  .loading-player{
    height: 600px;
  }
}


`;