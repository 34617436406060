import React from 'react';
import styledComponents from 'styled-components';
import { Welcome } from '../Welcome';
import { BgImgCom } from './BgImgCom';
import { Description } from './Description';

export const TopImg = () => {
  return (
    <React.Fragment>
    <ImgContainer>
         {/* <div className="container-fluid">
                <div className="row"> */}
                <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 slider">
                  {/* <BgImgCom bgImg={'https://i.ibb.co/4dPkCMR/erotica.jpg'}/> */}
                 <BgImgCom bgImg={'https://xcelebs.org/media/erotica.jpg'}/>

                </div>

                <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 description">
                  <Description/>
                </div>
        {/* </div>
        </div> */}
    </ImgContainer>
    <div className='w-header mt-3 px-2'>
    <Welcome/>
    </div>
    </React.Fragment>
  )
}

const ImgContainer=styledComponents.div`

position:relative;
top:0;
bottom:0;
left:0;
right:0;

//z-index:-1111;


.slider{
  
  position:relative;
 // height:40vh;
  box-sizing: border-box;
   //padding:2rem 0!important;
  // margin:0 !important;
//  align-items:center;
  overflow:hidden;
 
}

.description{
  // padding:0 !important;
  // margin:0 !important;
  position:absolute;
  top:30%;
  //top:5.5rem;
  left:0;
  right:0;
  bottom:0;
 
}

.desc{

  .header{
    color: var(--mainWhite);
    //font-family: 'Oswald', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-size:3rem;
    font-weight:bold;

    .x{
      color: var(--lightOrange);
    }
  }

  .header-md{
    color: var(--mainWhite);
    //font-family: 'Oswald', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-size:2.3rem;
    font-weight:bold;

    .x{
      color: var(--lightOrange);
    }
  }


  .header-sm{
    color: var(--mainWhite);
   // font-family: 'Oswald', sans-serif;
   font-family: 'Roboto', sans-serif;
    font-size:1.5rem;
    font-weight:bold;

    .x{
      color: var(--lightOrange);
    }
    
  }

  .des{
    color: var(--mainWhite);
    font-family: 'Karla', sans-serif;
    font-size:1rem;
  }

  .des-sm{
    color: var(--mainWhite);
    font-family: 'Karla', sans-serif;
    font-size:0.8rem;
  }
}

.cus-btn{
  width: auto;
  color: var(--mainWhite);
  margin: 0.3rem 0.3rem;
  text-align: center;
  display:inline-block;
  
}

`;