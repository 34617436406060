import React from 'react';
import { Link } from 'react-router-dom';

export const Title =({name,index}) => {

  // const popunder_desktop_link = 'https://syndication.realsrv.com/splash.php?cat=&idzone=4851132&type=8&p=&sub=%SUB1%&tags=%KW%';

  // const popunder_mobile_link = 'https://syndication.realsrv.com/splash.php?cat=&idzone=4851130&type=8&p=&sub=%SUB1%&tags=%KW%';

  const popunder_desktop_link = 'https://blackandwhite-temporary.com/bl3UVa0.P/3NpZvqbhmGVzJVZDDW0S1tMTz_IpyKNJjrMn1YLrTnUdzdMSj/Io2WM/z/Yy';

  const popunder_mobile_link = 'https://blackandwhite-temporary.com/b.3/VX0WPH3qpzvcbHmMVtJpZrDF0R1gMYzMI/yIN/jRQJzcLST/U/zIMZjhIO2CNtDMQ_';

  const detectDevice=()=>{

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile;

  }

  const redirectTo=(name)=>{
    if(name==='celebrities'){

      if(detectDevice())
      window.open(popunder_mobile_link);
      else
      window.open(popunder_desktop_link);
    }
    if(name==='pornstars'){
      
      if(detectDevice())
      window.open(popunder_mobile_link);
      else
      window.open(popunder_desktop_link);
    }
   
    if(name==='videos'){

      if(detectDevice())
      window.open(popunder_mobile_link);
      else
      window.open(popunder_desktop_link);
    }
    
    if(name=='lesbian'){
     
      if(detectDevice())
      window.open(popunder_mobile_link);
      else
      window.open(popunder_desktop_link);
    }
}

let toPath;

if (name === 'celebrities') {
  toPath = '/browse/celebs/a';
} else if (name === 'pornstars') {
  toPath = '/browse/pornstars/a';
} else if (name === 'movies') {
  toPath = '/browse/movies/a';
} else if (name === 'videos') {
  toPath = '/browse/videos/recent';
} else if (name === 'missionary') {
  toPath = '/browse/tags/videos/missionary';
} else if (name === 'lesbian') {
  toPath = '/browse/tags/videos/lesbian';
}

// const redirectTo=(name)=>{
//   if(name==='celebrities')
//     return '/browse/celebs/a';
//   if(name==='pornstars')
//     return '/browse/pornstars/a';
//   if(name==='movies')
//      return '/browse/movies/a';
//   if(name==='videos')
//   return '/browse/videos/recent';
//   if(name==='fashion')
//     return `/browse/tags/videos/fashion`;
//   if(name=='lesbian'){
//     return `/browse/tags/videos/lesbian`;
//   }
// }

  return (
     <React.Fragment>
          <Link className={"col-4 mx-auto col-md-2 col-lg-2 col-sm-4 titles text-capitalize text-center d-none d-md-block "+(index==6 ? 'last-title' : 'first-title')}
          to={toPath}  onClick={()=>redirectTo(name.toLowerCase())} >
            <label className='title'>{name}</label> 
          </Link>
          
          <Link className={"col-4 mx-auto col-md-2 col-lg-2 col-sm-4 titles text-capitalize text-center d-md-none "+((index==6 || index==3) ? 'last-title' : 'first-title')}
          to={toPath} onClick={()=>redirectTo(name.toLowerCase())}>
            <label className='title'>{name}</label> 
          </Link>
     </React.Fragment>
  )
}
