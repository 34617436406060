import axios from 'axios';
import React,{useState,useEffect} from 'react'
import { Alphabat } from '../category/Alphabat';
import { Footer } from '../footer/Footer';
import { TopBar } from '../Index/TopBar/TopBar';
import { Tags } from '../stars/Tags';
import styledComponents from 'styled-components'
import { CelebResults } from './CelebResults';
import { ImgLoadingList } from '../loading/ImgLoadingList';
import { Alph } from '../test/Alph';
import { TopBarList } from '../Index/TopBar/TopBarList';
import { LiveBoard } from '../live/LiveBoard';
import { ConnectionStatus } from '../internet-connection/ConnectionStatus';
import { Helmet } from 'react-helmet';

export const TopCelebList = () => {

  // const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Top 100 Nude Celebs of all Time - Xcelebs`);
  const [pageDescription, setPageDescription] = useState(`Discover the ultimate list of the top 100 celebrities of all time at Xcelebs.`);

    const [isShow,setIsShow]=useState(false);

    const [celebs,setCelebs]=useState([]);
    const [loading,setLoading]=useState(true);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {

      const handleOnline = () => {
        setIsOnline(true);
      };
  
      const handleOffline = () => {
        setIsOnline(false);
      };
  
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
  
      return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
      };

    }, []);

    useEffect(()=>{

      var check = document.getElementById('checkMenu');
      check.checked = false;

      window.scrollTo(0, 0);
      const title=`Top 100 Nude Celebs of all Time - Xcelebs`;
      const desc = `Discover the ultimate list of the top 100 celebrities of all time at Xcelebs.`

      setPageTitle(title);
      setPageDescription(desc);
      document.title = title;
      document.body.style.zoom="100%";
     
    },[]);

    useEffect(() => {

      const fetchData = async () => {

        const celeb_url = process.env.REACT_APP_CELEB;

        setLoading(true);
    
        if (!isOnline)
          return;
    
        try {
          const response = await axios.get(`${celeb_url}/top-celebs`);
          setCelebs(response.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      };
    
      fetchData();
    }, [isOnline]);
    

    const changeCheckHandler=(flag)=>{
      setIsShow(flag);
      var check = document.getElementById('checkMenu');
      check.checked = flag;
  }
   
  return (
    <TopCelebContainer>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        {/* <link rel="canonical" href={canonicalUrl} /> */}
      </Helmet>
    <div className='container-fluid'>
       <div className='row'>

       <input type="checkbox" id="checkMenu"/>

       <div className='top-bar'>
       <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
       </div>

       <div className='top-bar-list'>
                             <TopBarList/>
                             </div>
                            

       <div className='alph-header mb-2 mt-1 d-none d-md-block'>
                          <Alph/>
                     </div>

                     <div className='alph-header mb-2 mt-1 d-md-none'>
                         <Alphabat/>
                     </div>

                     <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto celeb-header text-center mt-4'>
                               <h1 className='c-header'>TOP 100 CELEBS <span className='high'>OF ALL TIME</span></h1>
                   </div>

      { (!loading) &&  <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto celeb-div mb-4'>
                           {(celebs && celebs.length>=1) && <CelebResults celebs={celebs}/> } 
            </div> }

            {
              (loading) &&  <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto celeb-div mb-4'> <ImgLoadingList/> </div>
            }

<div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto live-div'>
                <LiveBoard/>
            </div>

      <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto trending-tags mb-5'>
         <Tags/>
       </div>
       
       <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                  <Footer/>
       </div>

       </div>
       </div>

        <ConnectionStatus/>

</TopCelebContainer>
  )
}


const TopCelebContainer=styledComponents.div`

background: var(--lightBlack);


.celeb-div{
  //padding:0 !important;
 // margin:0 !important;
} 

@media (min-width: 1024px) {
  .top-bar {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
    // padding:0 !important;
    // margin:0 !important;
  }

  .trending-tags{
    // padding:0 !important;
    // margin:0 !important;
  }
  
  .footer-div{
    padding:0 !important;
    margin:0 !important;
  }

  #checkMenu{
    display:none;
  }
  
  .top-bar-list{
    padding:0 !important;
    margin:0 !important;
    overflow: hidden;
    position:relative;
    max-height: 0;
    transition: max-height 0.5s ease;
  }
  
  #checkMenu:checked ~ .top-bar-list{
    max-height: 300px;
    transition: max-height 0.5s ease;
  }

.c-header{
    color: var(--mainWhite);
    font-weight:bold;
    font-family: 'Karla', sans-serif;
    font-size:2.2rem;

    .high{
        color: var(--lightOrange);
      }
}

`;