import styled from "styled-components";

export const Reset = styled.button`

font-size:1rem;
text-transform: capitalize;
padding: 0.2rem 1.5rem;
font-family: 'Open Sans', sans-serif;
border:none;
color: var(--mainWhite);
background-color: rgba(135, 206, 235, 0.5);
border-radius: 0.2rem;
cursor:pointer;
transition: 0.3s ease-in-out;

&:hover{
    background-color: rgba(135, 206, 235, 0.55);
    transition: 0.3s ease-in-out;
}
`;