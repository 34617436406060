import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faAngleDown, faCircle } from '@fortawesome/free-solid-svg-icons';

type ItemProps={
    item:string;
}


export const Item = ({item}:ItemProps) => {

//   const navigate = useNavigate();

//   const redirectTo=(name:string)=>{
//     name = name.replaceAll(" ","-");
//    if(name==='other')
//       return navigate('/tags');
//    if(name==='live-sex')
//       return navigate('/live-sex');   
//    return navigate(`/browse/tags/videos/${name}`);
//   }

  const redirectTo=(name:string)=>{
    
    name = name.replaceAll(" ","-");
   if(name==='other')
      return '/tags';
   if(name==='live-sex')
      return '/live-sex';   
   return `/browse/tags/videos/${name}`;

  }


  return <React.Fragment> {
    (item.toLowerCase()==='other') ?
  <Link to={redirectTo(item.toLowerCase())} className='item text-uppercase mb-1 mt-2'>{`${item} (1,228)`}</Link>
  :
  <Link to={redirectTo(item.toLowerCase())} className='item text-uppercase mb-1 mt-2'> { (item==='Live Sex') && <FontAwesomeIcon className="fa-circle icon" icon={faCircle}/> } {item}</Link>
  }
   </React.Fragment>

};
