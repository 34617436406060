import React, { useEffect } from 'react';
import { Banner } from "exoclick-react";
import useGeoLocation from "react-ipgeolocation";
import {isMobile} from 'react-device-detect';
import {isBrowser} from 'react-device-detect';
import axios from 'axios';

export const RecentAds = () => {

  useEffect(()=>{

    const script = document.createElement('script');

  script.src = "https://poweredby.jads.co/js/jads.js";
  script.async = true;

  document.body.appendChild(script);

  },[]);

  const location = useGeoLocation().country;

  const trackAffiliateOffer= async (id,name,type,provider,link)=>{

    const res = axios.get(`https://hutils.loxal.net/whois`);
    const ip = (await res).data.ip;

    let device="undetected";

    if (isMobile)
     device="mobile";

    if (isBrowser)
     device="desktop";
    

    const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
      affiliateLink:link,deviceType:device,ipAddress:ip,country:location};

      const save_url=process.env.REACT_APP_AFFILIATE;

      axios.post(`${save_url}`,detail,{
          headers:{
              'Content-Type':'application/json',
           //   'Authorization':'bearer '.concat(token)
          }
      });

  }

  return (
    <React.Fragment>

    <div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto stream-offer text-center d-none d-md-block'>

    {/* <ins id="1000113" data-width="728" data-height="90"></ins> */}

   <a href="https://t.amyfc.link/226438/779/19534" target="_blank"><img src="https://i.ibb.co/c1C93DF/006362-A-GDAT-18-ALL-EN-125-L.jpg" width="728" height="90" border="0"  className='mb-1' onClick={()=>trackAffiliateOffer('CHR104','CR-CAM-RECENT','PPL','crakrevenue','https://t.amyfc.link/226438/779/19534')}/></a>

   </div>

<div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto stream-offer text-center d-md-none'>


{/* <ins id="1000388" data-width="300" data-height="100"></ins> */}

<Banner zoneId={4870402}/>

</div>

</React.Fragment>

  );
}
