import React from 'react';
import styled from 'styled-components';

export const Success = (props) => {

    return (
        <SuccessContainer>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-sm-12 col-lg-12 col-md-12 mx-auto text-center success text-capitalize">
                        <p className="msg my-2">user registration successful! please login
                        </p>

                    </div>
                </div>
            </div>
        </SuccessContainer>
    );
}

const SuccessContainer = styled.div`
   
.success{
    border: 1px solid var(--mainWhite);
    background: var(--lightGreen);
    border-radius:0.5rem;
    .msg{
        font-size:0.9rem;
        font-family: 'Open Sans', sans-serif;
        color: var(--mainWhite);
    }
   
}
`;