import React, { useEffect, useState } from 'react'
import styledComponents from 'styled-components'
import { Footer } from '../footer/Footer'
import { TopBar } from '../Index/TopBar/TopBar'
import { Tags } from '../stars/Tags'
import { TagVideos } from './TagVideos'
import { useLocation } from 'react-router-dom'
import { SearchList } from '../most-search/SearchList'
import { TopBarList } from '../Index/TopBar/TopBarList'
import { LiveBoard } from '../live/LiveBoard'
import { ConnectionStatus } from '../internet-connection/ConnectionStatus'
import { TagImages } from './TagImages'
import { SelectHeader } from './SelectHeader'
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';

export const TagList = () => {

  // const canonicalUrl = window.location.href;

  // const [pageTitle, setPageTitle] = useState(`Browse Celebrity MILF videos - Xcelebs`);
  // const [pageDescription, setPageDescription] = useState(`Browse Celebrity MILF videos at Xcelebs. Every day growing universe of celebrity nude`);

  const urlParams = new URLSearchParams(window.location.search);
  const pageParam = urlParams.get('page');

  const pageNum =  (pageParam) ? parseInt(pageParam, 10) : 1;

      const select = (window.location.pathname.split("/").length>2) ? window.location.pathname.split("/")[3] : 'videos';

      const [tag,setTag]=useState('');
      const [isShow,setIsShow]=useState(false);
      const [selected,setSelected] = useState(select);

      const navigate = useNavigate();

      const location = useLocation();

      const selectedHandler=(header)=>{       
        setSelected(header);
      }

      // useEffect(()=>{

      //   const title=`Browse Celebrity ${(tag) && tag.replace("-"," ").toUpperCase()} ${selected} - Xcelebs`; 

      //   const description = `Browse Celebrity ${(tag) && tag.replace("-"," ").toUpperCase()} ${selected} at Xcelebs. Every day growing universe of celebrity nude`;

      // setPageTitle(title);
      // setPageDescription(description);

      //   document.body.style.zoom="100%";
      // },[tag,pageDescription]);

      useEffect(()=>{
        var check = document.getElementById('checkMenu');
        check.checked = false;  
      },[]);

      useEffect(()=>{

        if(window.location.href.split("/").length>6){

          const select =  window.location.href.split("/")[6].split("?")[0];

          if(pageNum==1)
          navigate(`/browse/tags/${selected}/${select}`);

          return setTag(select.toLowerCase());
        }else
          return setTag('milf');
    
     },[location.pathname]);

     const changeCheckHandler=(flag)=>{
      setIsShow(flag);
      var check = document.getElementById('checkMenu');
      check.checked = flag;
  }

  return (
     <TagListContainer>
        {/* <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet> */}
         <div className='container-fluid'>
                  <div className='row'>

                  <input type="checkbox" id="checkMenu"/>

                  <div className='top-bar'>
                  <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
                 </div>

                 <div className='top-bar-list'>
                             <TopBarList/>
                             </div>

                  <div className='alph-header mb-2 mt-1 d-none d-lg-block'>
                         <SearchList/>
                 </div>

                      <div className='col-12 col-md-12 col-lg-11 col-sm-12 mx-auto tag-title text-uppercase mt-4 mb-3'>
                         { (tag) && <h1 className='_tag'>Tag <span className='title-name'>#{tag.replaceAll('-',' ')}</span></h1> }
                      </div>

                      <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto header-div'>
                 { (selected && tag) && <SelectHeader header={selected} selectedHandler={selectedHandler} tag={tag}/> }
                     </div>

                      <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto tag-video-list'>
                         { (tag && selected==='videos') &&  <TagVideos tag={tag}/> }
                      </div>

                      <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto tag-img-list mb-4'>
                         { (tag && selected==='images') &&  <TagImages tag={tag}/> }
                      </div>


                      <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto live-div'>
                <LiveBoard/>
                      </div>

                      <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto trending-tags mb-5'>
                             <Tags/>
                     </div>

                     <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                        <Footer/>
                       </div>
                      
                  </div>
         </div>

         <ConnectionStatus/>
         
     </TagListContainer>
  )
}

const TagListContainer=styledComponents.div`

background: var(--lightBlack);

@media (min-width: 1024px) {
  .top-bar {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
    // padding:0 !important;
    // margin:0 !important;
  } 

  .tag-video-list{
    // padding:0 !important;
    // margin:0 !important;
    margin-top: 1.5rem;
  }  
  
  .tag-img-list{
    // padding:0 !important;
    // margin:0 !important;
  } 

._tag{
    color: var(--mainWhite);
    font-size:1.6rem;
    font-family: 'Josefin Sans', sans-serif;
    font-weight:bold;
}  

@media (min-width: 1020px) {
  ._tag{
    font-size:1.8rem;
  }
}

.title-name{
    color: var(--lightOrange);
    font-size:1.6rem;
    font-family: 'Josefin Sans', sans-serif;
    font-weight:bold;
}

@media (min-width: 1020px) {
  .title-name{
    font-size:1.8rem;
  }
}

.trending-tags{
  //  padding:0 !important;
   // margin:0 !important;
  }
  
  .footer-div{
    padding:0 !important;
    margin:0 !important;
  }

  #checkMenu{
    display:none;
  }
  
  .top-bar-list{
    padding:0 !important;
    margin:0 !important;
    overflow: hidden;
    position:relative;
    max-height: 0;
    transition: max-height 0.5s ease;
  }
  
  #checkMenu:checked ~ .top-bar-list{
    max-height: 300px;
    transition: max-height 0.5s ease;
  }

  .header-div{
    padding:0 !important;
    margin:0 !important;
}

`;