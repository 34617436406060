import React from 'react'
import { useNavigate } from 'react-router-dom';

export const Letter = ({letter}) => {

    let navigate = useNavigate();

    const selectedLetter=(letter)=>{
        if(letter==='#')
         return;
        return navigate(`/browse/celebs/${letter}`,{state:{letter:letter}});
     }

  return (
    <React.Fragment>
       <span class="letter text-capitalize" onClick={()=>selectedLetter(letter)}>{letter}</span>
    </React.Fragment>
  )
}
