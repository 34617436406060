import React,{useState} from 'react'
import styledComponents from 'styled-components'
import Title from './Title';
import { Browse } from '../Trending/Button';
import axios from 'axios';
import { useEffect } from 'react';
import { VideoLoading } from '../loading/VideoLoading';
import { Video } from '../test/Video';
import {CelebsList} from '../category/CelebsList';

export const Recommend = () => {

    const [videos,setVideos]=useState([]);
    const [loading,setLoading] = useState(false);
    
    useEffect(()=>{

      // const token = from local storage
  
      const recommend_url=process.env.REACT_APP_CELEB_VIDEO;

      setLoading(true);
  
      axios.get(`${recommend_url}/popular`,{
          headers:{
          //    'Authorization':'bearer '.concat(token)
            }
      }).then(res=>{
          setVideos(res.data);
          setLoading(false);
      }).catch(error=>{
        setLoading(false);
          console.log(error);
      });
  
    },[]);

  return (
    <RecommendContainer>
         <Title/>
          <CelebsList/>
        
    
                     { (!loading) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 plays-list px-3 my-4 d-none d-lg-block">
                     <div className="row">

                      {(videos) && videos.slice(8).map((video)=><Video key={video.video_id} item={video}/>)}

                        </div>
                      </div>}

                      { (!loading) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 plays-list my-4 d-lg-none">
                     <div className="row">

                      {(videos) && videos.slice(8).map((video)=><Video key={video.video_id} item={video}/>)}

                        </div>
                      </div>}

                      {
                        (loading) && <VideoLoading/>
                      }
                    
        { (!loading) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 browse text-center mt-3">
                          <Browse>browse recommended videos</Browse>
        </div> }     
    </RecommendContainer>
  )
}

const RecommendContainer=styledComponents.div`

background: var(--lightBlack);

// .plays-list .plays{
//   position:relative;
//   border: 3px solid transparent;
//   padding:0 !important;
//   margin:0 !important;
//   transform: scale(0.9);
//   transition:0.8s all;

//   &:hover{
//     transition:0.8s all;
//     cursor:pointer;
//     border: 3px solid var(--lightOrange);
//     .title{
//       color: var(--lightOrange);
//     }
//     .video-views{
//       transition:0.8s all;
//       z-index:-1111;
//     }
//     .time-quality{
//       transition:0.8s all;
//       z-index:-1111;
//     }
//   }
// }

// .card{
//   background: transparent;
// }

// .card-footer{
//   padding:0 !important;
//   margin:0 !important;
// }

// .plays .title{

//   // position: absolute;
//   // //top:90%;
//   // z-index:1111;  
//     font-size:1rem;
//     font-family: 'Open Sans', sans-serif; 
//     color: var(--mainWhite);
//     transition:0.8s all;
// }

// .time-quality{
//   position: absolute;
//   top:1%;
//   z-index:1111;

//   .plays-time{

//     .duration{
//       background: var(--lightGrey);
//       padding: 0.2rem 0.5rem 0.2rem 0.5rem;
//       font-size:0.9rem;
//       color: var(--mainDark2);
//       font-family: 'Open Sans', sans-serif; 
//     //  font-weight:bold;
//     }

//   }

//   .plays-time-sm{

//     .duration{
//       background: var(--lightGrey);
//       padding: 0.2rem 0.3rem 0.2rem 0.3rem;
//       font-size:0.7rem;
//       color: var(--mainDark2);
//       font-family: 'Open Sans', sans-serif; 
//     //  font-weight:bold;
//     }
//   }

//     .plays-quality{

//       .resolution{
//         font-family: 'Open Sans', sans-serif; 
//         background: var(--lightGrey);
//         padding: 0.2rem 0.4rem 0.2rem 0.4rem;
//         font-size:0.9rem;
//         color: var(--lightRed);
//         font-weight:bold;
//       }
//     }

//     .plays-quality-sm{

//       .resolution{
//         font-family: 'Open Sans', sans-serif; 
//         background: var(--lightGrey);
//         padding: 0.2rem 0.3rem 0.2rem 0.3rem;
//         font-size:0.7rem;
//         color: var(--lightRed);
//         font-weight:bold;
//       }
//     }

//   }


// .film-rate{
  
// }

// .film{
//   .film-name {
//     color: var(--lightGrey);
//     font-size:0.8rem;
//     font-family: 'Josefin Sans', sans-serif;
//     transition:0.5s all;

//     &:hover{
//       transition:0.5s all;
//       color: var(--mainWhite);
//     }
//   }

// }

// .play-rate{
//   color: var(--lightGreen);
//   .fa-thumb{
//    font-size:1rem;
//   }
//   .percentage{
//     font-size:0.9rem;
//   }
// }

// .video-views{
//   position: absolute;
//   top:1%;
//   z-index:1111;
//   margin:0;
//   padding:0;

//   .view{

//   .total-views{
//       background: rgba(0,0,0,0.7);
//       padding: 0.2rem 0.4rem 0.2rem 0.4rem;
//       color: var(--mainWhite);
//       font-size:0.9rem;
//       font-family: 'Open Sans', sans-serif; 
//   }
//   .fa-views{
//       font-size:1rem;
//   }
// }

// .view-sm{

//   .total-views{
//       background: rgba(0,0,0,0.7);
//       padding: 0.2rem 0.3rem 0.2rem 0.3rem;
//       color: var(--mainWhite);
//       font-size:0.7rem;
//       font-family: 'Open Sans', sans-serif; 
//   }
//   .fa-views{
//       font-size:0.8rem;
//   }
// }

.plays-list .plays{
  position:relative;
  border: 3px solid transparent;
  padding:0 !important;
  margin:0 !important;
  transform: scale(1);
  transition:0.8s all;

  &:hover{
    transition:0.8s all;
    cursor:pointer;
    // border: 3px solid var(--lightOrange);
    .title{
      color: var(--lightOrange);
    }
    .title-sm{
      color: var(--lightOrange);
    }
    .video-views{
      transition:0.8s all;
      z-index:-1111;
    }
    .time-quality{
      transition:0.8s all;
      z-index:-1111;
    }
  }
}


.plays-list .player{

  border: 3px solid transparent;

  &:hover{
    border: 3px solid var(--lightOrange);
  }

}

.card{
  background: transparent;
}

.card-footer{
  padding:0 !important;
  margin:0 !important;
}

.plays .title{

  // position: absolute;
  // //top:90%;
  // z-index:1111;  
    font-size:1rem;
    font-family: 'Open Sans', sans-serif; 
    color: var(--mainWhite);
    transition:0.8s all;
}

.plays .title-sm{

  // position: absolute;
  // //top:90%;
  // z-index:1111;  
    font-size:0.8rem;
    font-family: 'Open Sans', sans-serif; 
    color: var(--mainWhite);
    transition:0.8s all;
}

  

.time-quality{
  position: absolute;
  top:1%;
  z-index:1111;

  .plays-time{

    .duration{
      background: var(--lightGrey);
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      font-size:0.7rem;
      color: var(--mainDark2);
      font-family: 'Open Sans', sans-serif; 
      font-weight:bold;
    }

  }

  .plays-time-sm{

    .duration{
      background: var(--lightGrey);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      font-size:0.6rem;
      color: var(--mainDark2);
      font-family: 'Open Sans', sans-serif; 
      font-weight:bold;
    }
  }

    .plays-quality{

      .resolution{
        font-family: 'Open Sans', sans-serif; 
        background: var(--lightGrey);
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;
        font-size:0.7rem;
        color: var(--lightRed);
        font-weight:bold;
      }
    }

    .plays-quality-sm{

      .resolution{
        font-family: 'Open Sans', sans-serif; 
        background: var(--lightGrey);
        padding: 0.1rem 0.2rem 0.1rem 0.2rem;
        font-size:0.6rem;
        color: var(--lightRed);
        font-weight:bold;
      }
    }

  }

.film{
  .film-name {
    color: var(--lightGrey);
    font-size:0.8rem;
    font-family: 'Josefin Sans', sans-serif;
    transition:0.5s all;

    &:hover{
      transition:0.5s all;
      color: var(--mainWhite);
    }
  }

  .film-name-sm{
    color: var(--lightGrey);
    font-size:0.7rem;
    font-family: 'Josefin Sans', sans-serif;
    transition:0.5s all;

    &:hover{
      transition:0.5s all;
      color: var(--mainWhite);
    }
  }

}

.play-rate{
  color: var(--lightGreen);
  .fa-thumb{
   font-size:0.8rem;
  }
  .percentage{
    font-size:0.8rem;
  }
}

.video-views{
  position: absolute;
  top:1%;
  z-index:1111;
  margin:0;
  padding:0;

  .view{

  .total-views{
      background: rgba(0,0,0,0.7);
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      color: var(--mainWhite);
      font-size:0.7rem;
      font-family: 'Open Sans', sans-serif; 
  }
  .fa-views{
      font-size:0.8rem;
  }
}

.view-sm{

  .total-views{
      background: rgba(0,0,0,0.7);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      color: var(--mainWhite);
      font-size:0.6rem;
      font-family: 'Open Sans', sans-serif; 
  }
  .fa-views{
      font-size:0.7rem;
  }
}

} 

`;