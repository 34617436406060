import React,{useState,useEffect} from 'react';
import styledComponents from 'styled-components';
import { SliderButton } from '../Detail/SliderButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import ReactDOM from 'react-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import axios from 'axios';
import { SliderButtonSm } from '../Detail/SliderButtonSm';
import { Link } from 'react-router-dom';

export const ImgModal = ({images,a_index,openImgModalHandler,currentImgId,tagName,currentPage}) => {

    const handle = useFullScreenHandle();

    const [activeIndex,setActiveIndex]=useState(0);
    const [items,setItems] = useState([]);
    const [item,setItem]=useState({imgId:'',imgUrl:'',celebName:'',filmName:''});
    const [loading,setLoading]=useState(false);
    const [imageNotLoaded,setImageNotLoaded]=useState(false);
    const [openModal,setOpenModal]=useState(false);

    const [showButton, setShowButton] = useState(true);

    const [photos,setPhotos]= useState([]);

    useEffect(()=>{
          setItems(images);
          setActiveIndex(a_index);
    },[images]);

      useEffect(() => {
      // Preload all images when the component mounts
      if (photos.length > 0) {
        photos.forEach((photo) => {
          const img = new Image();
          img.src = photo.imgUrl;
        });
      }
    }, [photos]);

    const setImage = async (id) => {
      const selectedImage = photos && photos.find(image => image.imgId === id);
  
      if (selectedImage) {
          setItem({
              imgId: selectedImage.imgId,
              imgUrl: selectedImage.imgUrl,
              celebName: selectedImage.celebName,
              filmName: selectedImage
          });
          return;
      }
  
      setLoading(true);
      setImageNotLoaded(false);
  
      try {
          const img_url = process.env.REACT_APP_CELEB_IMAGE;
          const response = await axios.get(`${img_url}/${id}`);
  
          setPhotos([...photos, response.data]);
          setItem({
              imgId: response.data.imgId,
              imgUrl: response.data.imgUrl,
              celebName: response.data.celebName,
              filmName: response.data.filmName
          });
          setLoading(false);
          setOpenModal(true);
      } catch (error) {
        setLoading(false);
        setOpenModal(true);
        console.log(error);
      }
  };
  

    const getImageList=(tagName,page,index,id)=>{

      const selectedImage = photos && photos.find(image => image.imgId === id);

      if(selectedImage){
      setItem({imgId:selectedImage.imgId,imgUrl:selectedImage.imgUrl,celebName:selectedImage.celebName,filmName:selectedImage});
       return;
      }

      setLoading(true);
      setImageNotLoaded(false);

      // const img_url=process.env.REACT_APP_CELEB_IMAGE;

      // const name = (tagName) && tagName.replaceAll("-"," ");

    //   axios.get(`${img_url}/img-list-by-tag?tag=${encodeURIComponent(name)}&page=${page}&index=${index}`)
    // .then(res=>{
    //      setPhotos((prevItems) => [...prevItems, ...res.data]);
    //      const resp = res.data.find(image => image.imgId === id);
    //      setItem({imgId:resp.imgId,imgUrl:resp.imgUrl,celebName:resp.celebName,filmName:resp.filmName});
    //      setLoading(false);
    //      setOpenModal(true);
    // }).catch(error=>{
    //    setLoading(false);
    //    setOpenModal(true);
    //    console.log(error);
    // });

    let start=0;
    let end=0;

    if(index<=10){
      start=0;
      end=10
    }else if(index>10 && index<=20){
      start=10;
      end=20
    }else{
      start=20;
      end=30
    }

    setPhotos((prevItems) => [...prevItems, ...images.slice(start, end)]);
    const resp = images.find(image => image.imgId === id);
    setItem({imgId:resp.imgId,imgUrl:resp.imgUrl,celebName:resp.celebName,filmName:resp.filmName});
    setLoading(false);

    }

    const getRightImage=(id)=>{
   
      // setLoading(true);
  
      const selectedImage = photos && photos.find(image => image.imgId === id);

      if(selectedImage)
      setItem({imgId:selectedImage.imgId,imgUrl:selectedImage.imgUrl,celebName:selectedImage.celebName,filmName:selectedImage});
       else{
        if((activeIndex+2)>30)
        getImageList(tagName,currentPage,(1),id);
        else
        getImageList(tagName,currentPage,(activeIndex+2),id);
       }
      // setLoading(false);
  
    }

    const getLeftImage=(id)=>{
   
      // setLoading(true);
  
      const selectedImage = photos && photos.find(image => image.imgId === id);

      const index = images.length;

      if(selectedImage)
      setItem({imgId:selectedImage.imgId,imgUrl:selectedImage.imgUrl,celebName:selectedImage.celebName,filmName:selectedImage});
       else{
        if(activeIndex<1)
        getImageList(tagName,currentPage,(index),id);
        else
        getImageList(tagName,currentPage,(activeIndex),id);
       }

      // setLoading(false);
  
    }

    useEffect(()=>{
          // setImage(currentImgId);
        getImageList(tagName,currentPage,(a_index+1),currentImgId);
    },[]);

    useEffect(()=>{

      if(imageNotLoaded)
       return;

      const timer = setTimeout(() => {
        setShowButton(false);
      }, 4000);
  
      return () => {
        clearTimeout(timer);
      };

    },[item]);
    
const goLeft=()=>{
        
        if(activeIndex==0){  
          setActiveIndex(items.length-1);
          // setImage(images[items.length-1].imgId);
         getLeftImage(images[items.length-1].imgId);
          setShowButton(true);
        }else{
          setActiveIndex(prev=>prev-1);
          // setImage(images[activeIndex-1].imgId);
          getLeftImage(images[activeIndex-1].imgId);
          setShowButton(true);
        }
        
    }

    const goRight=()=>{
       
        if(activeIndex==items.length-1){
            setActiveIndex(0);
            // setImage(images[0].imgId);
            getRightImage(images[0].imgId);
            setShowButton(true);
        }else{
           setActiveIndex(prev=>prev+1);
          //  setImage(images[activeIndex+1].imgId);
          getRightImage(images[activeIndex+1].imgId);
           setShowButton(true);
        }
        
    }


    const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft')
          goLeft();
        else if(event.key === 'ArrowRight')
         goRight();
    }

    const openImage=(link)=>{
      return window.open(link);
    }

    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown); 
    
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [item]);

    useEffect(() => {

      if(!openModal)
       return;
      
      const handleOutsideClick = (event) => {
    
        const excludeLeftFromClose = document.getElementById('leftBtn');
        const excludeRightFromClose = document.getElementById('rightBtn');
        const excludeLeftSmFromClose = document.getElementById('leftBtnSm');
        const excludeRightSmFromClose = document.getElementById('rightBtnSm');
        const excludeFromClose = document.getElementById('controlDiv');
        const excludeSmFromClose = document.getElementById('controlDivSm');
        const excludeImgFromClose = document.getElementById('slideImg');
    
        if ( (excludeLeftFromClose && !excludeLeftFromClose.contains(event.target)) &&  (excludeRightFromClose && !excludeRightFromClose.contains(event.target))
        && (excludeLeftSmFromClose && !excludeLeftSmFromClose.contains(event.target)) && (excludeRightSmFromClose && !excludeRightSmFromClose.contains(event.target))
        && (excludeFromClose && !excludeFromClose.contains(event.target)) && (excludeSmFromClose && !excludeSmFromClose.contains(event.target))
        && (excludeImgFromClose && !excludeImgFromClose.contains(event.target))) {
          openImgModalHandler(false);      
        }
      };
    
      document.addEventListener('click', handleOutsideClick);
    
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, [openModal]);

  return ReactDOM.createPortal(

       <ImgContainer>

<div id="controlDiv" className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 col-xl-12 control-div">

  <span className='num px-2 d-none d-lg-block'><span className='current'>{activeIndex+1}</span> / {items.length}</span>

  <Link to={`/zoom-in/${item.celebName.replaceAll(" ","-").toLowerCase()}/photo/${item.imgId}`} className="zoom-in-link">
   <FontAwesomeIcon className='fa-search px-2 d-none d-lg-block' icon={faSearchPlus}/>
  </Link>

  <FontAwesomeIcon className='fa-expand px-2 d-none d-lg-block' icon={faExpand} onClick={handle.enter}/>

  <FontAwesomeIcon className='fa-close px-2 d-none d-lg-block' icon={faClose} onClick={()=>openImgModalHandler(false)}/>

    </div>

    <div id="controlDivSm" className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 control-div">

  <span className='num-sm px-2 d-lg-none'><span className='current'>{activeIndex+1}</span> / {items.length}</span>

  <FontAwesomeIcon className='fa-close px-2 d-lg-none' icon={faClose} onClick={()=>openImgModalHandler(false)}/>

    </div>


    <FullScreen handle={handle}>

        <div className='container'>
             <div className='row'>
                 

             <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 img-slider text-center">

                 { (item.imgUrl === '' || loading) &&  <span className='loading'>Loading . . . .</span>  }
                 { (!loading && imageNotLoaded) &&  <span className='img-loading'><span className='img-link' onClick={()=>openImage(item.imgUrl)}>This Image</span> could not be loaded</span>  }

            {  (item.imgUrl && !loading) && <div id="slide" className="slide">
                 {/* <img srcSet={items[activeIndex]} alt={'image'} loading="lazy"/> */}
                 <LazyLoadImage id="slideImg" key={item.imgId} srcSet={item.imgUrl} alt={(item.celebName) && `${item.celebName} Nude Picture`.concat((item.filmName) && ` in ${item.filmName}`)}
                title={(item.celebName) && `${item.celebName} Nude Picture`.concat((item.filmName) && ` in ${item.filmName}`)}
                onError={()=>setImageNotLoaded(true)} threshold={200} offset={100} loading="lazy"/>
              </div>  }

             {/* {  (item.imgUrl && !loading) && <div id="slide" className="slide-sm d-lg-none">

                 <LazyLoadImage id="slideImgSm" srcSet={item.imgUrl} alt={(item.celebName) && `${item.celebName} Nude Picture`.concat((item.filmName) && ` in ${item.filmName}`)}
                 title={(item.celebName) && `${item.celebName} Nude Picture`.concat((item.filmName) && ` in ${item.filmName}`)} 
                 onError={()=>setImageNotLoaded(true)} threshold={200} offset={100} loading="lazy"/>
              </div>  } */}
             

             <SliderButton left id="leftBtn" className="left-btn d-none d-lg-block" onClick={()=>goLeft()}>
                              <FontAwesomeIcon icon={faCaretLeft} className="arrow-icon"/>
            </SliderButton>

           <SliderButtonSm left id="leftBtnSm" className="left-btn-sm d-lg-none" onClick={()=>goLeft()}>
                             { (showButton) && <FontAwesomeIcon icon={faCaretLeft} className="arrow-icon"/> }
            </SliderButtonSm>

             <SliderButton right id="rightBtn" className="right-btn d-none d-lg-block" onClick={()=>goRight()}>
              <FontAwesomeIcon icon={faCaretRight} className="arrow-icon"/> 
              </SliderButton>

             <SliderButtonSm right id="rightBtnSm" className="right-btn-sm d-lg-none" onClick={()=>goRight()}>
                         { (showButton) && <FontAwesomeIcon icon={faCaretRight} className="arrow-icon" /> }
              </SliderButtonSm> 


              </div>
             </div>

        </div>

        </FullScreen>

       </ImgContainer>,document.getElementById('image-modal')
  )
}

const ImgContainer=styledComponents.div`

position:fixed;
top:0;
bottom:0;
left:0;
right:0;
display:flex;
background: rgba(0,0,0,0.9);
align-items:center;
justify-content:center;
z-index:1111;


.loading{
    color: var(--lightGrey);
    font-family: 'Karla', sans-serif;
    font-size:0.9rem;
}

.img-loading{
  color: var(--lightGrey);
  font-family: 'Open Sans', sans-serif; 
  font-size:0.9rem;

  .img-link{
    text-decoration: underline;
    cursor:pointer;
    color: var(--mainWhite);
  }
}

.control-div{
  //  position:absolute;
  position:fixed;
  top:0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:0.5rem;
  padding-right:0.4rem;
 }

.fa-close{
    opacity:0.9;
    transition:0.5s all;
    cursor: pointer;
    font-size:1.5rem;
    color: var(--lightGrey);
    z-index:1111;

    &:hover{
     opacity:1;
     transition:0.5s all;
    }
 }

.fa-expand{
    opacity:0.9;
    transition:0.5s all;
    cursor: pointer;
    font-size:1.2rem;
    color: var(--lightGrey);
    z-index:1111;

    &:hover{
     opacity:1;
     transition:0.5s all;
    }
 }

 .fa-search{
  opacity:0.9;
  transition:0.5s all;
  cursor: pointer;
  font-size:1.2rem;
  color: var(--lightGrey);
  z-index:1111;

  &:hover{
   opacity:1;
   transition:0.5s all;
  }
}

.slide img{
  width: 100%;
  height: auto;
  max-height: 100vh;
  object-fit: contain;
  padding: 30px 0;
 }

 @media screen and (max-width: 1000px) {
  .slide img {
    padding: 25px 0; /* Adjust padding for smaller screens */
  }
}

// .slide-sm img{
//   width: 100%;
//   height: auto;
//   max-height: 100vh;
//   object-fit: contain;
//   padding: 25px 0;
//  }


.left-btn{
     background: transparent;
    //  color: var(--lightGrey);
    padding-right: 250px;
 }

 .right-btn{
    background: transparent;
   // color: var(--lightGrey);
   padding-left: 250px;
 }

 .left-btn-sm{
  background: transparent;
  // color: transparent;
 // color: var(--lightGrey);
  padding-right: 85px;
}

.right-btn-sm{
 background: transparent;
// color: transparent;
//color: var(--lightGrey);
 padding-left: 85px;
}

 .arrow-icon{
  //  cursor:pointer;
   opacity:0.9;
   transition:0.5s all;
   color: var(--lightGrey3);

   &:hover{
    transition:0.5s all;
    opacity: 1;
   }
 }

 .close{
  background: transparent;
  color: var(--lightGrey);
 }


 .num{
  color: var(--lightGrey);
  font-size:0.85rem;
  z-index:1111;
 }

 .num-sm{
  color: var(--lightGrey);
  font-size:0.8rem;
  z-index:1111;
 }

 .current{
   color: var(--mainWhite);
 }

`;