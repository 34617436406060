import React from 'react';
import styledComponents  from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faCameraAlt } from '@fortawesome/free-solid-svg-icons';

export const CamBoard = ({changeCamHandler,selected}) => {

    const selects=['Chaturbate V1','Stripchat V1','Chaturbate V2','BongaCams V1'];

    const selectedCam=(camType)=>{
      changeCamHandler(camType);
    }

  return (
    <SelectCamContainer>
    <div className='container'>
    <div className='row'>

    <div className='col-12 col-md-12 col-xl-12 col-lg-12 col-sm-12 mx-auto best text-center mb-3'>
           <h1 className='model px-2'>Choose your Favourite Models from <span className='x'>X</span>celebs Live</h1>
           <h6 className='follow'>Make sure to smash the <span className='f'>Follow</span> button</h6>
    </div>

       <div className='col-8 col-md-12 col-xl-10 col-lg-12 col-sm-10 mx-auto buttons'>
         <div className='row'>
           {
             selects.map((cam,index)=>{
              return( <React.Fragment key={index}>
                    <div className={"col-12 col-md-6 col-sm-12 col-lg-3 mx-auto select-btn text-center "+(selected===cam.toLowerCase() && 'selected')}
                    onClick={()=>selectedCam(cam.toLowerCase())}>

                    { (cam.toLowerCase()==='chaturbate v1') && <React.Fragment> <FontAwesomeIcon className="fa-clock icon px-2" icon={faEye}/>  <label className='select-name'>{cam}</label> </React.Fragment> }
                     
                    { (cam.toLowerCase()==='stripchat v1') && <React.Fragment> <FontAwesomeIcon className="fa-clock icon px-2" icon={faCameraAlt}/>  <label className='select-name select-stripchat'>{cam}</label> </React.Fragment> }

                    { (cam.toLowerCase()==='chaturbate v2') && <React.Fragment> <FontAwesomeIcon className="fa-eye icon px-2" icon={faEye}/>  <label className='select-name'>{cam}</label> </React.Fragment> }

                    { (cam.toLowerCase()==='bongacams v1') && <React.Fragment> <FontAwesomeIcon className="fa-eye icon px-2" icon={faCameraAlt}/>  <label className='select-name'>{cam}</label> </React.Fragment> }
                     </div> 
                     </React.Fragment>
               );
             })
           }
         </div>
       </div>
    </div>
</div>
</SelectCamContainer>
  )
}

const SelectCamContainer=styledComponents.div`

background: var(--lightBlack);

.select-btn{
  background: linear-gradient(0deg, #343434, #4b4b4b);
  cursor:pointer;
  border-radius:0.3rem;
  transition: opacity 0.8s ease;
  padding: 0.5rem 0;
  transform: scale(0.9);
  padding:0 !important;
  margin: 0.3rem 0 0 0!important;

  &:hover{
    background: var(--lightOrange);
    transition: opacity 0.8s ease;
  }
}


.select-name{
  color: var(--mainWhite);
  font-family: 'Karla', sans-serif;
  font-size:0.9rem;
  cursor:pointer;
  margin: 0.5rem 0;
}

.icon{
  color: var(--mainWhite);
  font-size:0.9rem;
  cursor:pointer;
}

.selected{
  background: var(--lightOrange2);
  transition: opacity 0.8s ease;
  .select-name{
   color: var(--darkBlue);
  }
  .icon{
    color: var(--darkBlue);
  }
  &:hover{
    background: var(--lightOrange2);
    transition: opacity 0.8s ease;
  }
}

.best .model{
  font-size: 1.2rem;
  color: var(--mainWhite);
  // font-family: 'Karla', sans-serif;
  font-family: 'Open Sans', sans-serif; 
  font-weight:bold;
  letter-spacing: 0.05rem;

  .x{
    color: var(--lightOrange);
    font-weight:bold;
  }
}

@media (min-width: 1024px) {
  .best .model{
    font-size: 1.4rem;
  }
}

.best .follow{
  font-size: 0.8rem;
  color: var(--lightGrey);
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.01rem;
  
  .f{
    color: var(--lightOrange);
    font-weight:bold;
  }
}

@media (min-width: 1024px) {
  .best .follow{
    font-size: 0.9rem;
  }
}

`