import React from 'react'
import styledComponents from 'styled-components'

export const Policy = () => {
  return (
     <PolicyContainer>
         <div className='container'>
            <div className='row'>

            <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto privacy'>
                
                <h4 className='header text-capitalize'>Privacy Policy</h4>

                <p className='message'>
                We are very delighted that you have shown interest in our enterprise. Data protection is of a particularly high priority for the management of the Xcelebs.
                 The use of the Internet pages of the Xcelebs is possible without any indication of personal data; however, if a data subject wants to use special enterprise services via our website, processing of personal data could become necessary.
                If the processing of personal data is necessary and there is no statutory basis for such processing, we generally obtain consent from the data subject.
                </p>

                <p className='message'>
                The processing of personal data, such as the name, address, e-mail address, or telephone number of a
                 data subject shall always be in line with the General Data Protection Regulation (GDPR), and in accordance with the country-specific data protection regulations applicable to Xcelebs.
                  By means of this data protection declaration, our enterprise would like to inform the general public of the nature, scope, and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed, by means of this data protection declaration, of the rights to which they are entitled.
                </p>

                <p className='message'>
                As the controller, the Xcelebs has implemented numerous technical and organizational measures to ensure the most
                 complete protection of personal data processed through this website. However, Internet-based data transmissions may in principle have security gaps, so absolute protection may not be guaranteed. For this reason,
                 every data subject is free to transfer personal data to us via alternative means, e.g. by telephone.
                </p>    

            </div>

            <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto definition'>
             
            <h3 className='header text-capitalize'>1. Definitions</h3>

            <p className='message'>
            The data protection declaration of the Xcelebs is based on the terms used by the European legislator for the adoption of the General
             Data Protection Regulation (GDPR). Our data protection declaration should be legible and understandable
              for the general public, as well as our customers and business partners. To ensure this, we would like to 
              first explain the terminology used.
            </p>

            <p className='message'>
            In this data protection declaration, we use, inter alia, the following terms:
            </p>

             </div> {/*definition*/}   

             <div className='col-11 col-md-11 col-lg-11 col-sm-11 mx-auto personal-data'>

             <h3 className='header'>a) Personal data</h3>

             <p className='message'>
             Personal data means any information relating to an identified or identifiable natural person (“data subject”). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical,
              physiological, genetic, mental, economic, cultural or social identity of that natural person.
             </p>

             </div> {/*personal-data*/}   


            <div className='col-11 col-md-11 col-lg-11 col-sm-11 mx-auto data-subject'>

             <h3 className='header'>b) Data subject</h3>

             <p className='message'>
             Data subject is any identified or identifiable natural person, whose personal data is processed by the controller responsible for the processing.
             </p>

             </div> {/*data-subject*/}

             <div className='col-11 col-md-11 col-lg-11 col-sm-11 mx-auto profiling'>

<h3 className='header'>c) Profiling</h3>

<p className='message'>
Profiling means any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal aspects relating to a natural person, in particular to analyse or predict aspects concerning that natural person's performance at work,
 economic situation, health, personal preferences, interests, reliability, behaviour, location or movements.
</p>

</div> {/*profiling*/}

<div className='col-11 col-md-11 col-lg-11 col-sm-11 mx-auto pseudonymisation'>

<h3 className='header'>d) Pseudonymisation</h3>

<p className='message'>
Pseudonymisation is the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separately and is subject to technical and organisational measures
 to ensure that the personal data are not attributed to an identified or identifiable natural person.
</p>

</div> {/*pseudonymisation*/}

<div className='col-11 col-md-11 col-lg-11 col-sm-11 mx-auto recipient'>

<h3 className='header'>e) Recipient</h3>

<p className='message'>
Recipient is a natural or legal person, public authority, agency or another body,
 to which the personal data are disclosed, whether a third party or not. However, public authorities which may receive personal data in the framework of a particular inquiry in accordance with Union or Member State law shall not be regarded as recipients; the processing of those data by those public authorities shall
 be in compliance with the applicable data protection rules according to the purposes of the processing.
</p>

</div> {/*recipient*/}

<div className='col-11 col-md-11 col-lg-11 col-sm-11 mx-auto third-party'>

<h3 className='header'>f) Third party</h3>

<p className='message'>
Third party is a natural or legal person, public authority, agency or body other than the data subject, controller, processor and persons who, under the direct authority of the controller or processor, are authorised to process personal data.
</p>

</div> {/*third-party*/}

<div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto registration'>

<h3 className='header'>2. Registration on our website</h3>

<p className='message'>
The data subject has the possibility to register on the website of the controller with the indication of personal data.
 Which personal data are transmitted to the controller is determined by the respective input mask used for the registration. The personal data entered by the data subject are collected and stored exclusively for internal use by the controller, and for his own purposes. The controller may request transfer to one or more processors (e.g. a parcel service)
 that also uses personal data for an internal purpose which is attributable to the controller.
</p>

<p className='message'>
By registering on the website of the controller, the IP address—assigned by the Internet service provider (ISP)
 and used by the data subject—date, and time of the registration are also stored. The storage of this data takes place against the background that this is the only way to prevent the misuse of our services, and, if necessary, to make it possible to investigate committed offenses. Insofar, the storage of this data is necessary to secure the controller. This data is not passed on to third parties unless there is a
 statutory obligation to pass on the data, or if the transfer serves the aim of criminal prosecution.
</p>

<p className='message'>
The registration of the data subject, with the voluntary indication of personal data, 
is intended to enable the controller to offer the data subject contents or services that may only be offered to registered users due to the nature of the matter in question. Registered persons are free to change the personal data specified during the registration at any time,
 or to have them completely deleted from the data stock of the controller.
</p>

<p className='message'>
The data controller shall, at any time, provide information upon request to each data subject as to what personal data are stored about the data subject. In addition, the data controller shall correct or erase personal data at the request or indication of the data subject, insofar as there are no statutory storage obligations. The entirety of the controller’s employee
s are available to the data subject in this respect as contact persons.
</p>

</div> {/*registraion*/}

<div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto controller'>

<h3 className='header'>3. Name and Address of the controller</h3>

<p className='message'>
Controller for the purposes of the General Data Protection Regulation (GDPR), other data protection laws applicable in Member states of the European Union and other provisions related to data protection is:
</p>

<p className='message'>
Xcelebs
</p>

<p className='message'>
Email: <a className='link' href="mailto:xcelebs@protonmail.com">xcelebs@protonmail.com</a>
</p>

<p className='message'>
Website: https://www.xcelebs.org
</p>

</div> {/*controller*/}
             

<div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto cookies'>

<h3 className='header'>4. Cookies</h3>

<p className='message'>
The Internet pages of the Xcelebs use cookies. Cookies are text files that are stored in a computer system via an Internet browser.
</p>

<p className='message'>
Many Internet sites and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a unique identifier of the cookie. It consists of a character string through which Internet pages and servers can be assigned to the specific Internet browser in which the cookie was stored. This allows visited Internet sites and servers to differentiate the individual browser of the dats subject from other Internet browsers that contain other cookies. A specific Internet browser can be recognized and identified using the unique cookie ID.
</p>

<p className='message'>
Through the use of cookies, the Xcelebs can provide the users of this website with more user-friendly services that would not be possible without the cookie setting.
</p>

<p className='message'>
By means of a cookie, the information and offers on our website can be optimized with the user in mind. Cookies allow us, as previously mentioned, to recognize our website users. The purpose of this recognition is to make it easier for users to utilize our website. The website user that uses cookies, e.g. does not have to enter access data each time the website is accessed, because this is taken over by the website, and the cookie is thus stored on the user's computer system. Another example is the cookie of a shopping cart in an online shop. The online store remembers the articles that a customer has placed in the virtual shopping cart via a cookie.
</p>

<p className='message'>
The data subject may, at any time, prevent the setting of cookies through our website by means of a corresponding setting of the Internet browser used, and may thus permanently deny the setting of cookies. Furthermore, already set cookies may be deleted at any time via an Internet browser or other software programs. This is possible in all popular Internet browsers. If the data subject deactivates the setting of cookies in the Internet browser used, not all functions of our website may be entirely usable.
</p>

</div> {/*cookies*/}   

<div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto collection'>

<h3 className='header'>5. Collection of general data and information</h3>

<p className='message'>
The website of the Xcelebs collects a series of general data and information when a data subject or automated system calls up the website. This general data and information are stored in the server log files. Collected may be (1) the browser types and versions used, (2) the operating system used by the accessing system, (3) the website from which an accessing system reaches our website (so-called referrers), (4) the sub-websites, (5) the date and time of access to the Internet site, (6) an Internet protocol address (IP address), (7) the Internet service provider of the accessing system, and (8) any other similar data
 and information that may be used in the event of attacks on our information technology systems.
</p>

<p className='message'>
When using these general data and information, the Xcelebs does not draw any conclusions about the data subject. Rather, this information is needed to (1) deliver the content of our website correctly, (2) optimize the content of our website as well as its advertisement, (3) ensure the long-term viability of our information technology systems and website technology, and (4) provide law enforcement authorities with the information necessary for criminal prosecution in case of a cyber-attack. Therefore, the Xcelebs analyzes anonymously collected data and information statistically, with the aim of increasing the data protection and data security of our enterprise, and to ensure an optimal level of protection for the personal data we process. The anonymous
 data of the server log files are stored separately from all personal data provided by a data subject.
</p>


</div> {/*collection*/}

<div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto contact'>

<h3 className='header'>6. Contact possibility via the website</h3>

<p className='message'>
The website of the Xcelebs contains information that enables a quick electronic contact to our enterprise, as well as direct communication with us, which also includes a general address of the so-called electronic mail (e-mail address). If a data subject contacts the controller by e-mail or via a contact form, the personal data transmitted by the data subject are automatically stored. Such personal data transmitted on a voluntary basis by a data subject to the data controller are stored for the purpose of processing
 or contacting the data subject. There is no transfer of this personal data to third parties.
</p>

</div> {/*contact*/}

<div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto analytics'>

<h3 className='header'>7. Data protection provisions about the application and use of Google Analytics (with anonymization function)</h3>

<p className='message'>
On this website, the controller has integrated the component of Google Analytics (with the anonymizer function). Google Analytics is a web analytics service. Web analytics is the collection, gathering, and analysis of data about the behavior of visitors to websites. A web analysis service collects, inter alia, data about the website from which a person has come (the so-called referrer), which sub-pages were visited, or how often and for what duration a sub-page was viewed. Web analytics are mainly used for
 the optimization of a website and in order to carry out a cost-benefit analysis of Internet advertising.
</p>

<p className='message'>
The operator of the Google Analytics component is Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, United States.
</p>

<p className='message'>
For the web analytics through Google Analytics the controller uses the application "_gat. _anonymizeIp". By means of this application the IP address of the Internet connection of the data subject is abridged by Google and anonymised when accessing our websites from a Member
 State of the European Union or another Contracting State to the Agreement on the European Economic Area.
</p>

<p className='message'>
The purpose of the Google Analytics component is to analyze the traffic on our website. Google uses the collected data and information, inter alia, to evaluate the use of our website and to provide online reports, which show the activities on our websites,
 and to provide other services concerning the use of our Internet site for us.
</p>

<p className='message'>
Google Analytics places a cookie on the information technology system of the data subject. The definition of cookies is explained above. With the setting of the cookie, Google is enabled to analyze the use of our website. With each call-up to one of the individual pages of this Internet site, which is operated by the controller and into which a Google Analytics component was integrated, the Internet browser on the information technology system of the data subject will automatically submit data through the Google Analytics component for the purpose of online advertising and the settlement of commissions to Google. During the course of this technical procedure, the enterprise Google gains knowledge of personal information, such as the IP address of the data subject, which serves Google, inter alia,
 to understand the origin of visitors and clicks, and subsequently create commission settlements.
</p>

<p className='message'>
The cookie is used to store personal information, such as the access time, the location from which the access was made, and the frequency of visits of our website by the data subject. With each visit to our Internet site, such personal data, including the IP address of the Internet access used by the data subject, will be transmitted to Google in the United States of America. These personal data are stored by Google in the United States of America.
 Google may pass these personal data collected through the technical procedure to third parties.
</p>

<p className='message'>
The data subject may, as stated above, prevent the setting of cookies through our website at any time by means of a corresponding adjustment of the web browser used and thus permanently deny the setting of cookies. Such an adjustment to the Internet browser used would also prevent Google Analytics from setting a cookie on the information technology system of the data subject. In addition, cookies
 already in use by Google Analytics may be deleted at any time via a web browser or other software programs.
</p>

<p className='message'>
In addition, the data subject has the possibility of objecting to a collection of data that are generated by Google Analytics, which is related to the use of this website, as well as the processing of this data by Google and the chance to preclude any such. For this purpose, the data subject must download a browser add-on under the link https://tools.google.com/dlpage/gaoptout and install it. This browser add-on tells Google Analytics through a JavaScript, that any data and information about the visits of Internet pages may not be transmitted to Google Analytics. The installation of the browser add-ons is considered an objection by Google. If the information technology system of the data subject is later deleted, formatted, or newly installed, then the data subject must reinstall the browser add-ons to disable Google Analytics. If the browser add-on was uninstalled by the data subject or any other person who is attributable to their sphere of competence,
 or is disabled, it is possible to execute the reinstallation or reactivation of the browser add-ons.
</p>

<p className='message'>
Further information and the applicable data protection provisions of Google may be retrieved under https://www.google.com/intl/en/policies/privacy/ and under http://www.google.com/analytics/terms/us.html.
 Google Analytics is further explained under the following Link https://www.google.com/analytics/.
</p>

</div> {/*analytics*/}

<div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto automated'>

<h3 className='header'>8. Existence of automated decision-making</h3>

<p className='message'>
As a responsible company, we do not use automatic decision-making or profiling.
</p>

</div> {/*automated*/}

<div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto updated mt-1'>

<h3 className='update'>Last updated: December 2022.</h3>


</div> {/*updated*/}
                 

            </div>

            </div>
     </PolicyContainer>
  )
}

const PolicyContainer=styledComponents.div`

background: var(--lightBlack);

.header{
  color: var(--lightOrange);
  font-family: 'Open Sans', sans-serif; 
  font-weight:bold;
  font-size:1.4rem;
}

.message{
    color: var(--mainWhite);
    font-family: 'Open Sans', sans-serif; 
    font-size:0.9rem;
}

.link{
  text-decoration:none;
  transition: 0.5s ease-in-out;
  color: var(--lightOrange);
  cursor:pointer;

  &:hover{
    text-decoration:underline;
    transition: 0.5s ease-in-out;
  }
}

.updated .update{
  color: var(--mainWhite);
  font-family: 'Open Sans', sans-serif; 
  font-size:1.1rem;
}

`;