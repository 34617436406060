import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router';
import styledComponents from 'styled-components'
import { Alphabat } from '../category/Alphabat';
import { RecommendedList } from '../Detail/RecommendedList';
import { Footer } from '../footer/Footer';
import { TopBar } from '../Index/TopBar/TopBar';
import { Tags } from '../stars/Tags';
import { CelebResults } from './CelebResults';
import { VideoResults } from './VideoResults';
import { MovieResults } from './MovieResults';
import { Alph } from '../test/Alph';
import { TopBarList } from '../Index/TopBar/TopBarList';
import { ConnectionStatus } from '../internet-connection/ConnectionStatus';
import { Helmet } from 'react-helmet';

export const SearchMainList = () => {

  const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(``);
  const [pageDescription, setPageDescription] = useState(``);

      const[name,setName]=useState('');
      const [celebs,setCelebs]=useState([]);
      const [videos,setVideos]=useState([]);
      const [movies,setMovies]=useState([]);
      const [isShow,setIsShow]=useState(false);

      const location = useLocation();

      // const search=(location.pathname.split("/").length>=3) && location.pathname.split("/")[2];

       const search = location.search.substring(3);

      useEffect(()=>{

        window.scrollTo(0, 0);

        const title=`${name} - Xcelebs`;
        const desc=`Search results for "${name}" on Xcelebs.`;

        setPageTitle(title);
        setPageDescription(desc);
        document.title = title;
        document.body.style.zoom="100%";
       
      },[name]);

      useEffect(() => {

        const fetchData = async () => {

          const video_url = process.env.REACT_APP_CELEB_VIDEO;
      
          try {
            const response = await axios.get(`${video_url}/search-list?q=${search.replaceAll("+"," ")}`);
            setVideos(response.data);
            // setIsLoading(false);
          } catch (error) {
            //  setIsLoading(false);
            console.log(error);
          }
        };
      
        fetchData();
      }, [location.pathname,search]);
      

      useEffect(()=>{

        setName(decodeURIComponent(search.replaceAll("+"," ")));

      },[location.pathname,search]);


      useEffect(() => {

        const fetchData = async () => {

          try {
            const celeb_url = process.env.REACT_APP_CELEB;
            const response = await axios.get(`${celeb_url}/search-list?q=${search.replaceAll("+"," ")}`);
            setCelebs(response.data);
            // setIsLoading(false);
          } catch (error) {
             // setIsLoading(false);
            console.log(error);
          }
        };
      
        fetchData();
      }, [location.pathname,search]);
      

      useEffect(() => {

        const fetchData = async () => {

          try {
            const film_url = process.env.REACT_APP_FILM;
            const response = await axios.get(`${film_url}/search-list?q=${search.replaceAll("+"," ")}`);
            setMovies(response.data);
            // setIsLoading(false);
          } catch (error) {
            //  setIsLoading(false);
            console.log(error);
          }
        };
      
        fetchData();
      }, [location.pathname,search]);
      

      useEffect(()=>{

        var check = document.getElementById('checkMenu');
        check.checked = false;

      },[]);

      const changeCheckHandler=(flag)=>{
        setIsShow(flag);
        var check = document.getElementById('checkMenu');
        check.checked = flag;
    }

  return (
    <SearchContainer>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
       <div className='container-fluid'>
                  <div className='row'>

                  <input type="checkbox" id="checkMenu"/>
       
             <div className='top-bar'>
             <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
             </div>

             <div className='top-bar-list'>
                             <TopBarList/>
                             </div>
                          

             <div className='alph-header mb-2 mt-1 d-none d-md-block'>
                          <Alph/>
                     </div>

                     <div className='alph-header mb-2 mt-1 d-md-none'>
                         <Alphabat/>
                     </div>

                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto search-header text-center my-3'>
                            {(name) &&   <h1 className='header'>Results for <span className='search-name'>{name}</span></h1> }
                   </div>
                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto video-div mt-2 mb-3'>
                           { (videos && videos.length>=1) && <VideoResults videos={videos}/> }
                   </div>
             
                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto celeb-div mt-2 mb-3'>
                           {(celebs && celebs.length>=1) && <CelebResults celebs={celebs}/> } 
                   </div>

                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto celeb-div mt-2 mb-3'>
                           {(movies && movies.length>=1) && <MovieResults movies={movies}/> } 
                   </div>

                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto recommended-div'>
                   { (name) && <RecommendedList celebName={name}/> }
                    </div>

                    <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto trending-tags mb-5'>
               <Tags/>
             </div>
             
             <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                        <Footer/>
             </div>

     </div>

     </div>
           
       <ConnectionStatus/>

    </SearchContainer>
  )
}

const SearchContainer=styledComponents.div`

background: var(--lightBlack);

@media (min-width: 1024px) {
  .top-bar {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
  // padding:0 !important;
  // margin:0 !important;
} 

.header{
  color: var(--mainWhite);
   font-weight:bold;
   font-family: 'Karla', sans-serif;
   font-size:1.8rem;

   .search-name{
    color: var(--lightOrange);
   }
}

.celeb-div{
 // padding:0 !important;
 // margin:0 !important;
}  

.video-div{
 // padding:0 !important;
 // margin:0 !important;
}  

.recommended-div{
  padding:0 !important;
  margin:0 !important;
}

.trending-tags{
 // padding:0 !important;
 // margin:0 !important;
}

.footer-div{
  padding:0 !important;
  margin:0 !important;
}

#checkMenu{
  display:none;
}

.top-bar-list{
  padding:0 !important;
  margin:0 !important;
  overflow: hidden;
  position:relative;
  max-height: 0;
  transition: max-height 0.5s ease;
}

#checkMenu:checked ~ .top-bar-list{
  max-height: 300px;
  transition: max-height 0.5s ease;
}


`;