import React from 'react'
import styledComponents from 'styled-components'
import { useNavigate } from 'react-router-dom';

export const Alphabat = ()=> {

    let navigate = useNavigate();

    const letterrs= ['#','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];

    const selectedLetter=(letter)=>{
       if(letter==='#')
        return;
       return navigate(`/browse/celebs/${letter}`,{state:{letter:letter}});
    }

  return (
    <AlphabatContainer>
    <div className='container'>
            <div className='row'>
                  <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto alphabat-col py-2'>
                  <div className='row'>
                      { letterrs.map((a,index)=>{
                    return (<React.Fragment key={index}>
                     <div className={'col-1 col-md-1 col-lg-1 col-sm-1 mx-auto alphabat text-uppercase text-center'} onClick={()=>selectedLetter(a)}>
                             <span className='letter my-2'>{a}</span>
                     </div>
                     
                     </React.Fragment>
                       ); })
                      }
                      </div>
                  </div>
            </div>
    </div>
</AlphabatContainer>
  )
}

const AlphabatContainer=styledComponents.div`

.alphabat-col{
background: var(--darkBlue);
border-radius:0.3rem;
}

.alphabat{
    margin:0 !important;
    padding:0 !important;
   
}

.letter{
    color: var(--mainWhite);
    font-family: 'Open Sans', sans-serif; 
    font-weight:bold;
    font-size:0.8rem;
    cursor:pointer;
    padding: 0.1rem 0.4rem 0.1rem 0.4rem;
    transition:0.3s all;
    border-radius:0.2rem;
   // transform: scale(1);

    &:hover{
        background: var(--lightOrange);
        transition:0.3s all;
    }
}

`;
