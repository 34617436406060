import React from 'react'
import styledComponents from 'styled-components'
import { Creator } from './Creator';

export const CreatorList = ({creators,offer_type}) => {

  return (
     <CreatorContainer>
          <div className='container-fluid'>
                  <div className='row'>
                   
                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto item-list'>
                   <div className='row'>
                   { (creators) && creators.map((creator)=><Creator key={creator.creatorId} cast={creator} offer_type={offer_type}/>)}
                  </div>
                   </div>

                  </div>
            </div>      
     </CreatorContainer>
  )
}

const CreatorContainer=styledComponents.div`

// .c-header{
//     color: var(--mainWhite);
//     font-weight:bold;
//     font-family: 'Karla', sans-serif;
//     font-size:2.2rem;

//     .high{
//         color: var(--lightOrange);
//       }
// }

.item-list .item{
    position:relative;
    padding:0 !important;
    margin:0 !important;
    transform: scale(0.8);
    cursor:pointer;
    transition:0.5s all;

    &:hover{
        .cast-name{
        color: var(--lightOrange);
        transition:0.5s all;
        }
        .img-container{
            border: 3px solid var(--lightOrange);
            transition:0.5s all;
        }
    }
}

.img-container{
    cursor:pointer;
    border: 3px solid transparent;
    transition:0.5s all;

    &:hover{
        border: 3px solid var(--lightOrange);
        transition:0.5s all;
    }
}

.card{
    background: transparent;
  }

.cast-name{
    // font-family: 'Radio Canada', sans-serif;
    font-family: 'Montserrat', sans-serif;
    color: var(--mainWhite);
    font-weight:bold;
    font-size:1.1rem;
    margin: 0.8rem 0;
}

.top{
    position: absolute;

  .view{
    
    .total-views-img{
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;
        color: var(--mainWhite);
        font-size:1rem;
        font-family: 'Open Sans', sans-serif; 

        .top-per{
            font-family: 'Karla', sans-serif;
            font-size:1.1rem;
        }
    }
   
  }

//   .view-sm{
//     .total-views-img{
//         padding: 0.2rem 0.4rem 0.2rem 0.4rem;
//         color: var(--mainWhite);
//         font-size:0.9rem;
//         font-family: 'Open Sans', sans-serif; 
//     }
//     .fa-views{
//         font-size:1rem;
//     }
//   }
} 

// .hoverable-image{
//     border-bottom: 32px solid rgba(0,0,0,0.7);
//   }

 .country{
    position: absolute;

    .flag{

        .flag-img{
            padding: 0.2rem 0.4rem 0.2rem 0.4rem;
        }

    }
 }

`;