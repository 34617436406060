import React from 'react'
import styledComponents from 'styled-components'

export default function Header() {
  return (
      <HeaderContainer>
          <div className='container-fluid'>
               <div className='row'>
                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto header-col text-center text-uppercase mt-3 mb-4'>
                        <h3 className='header'><label className='x'>x</label>celebs tags</h3>
                   </div>
               </div>
          </div>
      </HeaderContainer>
  )
}

const HeaderContainer=styledComponents.div`
 
.header{
   color: var(--mainWhite);
   font-weight:bold;
   font-family: 'open-extra-bold';
   font-size:2rem;

   .x{
       color: var(--lightOrange);
   }
}

`;
