import React from 'react'
import { Link, useNavigate } from 'react-router-dom';


export const Star = ({cast}) => {

  // const navigate = useNavigate();

  const redirectToCeleb=(id,name)=>{
    return (id && name) && `/view/pornstar/${id}/${name.toLowerCase().replaceAll(/[-\s]+/g, '-')}`;
}


  return (
     <React.Fragment>

      <Link to={redirectToCeleb(cast.celebId,cast.celebName)} className="col-6 col-lg-2 col-md-3 col-sm-4 mx-auto item"> 

           {/* <div className="col-6 col-lg-2 col-md-3 col-sm-4 mx-auto item" onClick={()=>redirectToCeleb(cast.celebId,cast.celebName)}> */}
           
           <div className="card">

           <div className="img-container">
           <img src={cast.imgUrl} alt={`${cast.celebName} Nude`} title={`${cast.celebName} Nude`} className="card-img-top" style={{width:'100%',height:'17rem'}}/> 
           </div>

           </div> {/*card*/}

           <div className="card-footer">
                {(cast.celebName) && <h6 className='cast-name text-center px-2 text-uppercase'>{cast.celebName}</h6> }
            </div>

           {/* </div> */}

           </Link>
     </React.Fragment>
  )
}