import React from 'react'
import styledComponents from 'styled-components'

export const Contact = () => {
  return (

    <ContactContainer>
    <div className='container'>
    <div className='row'>


    <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto contact my-2 text-center'>

    <h5 className='header'>If you find inappropriate, illegal, abusive, violent, vulgar, or offensive content, please report it to our email at <a className='email' href="mailto:xcelebs@protonmail.com">xcelebs@protonmail.com</a></h5>

      </div>

      </div>

      </div>

      </ContactContainer>
  )
}


const ContactContainer=styledComponents.div`

background: var(--lightBlack);

.header{
    color: var(--mainWhite);
    font-family: 'Open Sans', sans-serif; 
    font-size:0.9rem;
  }

  .email{
    text-decoration:none;
    transition: 0.5s ease-in-out;
    color: var(--lightOrange);
    cursor:pointer;
  
    &:hover{
      text-decoration:underline;
      transition: 0.5s ease-in-out;
    }
  }


`;