import React from 'react';
import useGeoLocation from "react-ipgeolocation";
import {isMobile} from 'react-device-detect';
import {isBrowser} from 'react-device-detect';
import axios from 'axios';

export const Offer_2 = () => {

  const location = useGeoLocation().country;

  const trackAffiliateOffer= async (id,name,type,provider,link)=>{

    const res = axios.get(`https://hutils.loxal.net/whois`);
    const ip = (await res).data.ip;

    let device="undetected";

    if (isMobile)
     device="mobile";

    if (isBrowser)
     device="desktop";
    

    const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
      affiliateLink:link,deviceType:device,ipAddress:ip,country:location};

      const save_url=process.env.REACT_APP_AFFILIATE;

      axios.post(`${save_url}`,detail,{
          headers:{
              'Content-Type':'application/json',
           //   'Authorization':'bearer '.concat(token)
          }
      });

  }

  return (
    
    <React.Fragment>

         <div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto offer-2 text-center d-none d-md-block'>

<a href="https://t.acam-2.com/226438/6360/19826" target="_blank"><img src="https://www.imglnkd.com/2994/008921B_ROYA_18_ALL_EN_125_L.gif" width="728" height="90" border="0" className='mt-2 mb-3' onClick={()=>trackAffiliateOffer('CHR118','CR-CAM-OFFER-2','PPL','crakrevenue','https://t.acam-2.com/226438/6360/19826')}/></a>

        </div>

        
        <div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto offer-2 text-center d-md-none'>

        <a href="https://t.amyfc.link/226438/779/19534" target="_blank"><img src="https://www.imglnkd.com/2676/001753A_LIJA_18_ALL_EN_71_L.gif" width="300" height="250" border="0" className='mb-3' onClick={()=>trackAffiliateOffer('CHR119','CR-CAM-OFFER-2','PPL','crakrevenue','https://t.amyfc.link/226438/779/19534')}/></a>

    </div>

        </React.Fragment>
    
  );
}
