import React, { useState } from 'react'
import styledComponents from 'styled-components'
import { Celebs } from '../common/Celebs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import { ImgLoading } from '../loading/ImgLoading';


export const CelebsList = () => {

    const [celebs,setCelebs] = useState([]);
    const [loading,setLoading]=useState(false);
  

    const navigate = useNavigate();

  //  const celeb={celeb_id:'w1232',name:'Alexendra Deaddario',img_url:'https://cdn2.aznude.com/biopic/89061_biopic.jpg',views:15.2};

 useEffect(() => {

    const fetchData = async () => {
        try {

            // const token = from local storage

            const recommend_url = process.env.REACT_APP_CELEB;

            setLoading(true);

            const response = await axios.get(`${recommend_url}/popular`, {
                headers: {
                    //  'Authorization':'bearer '.concat(token)
                }
            });

            setCelebs(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    fetchData();

}, []);

    const redirectTo=()=>{
        return navigate('/browse/xcelebs/popular');
    }

  return (
       <CelebsContainer>
         
            { (!loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 item-list px-3">
            <div className="row">
            { (celebs) && celebs.map((celeb)=><Celebs key={celeb.celebId} cast={celeb}/>)}
             </div>
            </div> }

            {
                (loading) && <ImgLoading/>
            }
        

            <div className="view-all text-capitalize text-center">
                <span className='view-more px-4' onClick={()=>redirectTo()}> <span>view all</span> <FontAwesomeIcon className="fa-angle-right icon" icon={faAngleDoubleRight}/></span>
            </div>

            
       </CelebsContainer>
  )
}

const CelebsContainer=styledComponents.div`

background: var(--lightBlack);

.item-list .item{
    position:relative;
    padding:0 !important;
    margin:0 !important;
    transform: scale(0.8);
    cursor:pointer;
    transition:0.5s all;

    &:hover{
        .cast-name{
        color: var(--lightOrange);
        transition:0.5s all;
        }
        .img-container{
            border: 3px solid var(--lightOrange);
            transition:0.5s all;
        }
    }
}

.img-container{
    cursor:pointer;
    border: 3px solid transparent;
    transition:0.5s all;

    &:hover{
        border: 3px solid var(--lightOrange);
        transition:0.5s all;
    }
}

.card{
    background: transparent;
  }

.views-img{
    position: absolute;
    top:1%;

    .view{
    .total-views-img{
        background: rgba(0,0,0,0.7);
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;
        color: var(--mainWhite);
        font-size:1rem;
        font-family: 'Open Sans', sans-serif; 
    }
    .fa-views{
        font-size:1.1rem;
    }
  }

  .view-sm{
    .total-views-img{
        background: rgba(0,0,0,0.7);
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;
        color: var(--mainWhite);
        font-size:0.9rem;
        font-family: 'Open Sans', sans-serif; 
    }
    .fa-views{
        font-size:1rem;
    }
  }
}  

.cast-name{
    font-family: 'Radio Canada', sans-serif;
    color: var(--mainWhite);
    font-weight:bold;
    font-size:1.1rem;
}

.view-more{
    color: var(--lightBlue2);
    cursor:pointer;
    transition:0.5s all;
    font-family: 'Open Sans', sans-serif; 
    font-size:1.1rem;
    font-weight:bold;
    transition:0.5s all;

    &:hover{
        color: var(--lightBlue);
        transition:0.5s all;
    }
}

`;