import React from 'react';
import { Banner } from "exoclick-react";
import useGeoLocation from "react-ipgeolocation";
import {isMobile} from 'react-device-detect';
import {isBrowser} from 'react-device-detect';
import axios from 'axios';

export const CelebVideoAd = () => {

  const location = useGeoLocation().country;

  const trackAffiliateOffer= async (id,name,type,provider,link)=>{

    const res = axios.get(`https://hutils.loxal.net/whois`);
    const ip = (await res).data.ip;

    let device="undetected";

    if (isMobile)
     device="mobile";

    if (isBrowser)
     device="desktop";
    

    const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
      affiliateLink:link,deviceType:device,ipAddress:ip,country:location};

      const save_url=process.env.REACT_APP_AFFILIATE;

      axios.post(`${save_url}`,detail,{
          headers:{
              'Content-Type':'application/json',
           //   'Authorization':'bearer '.concat(token)
          }
      });

  }

  return (
    <React.Fragment>

    <div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto stream-offer text-center d-none d-md-block mb-1'>

    {/* <a href="https://t.acam-2.com/226438/8059/27579?file_id=581156" target="_blank"><img src="https://i.ibb.co/cxB455W/006357-A-MYFC-18-ALL-EN-125-L.gif" width="728" height="90" border="0" className='mb-1'/></a> */}

         <a href="https://t.acam-2.com/226438/2994/31233" target="_blank"><img src="https://www.imglnkd.com/2994/008921A_ROYA_18_ALL_EN_125_L.gif" width="728" height="90" border="0" className='mb-2' onClick={()=>trackAffiliateOffer('CHR122','CR-CAM-CELEB-VIDEO','PPL','crakrevenue','https://t.acam-2.com/226438/2994/31233')}/></a>

     {/* <Banner zoneId={4827354}/>  */}

   </div>

<div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto stream-offer text-center d-md-none mb-2'>

{/* <iframe src="//a.realsrv.com/iframe.php?idzone=4827350&size=300x100" width="300" height="100" scrolling="no" marginwidth="0" marginheight="0" frameborder="0"></iframe> */}

<Banner zoneId={4870562}/> 

</div>

</React.Fragment>
  )
}
