import React, { useEffect, useState } from 'react'
import styledComponents from 'styled-components'
import { Alphabat } from '../common/Alphabat';
import { Footer } from '../footer/Footer';
import { TopBar } from '../Index/TopBar/TopBar';
import { CelebsList } from './CelebsList';
import { FilmList } from './FilmList';
import { Header } from './Header';
import { SelectHeader } from './SelectHeader';
import { StarList } from './StarList';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Alph } from '../common/Alph';
import { TopBarList } from '../Index/TopBar/TopBarList';
import { CelebMainAd } from '../ads/CelebMainAd';
import { Tags } from './Tags';
import { LiveBoard } from '../live/LiveBoard';
import { ConnectionStatus } from '../internet-connection/ConnectionStatus';
import { Helmet } from 'react-helmet';

export const Main = () => {

  // const canonicalUrl = window.location.href;

  // const [pageTitle, setPageTitle] = useState(`Browse Nude Celebs - A - Xcelebs`);
  // const [pageDescription, setPageDescription] = useState(`Browse Nude Celebrities Whose First Names Start With A at Xcelebs.`);

  const urlParams = new URLSearchParams(window.location.search);
  const pageParam = urlParams.get('page');

  const pageNum =  (pageParam) ? parseInt(pageParam, 10) : 1;

  const  h = window.location.pathname.split('/')[2];
  const ch = window.location.pathname.split('/')[3];

  const head = (h) ? h : 'celebs';
  const letr = (ch) ? ch : 'a';

  const [selectedTitle,setSelectedTitle] = useState(head);
  const [selectedLetter,setSelectedLetter]=useState(letr);
  const [selectedHeader,setSelectedHeader]=useState(head);
  const [flag,setFlag]=useState(false);
  const [isShow,setIsShow]=useState(false);


  let navigate = useNavigate();
  const location = useLocation();

  useEffect(()=>{

    window.scrollTo(0, 0);

    var title = 'Nude Celebs';

    if(selectedHeader=='celebs')
       title = 'Nude Celebs';
    else  if(selectedHeader=='pornstars')   
       title = 'Pornstars';
    if(selectedHeader=='movies')   
       title = 'Movies';

    // title=`Browse ${title} - ${(selectedLetter) && selectedLetter.toUpperCase()} - Xcelebs`;

    // const description = `Browse ${title} Whose First Names Start With A at Xcelebs.`;

    // setPageTitle(title);
    // setPageDescription(description);

    document.body.style.zoom="100%";
   
  },[selectedHeader,selectedLetter]);

 
  useEffect(()=>{

    var check = document.getElementById('checkMenu');
    check.checked = false;

    setFlag(true);

    if(window.location.href.split("/").length>4){

     const select = window.location.href.split("/")[4].toLowerCase();

     if(select.includes('celebs'))
       return setSelectedHeader('celebs');
     if(select.includes('pornstars'))  
      return setSelectedHeader('pornstars');
      if(select.includes('movies'))
      return setSelectedHeader('movies');
    }else
      return setSelectedHeader('celebs')

 },[]);

 const changeCheckHandler=(flag)=>{
  setIsShow(flag);
  var check = document.getElementById('checkMenu');
  check.checked = flag;
}



  useEffect(()=>{

    window.scrollTo(0, 0);
    
    if(location.state){
     setSelectedLetter(location.state.letter);
     navigate(`/browse/celebs/${location.state.letter}`);
    }
    //  if(location.split('?').length>=2){
    //    const alph = location.split('?')[1].split('&')[1].split('=')[1];
    //    setSelectedLetter(alph);
    //    navigate(`/browse`)
    //  }
  
  },[location.state]);

  const changeSelectedHaeder=(name)=>{
        setSelectedTitle('nude '.concat(name));
        setSelectedHeader(name);
        // navigate(`/browse/${selectedHeader}/${selectedLetter}`);
  }

  const changeLetterHandler=(letter)=>{
      setSelectedLetter(letter);
      // navigate(`/browse/${selectedHeader}/${letter}`);
  }

  return (
    <MainContainer>
      {/* <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet> */}
        <div className='container-fluid'>
                <div className='row'>

                <input type="checkbox" id="checkMenu"/>
                  
                <div className='top-bar'>
                <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
                   </div>

                   <div className='top-bar-list'>
                             <TopBarList/>
                  </div>


                <div className='alph-header mb-2 mt-1 d-none d-md-block'>
                          <Alph changeLetter={changeLetterHandler} selectedType={selectedHeader}/>
                     </div>

                     <div className='alph-header mb-2 mt-1 d-md-none'>
                         <Alphabat changeLetter={changeLetterHandler} selectedType={selectedHeader}/>
                     </div>

                            <div className='header-tag'>
                             { (selectedTitle && selectedLetter) && <Header title={selectedTitle.toUpperCase()} alph={selectedLetter.toUpperCase()}/> }
                          </div>

                          <CelebMainAd/>

                     <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto select-div'>
                          { (selectedHeader) && <SelectHeader selectedHeader={changeSelectedHaeder} header={selectedHeader} /> }
                     </div>
                     <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto list-div mb-4'>
                           { (selectedHeader && selectedLetter && selectedHeader.toLowerCase()==='celebs' && flag) && <CelebsList letter={selectedLetter} /> }
                           { (selectedHeader && selectedLetter && selectedHeader.toLowerCase()==='pornstars' && flag) && <StarList letter={selectedLetter} /> }
                           { (selectedHeader && selectedLetter && selectedHeader.toLowerCase()==='movies' && flag) && <FilmList letter={selectedLetter} /> }
                      </div>

                      <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto live-div'>
                <LiveBoard/>
            </div>

                      <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto trending-tags mb-5'>
               <Tags/>
             </div>
             

                      <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                        <Footer/>
                      </div>
                </div>
        </div>

             <ConnectionStatus/>

    </MainContainer>
  )
}

const MainContainer = styledComponents.div`

background: var(--lightBlack);

.select-div{
  padding:0 !important;
  margin:0 !important;
}

.list-div{
  // padding:0 !important;
  // margin:0 !important;
}

@media (min-width: 1024px) {
  .top-bar {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
  // padding:0 !important;
  // margin:0 !important;
} 

.footer-div{
  padding:0 !important;
  margin:0 !important;
}

#checkMenu{
  display:none;
}

.top-bar-list{
  padding:0 !important;
  margin:0 !important;
  overflow: hidden;
  position:relative;
  max-height: 0;
  transition: max-height 0.5s ease;
}

#checkMenu:checked ~ .top-bar-list{
  max-height: 300px;
  transition: max-height 0.5s ease;
}

.trending-tags{
  // padding:0 !important;
  // margin:0 !important;
 }

`;