import React, { useEffect } from 'react';
import { Banner } from "exoclick-react";
import useGeoLocation from "react-ipgeolocation";
import {isMobile} from 'react-device-detect';
import {isBrowser} from 'react-device-detect';
import axios from 'axios';

export const CelebAd = () => {

    useEffect(()=>{

        const script = document.createElement('script');
    
      script.src = "https://poweredby.jads.co/js/jads.js";
      script.async = true;
    
      document.body.appendChild(script);
    
      },[]);

      const location = useGeoLocation().country;

      const trackAffiliateOffer= async (id,name,type,provider,link)=>{

        const res = axios.get(`https://hutils.loxal.net/whois`);
        const ip = (await res).data.ip;
    
        let device="undetected";
    
        if (isMobile)
         device="mobile";
    
        if (isBrowser)
         device="desktop";
        
    
        const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
          affiliateLink:link,deviceType:device,ipAddress:ip,country:location};
    
          const save_url=process.env.REACT_APP_AFFILIATE;
    
          axios.post(`${save_url}`,detail,{
              headers:{
                  'Content-Type':'application/json',
               //   'Authorization':'bearer '.concat(token)
              }
          });
    
      }

  return (
    <React.Fragment>

         <div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto stream-offer text-center d-none d-md-block mb-1'>

         {/* <ins id="1000109" data-width="728" data-height="90" className='mb-2'></ins> */}

         <a href="https://t.acam-2.com/226438/2994/31233" target="_blank"><img src="https://www.imglnkd.com/2994/008921A_ROYA_18_ALL_EN_125_L.gif" width="728" height="90" border="0" className='mb-2' onClick={()=>trackAffiliateOffer('CHR120','CR-CAM-CELEB','PPL','crakrevenue','https://t.acam-2.com/226438/2994/31233')}/></a>
      
        </div>

<div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto stream-offer text-center d-md-none mb-2'>

{/* <ins id="1000010" data-width="300" data-height="100" className='mb-1'></ins> */}

<Banner zoneId={4852892}/>

    </div>

    </React.Fragment>
  )
}
