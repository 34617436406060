import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import styledComponents from 'styled-components'
import { AdComponent } from '../ads/AdComponent';
import { TagLoading } from '../loading/TagLoading';

export const Tags = () => {

   const[tags,setTags]=useState();
   const [loading,setLoading] = useState(true);

   const [isOnline, setIsOnline] = useState(navigator.onLine);

   useEffect(() => {

    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };

  }, []);


  useEffect(() => {

    const fetchData = async () => {
        try {
            const tag_url = process.env.REACT_APP_TAG;

            setLoading(true);

            if (!isOnline)
                return;

            const response = await axios.get(`${tag_url}/trending`, {
                headers: {
                    // 'Authorization':'bearer '.concat(token)
                }
            });

            setTags(response.data);
            setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
    };

    fetchData();

}, [isOnline]);


  // const redirectToTag=(tag)=>{
  //     return navigate(`/browse/tags/${tag.replaceAll(" ","_")}`);
  // }

  const redirectToSearch=(tag)=>{
    // tag=encodeURIComponent(tag);
    // return navigate(`/search?q=${tag.replaceAll("%20","+")}`);
   return (tag) && `/search?q=${encodeURIComponent(tag.toLowerCase()).replaceAll("%20","+")}`
  }


  return (
     <TagContainer>
                         { (!loading) && <div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto tags px-2 mb-5'>
                           <div className='row'>
                                { (tags) && tags.map((tag)=>{
                                return( <React.Fragment key={tag.tagId}>
                                           {/* <div className='col-md-3 col-sm-4 col-lg-2 col-6 mx-auto tag text-center my-1 text-capitalize' onClick={()=>redirectToSearch(tag.tagName)}>
                                             <span className='tag-name text-uppercase'>{tag.tagName}</span>
                                               </div> */}

                                               <Link className='tag text-center my-2' to={redirectToSearch(tag.tagName)}>
                                             <span className='tag-name text-uppercase'>{tag.tagName}</span>
                                               </Link> 
                                               
                                  </React.Fragment>
                                 );  })
                                }
                           </div>
                        </div>   }

                        {
                          (loading) && <TagLoading/>
                        }

                        <AdComponent/>
              
     </TagContainer>
  )
}

const TagContainer=styledComponents.div`

background: var(--lightBlack);

.tag{

  width: auto;
  //background: var(--lightDark2);
  background-color: #272727;
  padding:0.5rem 1.5rem;
 // transform: scale(0.9);
  cursor:pointer;
  display:inline-block;
  border-radius:0.3rem;
  transition: 0.5s all;
  margin: 0.3rem 1rem;
  text-align: center;
  text-decoration: none;

  &:hover{
    background: var(--lightOrange);
    transition: 0.5s all;
  }
}

@media (max-width: 1024px) {
  .tag{
  margin: 0.3rem 0.6rem;
  }
}

.tag .tag-name{
 // color: var(--mainWhite);
  color: #fff;
  font-family: 'Open Sans', sans-serif; 
  //  font-family: 'Radio Canada', sans-serif;
  // font-family: 'Montserrat', sans-serif;
 // font-family: 'Karla', sans-serif;
  //font-family: 'Josefin Sans', sans-serif;
  font-weight:bold;
  letter-spacing:0.06em;
  font-size:0.8rem;
}

`;