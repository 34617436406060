import styledComponents from "styled-components";

export const Browse= styledComponents.button`

color: var(--mainWhite);
background-color: var(--lightDark2);
padding: 0.5rem 1.5rem;
font-size:1rem;
text-transform: capitalize;
border:none;
outline:none;
border-radius:0.4rem;
font-family: 'Open Sans', sans-serif; 
font-weight:bold;
transition:0.5s all;

&:hover{
    background-color: var(--lightOrange);
    transition:0.5s all;
}

`;