import React from 'react';
import ReactCountryFlag from "react-country-flag";
import {isMobile} from 'react-device-detect';
import {isBrowser} from 'react-device-detect';
import axios from 'axios';

export const Creator = ({cast,offer_type}) => {


      const redirectToCeleb = async (affi_url, platform) => {

        try {
            const response = await axios.get('https://ipapi.co/json/');
            const data = response.data;
            const country = data.country_name;
    
            await trackAffiliateOffer('CRT155', platform, 'PPL', offer_type, affi_url, country);
    
            return (affi_url) && window.open(affi_url);
            
        } catch (error) {
            console.error('Error fetching country:', error);

            await trackAffiliateOffer('CRT155', platform, 'PPL', offer_type, affi_url, '');
    
            return (affi_url) && window.open(affi_url);
        }
    };

      const trackAffiliateOffer= async (id,name,type,provider,link,country)=>{

            const res = axios.get(`https://hutils.loxal.net/whois`);
            const ip = (await res).data.ip;
          
            let device="undetected";
          
            if (isMobile)
             device="mobile";
          
            if (isBrowser)
             device="desktop";
            
          
            const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
              affiliateLink:link,deviceType:device,ipAddress:ip,country:country};
          
              const save_url=process.env.REACT_APP_AFFILIATE;
          
              axios.post(`${save_url}`,detail,{
                  headers:{
                      'Content-Type':'application/json',
                   //   'Authorization':'bearer '.concat(token)
                  }
              });
          
      }

      return (
     <React.Fragment>
           <div className="col-6 col-lg-2 col-md-3 col-sm-4 mx-auto item" onClick={()=>redirectToCeleb(cast.affiliateUrl,cast.platform)}>

           <div className="card">

           <div className="img-container">
           <img src={cast.imgUrl} alt={`${cast.displayName} ${cast.platform}`} title={`${cast.displayName} ${cast.platform}`} className="card-img-top" style={{width:'100%',height:'17rem'}}/> 
           </div>

           </div> {/*card*/}

           <div className="card-footer">
                {(cast.displayName) && <h6 className='cast-name text-center px-2 text-uppercase'>{cast.displayName}</h6> }
            </div>

           <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 top text-start">

<div className="view ps-1">
{  (cast.imgUrl) && <span className='total-views-img'>Top <span className='top-per pe-1'> {`${cast.top}%`}</span></span> }
 </div>

 </div> {/*top*/}

 <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 country text-end">

<div className="flag pe-1">
{  (cast.imgUrl && cast.countryCode) && <span className='flag-img'>  <ReactCountryFlag
     countryCode={cast.countryCode}
     svg
     style={{
         width: '2em',
         height: '1.2em',
     }}
     title={cast.countryCode}
 />
 </span> }
 </div>

 </div> {/*country*/}

           </div>
     </React.Fragment>
  )
}
