import React from 'react';
import {VideoJs} from './VideoJs';
import { useParams } from 'react-router-dom';

export const VideoJsDecode = () => {

    const { embedVideo } = useParams();

    const decodedEmbedVideo = JSON.parse(decodeURIComponent(embedVideo));
    
    return (decodedEmbedVideo) && <VideoJs embedVideo={decodedEmbedVideo}/>
}
