import axios from 'axios';
import React,{useState,useEffect} from 'react'
import { Footer } from '../footer/Footer';
import { TopBar } from '../Index/TopBar/TopBar';
import { Tags } from '../stars/Tags';
import styledComponents from 'styled-components'
import { ImgLoadingList } from '../loading/ImgLoadingList';
import { TopBarList } from '../Index/TopBar/TopBarList';
import { LiveBoard } from '../live/LiveBoard';
import { ConnectionStatus } from '../internet-connection/ConnectionStatus';
import { CreatorList } from './CreatorList';
import { Helmet } from 'react-helmet';
import { CreatorBoard } from './CreatorBoard';

export const PremiumCreatorList = () => {

  // const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Best Premium OnlyFans Creators & MYM Accounts 2024 - Xcelebs`);
  const [pageDescription, setPageDescription] = useState(`Best Premium OnlyFans Creators You Shouldn't Miss in Sep 2024`);

    const [isShow,setIsShow]=useState(false);

    const [creators,setCreators]=useState([]);
    const [loading,setLoading]=useState(true);

    const [currentYear, setCurrentYear] = useState('2024');
    const [previousMonth, setPreviousMonth] = useState('');

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
    
        const previousMonthDate = new Date(year, month - 1);
    
        setPreviousMonth(previousMonthDate.toLocaleString('default', { month: 'short' }));
      }, []);

    useEffect(() => {

      const handleOnline = () => {
        setIsOnline(true);
      };
  
      const handleOffline = () => {
        setIsOnline(false);
      };
  
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
  
      return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
      };

    }, []);

    useEffect(()=>{

      var check = document.getElementById('checkMenu');
      check.checked = false;
      window.scrollTo(0, 0);
     
    },[]);

    useEffect(()=>{

      const title=`Best Premium OnlyFans Creators & MYM Accounts 2024`;
      const desc = `Best Premium OnlyFans Creators You Shouldn't Miss in ${previousMonth} ${currentYear}`;

      setPageTitle(title);
      setPageDescription(desc);
      document.title = title;
      document.body.style.zoom="100%";

    },[previousMonth]);

    
    useEffect(() => {

      const fetchData = async () => {

        try {
          const creator_url = process.env.REACT_APP_CREATOR;

          setLoading(true);
    
          if (!isOnline)
            return;
    
          const response = await axios.get(`${creator_url}/list/premium`);
          setCreators(response.data.creatorList);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      };
    
      fetchData();
    }, [isOnline]);
    

    const changeCheckHandler=(flag)=>{
      setIsShow(flag);
      var check = document.getElementById('checkMenu');
      check.checked = flag;
  }
   
  return (
    <TopCreatorContainer>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        {/* <link rel="canonical" href={canonicalUrl} /> */}
      </Helmet>
    <div className='container-fluid'>
       <div className='row'>

       <input type="checkbox" id="checkMenu"/>

       <div className='top-bar'>
       <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
       </div>

       <div className='top-bar-list'>
                             <TopBarList/>
                             </div>

                     <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto celeb-header text-center mt-4'>
                               <h1 className='c-header px-2'>Best <span className='high'>Premium</span> OnlyFans Creators</h1>
                   </div>

                   <div className='select-of-type-div'>
                       <CreatorBoard/>
                   </div>

      { (!loading) &&  <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto celeb-div mb-4'>
                           {(creators && creators.length>=1) && <CreatorList creators={creators} offer_type="premium-onlyfans"/> } 
            </div> }

            {
              (loading) &&  <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto celeb-div'> <ImgLoadingList/> </div>
            }

<div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto live-div'>
                <LiveBoard/>
            </div>

      <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto trending-tags mb-5'>
         <Tags/>
       </div>
       
       <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                  <Footer/>
       </div>

       </div>
       </div>

        <ConnectionStatus/>

</TopCreatorContainer>
  )
}


const TopCreatorContainer=styledComponents.div`

background: var(--lightBlack);


.celeb-div{
  //padding:0 !important;
 // margin:0 !important;
} 

@media (min-width: 1024px) {
  .top-bar {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
  // padding:0 !important;
  // margin:0 !important;
}

  .trending-tags{
    // padding:0 !important;
    // margin:0 !important;
  }
  
  .footer-div{
    padding:0 !important;
    margin:0 !important;
  }

  #checkMenu{
    display:none;
  }
  
  .top-bar-list{
    padding:0 !important;
    margin:0 !important;
    overflow: hidden;
    position:relative;
    max-height: 0;
    transition: max-height 0.5s ease;
  }
  
  #checkMenu:checked ~ .top-bar-list{
    max-height: 300px;
    transition: max-height 0.5s ease;
  }

.c-header{
    color: var(--mainWhite);
    font-weight:bold;
    font-family: 'Karla', sans-serif;
    font-size:1.6rem;

    .high{
        color: var(--lightOrange);
      }
}

@media (min-width: 1024px) {
  .c-header{
    font-size: 2rem;
  }
}

.select-of-type-div{
  margin-top: 0.8rem;
  margin-bottom: 0.2rem; 
}

`;