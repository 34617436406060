import axios from 'axios';
import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router';
import styledComponents from 'styled-components'
import { ImgLoadingList } from '../loading/ImgLoadingList';
import { Celeb } from './Celeb';
// import { Pagination } from './Pagination';
import { Pagination } from '../pagination/Pagination';
import { Helmet } from 'react-helmet';

export const CelebsList = ({letter}) =>{

  const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Browse Nude Celebs - A - Xcelebs`);
  const [pageDescription, setPageDescription] = useState(`Browse Nude Celebrities Whose First Names Start With A at Xcelebs.`);

  const [nextPageUrl,setNextPageUrl]=useState('');
  const [prevPageUrl,setPrevPageUrl]=useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const pageParam = urlParams.get('page');

  const pageNum =  (pageParam && window.location.pathname.split('/')[2].includes('celebs')) ? parseInt(pageParam, 10) : 1;

   const [celebs,setCelebs] = useState([])
   const [pages,setPages]=useState(0);
   const [currentPage,setCurrentPage]=useState(pageNum);
   const [currentBtn,setCurrentBtn]=useState(pageNum);
   const [pageLink,setPageLink]=useState(null);
   const [loading,setLoading]=useState(true);

   const [isOnline, setIsOnline] = useState(navigator.onLine);

   const navigate = useNavigate();

   const itemPerPage=24;

   const currentPageHandler=page=>{
       setCurrentPage(page);
   }

   useEffect(() => {

    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };

  }, []);

  useEffect(()=>{
    setCurrentBtn(pageNum);
},[pageNum]);

   useEffect(()=>{
    document.body.style.zoom="100%";
    setPageLink(`/browse/celebs/${letter}`);
    setCurrentPage(pageNum);
    if(pageNum==1 || !window.location.pathname.split('/')[2].includes('celebs')){
    const title=`Browse Nude Celebs - ${(letter) && letter.toUpperCase()} - Xcelebs`;
    const description = `Browse Nude Celebrities Whose First Names Start With ${(letter) && letter.toUpperCase()} at Xcelebs.`;

    setPageTitle(title);
    setPageDescription(description);

    document.title = title;

    setNextPageUrl(`https://xcelebs.org/browse/celebs/${letter}?page=${2}`);

    navigate(`/browse/celebs/${letter}`);
    
    }else{

    const title=`Browse Nude Celebs - ${ (letter) && letter.toUpperCase()} - Page ${pageNum} - Xcelebs`;

    const description = `Page ${pageNum}:Browse Nude Celebrities Whose First Names Start With ${(letter) && letter.toUpperCase()} at Xcelebs.`;

    setPageTitle(title);
    setPageDescription(description);

    document.title = title;

    setNextPageUrl(`https://xcelebs.org/browse/celebs/${letter}?page=${pageNum+1}`);

    if(pageNum==2)
         setPrevPageUrl(`https://xcelebs.org/browse/celebs/${letter}`);
        else
         setPrevPageUrl(`https://xcelebs.org/browse/celebs/${letter}?page=${pageNum-1}`);

    }
  },[letter,pageNum]);

useEffect(() => {

  const fetchData = async () => {

      try {
          if (isNaN(currentBtn))  
              return;   

              if (currentBtn === 1) {
                window.scrollTo(0, 0);
            } else {
                if (window.innerWidth >= 1300) { 
                    window.scrollTo(0, 140);
                }  else if (window.innerWidth >= 700) {
                    window.scrollTo(0, 180);
                } else
                    window.scrollTo(0, 220);
            }

          const celeb_url = process.env.REACT_APP_CELEB;
          const type = "celeb";

          setLoading(true);

          if (!isOnline)
              return;

          const response = await axios.get(`${celeb_url}/character-list?type=${type}&letter=${letter}&page=${pageNum}`);

          setCelebs(response.data.celebList);
          setPageHandler(response.data.numOfItems);
          setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
  };

  fetchData();

}, [letter,currentBtn,isOnline,pageNum]);

   const setPageHandler =length=>{
 
    const numOfItems=length;     
    let page = parseInt(numOfItems/itemPerPage);

    if(parseInt(numOfItems%24)!==0)
     page+=1;

     setPages(page);
   };


  return (
     <CelebContainer>
       <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
        { (prevPageUrl) && <link rel="prev" href={prevPageUrl} />}
        { (nextPageUrl && pages !==1 && currentBtn !==pages) && <link rel="next" href={nextPageUrl} />}
      </Helmet>
         <div className="container-fluid">
            <div className="row">
            { (!loading) &&  <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 item-list">
            
            <div className="row">

            { (celebs) && celebs.map((celeb)=><Celeb key={celeb.castId} cast={celeb}/>)}

{/* { (currentBtn%2==0 ? <Celeb_Offer_2/> : <Celeb_Offer_1/> )  }

{ (celebs) && celebs.slice(12,24).map((celeb)=><Celeb key={celeb.castId} cast={celeb}/>)} */}


             </div>
            </div> }

            {
                        (loading) && <ImgLoadingList/>
             }

        { (celebs && celebs.length>=1 && !loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 pages">

            <Pagination pages={pages} currentPageHandler={currentPageHandler}
                link={pageLink} currentBtn={currentBtn} setCurrentBtn={setCurrentBtn}/> 

            </div> }

            {/* <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 trending-tags mt-4 mb-5">
                <Tags/>
            </div> */}

            </div>
            </div>
     </CelebContainer>
  )
}

const CelebContainer=styledComponents.div`

background: var(--lightBlack);

.item-list .item{
    position:relative;
    padding:0 !important;
    margin:0 !important;
    transform: scale(0.8);
    cursor:pointer;
    transition:0.5s all;
    text-decoration: none;

    &:hover{
        .cast-name{
        color: var(--lightOrange);
        transition:0.5s all;
        }
        .img-container{
            border: 3px solid var(--lightOrange);
            transition:0.5s all;
        }
    }
}

.img-container{
    cursor:pointer;
    border: 3px solid transparent;
    transition:0.5s all;

    &:hover{
        border: 3px solid var(--lightOrange);
        transition:0.5s all;
    }
}

.card{
    background: transparent;
  }


.cast-name{
    // font-family: 'Radio Canada', sans-serif;
    font-family: 'Montserrat', sans-serif;
    color: var(--mainWhite);
    font-weight:bold;
    font-size:1.1rem;
    letter-spacing:0.02em;
    margin: 0.8rem 0;
}

.view-more{
    color: var(--lightBlue2);
    cursor:pointer;
    transition:0.5s all;
    font-family: 'Open Sans', sans-serif; 
    font-size:1.1rem;
    font-weight:bold;
    transition:0.5s all;

    &:hover{
        color: var(--lightBlue);
        transition:0.5s all;
    }
}

`;