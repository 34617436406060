import React from 'react'
import styledComponents from 'styled-components'
import { Celeb } from './Celeb';

export const ImgLoading = () => {

    const items=[1,2,3,4,5,6];
  return (
     <ImgLoadingContainer>
              <div className="col-12 col-lg-12 col-md-12 col-sm-12 mx-auto load-item-div d-none d-lg-block px-2">
                    <div className='row'>
                   { items.slice(0,6).map((item,index) => <Celeb key={index}/>) }
                </div>
                </div>
                
                <div className="col-12 col-lg-12 col-md-12 col-sm-12 mx-auto load-item-div d-lg-none">
                    <div className='row'>
                   { items.slice(0,4).map((item,index) => <Celeb key={index}/>) }
                </div>
       </div>
     </ImgLoadingContainer>
  )
}

const ImgLoadingContainer=styledComponents.div`

background: var(--lightBlack);

.load-item{
    background: transparent;
  // background: rgb(73, 73, 73) !important;
    transform: scale(0.8);
  }

  .card{
    border:none !important;
  } 

`;

