import React, {useEffect,useRef,useState} from 'react';
import styledComponents from 'styled-components';
import videojs from 'video.js'; 
import 'video.js/dist/video-js.css'; 
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import axios from 'axios';
import { PlayerError } from './PlayerError';

export const VideoJs = ({embedVideo}) => {

  // const [playerError, setPlayerError] = useState(false);
  const isFirstPlayRef = useRef(true);
  const [isVideoViewed, setIsVideoViewed] = useState(false);

  const ad_direct_link="https://realistic-negotiation.com/bN3-V.0uP/3CpivNb/mYVHJoZmDi0D0sOKDOEl2aMOD/YQz_LGTQQX4/MQTAY/wcNMjrQe";

  const detectDevice=()=>{

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile;

  }

  const videoStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    color: 'var(--mainWhite)',
  };

  const getCurrentWatchedVideoData = (w_key) => {
    const cachedData = localStorage.getItem(w_key);
    return cachedData ? JSON.parse(cachedData) : { ids: [], expirationTime: 0 };
};

  const setWatchedVideoIds = (newVideoId) => {

      const w_key = "w-vids";
       
      const { ids, expirationTime } = getCurrentWatchedVideoData(w_key);
      const currentTime = new Date().getTime();

      const updatedVideoIds = [...ids, newVideoId];

      let updatedExpirationTime = expirationTime;

      if (!expirationTime || expirationTime <= currentTime) {
        updatedExpirationTime = currentTime + 24 * 60 * 60 * 1000;
      }
  
      const updatedData = {
        ids: updatedVideoIds,
        expirationTime: updatedExpirationTime
      };
  
      localStorage.setItem(w_key, JSON.stringify(updatedData));

  }

  const containsExactId = (arr, targetId) => {
    for (const id of arr) {
        if (id === targetId) {
            return true;
        }
    }
    return false;
};

  const checkWatchedVideoId = (videoId) => {

    const w_key = "w-vids";

    const { ids, expirationTime } = getCurrentWatchedVideoData(w_key);
    const currentTime = new Date().getTime();
    
    if (currentTime >= expirationTime) {
      return false;
    }

    return containsExactId(ids, videoId);
};

const updateVideoCount = async (videoId)=>{
    
  const video_url = process.env.REACT_APP_CELEB_VIDEO;

  const request = {
    videoId: videoId
  }

  try {
     const response = await axios.post(`${video_url}/updateCount`,request);
     return { success: response.data.status, message: response.data.message };
   } catch (error) {
      console.log(error);
      return { success: false, message: 'Failed to update video count' };
    }

}

useEffect(() => {

  const fetchDataAndUpdate = async () => {
      const videoId = embedVideo && embedVideo.videoId;
      
      if (isVideoViewed && !checkWatchedVideoId(videoId)) {
          setWatchedVideoIds(videoId);
          await updateVideoCount(videoId);
      }
      
  };

  fetchDataAndUpdate();
}, [isVideoViewed, embedVideo.videoId]);


  useEffect(() => {

    let player;

    if (embedVideo.embedId && document.getElementById('my-player')) {

       player = videojs('my-player');
      
      // setIsVideoJsLoaded(true);

      // Listen for the 'play' event only once
      const playHandler = () => {
        if (isFirstPlayRef.current) {
          // Your code to handle the first play goes here
          if(detectDevice())
          window.open(ad_direct_link);
          else
          window.open(ad_direct_link);
          isFirstPlayRef.current = false; // Mark the first play as handled
        }
      };

      const handlePlayerError = () => {
        // Refresh the player when an error occurs
        if (embedVideo.hlsUrl) {
          // setPlayerError(true);
          window.location.reload();
        }
      };

      player.hlsQualitySelector({
        displayCurrentQuality: true,
      });

      player.fluid(true);
      player.aspectRatio('16:9');
      player.fill(true);

      player.on('play', playHandler);
      player.on('error', handlePlayerError);

      let isTenPercentWatched = false;

      player.on('timeupdate', () => {

        // Calculate 10% of the video duration
        const tenPercentDuration = player.duration() * 0.1;

        // Check if the current time is greater than or equal to 10% of the video duration
        if (!isTenPercentWatched && player.currentTime() >= tenPercentDuration && tenPercentDuration > 0) {
          isTenPercentWatched = true;
          setIsVideoViewed(true);
        }
      });

    }

    return () => {
      if (player) {
        player.dispose(); // Dispose of the player to prevent memory leaks
      }
    };

  }, [embedVideo.embedId,embedVideo]);


  useEffect(() => {
    const player = videojs('my-player');
    let isPlayerPlaying = false; // Variable to store player's current state
    let wasPlayerPlayingBeforeHidden = false; // Variable to store player's state before tab was hidden

    // Check if the player is playing
    const checkPlayerState = () => {
      isPlayerPlaying = !player.paused();
    };

    // Event listener for visibility change
    const handleVisibilityChange = () => {
      // Check if the player is playing
      checkPlayerState();

      if (document.hidden) {
        // Store player's state before tab is hidden
        wasPlayerPlayingBeforeHidden = isPlayerPlaying;

        // Pause the video if it's currently playing
        if (isPlayerPlaying) {
          player.pause();
        }
      } else {
        // Resume playing the video if it was playing before becoming hidden
        if (wasPlayerPlayingBeforeHidden) {
          player.play();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);


  return (
    <React.Fragment>

         {/* { (playerError) && <PlayerError/>} */}

     { (embedVideo.hlsUrl || embedVideo.embedUrl) && <PlayerContainer>
   <div className="video-container">
   <video
    key={embedVideo.videoId}
    id="my-player"
    className="video-js vjs-default-skin vjs-big-play-centered"
    controls
    preload="auto"
    // poster="https://vz-2338e013-b0c.b-cdn.net.b-cdn.net/f63cbe33-12b3-46e0-92cf-4ab5bfa6ff5f/thumbnail.jpg"
    style={videoStyle}
    data-setup='{}'
    >

       {/* HLS source */}
    {embedVideo.hlsUrl && (
    <source src={embedVideo.hlsUrl} type="application/x-mpegURL"/>
  )}

       {/* MP4 source */}
 {embedVideo.embedUrl && (
    <source src={embedVideo.embedUrl} type="video/mp4"/>
  )}

{/* WebM source */}
{embedVideo.embedUrl && (
    <source src={embedVideo.embedUrl} type="video/webm"/>
  )}

  </video> 
  </div> 
  </PlayerContainer> }
  </React.Fragment>
);
};

const PlayerContainer=styledComponents.div`

background: transparent !important;

.video-container{
  position: relative;
  width: 100%;
  height: 315px;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .video-container{
    height: 600px;
  }
}


.video-js .vjs-control-bar {
  font-size: 12px;
  display: flex;
  // background: transparent;
  background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for transparency */
}


.video-js .vjs-control-bar {
  font-size: 12px;
}

.video-js .vjs-play-control:before {
  font-size: 12px;
}

.video-js .vjs-volume-panel .vjs-volume-level:before {
  font-size: 5px;
}

.video-js .vjs-progress-holder .vjs-play-progress {
  background-color: var(--lightOrange) !important;
}

// @media (max-width: 768px) {
//   .video-js .vjs-volume-panel {
//     display: none;
//   }
// }

@media (max-width: 768px) {
  .video-js .vjs-control-bar .vjs-picture-in-picture-control {
    display: none;
  }
}

@media (max-width: 768px) {
.video-js .vjs-progress-control {  
  position: absolute;
  bottom: 30px; /* The height of the ControlBar minus 4px. */
  left: 0;
  right: 0;
  padding-left:10px;
  padding-right:10px;
  width: 100%;
  height: 20px; /* the height must be reduced from 30 to 10px in order to allow the buttons below (e.g. play) to be pushed */
}

.video-js .vjs-progress-holder {/* needed to have a real 100% width display. */
  margin-left: 0px;
  margin-right: 0px;
}

.video-js .vjs-control-bar {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video-js .vjs-play-control,
.video-js .vjs-volume-panel,
.video-js .vjs-duration-display, {
  order: 1; /* Keep play and volume icons on the left */
}

.video-js .vjs-fullscreen-control,
.video-js .vjs-quality-selector {
  order: 3; /* Place fullscreen and quality selector icons on the right */
}

}

.video-js .vjs-time-control {
    display: block;
}
.video-js .vjs-remaining-time {
    display: none;
}

`;
