import React from 'react'
import styledComponents from 'styled-components'

export const Title = ({name}) => {
  return (
     <TitleContainer>
         <div className='container-fluid'>
                <div className='row'>
                <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 main-title text-center">
             <h1 className='title'>Browse {name} at <span className='x'>Xcelebs</span></h1>
                     </div>
                </div>
         </div>
     </TitleContainer>
  )
}

const TitleContainer=styledComponents.div`

background: var(--lightBlack);

.main-title{
    .title{
        color: var(--mainWhite);
        font-size:2rem;
        font-family: 'Karla', sans-serif;
        font-weight:bold;
    }
    .x{
        color: var(--lightOrange);
    }
 }

`;