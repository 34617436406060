import React from 'react'
import { Link } from 'react-router-dom';
import styledComponents from 'styled-components'

export const SearchListSm = ({names,redirectToSearch}) => {

  return (
    <SearchContainer>
                <div className='container search-list'>
                     <div className='row'>
                 { names.map(name=>{
                return (
                   <Link className='col-12 mx-auto col-md-12 col-lg-12 col-xl-12 text-capitalize search-name text-start' onClick={()=>redirectToSearch(name)} to={`/search?q=${encodeURIComponent(name).replaceAll("%20","+")}`}>

                   <div className="cast-name px-2 py-1">
                          <span>{name}</span>
                    </div>  

                    </Link>  ); 
                      })

                  }
                   </div>
                </div>
    </SearchContainer>
  )
}

const SearchContainer=styledComponents.div`

.container{
  margin: 0;
  padding: 0;
}

.search-list{
  border: 1px solid var(--lightGrey);
  background: var(--lightDark2);
  border-radius:0.2rem;
}

.search-name{
 text-decoration:none;
}

background: var(--lightDark2);

.cast-name{
    color: var(--lightGrey);
    font-family: 'Karla', sans-serif;
    font-size:0.9rem;
    cursor:pointer;
    transition: 0.3s all;

    &:hover{
      color: var(--mainWhite);
      transition: 0.3s all;
    }
}


`;