import React from 'react'


export const Celeb = () => {


  return (
     <React.Fragment>
           <div className="col-6 col-lg-2 col-md-3 col-sm-4 mx-auto item my-2">

           <div className="card load-item">

           <div className="load-img-container">
           <img className="card-img-top" style={{width:'100%',height:'17rem'}}/> 
           </div>

        
           </div> {/*card*/}
           </div>
     </React.Fragment>
  )
}
