import React,{useEffect, useRef, useState,useContext} from 'react';
import styledComponents from 'styled-components';
import { RegisterButton } from './RegisterButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export const Login = () => {

    const [login,setLogin]=useState({email:'',pwd:''});
    const [error,setError]=useState({email:'',pwd:''});
    const [hasError,setHasError]=useState(false);
    const [invalid,setInvalid]=useState(false);
    const [submit,setSubmit]=useState(false);

    const siteKey = process.env.REACT_APP_SITE_KEY;

  //  const getUserInfo = useContext(UserContext).getUserInfo;

    const inputRef=useRef();

    let navigate = useNavigate();

    let logImg = "/logo.jpg";

    const closeLoginModal=flag=>{
       // openLoginModal(flag);
    }

    const redirectToForget=()=>{
       return '/reset';
    }

    useEffect(()=>{
        const script = document.createElement('script');
        script.src=`https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        document.body.appendChild(script);

        if(inputRef.current!==null)
          inputRef.current.focus();
    },[]);

    const validationChack=()=>{
         if(login.email.trim().length<=0){
            setHasError(true);
            setError({...error,email:'**Email is required**',pwd:''});
           return true;
         }
         if(!emailRegExp()){
             setHasError(true);
             setError({...error,email:'**invalid email**',pwd:''});
             return true;
         }
         if(login.pwd.trim().length<=7){
             setHasError(true);
             setError({...error,email:'',pwd:'**Password is required**'});
             return true;
         }
         setError({...error,email:'',pwd:''});
         return false;
    }

    const emailRegExp=()=>{
        if(login.email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/))
         return true;
    }

    const submitHandler=(e)=>{
            e.preventDefault();

            if(validationChack())
              return;

              setSubmit(true);

                window.grecaptcha.ready(()=>{
                    window.grecaptcha.execute(siteKey,{action: 'submit'})
                    .then(token=>{

                        const request={
                             email:login.email,
                             password:login.pwd,
                             token:token
                        };

                        const user_url=process.env.REACT_APP_USER;
              
                       axios.post(`${user_url}/login`,request,{
                          headers:{'Content-Type':'application/json'}
                       })
                        .then(res=>{
                           if(res.data.token){
                            setInvalid(false);
                            setUserInLocalStorage(res.data);
                            closeLoginModal(false);
                           // getUserInfo();
                           }else{
                            setInvalid(true); 
                           }
                           setSubmit(false);
                        }).catch(error=>{
                           console.log(error);
                           setInvalid(true);
                           setSubmit(false);
                        });
            
                          });
        
                    });
            
    }

    const setUserInLocalStorage=user=>{
        localStorage.setItem("voyageUser",JSON.stringify(user));
    }

    const changeHandler=(e)=>{
        const {name,value}=e.target;
        setLogin(prev=>({...prev,[name]:value}));
    }


  return (
     <LoginContainer>
          <div className="container">
                <div className="row">
                    <div id="login-modal" className="col-11 col-md-10 col-lg-8 col-sm-10 mx-auto login-modal text-center">
                         

                         <div className="col-12 col-md-12 col-lg-8 col-sm-12 login mx-auto text-left mt-4 mb-4">
                             <p className="name my-2">
                                   Log In
                             </p>
                         </div>

                         <div className="col-12 col-md-12 col-lg-8 col-sm-12 login-info mx-auto">

                             <form onSubmit={(e)=>submitHandler(e)}>

                             <div className="form-group my-3">
                             <FontAwesomeIcon className="fa-user icon" icon={faUser}/>
                             <input type="text" className="form-control" placeholder="Email" name="email"
                             value={login.email.toLowerCase()} onChange={e=>changeHandler(e)} ref={inputRef}/>
                             { (hasError) && <div className="error text-danger my-1">{error.email}</div> }
                             </div> {/*username*/}

                             <div className="form-group">
                             <FontAwesomeIcon className="fa-lock icon" icon={faLock}/>
                             <input type="password" className="form-control" placeholder="Password" name="pwd"
                             value={login.pwd} onChange={e=>changeHandler(e)}/>
                       { (hasError) && <div className="error text-danger my-1">{error.pwd}</div> }

                             </div> {/*password*/}

                             <div className="form-group">

                             <div className="g-recaptcha"
        date-sitekey={siteKey}
        data-size="invisible"></div>

                             <div className="col-12 col-md-12 col-lg-12 col-sm-12 login-btn mx-auto d-flex mt-4 mb-3">

                             <div className="col-6 col-md-6 col-lg-6 col-sm-6 mx-auto login-btn-div text-left">
                                         <RegisterButton className={"submit-btn "+(submit && 'submit-btn-dis')} disabled={submit}
                                          type="submit"login>login</RegisterButton>
                              </div>  

                               <div className="col-6 col-md-6 col-lg-6 col-sm-6 forget mx-auto text-right text-capitalize">
                                     <p className="name my-3">
                                         <Link to={redirectToForget()} className="forgot-link">reset password?</Link>
                                         </p>
                                </div>            

                               </div> {/*login-btn*/}

                            </div> {/*password*/}

                             </form>

                            { (invalid) && <div className="invalid text-danger mb-4">**Invalid User**</div> }

                         </div>{/*login-info*/}
                         
                    </div>
                </div>
            </div>
     </LoginContainer>
  )
}

const LoginContainer=styledComponents.div`


#login-modal{
    background: var(--lightBlack);
    border: 3px solid var(--lightOrange);
    border-radius: 4rem;
}

.log-img{
    border-radius:50%;
}

.login-modal .title{
    color: var(--lightDark);
    font-family: 'Open Sans', sans-serif;
    font-weight:bold;
}

.login{
    .name{
        font-size: 1.8rem;
        font-family: 'Open Sans', sans-serif;
       color: var(--lightGrey);
       font-weight:bold;

    }
}

.login-btn{
    padding:0;
    margin:0;

    .forget{
        cursor: auto;
        .name{
            color: var(--lightBlue);
            font-family: 'Open Sans', sans-serif;
            transition:0.3s ease-in-out;
            font-size:0.8rem;
            span{
                cursor:pointer;
                &:hover{
                    color: var(--mainBlue);
                    transition:0.3s ease-in-out;
                  }
            }
            
        }
    }
}

.login-modal .form-control{
    background: transparent;
    box-shadow: none;
    border:none;
    border: 1px solid var(--mainBlue);
    padding-left:2rem;

    &:focus{
        border: 1px solid var(--lightGrey);
    }

    ::placeholder {
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif;
        opacity:0.6;
        font-size:0.9rem;
    }

    ::-ms-input-placeholder { 
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif;
        opacity:0.6;
        font-size:0.9rem;
      }
}

.login-modal input{
    color: var(--lightGrey);
    font-family: 'Open Sans', sans-serif;
    font-size:0.9rem;
}

.login-modal .form-group{
    position: relative;
}

.login-modal .icon{
    position:absolute;
    left:0.6rem;
    top:0.7rem;
    color: var(--lightBlue);
}


.login-modal .error{
    font-size:0.9rem;
    font-family: 'Open Sans', sans-serif;
}

.login-modal .invalid{
    font-size:1.1rem;
    font-family: 'Open Sans', sans-serif;
}

.close{
    .close-icon{
        font-size:2rem;
        cursor:pointer;
      color: var(--mainWhite) !important;
    }
}

.submit-btn{
    curosr:pointer;
    opacity:1;
}

.submit-btn-dis{
    cursor:not-allowed;
    opacity:0.7;
}

.forgot-link{
    text-decoration:none;
    cursor:pointer;
    color: var(--lightBlue);
    transition: 0.5s all;

    &:hover{
        color: var(--lightBlue3);
        transition: 0.5s all;
    }
}


`;