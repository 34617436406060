import React, { useState,useEffect } from 'react';
import styledComponents  from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faGem } from '@fortawesome/free-solid-svg-icons';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export const CreatorBoard = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [selected,setSelectedType]=useState('');

    const selects=['Free OnlyFans Creators','Premium OnlyFans Creators','Top OnlyFans Models'];

    function formatUrlText(url) {
        const path = url.startsWith('/') ? url.substring(1) : url;
        const pageName = path.split('/').pop();
        const formattedPageName = pageName.replace(/-/g, ' ').toLowerCase();
        if(formattedPageName.toLowerCase()==='top onlyfans creators')
         return 'top onlyfans models'
        return formattedPageName;
      }

      useEffect(() => {
        const currentPath = location.pathname;
        const formatted = formatUrlText(currentPath);
        setSelectedType(formatted);
      }, [location.pathname]);

    const selectedOFType=(of_type)=>{
        of_type = encodeURIComponent(of_type);
        const formattedOfType = of_type.replaceAll('%20', '-').toLowerCase();
        if(formattedOfType.toLowerCase()==='top-onlyfans-models')
           return navigate(`/top-onlyfans-creators`);
        return navigate(`/${formattedOfType}`);
      }

  return (
      <SelectOnlyFansContainer>
     <div className='container'>
         <div className='row'>

         <div className='col-9 col-md-12 col-xl-8 col-lg-10 col-sm-10 mx-auto buttons'>
         <div className='row'>
           {
             selects.map((of,index)=>{
              return( <React.Fragment key={index}>
                    <div className={"col-12 col-md-4 col-sm-12 col-lg-4 mx-auto select-btn text-center "+(selected===of.toLowerCase() && 'selected')}
                    onClick={()=>selectedOFType(of.toLowerCase())}>

                    { (of.toLowerCase()==='free onlyfans creators') && <React.Fragment> <FontAwesomeIcon className="fa-clock icon px-2" icon={faStar}/>  <label className='select-name'>{of}</label> </React.Fragment> }
                     
                    { (of.toLowerCase()==='premium onlyfans creators') && <React.Fragment> <FontAwesomeIcon className="fa-clock icon px-2" icon={faGem}/>  <label className='select-name'>{of}</label> </React.Fragment> }

                    { (of.toLowerCase()==='top onlyfans models') && <React.Fragment> <FontAwesomeIcon className="fa-eye icon px-2" icon={faCrown}/>  <label className='select-name'>{of}</label> </React.Fragment> }

                     </div> 
                     </React.Fragment>
               );
             })
           }
         </div>
       </div>

         </div>
     </div>
     </SelectOnlyFansContainer>
  )
}

const SelectOnlyFansContainer=styledComponents.div`

background: var(--lightBlack);

.select-btn{
    background: linear-gradient(0deg, #343434, #4b4b4b);
    cursor:pointer;
    border-radius:0.3rem;
    transition: opacity 0.8s ease;
    padding: 0.5rem 0;
    transform: scale(0.9);
    padding:0 !important;
    margin: 0.3rem 0 0 0!important;
  
    &:hover{
      background: var(--lightOrange);
      transition: opacity 0.8s ease;
    }
  }
  
  
  .select-name{
    color: var(--mainWhite);
    font-family: 'Karla', sans-serif;
    // font-family: 'Ubuntu', sans-serif;
    font-size:0.9rem;
    // letter-spacing:0.02em;
    cursor:pointer;
    margin: 0.5rem 0;
  }
  
  .icon{
    color: var(--mainWhite);
    font-size:0.9rem;
    cursor:pointer;
  }
  
  .selected{
    background: var(--lightOrange2);
    transition: opacity 0.8s ease;
    .select-name{
    color: var(--darkBlue);
    }
    .icon{
      color: var(--darkBlue);
    }
    &:hover{
      background: var(--lightOrange2);
      transition: opacity 0.8s ease;
    }
  }

`;
