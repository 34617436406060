import styled from 'styled-components';

export const RegisterButton = styled.button`
  background: rgba(255, 255, 255, 0.2);
  color: ${ props => props.login ? "var(--mainWhite)" : "var(--mainWhite)"};
  padding: 0.4rem 1.8rem;
  font-size: 1rem;
  border-radius: 0.2rem;
  border:none;
  text-transform: capitalize;
  font-family: 'Open Sans', sans-serif;
  cursor:pointer;
  transition: background-color 0.3s ease;

  &:hover{
    // color: ${ props => props.login ? "var(--lightGrey)" : "var(--lightGrey)"};
    background-color: ${ props => props.login ? "rgba(255, 255, 255, 0.25)" : "rgba(255, 255, 255, 0.25)"};
    transition: background-color 0.3s ease;
  }

`;