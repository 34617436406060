import React, { useState, useEffect } from 'react';
import styledComponents from 'styled-components';
import { IoIosWifi } from 'react-icons/io';
import { FaTimes } from 'react-icons/fa';

export const ConnectionStatus = () => {

    const [isConnected, setIsConnected] = useState(window.navigator.onLine);
    const [showPrompt, setShowPrompt] = useState(true);
    const [hasRendered, setHasRendered] = useState(false);
    const [hasRefreshed, setHasRefreshed] = useState(window.navigator.onLine);

      useEffect(()=>{

        if(isConnected)
        setHasRefreshed(true);

        setShowPrompt(true);

      },[isConnected]);
   
    useEffect(() => {

      const handleConnectionChange = () => {

        setIsConnected(window.navigator.onLine);

        if (!hasRendered)
        setHasRendered(true);
  
        if (window.navigator.onLine) {
          // Auto-hide the prompt after 5 seconds when connection is restored
          const timer = setTimeout(() => {
            setShowPrompt(false);
          }, 4000);
  
          return () => clearTimeout(timer);
        }
      };

        window.addEventListener('offline', handleConnectionChange);
        window.addEventListener('online', handleConnectionChange);
    
        return () => {
          window.removeEventListener('offline', handleConnectionChange);
          window.removeEventListener('online', handleConnectionChange);
        };

      }, []);

      const handleClosePrompt = () => {
        setShowPrompt(false);
      };

      const handleRefresh = () => {
        window.location.reload();
        setHasRefreshed(false);
      };

  return (
      <ConnectionContainer>
    {  (showPrompt && hasRefreshed && hasRendered) && (
      <div className="connection-status">
         {isConnected ? (
          (hasRendered) && <>
          <IoIosWifi size={20} className="wifi-icon-online" />
          <span className='connection ps-2'>Your internet connection was restored.</span> 
          </>
        ) : (
      <>
            <IoIosWifi size={20} className="wifi-icon-offline" />
            <span className='connection ps-2'>You are currently offline.</span>
      </> )}
      { (!isConnected) && <a className="refresh ps-2" onClick={handleRefresh}>Refresh</a> }
      { (hasRendered) && <FaTimes className="close-button" onClick={handleClosePrompt} /> }
    </div>
    ) }
    </ConnectionContainer>
  )
}

const ConnectionContainer=styledComponents.div`


.connection-status {
  position: fixed;
  bottom: 15px;
  left: 15px; /* Adjust the value as per your preference */
  padding: 15px 20px;
  border-radius: 5px;
  color: var(--lightDark3);
  background-color: var(--mainWhite);
  text-align: center;
  z-index: 9999;
  transition: all 0.3s ease-in-out;
}

.connection-status .connection{
  font-size: 0.8rem;
  font-family: 'Roboto', sans-serif;
}

.connection-status .refresh{
  font-size: 0.8rem;
  //font-family: 'Karla', sans-serif;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  cursor: pointer;
  color: var(--lightBlue);
}

.connection-status .refresh:hover{
  transition: opacity 0.3s ease-in-out;
  text-decoration: underline;
}

.connection-status .close-button {
  display: inline-block;
  margin-left: 15px;
  padding: 4px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #dedddd;
  text-align: center;
  line-height: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  color: #6a6969;
}

.connection-status .close-button:hover {
  background-color: #d8d6d6;
}

.wifi-icon-offline{
  color: var(--lightGrey);
}

.wifi-icon-online{
  color: var(--lightGreen);
}

`;
