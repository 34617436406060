import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export const ForgetCode = ({otp,setOtpHandler,checkOtp}) => {

    
    return (
        <CodeContainer>
            <div className="container">
                <div className="row">
                <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto otp-ctrl text-center d-flex">

                <div className="col-10 col-md-10 col-lg-10 col-sm-10 mx-auto otp text-center">

                <div className="form-group">
                <FontAwesomeIcon className="fa-otp icon" icon={faKey}/>
                <input type="text" className="form-control" placeholder="OTP" name="otp"
                value={otp} onChange={(e)=>setOtpHandler(e)}/>
               </div>

               </div>

               
               <div className="col-2 col-md-2 col-lg-2 col-sm-2 mx-auto otp-icon text-left">
                   <p className="my-2 text-success">
               { (checkOtp) && <FontAwesomeIcon className="fa-otp check" icon={faCheck}/>}
                  </p>
               </div>

                </div>
                </div>
            </div>
        </CodeContainer>
    );
}

const CodeContainer=styled.div`

.otp-ctrl .form-control{
    background: transparent;
    box-shadow: none;
    border:none;
    border: 1px solid var(--mainBlue);
    padding-left:2rem;

    &:focus{
        border: 1px solid var(--lightGrey);
    }

    ::placeholder {
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif;
        opacity:0.6;
        font-size:0.9rem;
    }

    ::-ms-input-placeholder { 
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif;
        opacity:0.6;
        font-size:0.9rem;
      }
}

.otp-ctrl input{
    color: var(--lightGrey);
    font-family: 'Open Sans', sans-serif;
    font-size:0.9rem;
}

.otp-ctrl .form-group{
    position: relative;
}

.otp-ctrl .icon{
    position:absolute;
    left:0.6rem;
    top:0.7rem;
    color: var(--lightBlue);
}

.otp-icon .check{
    font-size:1.2rem;
    font-weight:bold;
}


`;