import React,{useEffect,useState} from 'react'
import styledComponents from 'styled-components'
import { Alphabat } from '../category/Alphabat';
import { Footer } from '../footer/Footer';
import { TopBar } from '../Index/TopBar/TopBar';
import { Tags } from '../stars/Tags';
import { Login } from './Login';
import { Alph } from '../test/Alph';
import { TopBarList } from '../Index/TopBar/TopBarList';
import { ConnectionStatus } from '../internet-connection/ConnectionStatus';
import { Helmet } from 'react-helmet';

export const LoginPage = () => {

  // const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Login - Xcelebs`);
  const [pageDescription, setPageDescription] = useState(`... Sign in. Categories. Browse. Join for FREE! Sign in. Account. My Account · My Favorites; Logout. Upload. Categories. Browse. lesbian · real sex · nude · butt ...`);

  const [isShow,setIsShow]=useState(false);

  useEffect(()=>{

    var check = document.getElementById('checkMenu');
    check.checked = false;

    window.scrollTo(0, 0);
    const title=`Login - Xcelebs`;
    const description = `... Sign in. Categories. Browse. Join for FREE! Sign in. Account. My Account · My Favorites; Logout. Upload. Categories. Browse. lesbian · real sex · nude · butt ...`;
    
    setPageTitle(title);
    setPageDescription(description);

    document.title = title;
    
    document.body.style.zoom="100%";
   
  },[]);

  const changeCheckHandler=(flag)=>{
    setIsShow(flag);
    var check = document.getElementById('checkMenu');
    check.checked = flag;
}


  return (
    <LoginPageContainer>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        {/* <link rel="canonical" href={canonicalUrl} /> */}
      </Helmet>
         <div className='container-fluid'>
                <div className='row'>

                <input type="checkbox" id="checkMenu"/>

                <div className='top-bar'>
                <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
             </div>

             <div className='top-bar-list'>
                             <TopBarList/>
                   </div>

             <div className='alph-header mb-2 mt-1 d-none d-md-block'>
                          <Alph/>
                     </div>

                     <div className='alph-header mb-2 mt-1 d-md-none'>
                         <Alphabat/>
                     </div>
                     
                <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto login-div mt-4 mb-4'>
                 <Login/>
                </div>

                <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto trending-tags mb-5 mt-5'>
               <Tags/>
                 </div>

                <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                        <Footer/>
                  </div>

                </div>
         </div>

       <ConnectionStatus/>

    </LoginPageContainer>
  )
}

const LoginPageContainer=styledComponents.div`

background: var(--lightBlack);

@media (min-width: 1024px) {
  .top-bar {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
    // padding:0 !important;
    // margin:0 !important;
  } 

.trending-tags{
  //  padding:0 !important;
  //  margin:0 !important;
  }

.footer-div{
    padding:0 !important;
    margin:0 !important;
  }

  #checkMenu{
    display:none;
  }
  
  .top-bar-list{
    padding:0 !important;
    margin:0 !important;
    overflow: hidden;
    position:relative;
    max-height: 0;
    transition: max-height 0.5s ease;
  }
  
  #checkMenu:checked ~ .top-bar-list{
    max-height: 300px;
    transition: max-height 0.5s ease;
  }
  

`;