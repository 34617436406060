import axios from 'axios';
import React, { useState,useEffect } from 'react'
import styledComponents from 'styled-components'
import { ImageSm } from '../common/ImageSm';
import { ImageLoading } from '../loading/ImageLoading';
import { Pagination } from './Pagination';
import {ImgModal} from '../test/ImgModal';

export const CelebImgList = ({celebId,celebName}) => {

  const [images,setImages] = useState([])
  const [pages,setPages]=useState(0);
  const [currentPage,setCurrentPage]=useState(1);
  const [currentBtn,setCurrentBtn]=useState(1);
  const [loading,setLoading]=useState(true);

  const itemPerPage=30;

  const [activeIndex,setActiveIndex]=useState(0);
  const [imgModal,setImgModal]=useState(false);
  const [imgId,setImgId]=useState('');

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const openImgModal=(flag)=>{
    setImgModal(flag);
  }

  const viewImage=(index,id)=>{
      setActiveIndex(index);
      openImgModal(true);
      setImgId(id);
  }

  useEffect(() => {

    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };

  }, []);

useEffect(()=>{
  setCurrentBtn(1);
},[celebId,celebName]);

const currentPageHandler=page=>{
setCurrentPage(page);
}

useEffect(()=>{

document.body.style.zoom="100%";
setCurrentPage(1);

},[celebId,celebName]);


useEffect(() => {

  const fetchData = async () => {

    if (isNaN(currentBtn))  
      return;   

      if (currentBtn === 1) {
        window.scrollTo(0, 0);
    } else {
        if (window.innerWidth >= 1300) { 
            window.scrollTo(0, 350);
        }  else if (window.innerWidth >= 700) {
            window.scrollTo(0, 430);
        } else
            window.scrollTo(0, 510);
    }

    const img_url = process.env.REACT_APP_CELEB_IMAGE;

    setLoading(true);

    if (!isOnline)
      return;

    const name = (celebName) && celebName.replaceAll("-", " ");

    try {
      const response = await axios.get(`${img_url}/celeb-list?name=${encodeURIComponent(name)}&page=${currentPage}`);
      setImages(response.data.celebImageList);
      setPageHandler(response.data.numOfItems);
      setLoading(false);
    } catch (error) {
       setLoading(false);
       console.log(error);
    }
  };

  fetchData();
}, [currentBtn,celebName,currentPage,isOnline]);

const setPageHandler =length=>{
 
  const numOfItems=length;     
  let page = parseInt(numOfItems/itemPerPage);

  if(parseInt(numOfItems%30)!==0)
   page+=1;

   setPages(page);
 };

//  useEffect(() => {
//   // Preload all images when the component mounts
//   if (images.length > 0) {
//     images.forEach((image) => {
//       const img = new Image();
//       img.src = image.imgUrl;
//     });
//   }
// }, [images]);

  return (
     <CelebImgContainer>

                 {/* { (!loading) && <div className="img-list my-3">
                     
                     {
                       (images) && images.map((img,index)=> <Image item={img} index={index} key={img.imgId} viewImageHandler={viewImage}/>)
                     }
 
                       </div> }  */}
       
                     { (!loading) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 img-list px-2">
                     <div className="row">
                    {
                      (images) && images.map((img,index)=> <ImageSm item={img} index={index} key={img.imgId} viewImageHandler={viewImage}/>)
                    }

                        </div>
                      </div> }


                      {
                        (loading) && <ImageLoading/>
                      }

                      { (images && images.length>=1 && !loading) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 pages mt-3">

<Pagination pages={pages} currentPageHandler={currentPageHandler}
         currentBtn={currentBtn} setCurrentBtn={setCurrentBtn}/> 
                  
                    </div>   }  

                   
        {
          (imgModal && imgId) && <ImgModal images={images} a_index={activeIndex} openImgModalHandler={openImgModal} currentImgId={imgId} celebName={celebName} currentPage={currentPage}/>
        }


{/* {
(imgModal) && <ImgModal2 images={images} a_index={activeIndex} openImgModalHandler={openImgModal}/>
} */}
        
     </CelebImgContainer>
  )
}

const CelebImgContainer=styledComponents.div`


background: var(--lightBlack);
position: relative;


.card{
  background: transparent;
}

.pics{
  transform: scale(1);
  background: #403d3d47;
}

.pics-sm{
  transform: scale(0.95);
  background: #403d3d47;
  border-radius:0.4rem;
}

@media (max-width: 692px) {
  .pics-sm{
    transform: scale(0.96);
  }
}


.img-list .image-sm{
  padding:0 !important;
  margin:0 !important;
}

.img-list .image{
  
  width: auto;
  padding: 0.2rem 0.4rem;
  background: #403d3d47;
  margin: 0.3rem 0.3rem;
  text-align: center;
  display:inline-block;
  border-radius:0.3rem;
  
}

.img-div{

  border: 3px solid transparent;
  cursor:pointer;
  transition: 0.5s all;
  height:14rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
  border: 3px solid var(--lightOrange);
  transition: 0.5s all;
  }

}

.img-sm-div{

  border: 3px solid transparent;
  cursor:pointer;
  transition: 0.5s all;
  //max-height:11rem;
  height:11rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
  border: 3px solid var(--lightOrange);
  transition: 0.5s all;
  }

}

.img-div img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.img-sm-div img{
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

`;