import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { BrowseVideo } from './components/browse-video/BrowseVideo';
import { BrowseCeleb } from './components/browse/BrowseCeleb';
import { Categories } from './components/category/Categories';
import { CelebsIndex } from './components/CelebsIndex';
import { Detail } from './components/Detail/Detail';
import { Main } from './components/stars/Main';
import { Stream } from './components/stream/Stream';
import { ForgetPage } from './components/user-auth/ForgetPage';
import { LoginPage } from './components/user-auth/LoginPage';
import { SignupPage } from './components/user-auth/SignupPage';
import { MovieDetail } from './components/movie-detail/MovieDetail';
import { SearchMainList } from './components/search/SearchMainList';
import { TopCelebVideoList } from './components/top-list/TopCelebVideoList';
import { TopCelebList } from './components/top-list/TopCelebList';
import {StarDetail} from './components/Detail/StarDetail';
import { Default } from './components/common/Default';
import {TagList} from './components/tag-detail/TagList';
import { PolicyPage } from './components/footer/Info/PolicyPage';
import { DMCAPage } from './components/footer/Info/DMCAPage';
import { TermsPage } from './components/footer/Info/TermsPage';
import { GuidelinePage } from './components/footer/Info/GuidelinePage';
import { ContactPage } from './components/footer/Info/ContactPage';
import { ExemptPage } from './components/footer/Info/ExemptPage';
import { LivePage } from './components/live/LivePage';
import { VideoJsDecode } from './components/player/VideoJsDecode';
import { ZoomInImage } from './components/test/ZoomInImage';
import { TopCreatorList } from './components/creator/TopCreatorList';
import { FreeCreatorList } from './components/creator/FreeCreatorList';
import { PremiumCreatorList } from './components/creator/PremiumCreatorList';
import { VRLivePage } from './components/live/VRLivePage';

export const App = () => {
  return (
      <React.Fragment>
        
           <Routes>

           <Route path="/" element={<CelebsIndex/>}/>

           <Route path="/tags" element={<Categories/>}/>

           <Route path="/browse/tags/*" element={<TagList/>}/>

           <Route path="/browse/*" element={<Main/>}/>

           <Route path="/signup" element={<SignupPage/>}/>

           <Route path="/login" element={<LoginPage/>}/>

           <Route path="/reset" element={<ForgetPage/>}/>

           <Route path="/browse/xcelebs/*" element={<BrowseCeleb/>}/>

           <Route path="/browse/videos/*" element={<BrowseVideo/>}/>

           <Route path="/view/celeb/*" element={<Detail/>}/>

           <Route path="/view/pornstar/*" element={<StarDetail/>}/>

           <Route path="/view/movie/*" element={<MovieDetail/>}/>

           <Route path="/stream/*" element={<Stream/>}/>

           <Route path="/search/*" element={<SearchMainList/>}/>

           <Route path="/top-100-videos" element={<TopCelebVideoList/>}/>

           <Route path="/top-100-celebs" element={<TopCelebList/>}/>

           <Route path="/privacy-policy" element={<PolicyPage/>}/>

           <Route path="/dmca" element={<DMCAPage/>}/>

           <Route path="/terms" element={<TermsPage/>}/>

           <Route path="/communityguidelines" element={<GuidelinePage/>}/>

           <Route path="/contact" element={<ContactPage/>}/>

           <Route path="/2257" element={<ExemptPage/>}/>

           <Route path="/live-sex" element={<LivePage/>}/>

           <Route path="/live-vr-sex-cams" element={<VRLivePage/>}/>

           <Route path='/embed/:embedVideo' element={<VideoJsDecode/>}/>

           <Route path='/zoom-in/:name/photo/:id' element={<ZoomInImage/>}/>

           <Route path="/top-onlyfans-creators" element={<TopCreatorList/>}/>

           <Route path="/free-onlyfans-creators" element={<FreeCreatorList/>}/>

           <Route path="/premium-onlyfans-creators" element={<PremiumCreatorList/>}/>

           <Route path='*' element={<Default/>}/>

           </Routes>
           
      </React.Fragment>
  );
}
