import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

export const CountryField = ({cName,countryChangeHandler,error,hasError,setCountryName,enableCountry,
    setCountries,countries}) => {

    useEffect(()=>{
       axios.get('https://restcountries.com/v3.1/all',{
           headers:{ 'Access-Control-Allow-Origin' : '*',
        'Content-Type':'application/json'}
       })
         .then(res=>{
           setCountryHandler(res.data);
         }).catch(error=>{
             console.log(error);
         });

    },[]);

    const setCountryHandler=country=>{
        let tempCountries=[];
         tempCountries = country.map(c=>c.name.common);
         setCountries(tempCountries);
    }

    const getCountry=name=>{
        return countries.filter(c=>c.toLowerCase().includes(name.toLowerCase())).slice(0,3);
    }
    return (
        <CountryContainer>
            <div className="container">
                <div className="row">
                <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto country text-center mt-3">

                <div className="form-group">
                <FontAwesomeIcon className="fa-country icon" icon={faGlobe}/>
                <input type="text" className="form-control text-capitalize" placeholder="Country" name="country"
                value={cName} onChange={(e)=>countryChangeHandler(e)}/>

                    {  (hasError) && <div className="error text-danger my-1">{error.country}</div> }
           
              { (cName && !enableCountry && getCountry(cName).length>=1) &&
                <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto country-select text-center my-1">
                {
                    getCountry(cName).map((n,index)=>{
                      return <React.Fragment key={index}>
                       <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto country-name text-left text-capitalize"
                       onClick={()=>setCountryName(n)}>
                           <p className="name my-2">{n}</p>
                       </div>
                      </React.Fragment>
                    })
                
                }
                </div> //country-select
                }



                 </div>
               </div> {/*country*/}

                </div>
            </div>
        </CountryContainer>
    );
}

const CountryContainer= styled.div`


.country .form-control{
    background: transparent;
    box-shadow: none;
    border:none;
    border: 1px solid var(--mainBlue);
    padding-left:2rem;

    &:focus{
        border: 1px solid var(--lightGrey);
    }

    ::placeholder {
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif;
        opacity:0.6;
        font-size:0.9rem;
    }

    ::-ms-input-placeholder { 
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif;
        opacity:0.6;
        font-size:0.9rem;
      }
}

.country input{
    color: var(--lightGrey);
    font-family: 'Open Sans', sans-serif;
    font-size:0.9rem;
}

.country .form-group{
    position: relative;
}

.country .icon{
    position:absolute;
    left:0.6rem;
    top:0.7rem;
    color: var(--lightBlue);
}

.error{
    font-family: 'Open Sans', sans-serif;
    font-size:0.8rem;
}

.country-select{
    border: 1px solid var(--lightGrey);
    border-radius:0.2rem;
}

.country-name{
    border-bottom: 1px solid var(--lightDark);
    cursor:pointer;
    .name{
        color: var(--lightGrey);
        font-size:0.8rem;
        transition: 0.3s ease-in-out;
    }
    &:hover{
        .name{
            color: var(--mainWhite);
            transition: 0.3s ease-in-out;
        }
    }
}

`;