import axios from 'axios';
import React,{useState} from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import styledComponents from 'styled-components'
import { Stream_Offer } from '../ads/Stream_Offer';
import { StarVideo } from '../Detail/StarVideo';
import { VideoLoadingList } from '../loading/VideoLoadingList';
import { Video } from '../test/Video';

export const MoreVideos = ({stream})  => {

    const [videos,setVideos]=useState([]);

    const [moreVideos,setMoreVideos]=useState([]);
    const [loading,setLoading]=useState(true);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const navigate = useNavigate();

    // var name_1='';
    // //var name_2='';

    // const names = (stream.celebName) && stream.celebName.split(",");

    // if(names!==undefined){
    //     name_1 = (stream.celebName) && stream.celebName.split(",")[0];
    //  //   name_2 = (stream.celebName) && stream.celebName.split(",")[1];
    // }else
    //    name_1=stream.celebName;

    useEffect(() => {

      const handleOnline = () => {
        setIsOnline(true);
      };
  
      const handleOffline = () => {
        setIsOnline(false);
      };
  
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
  
      return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
      };

    }, []);

    useEffect(() => {

      const fetchData = async () => {

        const video_url = process.env.REACT_APP_CELEB_VIDEO;
    
        const request = {
          videoId: stream.videoId,
          name: stream.celebName,
          title: stream.title
        };
    
        try {
          const response = await axios.post(`${video_url}/stream-list`, request, {
            'Content-Type': 'application/json'
          });
          setVideos(response.data);
          // setIsLoading(false);
        } catch (error) {
           // setIsLoading(false);
          console.log(error);
        }
      };
    
      fetchData();
    }, [stream.videoId]);
    

    useEffect(() => {

      const fetchData = async () => {

        const video_url = process.env.REACT_APP_CELEB_VIDEO;
    
        setLoading(true);
    
        if (!isOnline)
          return;
    
        const celebName = stream.celebName ? stream.celebName : 'hentai';

        const request = {
          videoId: stream.videoId,
          type: stream.celebType,
          tag: stream.tag,
          name: celebName,
          title: stream.title
        };
    
        try {
          const response = await axios.post(`${video_url}/more-list`, request, {
            'Content-Type': 'application/json'
          });
          setMoreVideos(response.data);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };
    
      fetchData();
    }, [stream.videoId,isOnline]);
    

  const getCeleb = async (name, type) => {
    try {
      const celeb_url = process.env.REACT_APP_CELEB;
      const response = await axios.get(`${celeb_url}?name=${encodeURIComponent(name)}`);
      viewCeleb(response.data.celebId, response.data.celebName, type);
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      console.log(error);
    }
  };
  

 const viewCeleb=(id,name,type)=>{
    
     return (id && name && type && type.toLowerCase()==='celeb') ? navigate(`/view/celeb/${id}/${name.toLowerCase().replaceAll(/[-\s]+/g, '-')}`) :
         navigate(`/view/pornstar/${id}/${name.toLowerCase().replaceAll(/[-\s]+/g, '-')}`);
 } 
 
//  const viewFilm=(name)=>{
//   return navigate(`/view/movie/${name.replaceAll(" ","-")}`);
//  }

  return (
    <VideoContainer>
      <div className='container-fluid'>
             <div className='row'>

             
                    <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto sub-header text-center mt-4 mb-1'>
                     { (isOnline && stream.celebName && videos && videos.length>=1) && <h3 className='sub-title'>More videos from <span className='star' onClick={()=>getCeleb(stream.celebName,stream.celebType)}>{stream.celebName}</span></h3> }
                    </div>

                     { <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 plays-list plays-list-lg px-3 d-none d-lg-block">
                     <div className="row">

                      { (isOnline && videos && stream.celebType && stream.celebType.toLowerCase()==='celeb') && videos.slice(0,12).map((video)=><Video key={video.videoId} item={video}/>)}

                      { (isOnline && videos && stream.celebType && stream.celebType.toLowerCase()==='model') && videos.slice(0,12).map((video)=><StarVideo key={video.videoId} item={video}/>)}

                        </div>
                      </div> }

                      { <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 plays-list plays-list-sm d-lg-none">
                     <div className="row">

                      { (isOnline && videos && stream.celebType && stream.celebType.toLowerCase()==='celeb') && videos.slice(0,12).map((video)=><Video key={video.videoId} item={video}/>)}

                      { (isOnline && videos && stream.celebType && stream.celebType.toLowerCase()==='model') && videos.slice(0,12).map((video)=><StarVideo key={video.videoId} item={video}/>)}

                        </div>
                      </div> }

                     { (!loading && videos.length>2) && <Stream_Offer/> }

                     { <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto more-header text-start'>
                          {/* { (!showMore) && <label className='more py-1'>Show more related videos</label> }
                          { (showMore) && <label className='more py-1'>Show less</label> } */}
                        <h3 className='more'>More Related</h3>
                      </div> }   

                      { (!loading) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 plays-list plays-list-lg px-3 d-none d-lg-block">
                     <div className="row">

                      { (isOnline && moreVideos && stream.celebType && stream.celebType.toLowerCase()==='celeb') && moreVideos.slice(0,12).map((video)=><Video key={video.videoId} item={video}/>)}

                      { (isOnline && moreVideos && stream.celebType && stream.celebType.toLowerCase()==='model') && moreVideos.slice(0,12).map((video)=><StarVideo key={video.videoId} item={video}/>)}
   
                        </div>
                      </div> }

                      { (!loading) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 plays-list plays-list-sm d-lg-none">
                     <div className="row">

                      { (isOnline && moreVideos && stream.celebType && stream.celebType.toLowerCase()==='celeb') && moreVideos.slice(0,12).map((video)=><Video key={video.videoId} item={video}/>)}

                      { (isOnline && moreVideos && stream.celebType && stream.celebType.toLowerCase()==='model') && moreVideos.slice(0,12).map((video)=><StarVideo key={video.videoId} item={video}/>)}
   
                        </div>
                      </div> }

                      </div>
                      </div>

                      {
                        (loading) && <VideoLoadingList/>
                      }

    </VideoContainer>
  )
}

const VideoContainer=styledComponents.div`

background: var(--lightBlack);

.sub-header .sub-title{

    color: var(--mainWhite);
    //  font-family: 'Karla', sans-serif;
    font-family: 'Open Sans', sans-serif; 
    font-weight:bold;
    font-size:1.3rem;
    letter-spacing:0.03em;
  
     .star{
         color: var(--lightOrange);
         cursor:pointer;
         transition: 0.5s all;
 
         &:hover{
             text-decoration: underline;
             transition: 0.5s all;
         }
     }

     .film-name{
      color: var(--lightOrange);
      cursor:pointer;
      transition: 0.5s all;

      &:hover{
          text-decoration: underline;
          transition: 0.5s all;
      }
     }
}

@media (min-width: 900px) {
  .sub-header .sub-title{
      font-size:1.4rem;
  }
}

.more-header{
    
   padding: 0 !important;
   margin: 1.6rem 0 0.5rem 0 !important;

   .more{
       color: var(--mainWhite);
       font-size:1.5rem;
       font-weight:bold;
       font-family: 'Karla', sans-serif;
   }
   
   @media (min-width: 1020px) {
     .more{
      font-size:1.6rem;
       padding-left: 0.3rem;
     }
    }

 }

// .plays-list .plays{
//     position:relative;
//     border: 3px solid transparent;
//     padding:0 !important;
//     margin:0 !important;
//     transform: scale(0.9);
//     transition:0.8s all;
  
//     &:hover{
//       transition:0.8s all;
//       cursor:pointer;
//       border: 3px solid var(--lightOrange);
//       .title{
//         color: var(--lightOrange);
//       }
//       .video-views{
//         transition:0.8s all;
//         z-index:-1111;
//       }
//       .time-quality{
//         transition:0.8s all;
//         z-index:-1111;
//       }
//     }
//   }
  
//   .card{
//     background: transparent;
//   }
  
//   .card-footer{
//     padding:0 !important;
//     margin:0 !important;
//   }
  
//   .plays .title{
  
//     // position: absolute;
//     // //top:90%;
//     // z-index:1111;  
//       font-size:1rem;
//       font-family: 'Open Sans', sans-serif; 
//       color: var(--mainWhite);
//       transition:0.8s all;
//   }
  
//   .time-quality{
//     position: absolute;
//     top:1%;
//     z-index:1111;
  
//     .plays-time{
  
//       .duration{
//         background: var(--lightGrey);
//         padding: 0.2rem 0.5rem 0.2rem 0.5rem;
//         font-size:0.9rem;
//         color: var(--mainDark2);
//         font-family: 'Open Sans', sans-serif; 
//       //  font-weight:bold;
//       }
  
//     }
  
//     .plays-time-sm{
  
//       .duration{
//         background: var(--lightGrey);
//         padding: 0.2rem 0.3rem 0.2rem 0.3rem;
//         font-size:0.7rem;
//         color: var(--mainDark2);
//         font-family: 'Open Sans', sans-serif; 
//       //  font-weight:bold;
//       }
//     }
  
//       .plays-quality{
  
//         .resolution{
//           font-family: 'Open Sans', sans-serif; 
//           background: var(--lightGrey);
//           padding: 0.2rem 0.4rem 0.2rem 0.4rem;
//           font-size:0.9rem;
//           color: var(--lightRed);
//           font-weight:bold;
//         }
//       }
  
//       .plays-quality-sm{
  
//         .resolution{
//           font-family: 'Open Sans', sans-serif; 
//           background: var(--lightGrey);
//           padding: 0.2rem 0.3rem 0.2rem 0.3rem;
//           font-size:0.7rem;
//           color: var(--lightRed);
//           font-weight:bold;
//         }
//       }
  
//     }
  
//   .film-rate{
    
//   }
  
//   .film{
//     .film-name {
//       color: var(--lightGrey);
//       font-size:0.8rem;
//       font-family: 'Josefin Sans', sans-serif;
//       transition:0.5s all;
  
//       &:hover{
//         transition:0.5s all;
//         color: var(--mainWhite);
//       }
//     }
  
//   }
  
//   .play-rate{
//     color: var(--lightGreen);
//     .fa-thumb{
//      font-size:1rem;
//     }
//     .percentage{
//       font-size:0.9rem;
//     }
//   }
  
//   .video-views{
//     position: absolute;
//     top:1%;
//     z-index:1111;
//     margin:0;
//     padding:0;
  
//     .view{
  
//     .total-views{
//         background: rgba(0,0,0,0.7);
//         padding: 0.2rem 0.4rem 0.2rem 0.4rem;
//         color: var(--mainWhite);
//         font-size:0.9rem;
//         font-family: 'Open Sans', sans-serif; 
//     }
//     .fa-views{
//         font-size:1rem;
//     }
//   }
  
//   .view-sm{
  
//     .total-views{
//         background: rgba(0,0,0,0.7);
//         padding: 0.2rem 0.3rem 0.2rem 0.3rem;
//         color: var(--mainWhite);
//         font-size:0.7rem;
//         font-family: 'Open Sans', sans-serif; 
//     }
//     .fa-views{
//         font-size:0.8rem;
//     }
//   }
  
//   } 

.plays-list-sm{
  padding:0 !important;
  margin:0 !important;
}


.plays-list .plays{
  position:relative;
  border: 3px solid transparent;
  padding:0 !important;
  margin:0 !important;
  transform: scale(1);
  transition:0.8s all;
  text-decoration:none;

  &:hover{
    transition:0.8s all;
    cursor:pointer;
 //   border: 3px solid var(--lightOrange);
   .player{
       border: 3px solid var(--lightOrange);
    }
    .title{
      color: var(--lightOrange);
    }
    .title-sm{
      color: var(--lightOrange);
    }
    .video-views{
      // transition:0.8s all;
      z-index:-1111;
    }
    .time-quality{
      transition:0.8s all;
      z-index:-1111;
    }
    .player-watermark{
      // transition:0.8s all;
      z-index:1111;
      opacity: 1;
    }
    .player-watermark-sm{
      // transition:0.8s all;
      z-index:1111;
      opacity: 1;
    }
  }
}

@media (min-width: 768px) {
  .plays-list .plays {
    transform: scale(0.98);
  }
}

.plays-list .player{
  border: 3px solid transparent;
}

.card{
  background: transparent;
}

.card-footer{
  padding:0 !important;
  margin:0 !important;
}

.plays .title{

  // position: absolute;
  // //top:90%;
  // z-index:1111;  
    font-size:1rem;
    font-family: 'Open Sans', sans-serif; 
    color: var(--mainWhite);
    transition:0.8s all;
}

.plays .title-sm{

  // position: absolute;
  // //top:90%;
  // z-index:1111;  
    font-size:0.8rem;
    font-family: 'Open Sans', sans-serif; 
    color: var(--mainWhite);
    transition:0.8s all;
}

  

.time-quality{
  position: absolute;
  // top:-0.1%;
  z-index:1111;
  bottom:5%;

  padding-left: 0.4rem;

  .plays-time{

    .duration{
      background: var(--lightGrey);
      padding: 0.2rem 0.5rem 0.2rem 0.5rem;
      font-size:0.7rem;
      color: var(--mainDark2);
      font-family: 'Open Sans', sans-serif; 
      font-weight:bold;
    }

  }

  .plays-time-sm{

    .duration{
      background: var(--lightGrey);
      padding: 0.2rem 0.3rem 0.2rem 0.3rem;
      font-size:0.6rem;
      color: var(--mainDark2);
      font-family: 'Open Sans', sans-serif; 
      font-weight:bold;
    }
  }

    .plays-quality{

      padding-left: 0.1rem;

      .resolution{
        font-family: 'Open Sans', sans-serif; 
        background: var(--lightGrey);
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;
        font-size:0.7rem;
        color: var(--brightRed);
        font-weight:bold;
      }
    }

    .plays-quality-sm{

      padding-left: 0.1rem;

      .resolution{
        font-family: 'Open Sans', sans-serif; 
        background: var(--lightGrey);
        padding: 0.2rem 0.3rem 0.2rem 0.3rem;
        font-size:0.6rem;
        color: var(--brightRed);
        font-weight:bold;
      }
    }

  }

  @media (min-width: 768px) {
    .time-quality {
      bottom:5%;
      padding-left: 0.5rem;
    }
  }

.film{
  .name{
    text-decoration:none;
  }
  .film-name {
    color: var(--lightGrey);
    font-size:0.8rem;
    font-family: 'Josefin Sans', sans-serif;
    transition:0.5s all;

    &:hover{
      transition:0.5s all;
      color: var(--mainWhite);
      text-decoration: underline; 
      color: var(--mainWhite);
      text-decoration-thickness: 1.7px;
    }
  }

  .film-name-sm{
    color: var(--lightGrey);
    font-size:0.7rem;
    font-family: 'Josefin Sans', sans-serif;
    transition:0.5s all;

    &:hover{
      transition:0.5s all;
      color: var(--mainWhite);
      text-decoration: underline; 
      color: var(--mainWhite);
      text-decoration-thickness: 1.5px;
    }
  }

}

.play-rate{
  color: var(--lightGreen);
  .fa-thumb{
   font-size:0.8rem;
  }
  .percentage{
    font-size:0.8rem;
  }
}

.video-views{
  position: absolute;
  // top:-0.1%;
  bottom:5%;
  z-index:1111;
  margin:0;
  padding:0;

  padding-right: 0.4rem;

  .view{

  .total-views{
      background: rgba(0,0,0,0.7);
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      color: var(--mainWhite);
      font-size:0.7rem;
      font-family: 'Open Sans', sans-serif; 
  }
  .fa-views{
      font-size:0.8rem;
  }
}

.view-sm{

  .total-views{
      background: rgba(0,0,0,0.7);
      padding: 0.2rem 0.3rem 0.2rem 0.3rem;
      color: var(--mainWhite);
      font-size:0.6rem;
      font-family: 'Open Sans', sans-serif; 
  }
  .fa-views{
      font-size:0.7rem;
  }
}

} 

@media (min-width: 768px) {
  .video-views {
    bottom:5%;
    padding-right: 0.5rem;
  }
}

.player-watermark{
  position: absolute;
  top:1%;
  right:1%;
  z-index:-1111;
  opacity: 0;
  margin:0;
  padding:0;

  .watermark{

    .p-wm{
      background: rgba(0,0,0,0.9);
      padding: 0.1rem 0.4rem 0.1rem 0.4rem;
      color: var(--mainWhite);
      font-size:0.8rem;
      font-family: 'Open Sans', sans-serif; 
  }

  }

}

.player-watermark-sm{
  position: absolute;
  top:-0.1%;
  z-index:-1111;
  opacity: 0;
  margin:0;
  padding:0;

  .watermark{

    .p-wm{
      background: rgba(0,0,0,0.9);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      color: var(--mainWhite);
      font-size:0.6rem;
      font-family: 'Open Sans', sans-serif; 
  }

  }

}



`;