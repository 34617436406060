import React, { useState, useEffect } from 'react'
import styledComponents from 'styled-components'
import { Tag } from './Tag';

export const TagList = ({tags}) => {

   const [more,setMore]=useState(false);
   const [isMobile, setIsMobile] = useState(window.innerWidth <= 766);

   useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 766); // Adjust the width accordingly
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

   if(isMobile && !more && tags.length>8){
      tags = tags.slice(0, 8);
      tags.push("more");
   }

  return (
     <TagListContainer>
        
                      <div class="tag-list">
                     
                     {(tags) && tags.map((tag,index)=> <Tag key={index} name={tag} more={more} setMoreHandler={setMore}/>)}
                     
                      </div>                              
     </TagListContainer>
  )
}

const TagListContainer = styledComponents.div`

.tag-list {
   // padding:1rem;
  }

.tag-list .tag{
      width: auto;
      padding: 0.2rem 0.4rem;
      background: linear-gradient(0deg,#343434,#4b4b4b);
      color: var(--mainWhite);
      font-family: 'Karla', sans-serif;
      margin: 0.3rem 0.3rem;
      font-size:0.75rem;
      text-align: center;
      display:inline-block;
      border-radius:0.3rem;
     // transition: 0.5s all;
     // font-weight:bold;
     text-decoration:none;
      &:hover{
       
        cursor:pointer;
       // transition: 0.5s all;
        background:  var(--lightOrange);
     }
  }

@media (min-width: 900px) {
   .tag-list .tag{
      font-size:0.84rem;
   }
 }

`;