import React from 'react'
import styledComponents from 'styled-components'
import { Title } from '../Title';

export const TitleBar = () => {


  const titles=['celebrities','pornstars','movies','videos','missionary','lesbian'];

  return (
     <TitleContainer>
         <div className='container-fluid mt-3 mb-5'>
             <div className='row'>
             <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 title-bar px-2">
                   <div className='row'>
                  
                      { titles.map((names,index)=><Title key={index} name={names} index={index+1}/>)}

                   </div>
             </div>
             </div>
         </div>
     </TitleContainer>
  )
}

const TitleContainer = styledComponents.div`

.title-bar{
  padding:0;
  margin:0;
}

.first-title{
  border-right: 1px solid var(--lightGrey);
}

.titles{
// background: var(--lightDark2);
background: linear-gradient(0deg, #343434, #4b4b4b);
 margin-top:0.1rem;
 cursor:pointer;
 transition: 0.8s all;
 border-radius:0.2rem;
 text-decoration:none;

 &:hover{
   .title{
    transition: 0.8s all;
    color: var(--lightYellow);
   }
    transition: 0.8s all;
    // background: var(--darkBlue);
    background: linear-gradient(0deg, #1f1f1f, #333333);
 }

  .title{
    //font-family: 'Open Sans', sans-serif; 
    font-family: 'Karla', sans-serif;
   // font-weight:bold;
    transform: scale(0.9);
    font-size:1.1rem;
    color: var(--mainWhite);
    cursor:pointer;
    margin 0.5rem 0;
    text-align: center;
  }

}

`;
