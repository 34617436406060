import React from 'react';
import styledComponents from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

export const NameField = React.forwardRef(({nameChangeHandler,name,error,hasError},ref) => {
    return (
        <NameContainer>
            <div className="container">
                <div className="row">
                <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto name text-center">

                <div className="form-group">
                    <FontAwesomeIcon icon={faUser} className="icon"/>
                <input type="text" className="form-control text-capitalize" placeholder="First Name" name="fname"
                value={name.fname} onChange={(e)=>nameChangeHandler(e)} ref={ref}/>
                { (hasError) && <div className="error text-danger my-1">{error.fname}</div> }
                </div>  

                 </div>{/*fname*/}

                 <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto name text-center mt-3">

<div className="form-group">
    <FontAwesomeIcon icon={faUserPlus} className="icon"/>
<input type="text" className="form-control text-capitalize" placeholder="Last Name" name="lname"
value={name.lname} onChange={(e)=>nameChangeHandler(e)}/>
  {  (hasError) && <div className="error text-danger my-1">{error.lname}</div> }
</div>  

            </div>{/*lname*/}



                </div>
            </div>
        </NameContainer>
    );
});

const NameContainer=styledComponents.div`

.name .form-control{
    background: transparent;
    box-shadow: none;
    border:none;
    border: 1px solid var(--mainBlue);
    padding-left:2rem;

    &:focus{
        border: 1px solid var(--lightGrey);
    }

    ::placeholder {
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif;
        opacity:0.6;
        font-size:0.9rem;
    }

    ::-ms-input-placeholder { 
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif;
        opacity:0.6;
        font-size:0.9rem;
      }
}

.name input{
    color: var(--lightGrey);
    font-family: 'Open Sans', sans-serif;
    font-size:0.9rem;
}

.name .form-group{
    position: relative;
}

.name .icon{
    position:absolute;
    left:0.6rem;
    top:0.7rem;
    color: var(--lightBlue);
}

.error{
    font-family: 'Open Sans', sans-serif;
    font-size:0.8rem;
}



`;