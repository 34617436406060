import React,{useEffect, useState} from 'react'
import styledComponents from 'styled-components'
import { Alphabat } from '../category/Alphabat';
import { Footer } from '../footer/Footer';
import { TopBar } from '../Index/TopBar/TopBar';
import { PopularCelebList } from './PopularCelebList';
import { RandomCelebList } from './RandomCelebList';
import { SelectCelebs } from './SelectCelebs';
import { Title } from './Title';
import { TrendingCelebList } from './TrendingCelebList';
import { UpdateCelebList } from './UpdateCelebList';
import { Alph } from '../test/Alph';
import { Tags } from '../stars/Tags';
import { TopBarList } from '../Index/TopBar/TopBarList';
import { LiveBoard } from '../live/LiveBoard';
import { CelebAd } from '../ads/CelebAd';
import { ConnectionStatus } from '../internet-connection/ConnectionStatus';
import { Helmet } from 'react-helmet';

export const BrowseCeleb = () => {

  // const canonicalUrl = window.location.href;

  // const [pageTitle, setPageTitle] = useState(`Browse Nude Celebs - Xcelebs`);
  // const [pageDescription, setPageDescription] = useState(`Browse Nude Celebrities at Xcelebs.`);

    const [selectedHeader,setSelectedHeader]=useState('');
    const [flag,setFlag]=useState(false);
    const [isShow,setIsShow]=useState(false);

    useEffect(()=>{

      window.scrollTo(0, 0);

      var title = 'Browse Nude Celebs';
      var desc = 'Browse Nude Celebrities at Xcelebs.';

      if(selectedHeader==='sort by popularity'){
          title = 'Browse Nude Celebs Sorted by Popularity';
          desc = 'Browse Nude Celebrities Sorted by Popularity at Xcelebs.';
      }
       if(selectedHeader==='sort by recently updated'){
          title = 'Browse Nude Celebs Sorted by Recently Updated';
          desc = 'Browse Nude Celebrities Sorted by Recently Updated at Xcelebs.';
       }if(selectedHeader==='sort by trending'){
          title = 'Browse Nude Celebs Sorted by Trending';
          desc = 'Browse Nude Celebrities Sorted by Trending at Xcelebs.';
       }if(selectedHeader==='random sort'){
          title = 'Browse Randomly Sorted Nude Celebs';
          desc = 'Browse Randomly Sorted Nude Celebrities at Xcelebs.';
       }

      title=`${title} - Xcelebs`;
      // setPageTitle(title);
      // setPageDescription(desc);
      document.body.style.zoom="100%";
     
    },[selectedHeader]);

    useEffect(()=>{

      var check = document.getElementById('checkMenu');
      check.checked = false;

      setFlag(true);

      
       if(window.location.href.split("/").length>5){

        const select = window.location.href.split("/")[5].toLowerCase();

        if(select.includes('popular'))
          return setSelectedHeader('sort by popularity');
        if(select.includes('updated'))  
         return setSelectedHeader('sort by recently updated');
        if(select.includes('trending')) 
         return setSelectedHeader('sort by trending');
        if(select.includes('random'))
         return setSelectedHeader('random sort');

          return setSelectedHeader('sort by popularity');
        }else
          return setSelectedHeader('sort by popularity')
       
    },[]);

    const selectedHaderHandler=(header)=>{
        setSelectedHeader(header);
    }

    const changeCheckHandler=(flag)=>{
      setIsShow(flag);
      var check = document.getElementById('checkMenu');
      check.checked = flag;
  }


  return (
    <BrowseCelebContainer>
    {/* <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
    </Helmet> */}
     <div className='container-fluid'>
                <div className='row'>

                <input type="checkbox" id="checkMenu"/>

                <div className='top-bar'>
                <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
                   </div>

                   <div className='top-bar-list'>
                             <TopBarList/>
                             </div>
                            

                   <div className='alph-header mb-2 mt-1 d-none d-md-block'>
                          <Alph/>
                     </div>

                     <div className='alph-header mb-2 mt-1 d-md-none'>
                         <Alphabat/>
                     </div>

                <div className='header-tag my-3'>
                 <Title name={'Celebs'}/>
                </div>

                <CelebAd/>

                <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto select-div'>
                   <SelectCelebs selectedHaderHandler={selectedHaderHandler} selectedHeader={selectedHeader}/> 
                </div>

                <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto list-div mb-4'>
                  { (selectedHeader==='sort by popularity' && flag)  && <PopularCelebList/> }
                  { (selectedHeader==='sort by recently updated' && flag) && <UpdateCelebList/>}
                  { (selectedHeader==='sort by trending' && flag) && <TrendingCelebList/>}
                  { (selectedHeader==='random sort' && flag) && <RandomCelebList/>}
                </div>  

                 <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto live-div'>
                <LiveBoard/>
            </div> 

                <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 trending-tags mb-5">
                <Tags/>
            </div>

                 <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                        <Footer/>
                      </div> 

                </div>
    </div>

         <ConnectionStatus/>

    </BrowseCelebContainer>
  )
}


const BrowseCelebContainer=styledComponents.div`

background: var(--lightBlack);

@media (min-width: 1024px) {
  .top-bar {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
    // padding:0 !important;
    // margin:0 !important;
  }

.select-div{
    padding:0 !important;
    margin:0 !important;
  }

  .list-div{
    // padding:0 !important;
    // margin:0 !important;
  }

  .footer-div{
    padding:0 !important;
    margin:0 !important;
  }

  #checkMenu{
    display:none;
  }
  
  .top-bar-list{
    padding:0 !important;
    margin:0 !important;
    overflow: hidden;
    position:relative;
    max-height: 0;
    transition: max-height 0.5s ease;
  }
  
  #checkMenu:checked ~ .top-bar-list{
    max-height: 300px;
    transition: max-height 0.5s ease;
  }

`;