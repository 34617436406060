import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

export const Celeb = ({cast,index}) => {

      // const navigate = useNavigate();

      const redirectToCeleb=(id,name)=>{
            return (id && name) && `/view/celeb/${id}/${name.toLowerCase().replaceAll(/[-\s]+/g, '-')}`;
      }

  return (
     <React.Fragment>
           <Link className="col-6 col-lg-2 col-md-3 col-sm-4 mx-auto item" to={redirectToCeleb(cast.celebId,cast.celebName)}>

           <div className="card">

           <div className="img-container">
           <img src={cast.imgUrl} alt={`${cast.celebName} Nude`} title={`${cast.celebName} Nude`} className="card-img-top" style={{width:'100%',height:'17rem'}}/> 
           </div>

           <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 views-img text-end">

           <div className="view pe-1 pt-1">
         {  (cast.imgUrl) && <span className='total-views-img'> <span className='pe-1'>#{index}</span></span> }
            </div>

            </div>

           </div> {/*card*/}

           <div className="card-footer">
                {(cast.celebName) && <h6 className='cast-name text-center px-2 text-uppercase'>{cast.celebName}</h6> }
            </div>

           </Link>
     </React.Fragment>
  )
}
