import React from 'react'
import styledComponents from 'styled-components';

export const Guideline = ()  => {
  return (

    <GuidelineContainer>

    <div className='container'>
    <div className='row'>

      <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto guideline-header my-2'>

      <h4 className='header'>Xcelebs - Community Guidelines</h4>

        </div>

        <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto preface'>
              <h5 className='header'>Preface</h5>

              <p className='message'>
              Xcelebs receives a large number of uploads per day, such as images, videos, etc. We have strict community guidelines that all Xcelebs users are required to follow.
               Users who violate our community guidelines will be banned from all Xcelebs websites.
              </p>
         </div>

         <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto what'>
              <h5 className='header'>What are community guidelines?</h5>

              <p className='message'>
              Community guidelines are a set of rules created by each social media platform to ensure a standard of behavior expected on the platform.
               The guidelines create a safe environment where users can interact and have fun.
              </p>
         </div>

         <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto our'>
              <h5 className='header'>Our Community Guidelines</h5>

              <p className='message'>
              To avoid termination of your account, please make you understand and follow our community guidelines listed below:
              </p>

              <div className='col-11 col-md-11 col-lg-11 col-sm-11 mx-auto our'>

              <p className='message'>
              1. You should not upload content (including music, videos, and artwork) that you have not created or are not authorized to use; otherwise, this could result in a copyright violation.
                  </p>

                  <p className='message'>
              2. You should not upload content that violates or infringes someone else's copyrights, trademarks, or other intellectual property rights. 
                  </p>

                  <p className='message'>
              3. You should not upload content of celebrities that is considered to be private or was made publicly available without the celebrity's consent, like "revenge porn","leaks" or "fappening".
                  </p>

                  <p className='message'>
              4. You should not upload content of underage celebrities, that is celebrities that have not reached 18 years old at the time of the shooting. Our zero-tolerance policy for underage content is described below.
                  </p>

                  <p className='message'>
              5. You should not upload content depicting child pornography, rape, snuff, torture, death, violence, incest, racial slurs, or hate speech (either aurally or via the written word).
                  </p>

                  <p className='message'>
              6. You should not upload content that encourages participation in, or intends to recruit individuals to, dangerous organizations.
                  </p>

                  <p className='message'>
              7. You should not upload conspiratorial content that attacks a specific protected group or includes a violent call to action, or denies a violent or tragic event occurred.
                  </p>

              </div>
         </div>

         <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto child'>
              <h5 className='header'>Zero-tolerance policy for Child Pornography and Underage Content</h5>

              <p className='message'>
              Regardless if the content has nudity or not, you are not allowed to use the Xcelebs website or forum to upload, share, transmit, link to any content belonging to underage celebrities, that is celebrities that have not reached 18 years old at the time of the shooting. </p>

              <p className='message'>
If the celebrity has not reached 18 years old at the time of the shooting but reached that after the time of the shooting, you are also not allowed to share it.
</p>

<p className='message'>
If you are unsure whether a celebrity is 18 years old, do NOT upload the content to Xcelebs. We operate a zero tolerance policy for users who upload underage content. We immediately and permanently close all accounts of any users who breach our policy for underage content. We also report violators of this policy to law enforcement agencies such as the National Crime Agency.
</p>

<p className='message'>
All reports of content involving minors are forwarded to law enforcement agencies of various countries, including but not limited to the National Center for Missing and Exploited Children (NCMEC) at https://web.cybertip.org/ and Internet Watch Foundation (IWF) at https://report.iwf.org.uk/ depending on the IP address of the uploader. Child pornography videos and videos of non-consensual sexual acts get special attention.
 You have been warned. DO NOT DO IT.
              </p>
              
         </div>


        </div>
        </div>

        </GuidelineContainer>
  )
}


const GuidelineContainer=styledComponents.div`

background: var(--lightBlack);

.header{
    color: var(--mainWhite);
    font-family: 'Open Sans', sans-serif; 
    font-weight:bold;
    font-size:0.9rem;
  }

  .message{
    color: var(--mainWhite);
    font-family: 'Open Sans', sans-serif; 
    font-size:0.8rem;
}
 

`;