import React from 'react'
import { Link } from 'react-router-dom';

export const Letter = ({changeLetter,selectedType,letter}) => {

    const changeLetterHandler=(letter)=>{
        if(letter==='#')
        return;
        changeLetter(letter);
      }

  return (
    <React.Fragment>
       {/* <Link to={`/browse/celebs/${letter}`} class="letter text-capitalize" onClick={()=>changeLetterHandler(letter)}>{letter}</Link> */}
       {letter === '#' ? (
  <a className="letter text-capitalize">{letter}</a>
) : (
  <Link to={`/browse/${selectedType}/${letter}`} className="letter text-capitalize" onClick={() => changeLetterHandler(letter)}>
    {letter}
  </Link>
)}
    </React.Fragment>
  )
}
