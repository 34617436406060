import React from 'react';
import styledComponents from 'styled-components';


export const DMCA = () => {

    const redirectTo=(path)=>{
        return window.location.href=path;
    }

    return (
        <Container>
        <div className="container">
            <div className="row">
                    
                    <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto dmca my-2'>
                       <h4 className='header'>Xcelebs Copyright Enforcement - Digital Millennium Copyright Act ("DMCA") Policy</h4>
                    </div>

                    <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto preface'>
                       <h5 className='header'>Preface</h5>

                       <p className='message'>
                       Xcelebs.org (the "Company") respects the intellectual property rights of others and expects its users to do
                        the same. In accordance with the Digital Millennium Copyright Act of 1998, the text of which may be found on the U.S. Copyright Office website at (<span className='link' onClick={()=>redirectTo('https://www.copyright.gov/legislation/dmca.pdf')}>https://www.copyright.gov/legislation/dmca.pdf</span>), Xcelebs will respond expeditiously to claims of copyright infringement committed using the Xcelebs website (the “Site”) if such claims are reported to Xceleb's Designated Copyright Agent. Xcelebs treats
                        valid DMCA take-down notices extremely seriously and processes notices during public holidays and weekends. 
                       </p>
                    </div>

                    <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto agent'>
                       <h5 className='header'>Designated Agent</h5>

                       <p className='message'>
                       To exercise your DMCA rights, you must send your Proper DMCA Notice to the following agent designated by Company (the "Designated Agent").
                        The contact information for Xceleb's Designated Agent is:
                       </p>

                       <p className='message'>
                       Email address: <a className='link'> xcelebs@protonmail.com</a> <br/>
This email address may be used only for copyright infringement claims - you will not receive a reply if the matter is not a copyright issue.
                       </p>

                       <p className='message'>
                       Upon receipt of proper notification of claimed infringement, Company will follow the procedures outlined herein and in the DMCA. Please note that, due to security concerns, attachments cannot be accepted. Accordingly,
                        any notification of infringement submitted electronically with an attachment will not be received or processed.
                       </p>
                    </div>

                    <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto introduction'>
                       <h5 className='header'>Introduction</h5>

                       <p className='message'>
                       This policy is intended to implement the procedures set forth in 17 U.S.C. Section 512 and the Digital
                        Millennium Copyright Act ("DMCA") for the reporting of alleged copyright infringement. It is the policy of Xcelebs.org services (the "Company") to respect the legitimate rights of copyright owners, their agents, and representatives. Users of any part of the Company
                        computing system are required to respect the legal protections provided by applicable copyright law.
                       </p>
                    </div>

                    <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto takedown'>
                       <h5 className='header'>Notice and Takedown Procedure</h5>

                       <p className='message'>
                       It is expected that all users of any part of the Company system will comply with applicable copyright laws.
                        However, if the Company receives proper notification of claimed copyright infringement it will respond expeditiously by removing, or disabling access to, the material that is claimed to be infringing or to be the subject of infringing activity provided all such claims have been investigated and determined to be valid by Company in Company's sole and absolute discretion. Company will
                        comply with the appropriate provisions of the DMCA in the event a counter notification is received.
                       </p>

                       <p className='message'>
                       Please allow at least three business days to process any complaint or counter-notification. Note that emailing your complaint to other parties such as our Internet Service Provider will not expedite
                        your request and may result in a delayed response due to the complaint not being filed properly.
                        </p>   
                    </div>

                    <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto infringe'>
                       <h5 className='header'>Repeat Infringers</h5>

                       <p className='message'>
                       Under appropriate circumstances, Company may, in its discretion, terminate authorization of users of its system or network who are repeat infringers.
                       </p>
                    </div>

                    <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto luman'>
                       <h5 className='header'>Publication to Lumen</h5>

                       <p className='message'>
                       Please note that a copy of each legal notice we receive may be sent to the Lumen project (<span className='link' onClick={()=>redirectTo('https://www.lumendatabase.org')}>https://www.lumendatabase.org</span>) for publication and annotation. Lumen will redact the submitter's personal contact information (i.e. phone number, e-mail and address).
You can see an example of such a publication at (<span className='link' onClick={()=>redirectTo('https://www.lumendatabase.org/notices/5838')}>https://www.lumendatabase.org/notices/5838</span>).
We may also send the original notice to the alleged infringer or, if we have reason to suspect the validity of your complaint, to the rights holder.
We may also publish similar information from your notice in future transparency reports that Xcelebs would publish.
                       </p>
                    </div>
            
            </div>
        </div>
        </Container>
    );
}

const Container=styledComponents.div`

  background: var(--lightBlack);

  .header{
    color: var(--mainWhite);
    font-family: 'Open Sans', sans-serif; 
    font-weight:bold;
    font-size:0.9rem;
  }
 
  .message{
    color: var(--mainWhite);
    font-family: 'Open Sans', sans-serif; 
    font-size:0.8rem;
}

.link{
    color: var(--lightOrange);
    font-family: 'Open Sans', sans-serif; 
    font-size:0.8rem;
    cursor:pointer;

    &:hover{
        text-decoration: underline;
    }
}

`;
