import React from 'react'
import styledComponents from 'styled-components'

export const Header = ({title,alph}) => {
  return (
      <HeaderContainer>
          <div className='container-fluid'>
               <div className='row'>
                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto header-col text-center my-4'>
                        <h1 className='header'>{title} <label className='a'>{alph}</label> at   <label className='x'>X</label>CELEBS</h1>
                   </div>
               </div>
          </div>
      </HeaderContainer>
  )
}

const HeaderContainer=styledComponents.div`
 
.header{
   color: var(--mainWhite);
   font-weight:bold;
   font-family: 'Karla', sans-serif;
   font-size:2rem;

   .x{
       color: var(--lightOrange);
   }
   
   .a{
    color: var(--lightOrange);
   }
}

`;