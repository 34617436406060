import React from 'react'
import HoverVideoPlayer from 'react-hover-video-player';

export const Video = ({item,activeIndexHandler,index}) => {

  // var sourceUrl = (item.sourceUrl) ? item.sourceUrl.toUpperCase() : item.videoUrl;

  // const sourceUrl = (item.sourceUrl.includes('aznude.com')) ? item.sourceUrl.toUpperCase() : item.sourceUrl;

  const sourceUrl = item.sourceUrl;

  const watermark="xcelebs.org";

  // const sourceUrl = (item.sourceUrl && !item.sourceUrl.includes("aznude")) ? item.sourceUrl : item.videoUrl;

    // const hoverVideoRef = useRef();

    // useEffect(() => {
    //     const videoElement = hoverVideoRef.current;
    //     videoElement.playbackRate = 5;
    //   }, []);

  return (
    <React.Fragment>
        <div className="col-6 col-md-3 col-lg-3 col-sm-4 mx-auto plays" onClick={()=>activeIndexHandler(index,item.videoId)}>
 
        <div className="card">  

        <HoverVideoPlayer className='player d-none d-lg-block'
  videoStyle={{width:'100%',height:'12.5rem'}}
  videoSrc={sourceUrl}
 // videoRef={hoverVideoRef}
  //playbackRangeStart={50}
  pausedOverlay={
    <img
      src={item.imgUrl}
      alt={item.title}
      style={{
         width: '100%',
         height: '100%',
        objectFit: 'cover',
      }}
    />
  }
  loadingOverlay={
    <div className="loading-overlay">
      <div className="loading-spinner" />
    </div>
  }
  />

<HoverVideoPlayer className='player d-lg-none'
  videoStyle={{width:'100%',height:'10rem'}}
  videoSrc={sourceUrl}
 // videoRef={hoverVideoRef}
  //playbackRangeStart={50}
  pausedOverlay={
    <img
      src={item.imgUrl}
      alt={item.title}
      style={{
         width: '100%',
         height: '100%',
        objectFit: 'cover',
      }}
    />
  }
  loadingOverlay={
    <div className="loading-overlay">
      <div className="loading-spinner" />
    </div>
  }
  />

<div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 time-quality d-flex">

<div className="col-6 mx-auto col-lg-6 col-md-6 col-sm-6 time text-start">

<div className="plays-time d-none d-lg-block">
 {(item.duration) && <span className='duration'>{item.duration}</span> }
</div>

<div className="plays-time-sm d-lg-none">
 {(item.duration) && <span className='duration'>{item.duration}</span> }
</div>

</div>

<div className="col-6 mx-auto col-lg-6 col-md-6 col-sm-6 quality text-end">

<div className="plays-quality text-uppercase d-none d-lg-block">
 { (item.quality) && <span className='resolution'>{item.quality}</span> }

</div>

<div className="plays-quality-sm text-uppercase d-lg-none">
 { (item.quality) && <span className='resolution'>{item.quality}</span> }
</div>

</div>

</div> {/*time-quality*/}

<div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 player-watermark text-end px-1 d-none d-xl-block">

<div className="watermark">
<span className='p-wm'>{watermark}</span> 
</div>

</div> {/*player-watermark*/}

<div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 player-watermark-sm text-end px-1 d-xl-none">

<div className="watermark">
<span className='p-wm'>{watermark}</span> 
</div>

</div> {/*player-watermark-sm*/}

       </div>  {/*card*/}
             </div>
             </React.Fragment>
  )
}
