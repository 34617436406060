import React,{useState,useEffect} from 'react'
import styledComponents from 'styled-components'
import axios from 'axios';
import { useNavigate } from 'react-router';
import { VideoLoadingList } from '../loading/VideoLoadingList';
import { Video } from '../test/Video';
import {Offer_1} from '../ads/Offer_1';
import {Offer_2} from '../ads/Offer_2';
import { Video_Premium_Offer_1 } from '../ads/Video_Premium_Offer_1';
import { Video_Premium_Offer_2 } from '../ads/Video_Premium_Offer_2';
import { Pagination } from '../pagination/Pagination';
import { Helmet } from 'react-helmet';

export const PopularVideoList = () => {

  const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Browse Nude Celeb Videos Sorted by Popularity - Xcelebs`);
  const [pageDescription, setPageDescription] = useState(`Browse Nude Celebrity Videos Sorted by Popularity at Xcelebs.`);

  const [nextPageUrl,setNextPageUrl]=useState('');
  const [prevPageUrl,setPrevPageUrl]=useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const pageParam = urlParams.get('page');

  const pageNum =  (pageParam && window.location.pathname.includes('popular')) ? parseInt(pageParam, 10) : 1;

    const [pages,setPages]=useState(0);
    const [currentPage,setCurrentPage]=useState(pageNum);
    const [currentBtn,setCurrentBtn]=useState(pageNum);
    const [pageLink,setPageLink]=useState(null);
  
    const [videos,setVideos]=useState([]);
    const [loading,setLoading]=useState(true);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const navigate = useNavigate();
    
    const itemPerPage=20;

    useEffect(()=>{
        setCurrentBtn(pageNum);
 },[pageNum]);

 const currentPageHandler=page=>{
     setCurrentPage(page);
 }

 useEffect(() => {

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  window.addEventListener('online', handleOnline);
  window.addEventListener('offline', handleOffline);

  return () => {
    window.removeEventListener('online', handleOnline);
    window.removeEventListener('offline', handleOffline);
  };

}, []);

 useEffect(()=>{
  document.body.style.zoom="100%";
  setPageLink(`/browse/videos/popular`);
  setCurrentPage(pageNum);
  if(pageNum==1 || !window.location.pathname.includes('popular')){
    const title = 'Browse Nude Celeb Videos Sorted by Popularity - Xcelebs';
    const desc = 'Browse Nude Celebrity Videos Sorted by Popularity at Xcelebs.';
    setPageTitle(title);
    setPageDescription(desc);
    document.title = title;

    setNextPageUrl(`https://xcelebs.org/browse/videos/popular?page=${2}`);
   navigate(`/browse/videos/popular`);
  }else{
    const title=`Browse Nude Celeb Videos Sorted by Popularity - Page ${pageNum} - Xcelebs`;
    const desc = `Page ${pageNum}: Browse Nude Celebrity Videos Sorted by Popularity at Xcelebs.`;
    setPageTitle(title);
    setPageDescription(desc);
    document.title = title;

    setNextPageUrl(`https://xcelebs.org/browse/videos/popular?page=${pageNum+1}`);

    if(pageNum==2)
      setPrevPageUrl(`https://xcelebs.org/browse/videos/popular`);
     else
      setPrevPageUrl(`https://xcelebs.org/browse/videos/popular?page=${pageNum-1}`);
  }
},[pageNum]);


useEffect(() => {

  const fetchData = async () => {
    
    if (isNaN(currentBtn)) 
    return;

    if (currentBtn === 1) {
      window.scrollTo(0, 0);
  } else {
      if (window.innerWidth >= 1300) { 
          window.scrollTo(0, 140);
      }  else if (window.innerWidth >= 700) {
          window.scrollTo(0, 180);
      } else
          window.scrollTo(0, 220);
  }

    const popular_url = process.env.REACT_APP_CELEB_VIDEO;

    setLoading(true);

    if (!isOnline)
      return;

    try {
      const response = await axios.get(`${popular_url}/popular/list?page=${currentPage}`);
      setVideos(response.data.celebVideoList);
      setPageHandler(response.data.numOfItems);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  fetchData();
}, [currentBtn,isOnline,pageNum]);


const setPageHandler =length=>{

 const numOfItems=length;     
 let page = parseInt(numOfItems/itemPerPage);

 if(parseInt(numOfItems%20)!==0)
  page+=1;

  setPages(page);
};



  return (
     <PopularVideoListContainer>
           <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
        { (prevPageUrl) && <link rel="prev" href={prevPageUrl} />}
        { (nextPageUrl && pages !==1 && currentBtn !==pages) && <link rel="next" href={nextPageUrl} />}
    </Helmet>
          <div className="container-fluid mt-4 mb-2">
                     <div className="row">

                     
                     { (!loading) &&  <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 plays-list plays-list-lg d-none d-lg-block">
                     <div className="row">

                      { (videos) && videos.slice(0,8).map((video)=><Video key={video.videoId} item={video}/>)}

                      { (currentBtn%2==0 ? <Video_Premium_Offer_2/> : <Video_Premium_Offer_1/> )  }

                      { (videos) && videos.slice(8,16).map((video)=><Video key={video.videoId} item={video}/>)}

                      { (currentBtn%2==0 ? <Offer_2/> : <Offer_1/> )  }

                      { (videos) && videos.slice(16,20).map((video)=><Video key={video.videoId} item={video}/>)}

                        </div>
                      </div> }

                      { (!loading) &&  <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 plays-list plays-list-sm d-lg-none">
                     <div className="row">

                     { (videos) && videos.slice(0,8).map((video)=><Video key={video.videoId} item={video}/>)}

                     { (currentBtn%2==0 ? <Video_Premium_Offer_2/> : <Video_Premium_Offer_1/> )  }

                    { (videos) && videos.slice(8,16).map((video)=><Video key={video.videoId} item={video}/>)}

                    { (currentBtn%2==0 ? <Offer_2/> : <Offer_1/> )  }

                    { (videos) && videos.slice(16,20).map((video)=><Video key={video.videoId} item={video}/>)}

                        </div>
                      </div> }

                     </div>
        </div>  

        {
                      (loading) && <VideoLoadingList/>
                    }

        { (videos && videos.length>=1 && !loading) &&  <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 pages">

<Pagination pages={pages} currentPageHandler={currentPageHandler}
        link={pageLink} currentBtn={currentBtn} setCurrentBtn={setCurrentBtn}/> 
                  
                    </div>  }

                     {/* <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 trending-tags mt-4 mb-5">
                <Tags/>
            </div> */}
              
            
     </PopularVideoListContainer>
  )
}

const PopularVideoListContainer=styledComponents.div`


// background: var(--lightBlack);


// .plays-list .plays{
//   position:relative;
//   border: 3px solid transparent;
//   padding:0 !important;
//   margin:0 !important;
//   transform: scale(0.9);
//   transition:0.8s all;

//   &:hover{
//     transition:0.8s all;
//     cursor:pointer;
//     border: 3px solid var(--lightOrange);
//     .title{
//       color: var(--lightOrange);
//     }
//     .video-views{
//       transition:0.8s all;
//       z-index:-1111;
//     }
//     .time-quality{
//       transition:0.8s all;
//       z-index:-1111;
//     }
//   }
// }

// .card{
//   background: transparent;
// }

// .card-footer{
//   padding:0 !important;
//   margin:0 !important;
// }

// .plays .title{

//   // position: absolute;
//   // //top:90%;
//   // z-index:1111;  
//     font-size:1rem;
//     font-family: 'Open Sans', sans-serif; 
//     color: var(--mainWhite);
//     transition:0.8s all;
// }

// .time-quality{
//   position: absolute;
//   top:1%;
//   z-index:1111;

//   .plays-time{

//     .duration{
//       background: var(--lightGrey);
//       padding: 0.2rem 0.5rem 0.2rem 0.5rem;
//       font-size:0.9rem;
//       color: var(--mainDark2);
//       font-family: 'Open Sans', sans-serif; 
//     //  font-weight:bold;
//     }

//   }

//   .plays-time-sm{

//     .duration{
//       background: var(--lightGrey);
//       padding: 0.2rem 0.3rem 0.2rem 0.3rem;
//       font-size:0.7rem;
//       color: var(--mainDark2);
//       font-family: 'Open Sans', sans-serif; 
//     //  font-weight:bold;
//     }
//   }

//     .plays-quality{

//       .resolution{
//         font-family: 'Open Sans', sans-serif; 
//         background: var(--lightGrey);
//         padding: 0.2rem 0.4rem 0.2rem 0.4rem;
//         font-size:0.9rem;
//         color: var(--lightRed);
//         font-weight:bold;
//       }
//     }

//     .plays-quality-sm{

//       .resolution{
//         font-family: 'Open Sans', sans-serif; 
//         background: var(--lightGrey);
//         padding: 0.2rem 0.3rem 0.2rem 0.3rem;
//         font-size:0.7rem;
//         color: var(--lightRed);
//         font-weight:bold;
//       }
//     }

//   }

// .film-rate{
  
// }

// .film{
//   .film-name {
//     color: var(--lightGrey);
//     font-size:0.8rem;
//     font-family: 'Josefin Sans', sans-serif;
//     transition:0.5s all;

//     &:hover{
//       transition:0.5s all;
//       color: var(--mainWhite);
//     }
//   }

// }

// .play-rate{
//   color: var(--lightGreen);
//   .fa-thumb{
//    font-size:1rem;
//   }
//   .percentage{
//     font-size:0.9rem;
//   }
// }

// .video-views{
//   position: absolute;
//   top:1%;
//   z-index:1111;
//   margin:0;
//   padding:0;

//   .view{

//   .total-views{
//       background: rgba(0,0,0,0.7);
//       padding: 0.2rem 0.4rem 0.2rem 0.4rem;
//       color: var(--mainWhite);
//       font-size:0.9rem;
//       font-family: 'Open Sans', sans-serif; 
//   }
//   .fa-views{
//       font-size:1rem;
//   }
// }

// .view-sm{

//   .total-views{
//       background: rgba(0,0,0,0.7);
//       padding: 0.2rem 0.3rem 0.2rem 0.3rem;
//       color: var(--mainWhite);
//       font-size:0.7rem;
//       font-family: 'Open Sans', sans-serif; 
//   }
//   .fa-views{
//       font-size:0.8rem;
//   }
// }

background: var(--lightBlack);

.plays-list-sm{
  padding:0 !important;
  margin:0 !important;
}

.plays-list .plays{
  position:relative;
  border: 3px solid transparent;
  padding:0 !important;
  margin:0 !important;
  transform: scale(1);
  transition:0.8s all;
  text-decoration: none;

  &:hover{
    transition:0.8s all;
    cursor:pointer;
    // border: 3px solid var(--lightOrange);
    .player{
      border: 3px solid var(--lightOrange);
    }
    .title{
      color: var(--lightOrange);
    }
    .title-sm{
      color: var(--lightOrange);
    }
    .video-views{
      // transition:0.8s all;
      z-index:-1111;
    }
    .time-quality{
      transition:0.8s all;
      z-index:-1111;
    }
    .player-watermark{
      // transition:0.8s all;
      z-index:1111;
      opacity: 1;
    }
    .player-watermark-sm{
      // transition:0.8s all;
      z-index:1111;
      opacity: 1;
    }
  }
}

@media (min-width: 768px) {
  .plays-list .plays {
    transform: scale(0.98);
  }
}


.plays-list .player{
  border: 3px solid transparent;
}

.card{
  background: transparent;
}

.card-footer{
  padding:0 !important;
  margin:0 !important;
}

.plays .title{

  // position: absolute;
  // //top:90%;
  // z-index:1111;  
    font-size:1rem;
    font-family: 'Open Sans', sans-serif; 
    color: var(--mainWhite);
    transition:0.8s all;
}

.plays .title-sm{

  // position: absolute;
  // //top:90%;
  // z-index:1111;  
    font-size:0.8rem;
    font-family: 'Open Sans', sans-serif; 
    color: var(--mainWhite);
    transition:0.8s all;
}

  

.time-quality{
  position: absolute;
  // top:-0.1%;
  bottom:4.5%;
  z-index:1111;

  padding-left: 0.4rem;

  .plays-time{

    .duration{
      background: var(--lightGrey);
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      font-size:0.7rem;
      color: var(--mainDark2);
      font-family: 'Open Sans', sans-serif; 
      font-weight:bold;
    }

  }

  .plays-time-sm{

    .duration{
      background: var(--lightGrey);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      font-size:0.6rem;
      color: var(--mainDark2);
      font-family: 'Open Sans', sans-serif; 
      font-weight:bold;
    }
  }

    .plays-quality{

      padding-left: 0.1rem;

      .resolution{
        font-family: 'Open Sans', sans-serif; 
        background: var(--lightGrey);
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;
        font-size:0.7rem;
        color: var(--brightRed);
        font-weight:bold;
      }
    }

    .plays-quality-sm{

      padding-left: 0.1rem;

      .resolution{
        font-family: 'Open Sans', sans-serif; 
        background: var(--lightGrey);
        padding: 0.1rem 0.2rem 0.1rem 0.2rem;
        font-size:0.6rem;
        color: var(--brightRed);
        font-weight:bold;
      }
    }

  }

  @media (min-width: 768px) {
    .time-quality {
      bottom:5%;
      padding-left: 0.5rem;
    }
  }

.film{
  .name{
    text-decoration: none;
  }
  .film-name {
    color: var(--lightGrey);
    font-size:0.8rem;
    font-family: 'Josefin Sans', sans-serif;
    transition:0.5s all;

    &:hover{
      transition:0.5s all;
      color: var(--mainWhite);
      text-decoration: underline; 
      color: var(--mainWhite);
      text-decoration-thickness: 1.7px;
    }
  }

  .film-name-sm{
    color: var(--lightGrey);
    font-size:0.7rem;
    font-family: 'Josefin Sans', sans-serif;
    transition:0.5s all;

    &:hover{
      transition:0.5s all;
      color: var(--mainWhite);
      text-decoration: underline; 
      color: var(--mainWhite);
      text-decoration-thickness: 1.5px;
    }
  }

}

.play-rate{
  color: var(--lightGreen);
  .fa-thumb{
   font-size:0.8rem;
  }
  .percentage{
    font-size:0.8rem;
  }
}

.video-views{
  position: absolute;
  // top:-0.1%;
  bottom:4.5%;
  z-index:1111;
  margin:0;
  padding:0;

  padding-right: 0.4rem;

  .view{

  .total-views{
      background: rgba(0,0,0,0.7);
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      color: var(--mainWhite);
      font-size:0.7rem;
      font-family: 'Open Sans', sans-serif; 
  }
  .fa-views{
      font-size:0.8rem;
  }
}

.view-sm{

  .total-views{
      background: rgba(0,0,0,0.7);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      color: var(--mainWhite);
      font-size:0.6rem;
      font-family: 'Open Sans', sans-serif; 
  }
  .fa-views{
      font-size:0.7rem;
  }
}

} 

@media (min-width: 768px) {
  .video-views {
    bottom:5%;
    padding-right: 0.5rem;
  }
}

.player-watermark{
  position: absolute;
  top:1%;
  right:1%;
  z-index:-1111;
  opacity: 0;
  margin:0;
  padding:0;

  .watermark{

    .p-wm{
      background: rgba(0,0,0,0.9);
      padding: 0.1rem 0.4rem 0.1rem 0.4rem;
      color: var(--mainWhite);
      font-size:0.8rem;
      font-family: 'Open Sans', sans-serif; 
  }

  }

}

.player-watermark-sm{
  position: absolute;
  top:-0.1%;
  z-index:-1111;
  opacity: 0;
  margin:0;
  padding:0;

  .watermark{

    .p-wm{
      background: rgba(0,0,0,0.9);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      color: var(--mainWhite);
      font-size:0.6rem;
      font-family: 'Open Sans', sans-serif; 
  }

  }

}

`;
