import React, { useEffect } from 'react';
import { Banner } from "exoclick-react";
import {isMobile} from 'react-device-detect';
import {isBrowser} from 'react-device-detect';
import axios from 'axios';

export const BioAds  = () =>{

  useEffect(()=>{

    const script = document.createElement('script');

  script.src = "https://poweredby.jads.co/js/jads.js";
  script.async = true;

  document.body.appendChild(script);

  },[]);

  const trackAffiliate=(id,name,type,provider,link)=>{

    axios.get('https://ipapi.co/json/')
  .then((response) => {
    const data = response.data;
    const country = data.country_name;
    trackAffiliateOffer(id,name,type,country,provider,link);
    return (link) && window.open(link);
  })
  .catch((error) => {
    console.error('Error fetching country:', error);
    trackAffiliateOffer(id,name,type,'',provider,link);
    return (link) && window.open(link);
  });

    }


  const trackAffiliateOffer= async (id,name,type,location,provider,link)=>{

    const res = axios.get(`https://hutils.loxal.net/whois`);
    const ip = (await res).data.ip;

    let device="undetected";

    if (isMobile)
     device="mobile";

    if (isBrowser)
     device="desktop";
    

    const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
      affiliateLink:link,deviceType:device,ipAddress:ip,country:location};

      const save_url=process.env.REACT_APP_AFFILIATE;

      axios.post(`${save_url}`,detail,{
          headers:{
              'Content-Type':'application/json',
           //   'Authorization':'bearer '.concat(token)
          }
      });

  }


  const random = parseInt(Math.random() * 2, 10);

const random_ads=[{id:'ad101',offer:'https://t.acam-2.com/226438/2934/8990',src:'https://i.ibb.co/c1C93DF/006362-A-GDAT-18-ALL-EN-125-L.jpg',provider:'crakrevenue',name:'CR-CAM-BIO',type:'PPL'},
         {id:'ad102',offer:'https://lp.mydirtyhobby.com/2/?video=4&lang=de&ats=eyJhIjo3ODIwMzIsImMiOjYxMDE0ODAzLCJuIjoyMSwicyI6MjQxLCJlIjo5NTQyLCJwIjoyfQ==',src:'https://i.ibb.co/sytb8VM/900-X250-ENG-NSFW.jpg',provider:'adultforce',name:'AF-MyDirtyHobby-BIO',type:'RevShare'}];

  return (
    <React.Fragment>

         <div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto stream-offer text-center d-none d-md-block'>


         {/* <ins id="999784" data-width="728" data-height="90" className='mb-3'></ins> */}

         <a target="_blank"><img src={random_ads[random].src} width="728" height="90" border="0" className='mb-3' onClick={()=>trackAffiliate('CHAR108',random_ads[random].name,random_ads[random].type,random_ads[random].provider,random_ads[random].offer)} style={{ cursor: 'pointer' }}/></a>

        </div>

<div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto stream-offer text-center d-md-none mb-3'>

{/* <ins id="1000110" data-width="300" data-height="100" className='mb-2'></ins> */}

<Banner zoneId={4870560}/>

    </div>

    </React.Fragment>
  )
}
