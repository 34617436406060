import React,{useState,useRef,useEffect} from 'react';
import styledComponents from 'styled-components';
import { CountryField } from './CountryField';
import { DobField } from './DobField';
import { EmailField } from './EmailField';
import { NameField } from './NameField';
import { RegisterButton } from './RegisterButton';
import { PwdField } from './PwdField';
import axios from 'axios';
import { Success } from './Success';
import { Link, useNavigate } from 'react-router-dom';

export const Signup = () => {

    const navigate=useNavigate();

    const [submit,setSubmit]=useState(false);
    const [countries,setCountries]=useState([]);
    const [isRegisterd,setIsRegisterd]=useState(false);
    const [name,setName]=useState({fname:'',lname:''});
    const [hasError,setHasError]=useState(false);
    const [email,setEmail] = useState('');
    const [country,setCountry]=useState('');
    const [enableCountry,setEnableCountry] = useState(false);
    const [dob,setDob]=useState(new Date('05 02 1997'));
    const [pwd,setPwd]=useState({password:'',c_password:''});
    const [error,setError]=useState({fname:'',lname:'',email:'',country:'',password:'',c_password:'',dob:''});

    const inputRef=useRef();

    const siteKey = process.env.REACT_APP_SITE_KEY;

    useEffect(()=>{

        const script = document.createElement('script');
        script.src=`https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        document.body.appendChild(script);

         if(inputRef.current!==null)
           inputRef.current.focus();
    },[])

    const initializeMode=()=>{
        setName({...name,fname:'',lname:''});
        setEmail('');
        setCountry('');
        setDob(new Date('05 02 1997'));
        setPwd({...pwd,password:'',c_password:''});
    }

    const nameChangeHandler=e=>{
        const {name,value} = e.target;
        setName(prev=>({...prev,[name]:value}));
    }

    const emailChangeHandler=e=>{
           const {value} = e.target;
           setEmail(value);
    }

    const countryChangeHandler=e=>{
        const {value} = e.target;
        setCountry(value);
        setEnableCountry(false);
    }

    const setCountryName=name=>{
        setEnableCountry(true);
        setCountry(name);
    }

    const pwdChangeHandler=e=>{
         const {name,value}=e.target;
         setPwd(prev=>({...prev,[name]:value}));
    }

    const getMonth=month=>{
        switch(month){
            case 1: 
                return 'Jan';
            case 2:
                return 'Feb';
            case 3:
                return 'Mar';
            case 4:
                return 'Apr';
            case 5:
                return 'May';
            case 6:
                return 'Jun';
            case 7:
                return 'Jul';
            case 8:
                return 'Aug';
            case 9:
                return 'Sep';
            case 10:
                return 'Oct';
            case 11:
                return 'Nov';
            case 12:
                return 'Dec';                                    

        }
    }
    const setBirth=()=>{

        let date = dob.getDate();
        if(date<10)
          date = "0".concat(date.toString());
        date = date.toString();  

        const month = getMonth((parseInt(dob.getMonth())+1));
        const year = dob.getFullYear().toString();

        return date.concat("-").concat(month).concat("-").concat(year);
    }

    const dateValidate=(year)=>{

        if(year<1945 || year>2015){
        setError({...error,fname:'',lname:'',email:'',country:'',password:'',c_password:'',dob:'**Invalid Year**'});
        setHasError(true);
        return true;
        }
        return false;
    }

    const submitHandler=e=>{
        e.preventDefault();

        if(validationCheck())
           return;

           const user_url=process.env.REACT_APP_USER;

           setSubmit(true);

           window.grecaptcha.ready(()=>{
            window.grecaptcha.execute(siteKey,{action: 'submit'})
            .then(token=>{
      
                const user={
                      email:email,
                      fname:name.fname,
                      lname:name.lname,
                      dob: setBirth(dob),
                      country:country,
                      password:pwd.password,
                      token:token
                };               
               axios.post(`${user_url}/register`,user,{
                   'Content-Type':'application/json'
               }).then(res=>{
                   setIsRegisterd(res.data.status);
                   setSubmit(false);
                   initializeMode();
               }).catch(error=>{
                   console.log(error);
                   setSubmit(false);
               });
                
                  });

            });

    }

    const validationCheck=()=>{
        if(name.fname.trim().length<=0){
            setError({...error,fname:'**First Name is required**',lname:'',email:'',country:'',password:'',c_password:'',dob:''});
            setHasError(true);
            return true;
        }
        if(name.lname.trim().length<=0){
            setError({...error,fname:'',lname:'**Last Name is required**',email:'',country:'',password:'',c_password:'',dob:''});
            setHasError(true);
            return true;
        }
        if(email.trim().length<=0){
            setError({...error,fname:'',lname:'',email:'**Email is required**',country:'',password:'',c_password:'',dob:''});
            setHasError(true);
            return true;
        }
        if(!emailRegExp()){
            setError({...error,fname:'',lname:'',email:'**Invalid Email**',country:'',password:'',c_password:'',dob:''});
            setHasError(true);
            return true;
        }
        if(country.trim().length<=0){
        setError({...error,fname:'',lname:'',email:'',country:'**Country is required**',password:'',c_password:'',dob:''});
          setHasError(true);
          return true;
        }
        if(validateCountry(country).length===0){
            setError({...error,fname:'',lname:'',email:'',country:'**Invalid Country**',password:'',c_password:'',dob:''});
            setHasError(true);
            return true;
        }
        if(dob===null){
            setError({...error,fname:'',lname:'',email:'',country:'',password:'',c_password:'',dob:'**Date Of Birth is required**'});
            setHasError(true);
            return true;
        }
        if(dateValidate(dob.getFullYear())){
            return true;
        }
        if(pwd.password.trim().length<=0){
            setError({...error,fname:'',lname:'',email:'',country:'',password:'**Password is required**',c_password:'',dob:''});
            setHasError(true);
            return true;
        }
        if(!pwdRegExp()){
            setError({...error,fname:'',lname:'',email:'',country:'',password:'**Strong Password is required**',c_password:'',dob:''});
            setHasError(true);
            return true;
        }
        if(pwd.c_password.trim().length<=0){
            setError({...error,fname:'',lname:'',email:'',country:'',password:'',c_password:'**Confirm Password is required**',dob:''});
            setHasError(true);
            return true;
        }
        if(pwd.password!==pwd.c_password){
            setError({...error,fname:'',lname:'',email:'',country:'',password:'',c_password:'**Password is not matched**'});
            setHasError(true);
            return true;
        }
        setError({...error,fname:'',lname:'',email:'',country:'',password:'',c_password:'',dob:''});
        return false;
    }


    const validateCountry=(country)=>{
        return countries.filter(c=>c.toLowerCase()===country.toLowerCase());
      }
  
      const pwdRegExp=()=>{
          if(pwd.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/))
          return true;
      }
  
      const emailRegExp=()=>{
          if(email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/))
           return true;
      }
  
      const redirectTologinPage=()=>{
        return '/login';
      }

      
  return (
    <SignupContainer>
        <div className="container">
                <div className="row">
                    <div id="signup-modal" className="col-11 col-md-10 col-lg-8 col-sm-10 mx-auto signup-modal">

                   { (isRegisterd) &&
                     <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 registration">
                        <Success/>
                     </div>  
                   }  

                    <div className="col-12 col-md-12 col-lg-12 col-sm-12 header text-center mt-5">
                        <h4 className="name">Sign Up</h4>
                        <p className="value">Already a member? <Link className="link" to={redirectTologinPage()}
                        >Login</Link></p>
                    </div>

                    <form onSubmit={(e)=>submitHandler(e)}>

                    <div className="col-12 col-md-12 col-lg-12 col-sm-12 name-div">
                      <NameField nameChangeHandler={nameChangeHandler} name={name} error={error}
                      hasError={hasError} ref={inputRef}/>
                    </div>

                    <div className="col-12 col-md-12 col-lg-12 col-sm-12 email-div">
                       <EmailField emailChangeHandler={emailChangeHandler} email={email}
                       error={error} hasError={hasError}/>
                    </div>

                    <div className="col-12 col-md-12 col-lg-12 col-sm-12 country-div">
                       <CountryField countryChangeHandler={countryChangeHandler} cName={country}
                       error={error} hasError={hasError} setCountryName={setCountryName}
                       enableCountry={enableCountry} setCountries={setCountries} countries={countries}/>
                    </div>

                    <div className="col-12 col-md-12 col-lg-12 col-sm-12 dob-div">
                        <DobField dob={dob} setDob={setDob} error={error} hasError={hasError}/>
                    </div>

                    <div className="col-12 col-md-12 col-lg-12 col-sm-12 pwd-div">
                    <PwdField pwd={pwd} error={error} hasError={hasError}
                    pwdChangeHandler={pwdChangeHandler}/>
                    </div>

                    <div className="col-12 col-md-12 col-lg-12 col-sm-12 btn-div text-left px-5 mb-4">

                    <div className="g-recaptcha"
        date-sitekey={siteKey}
        data-size="invisible"></div>

                      <RegisterButton type="submit" className={"submit-btn "+(submit && 'submit-btn-dis')} disabled={submit}
                      >SignUp</RegisterButton>
                     </div>

                     </form>

                    </div>
                </div>
            </div>
    </SignupContainer>
  )
}

const SignupContainer=styledComponents.div`

background: var(--lightBlack);

// position:fixed;
// top:0;
// bottom:0;
// right:0;
// left:0;
// display:flex;
// align-items:center;
// justify-content:center;

#signup-modal{
    position:relative;
    background: var(--lightBlack);
    border: 3px solid var(--lightGreen);
    border-radius: 4rem;

}

.header .name{
    font-size:2.5rem;
    font-weight:bold;
    color: var(--lightGrey);
    font-family: 'Poppins', sans-serif;
}

.header .value{
    font-family: 'Open Sans', sans-serif;
    font-size:0.9rem;
    color: var(--lightGrey);
}

.header .link{
    cursor:pointer;
    color: var(--lightBlue);
    text-decoration:none;
    transition: 0.5s all;

    &:hover{
        color: var(--lightBlue3);
        transition: 0.5s all;
    }
}

.dob-div{
    padding:0;
    margin:0;
}

.close{
    z-index:1111;

    .close-icon{
        font-size:2rem;
        cursor:pointer;
        color: var(--mainWhite) !important;

    }
}

.sumbit-btn{
    cursor:pointer;
    opacity:1;
}

.submit-btn-dis{
    cursor: not-allowed;
    opacity:0.7;
}


`;
