import React,{useEffect,useState} from 'react'
import styledComponents from 'styled-components'
import { FullpageInterstitial } from "exoclick-react";

export const Pagination = ({pages,currentPageHandler,currentBtn,setCurrentBtn}) =>{

     const [arrOfCurrentBtns,setArrOfCurrentBtns]=useState([]);
 
 
     const numOfPages = [];
 
     const maximunBtn=6;
 
 
     for(let i=1;i<=pages;i++){
         numOfPages.push(i); 
     }
 
 
     useEffect(()=>{
 
         let tempNumOfPages = [...arrOfCurrentBtns];
 
           let dotsInitial='...';
           let dotsLeft='...\xa0';
           let dotsRight='\xa0...';
 
         if(numOfPages.length<=maximunBtn){
             const sliced = numOfPages.slice(0,numOfPages.length);
             tempNumOfPages=[...sliced];
         }else if(currentBtn>=maximunBtn-2 && currentBtn<numOfPages.length-2){
            const sliced=numOfPages.slice(currentBtn-2,currentBtn+1);
            tempNumOfPages=[1,dotsLeft,...sliced,dotsRight,numOfPages.length];
         }else if(currentBtn>=numOfPages.length-2){
              const sliced = numOfPages.slice(numOfPages.length-4,numOfPages.length);
              tempNumOfPages=[1,dotsLeft,...sliced];
         }
         else if(numOfPages.length>maximunBtn){
             const sliced = numOfPages.slice(0,maximunBtn-2);
             tempNumOfPages=[...sliced,dotsInitial,numOfPages.length];
         }
 
         if(currentBtn===dotsInitial)
         setCurrentBtn(arrOfCurrentBtns[arrOfCurrentBtns.length-3]+1);     
       else if(currentBtn===dotsLeft)
          setCurrentBtn(arrOfCurrentBtns[2]-1);
       else if(currentBtn===dotsRight)
         setCurrentBtn(arrOfCurrentBtns[arrOfCurrentBtns.length-3]+1);   
 
         setArrOfCurrentBtns(tempNumOfPages);
 
       //  console.log(tempNumOfPages);
 
     },[pages,currentBtn]);
 
 
     const changePageHandler=page=>{
 
         setCurrentBtn(page);
 
         let tempPage=page;
         
         let dotsInitial = '...';
         let dotsLeft = '...\xa0';
         let dotsRight = '\xa0...';
 
        if(tempPage===dotsInitial)
          tempPage= (arrOfCurrentBtns[arrOfCurrentBtns.length-3])+1;
        else if(tempPage===dotsLeft)  
          tempPage = (arrOfCurrentBtns[2])-1;
        else if(tempPage===dotsRight) 
           tempPage = (arrOfCurrentBtns[arrOfCurrentBtns.length-3])+1; 
 
           currentPageHandler(tempPage);
 
     }
     
  return (
    <PageContainer>
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-12 col-sm-12 col-lg-12 page text-center my-4
             d-none d-sm-block">

            <span className="ctrl-page">
                    <a  className={(currentBtn===1) && 'disable ad-page'} onClick={()=>{ (currentBtn !==1) && setCurrentBtn(prev=>prev-1); 
                  (currentBtn !==1) && currentPageHandler(currentBtn-1)}}>Prev</a>
              </span>      
                {
                    arrOfCurrentBtns.map((page,index)=>{
                       return <span className="single-page" key={index}>
                 <a  className={currentBtn===page && 'active ad-page'} onClick={()=>{changePageHandler(page)}}>{page}</a>
                       </span>
                    })
                }
             <span className="ctrl-page ">
                    <a  className={(currentBtn===numOfPages.length) && 'disable ad-page'}
                    onClick={()=>{(currentBtn!==numOfPages.length) && setCurrentBtn(prev=>prev+1);
                     (currentBtn!==numOfPages.length) && currentPageHandler(currentBtn+1)}}>Next</a>
             </span>      

            </div>  {/*page*/}


            <div className="col-12 col-md-12 col-sm-12 col-lg-12 page-sm text-center my-4 d-sm-none">

            <span className="ctrl-page">
                    <a  className={(currentBtn===1) && 'disable ad-page'} onClick={()=>{ (currentBtn !==1) && setCurrentBtn(prev=>prev-1);
                     (currentBtn !==1) && currentPageHandler(currentBtn-1)}}>Prev</a>
              </span>      
                {
                    arrOfCurrentBtns.map((page,index)=>{
                       return <span className="single-page" key={index}>
                 <a  className={currentBtn===page && 'active ad-page'} onClick={()=>{changePageHandler(page)}}>{page}</a>
                       </span>
                    })
                }
             <span className="ctrl-page">
                    <a className={(currentBtn===numOfPages.length) && 'disable ad-page'}
                    onClick={()=>{(currentBtn!==numOfPages.length) && setCurrentBtn(prev=>prev+1);
                     (currentBtn!==numOfPages.length) && currentPageHandler(currentBtn+1)}}>Next</a>
             </span>      

            </div> {/*page-sm*/}
        </div>
    </div>

    {/* <FullpageInterstitial
  zoneId={4833622}
  frequencyType="clicks"
  firstTriggerClicks={3}
  nextTriggerClicks={5}
  triggerClass={['ad-page']}
/>

<FullpageInterstitial
  zoneId={4833628}
  frequencyType="clicks"
  firstTriggerClicks={3}
  nextTriggerClicks={5}
  triggerClass={['ad-page']}
/> */}

</PageContainer>
  )
}

const PageContainer=styledComponents.div`

.page a.active{
    background-color: var(--lightGreen);
    color: var(--lightYellow) !important;
    border:1px solid var(--mainDark);
    transition: 0.5s;
}

.page a:hover:not(.active,.disable){
    color: var(--mainDark) !important;
    background-color: rgb(121, 196, 121);
    transition: 0.5s;
} 

.page .single-page a{
    text-decoration: none;
    cursor: pointer;
    color: var(--lightGrey);
    padding: 0.5rem 0.9rem;
    border:1px solid var(--lightGrey);
    margin: 0 0.2rem;
    border-radius: 0.2rem;
    font-size:0.8rem;

}

.page .ctrl-page a{
    text-decoration: none;
    cursor: pointer;
    color: var(--lightGrey);
    padding: 0.5rem 0.8rem;
    border:1px solid var(--lightGrey);
    margin: 0 0.2rem;
    border-radius: 0.2rem;
    font-size:0.8rem;
    font-family: 'Open Sans', sans-serif; 
    font-weight:bold;

}

.page-sm a.active{
    background-color: var(--lightGreen);
    color: var(--lightYellow) !important;
    border:1px solid var(--mainDark);
    transition: 0.5s;
}

.page-sm a:hover:not(.active,.disable){
    color: var(--mainDark) !important;
    background-color: rgb(121, 196, 121);
    transition: 0.5s;
} 

.page-sm .single-page a{
    text-decoration: none;
    cursor: pointer;
    color: var(--lightGrey);
    padding: 0.4rem 0.6rem;
    border:1px solid var(--lightGrey);
    margin: 0 0.2rem;
    border-radius: 0.2rem;
    font-size:0.6rem;

}

.page-sm .ctrl-page a{
    text-decoration: none;
    cursor: pointer;
    color: var(--lightGrey);
    padding: 0.4rem 0.6rem;
    border:1px solid var(--lightGrey);
    margin: 0 0.2rem;
    border-radius: 0.2rem;
    font-size:0.6rem;
    font-family: 'Open Sans', sans-serif; 
    font-weight:bold;

}


`;