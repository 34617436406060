import React from 'react'
import styledComponents from 'styled-components';
import { Tag } from './Tag';

export const TagLoading = ()=> {

  const tags=[1,2,3,4,5,6,7,8];

  return (
      <LoadContainer>

<div className="col-12 col-md-12 col-lg-12 col-sm-12 load-list mt-2 mb-5 d-none d-lg-block">
              <div className="row">
                {
                  tags.slice(0,8).map((tag,index)=><Tag key={index}/>)
                }
              </div>
           </div> {/*tag-lg*/}

           <div className="col-12 col-md-12 col-lg-12 col-sm-12 load-list mt-2 mb-5 d-md-block d-none d-lg-none">
              <div className="row">
                {
                  tags.slice(0,6).map((tag,index)=><Tag key={index}/>)
                }
              </div>
           </div> {/*tag-md*/}

           <div className="col-12 col-md-12 col-lg-12 col-sm-12 load-list mt-2 mb-5 d-md-none">
              <div className="row">
                {
                  tags.slice(0,8).map((tag,index)=><Tag key={index}/>)
                }
              </div>
           </div> {/*tag-sm*/}
           
    </LoadContainer>
  )
}


const LoadContainer=styledComponents.div`

background: var(--lightBlack);

.tag-load-div{
  background: var(--lightDark2);
  padding:0.5rem 0;
  transform: scale(0.9);
  cursor:pointer;
  border-radius:0.3rem;
}


`;