import { red } from '@material-ui/core/colors';
import React from 'react'
import HoverVideoPlayer from 'react-hover-video-player';



export const Video = () => {
  
  const isDesktopOrLaptop = window.innerWidth >= 1024;

  return (
    <React.Fragment>

  <div className={`col-6 col-md-3 col-lg-3 col-sm-4 mx-auto plays ${isDesktopOrLaptop ? '' : 'my-2'}`}>
 
        <div className="card">  

  <HoverVideoPlayer className='player'
  pausedOverlay={
    <img
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      }}
    />
  }
  loadingOverlay={
    <div className="loading-overlay">
      <div className="loading-spinner" />
    </div>
  }
  />

       </div>  {/*card*/}
             </div>
             </React.Fragment>
  )
}
