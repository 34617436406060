import React from 'react'
import { Link, useNavigate } from 'react-router-dom';


export const Film = ({film}) => {

  // const navigate = useNavigate();

  const redirectToCeleb=(name)=>{
    //return navigate(`/view/movie/333/${name.replaceAll(' ','-')}`);
    return (name) && `/view/movie/${encodeURIComponent(name.toLowerCase().replaceAll(/[-\s]+/g, '-'))}`;
}


  return (
     <React.Fragment>

 <Link to={redirectToCeleb(film.name)} className="col-6 col-lg-2 col-md-3 col-sm-4 mx-auto item"> 

           {/* <div className="col-6 col-lg-2 col-md-3 col-sm-4 mx-auto item" onClick={()=>redirectToCeleb(film.name)}> */}
          
           <div className="card">

           <div className="img-container">
           <img src={film.imgUrl} alt={film.name} title={film.name} className="card-img-top" style={{width:'100%',height:'17rem'}}/> 
           </div>

           </div> {/*card*/}

           <div className="card-footer">
                {(film.name) && <h6 className='cast-name text-center px-2'>{film.name}</h6> }
            </div>

           {/* </div> */}

           </Link>
     </React.Fragment>
  )
}