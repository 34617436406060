import React from 'react'
import styledComponents from 'styled-components'
import { Video } from './Video';

export const VideoLoadingList = ()  => {

    const items=[1,2,3,4,5,6,7,8];

  return (
    <VideoListContainer>
        <div className="col-12 col-lg-12 col-md-12 col-sm-12 mx-auto load-video-div px-1">
                    <div className='row'>
                   { items.map((item,index) => <Video key={index}/>) }
            </div>
       </div>
    </VideoListContainer>
  )
}

const VideoListContainer=styledComponents.div`

background: var(--lightBlack);

.plays{
  
  background: transparent;
  transform: scale(1);

  @media (min-width: 1024px) {
   /* For desktop or laptop devices */
   background: transparent;
   transform: scale(0.9);
 }

}

.card{
  border:none !important;
}   

.plays .player{
    //background: rgb(73, 73, 73);
   background: var(--lightDark3);
   }

`;
