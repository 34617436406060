import React from 'react'
import styledComponents from 'styled-components'
import { Video } from './Video';

export const VideoResults =({videos}) => {
  return (
     <VideoResultContainer>
             
             <div className='container-fluid'>
             <div className='row'>

                   {/* <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto celeb-header text-center mb-3'>
                               <h4 className='c-header'>TOP 100 VIDEOS <span className='high'>OF 2022</span></h4>
                   </div> */}

                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto plays-list plays-list-lg px-3 d-none d-lg-block'>
                   <div className='row'>
                   { (videos) && videos.map((video)=><Video key={video.videoId} item={video}/>)}
                    </div>
                   </div>

                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto plays-list plays-list-sm d-lg-none'>
                   <div className='row'>
                   { (videos) && videos.map((video)=><Video key={video.videoId} item={video}/>)}
                    </div>
                   </div>

                   </div>
                   </div>

     </VideoResultContainer>
  )
}

const VideoResultContainer=styledComponents.div`

background: var(--lightBlack);

// .c-header{
//     color: var(--mainWhite);
//     font-weight:bold;
//     font-family: 'Karla', sans-serif;
//     font-size:2.2rem;

//     .high{
//       color: var(--lightOrange);
//     }
// }

.plays-list-sm{
  padding:0 !important;
  margin:0 !important;
}


.plays-list .plays{
  position:relative;
  border: 3px solid transparent;
  padding:0 !important;
  margin:0 !important;
  transform: scale(1);
  transition:0.8s all;
  text-decoration:none;

  &:hover{
    transition:0.8s all;
    cursor:pointer;
  //  border: 3px solid var(--lightOrange);
    .player{
       border: 3px solid var(--lightOrange);
     }
    .title{
      color: var(--lightOrange);
    }
    .title-sm{
      color: var(--lightOrange);
    }
    .video-views{
      // transition:0.8s all;
      z-index:-1111;
    }
    .time-quality{
      transition:0.8s all;
      z-index:-1111;
    }
    .player-watermark{
      // transition:0.8s all;
      z-index:1111;
      opacity: 1;
    }
    .player-watermark-sm{
      // transition:0.8s all;
      z-index:1111;
      opacity: 1;
    }
  }
}

@media (min-width: 768px) {
  .plays-list .plays {
    transform: scale(0.98);
  }
}

.plays-list .player{
  border: 3px solid transparent;
}

.card{
  background: transparent;
}

.card-footer{
  padding:0 !important;
  margin:0 !important;
}

.plays .title{

  // position: absolute;
  // //top:90%;
  // z-index:1111;  
    font-size:1rem;
    font-family: 'Open Sans', sans-serif; 
    color: var(--mainWhite);
    transition:0.8s all;
}

.plays .title-sm{

  // position: absolute;
  // //top:90%;
  // z-index:1111;  
    font-size:0.8rem;
    font-family: 'Open Sans', sans-serif; 
    color: var(--mainWhite);
    transition:0.8s all;
}

  

.time-quality{
  position: absolute;
  // top:-0.1%;
  z-index:1111;
  bottom:4.5%;

  padding-left: 0.4rem;

  .plays-time{

    .duration{
      background: var(--lightGrey);
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      font-size:0.7rem;
      color: var(--mainDark2);
      font-family: 'Open Sans', sans-serif; 
      font-weight:bold;
    }

  }

  .plays-time-sm{

    .duration{
      background: var(--lightGrey);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      font-size:0.6rem;
      color: var(--mainDark2);
      font-family: 'Open Sans', sans-serif; 
      font-weight:bold;
    }
  }

    .plays-quality{

      padding-left: 0.1rem;

      .resolution{
        font-family: 'Open Sans', sans-serif; 
        background: var(--lightGrey);
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;
        font-size:0.7rem;
        color: var(--brightRed);
        font-weight:bold;
      }
    }

    .plays-quality-sm{

      padding-left: 0.1rem;

      .resolution{
        font-family: 'Open Sans', sans-serif; 
        background: var(--lightGrey);
        padding: 0.1rem 0.2rem 0.1rem 0.2rem;
        font-size:0.6rem;
        color: var(--brightRed);
        font-weight:bold;
      }
    }

  }

  @media (min-width: 768px) {
    .time-quality {
      bottom:5%;
      padding-left: 0.5rem;
    }
  }

.film{
  .name{
    text-decoration:none;
  }
  .film-name {
    color: var(--lightGrey);
    font-size:0.8rem;
    font-family: 'Josefin Sans', sans-serif;
    transition:0.5s all;

    &:hover{
      transition:0.5s all;
      color: var(--mainWhite);
      text-decoration: underline; 
      color: var(--mainWhite);
      text-decoration-thickness: 1.7px;
    }
  }

  .film-name-sm{
    color: var(--lightGrey);
    font-size:0.7rem;
    font-family: 'Josefin Sans', sans-serif;
    transition:0.5s all;

    &:hover{
      transition:0.5s all;
      color: var(--mainWhite);
      text-decoration: underline; 
      color: var(--mainWhite);
      text-decoration-thickness: 1.5px;
    }
  }

}

.play-rate{
  color: var(--lightGreen);
  .fa-thumb{
   font-size:0.8rem;
  }
  .percentage{
    font-size:0.8rem;
  }
}

.video-views{
  position: absolute;
  // top:-0.1%;
  bottom:4.5%;
  z-index:1111;
  margin:0;
  padding:0;

  padding-right: 0.4rem;

  .view{

  .total-views{
      background: rgba(0,0,0,0.7);
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      color: var(--mainWhite);
      font-size:0.7rem;
      font-family: 'Open Sans', sans-serif; 
  }
  .fa-views{
      font-size:0.8rem;
  }
}

.view-sm{

  .total-views{
      background: rgba(0,0,0,0.7);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      color: var(--mainWhite);
      font-size:0.6rem;
      font-family: 'Open Sans', sans-serif; 
  }
  .fa-views{
      font-size:0.7rem;
  }
}

} 

@media (min-width: 768px) {
  .video-views {
    bottom:5%;
    padding-right: 0.5rem;
  }
}

.player-watermark{
  position: absolute;
  top:1%;
  right:1%;
  z-index:-1111;
  opacity: 0;
  margin:0;
  padding:0;

  .watermark{

    .p-wm{
      background: rgba(0,0,0,0.9);
      padding: 0.1rem 0.4rem 0.1rem 0.4rem;
      color: var(--mainWhite);
      font-size:0.8rem;
      font-family: 'Open Sans', sans-serif; 
  }

  }

}

.player-watermark-sm{
  position: absolute;
  top:-0.1%;
  z-index:-1111;
  opacity: 0;
  margin:0;
  padding:0;

  .watermark{

    .p-wm{
      background: rgba(0,0,0,0.9);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      color: var(--mainWhite);
      font-size:0.6rem;
      font-family: 'Open Sans', sans-serif; 
  }

  }

}
  
`;