import React,{useEffect,useState} from 'react';
import axios from 'axios';
import {isMobile,isBrowser} from 'react-device-detect';

export const VRLiveCam = ({item}) => {

      const openLiveSex = async (name, id) => {

        const campaign_name = 'xcelebs.org';
        const url = `https://vrporn.com/camgirls/${name}/?a=${id}&c=${campaign_name}`;
      
        try {

          const response = await axios.get('https://ipapi.co/json/');
          const data = response.data;
          const country = data.country_name;
      
          await trackAffiliateOffer('VRL150', 'VR-LIVE-SEX-RevShare', 'RevShare', 'VRPorn', url, country);
      
          return (url) && window.open(url);

        } catch (error) {

          console.error('Error fetching country:', error);
      
          await trackAffiliateOffer('VRL150', 'VR-LIVE-SEX-RevShare', 'RevShare', 'VRPorn', url, '');
      
          return (url) && window.open(url);
        }
      };
      

      const trackAffiliateOffer= async (id,name,type,provider,link,country)=>{

        const res = axios.get(`https://hutils.loxal.net/whois`);
        const ip = (await res).data.ip;
      
        let device="undetected";
      
        if (isMobile)
         device="mobile";
      
        if (isBrowser)
         device="desktop";
        
      
        const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
          affiliateLink:link,deviceType:device,ipAddress:ip,country:country};
      
          const save_url=process.env.REACT_APP_AFFILIATE;
      
          axios.post(`${save_url}`,detail,{
              headers:{
                  'Content-Type':'application/json',
               //   'Authorization':'bearer '.concat(token)
              }
          });
      
      }

      const [cam,setCam]=useState({id:'',display_name:'',image_url:'',temp_img_url:'',modelSex:'',age:0,affiliate_id:1189507});

      useEffect(()=>{
        setVRCams(item);
      },[item]);

      const setVRCams=(item)=>{
          setCam({id:item.id,display_name:item.modelNickname,image_url:item.thumbnailsUrl.preview3D,temp_img_url:item.modelLivePhotoUrl,modelSex:item.modelSex,age:item.modelAge,affiliate_id:1189507});
      }


  return (
    <React.Fragment>
    <div className="col-6 col-lg-2 col-sm-2 col-md-3 mx-auto live" onClick={()=>openLiveSex(cam.display_name,cam.affiliate_id)}>

    { (cam.display_name) && <div className="card">

    <div className="img-container hoverable-image">
   { (cam.image_url) ? <img src={cam.image_url} alt={`${cam.display_name} - VR Live Sex Cams | VR Live Sex Cams`} className="card-img-top" style={{width:'100%',height:'11rem'}}/> :
    <img src={cam.temp_img_url} alt={`${cam.display_name} - VR Live Sex Cams | VR Live Sex Cams`} className="card-img-top" style={{width:'100%',height:'11rem'}}/> }
      </div>

      <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 status text-start px-1">
       { (cam.display_name) && <span className='now'>VR Live</span> }
       </div>

       <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 name-flag d-flex px-1">

       <div className="col-8 mx-auto col-md-8 col-lg-8 col-sm-8 name-div text-start">
         { (cam.display_name && cam.display_name.length<=14) && <label className='name'>{cam.display_name.substring(0,14)}</label> }
         { (cam.display_name && cam.display_name.length>14) && <label className='name'>{`${cam.display_name.substring(0,14)}...`}</label> }
       </div>

       <div className="col-4 mx-auto col-md-4 col-lg-4 col-sm-4 flag-div text-end">
       {/* <img src={item.country_flag} alt={item.name} className="card-img-top" style={{width:'25%',height:'1rem'}}/>  */}
        { (cam.age) && <label className='age'>{cam.age}</label> }
       </div>

       </div>
          
      </div>}
    </div>
    </React.Fragment>
  )
}
