import React, { useState } from 'react'
import styledComponents from 'styled-components'
import { Celebs } from '../Trending/Celebs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import { ImgLoading } from '../loading/ImgLoading';


export const CelebsList = () => {


    // const popunder_desktop_link='https://syndication.realsrv.com/splash.php?cat=&idzone=4851132&type=8&p=&sub=%SUB1%&tags=%KW%';

    //  const popunder_mobile_link='https://syndication.realsrv.com/splash.php?cat=&idzone=4851130&type=8&p=&sub=%SUB1%&tags=%KW%';

    const popunder_desktop_link = 'https://blackandwhite-temporary.com/bE3.VZ0dPD3npEvOb_mxVoJnZ/DY0/1JMEzcI/y/NCjsMZ1FLITWUczqMwjhI/2wMrz/YW';

    const popunder_mobile_link = 'https://blackandwhite-temporary.com/bf3_V_0/P.3/pmvAbOmBVaJvZBDA0/1yM-zVIJyoNLjeQSzMLSTOUczNMEjGIO2FNhDEQc';
    
    const [celebs,setCelebs] = useState([]);
    const [loading,setLoading]=useState(true);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const navigate = useNavigate();

  //  const celeb={celeb_id:'w1232',name:'Alexendra Deaddario',img_url:'https://cdn2.aznude.com/biopic/89061_biopic.jpg',views:15.2};


  useEffect(() => {

    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };

  }, []);

  useEffect(()=>{

    // const token = from local storage

     const recommend_url=process.env.REACT_APP_CELEB;

     setLoading(true);

     if(!isOnline)
     return;

     axios.get(`${recommend_url}/popular`,{
         headers:{
           //  'Authorization':'bearer '.concat(token)
           }
     }).then(res=>{
         setCelebs(res.data);
         setLoading(false);
     }).catch(error=>{
         console.log(error);
         setLoading(false);
     });
     
 },[isOnline]);

 useEffect(() => {

  const fetchData = async () => {
      try {

            // const token = from local storage

          const recommend_url = process.env.REACT_APP_CELEB;

          setLoading(true);

          if (!isOnline)
              return;

          const response = await axios.get(`${recommend_url}/popular`, {
              headers: {
                  //  'Authorization':'bearer '.concat(token)
              }
          });

          setCelebs(response.data);
          setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
  };

  fetchData();
}, [isOnline]);



 const detectDevice=()=>{

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile;
  
  }


    const redirectTo=()=>{
       
        if(detectDevice())
        window.open(popunder_mobile_link);
        else
        window.open(popunder_desktop_link);
        
        return navigate('/browse/xcelebs/popular');
    }

  return (
       <CelebsContainer>
           <div className="container-fluid">
            <div className="row">


            { (!loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 item-list d-md-none d-lg-block">
            <div className="row">
            { (celebs) && celebs.map((celeb)=><Celebs key={celeb.celebId} cast={celeb}/>)}
             </div>
            </div> }

            { (!loading) && <div className="col-12 mx-auto col-lg-12 col-md-12 col-sm-12 item-list d-none d-md-block d-lg-none">
            <div className="row">
            { (celebs) && celebs.slice(0,4).map((celeb)=><Celebs key={celeb.celebId} cast={celeb}/>)}
             </div>
            </div> }

            {
                (loading) && <ImgLoading/>
            }
        

           { (!loading && celebs && celebs.length>=1) && <div className="view-all text-capitalize text-center">
                <span className='view-more px-3' onClick={()=>redirectTo()}> <span>view all</span> <FontAwesomeIcon className="fa-angle-right icon" icon={faAngleDoubleRight}/></span>
            </div> }

            </div>
            </div>
       </CelebsContainer>
  )
}

const CelebsContainer=styledComponents.div`

background: var(--lightBlack);

.item-list .item{
    position:relative;
    padding:0 !important;
    margin:0 !important;
    transform: scale(0.8);
    cursor:pointer;
    transition:0.5s all;
    text-decoration: none;

    &:hover{
        .cast-name{
        color: var(--lightOrange);
        transition:0.5s all;
        }
        .img-container{
            border: 3px solid var(--lightOrange);
            transition:0.5s all;
        }
    }
}

.img-container{
    cursor:pointer;
    border: 3px solid transparent;
    transition:0.5s all;

    &:hover{
        border: 3px solid var(--lightOrange);
        transition:0.5s all;
    }
}

.card{
    background: transparent;
  }

.views-img{
    position: absolute;
    // top:1%;
    bottom:3%;

    .view{

      padding-right: 0.4rem;

    .total-views-img{
        background: rgba(0,0,0,0.7);
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;
        color: var(--mainWhite);
        font-size:1rem;
        font-family: 'Open Sans', sans-serif; 
    }
    .fa-views{
        font-size:1.1rem;
    }
  }

  .view-sm{

    padding-right: 0.4rem;

    .total-views-img{
        background: rgba(0,0,0,0.7);
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;
        color: var(--mainWhite);
        font-size:0.9rem;
        font-family: 'Open Sans', sans-serif; 
    }
    .fa-views{
        font-size:1rem;
    }
  }
}  

.cast-name{
    // font-family: 'Radio Canada', sans-serif;
    font-family: 'Montserrat', sans-serif;
    color: var(--mainWhite);
    font-weight:bold;
    font-size:1.1rem;
    letter-spacing:0.02em;
    margin: 0.8rem 0;
}

.view-more{
    color: var(--lightBlue2);
    cursor:pointer;
    transition:0.5s all;
    font-family: 'Open Sans', sans-serif; 
    font-size:1.1rem;
    font-weight:bold;
    transition:0.5s all;

    &:hover{
        color: var(--lightBlue);
        transition:0.5s all;
    }
}

`;