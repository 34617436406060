import React,{useEffect,useRef} from 'react';
import { Banner } from "exoclick-react";
import useGeoLocation from "react-ipgeolocation";
import {isMobile} from 'react-device-detect';
import {isBrowser} from 'react-device-detect';
import axios from 'axios';

export const Stream_Offer = () => {

  const location = useGeoLocation().country;

  const trackAffiliateOffer= async (id,name,type,provider,link)=>{

    const res = axios.get(`https://hutils.loxal.net/whois`);
    const ip = (await res).data.ip;

    let device="undetected";

    if (isMobile)
     device="mobile";

    if (isBrowser)
     device="desktop";
    

    const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
      affiliateLink:link,deviceType:device,ipAddress:ip,country:location};

      const save_url=process.env.REACT_APP_AFFILIATE;

      axios.post(`${save_url}`,detail,{
          headers:{
              'Content-Type':'application/json',
           //   'Authorization':'bearer '.concat(token)
          }
      });

  }

  return (

    <React.Fragment>

    <div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto stream-offer text-center d-none d-md-block'>

      <a href="https://t.acam-2.com/226438/1639/28614" target="_blank"><img src="https://www.imglnkd.com/2676/001753A_LIJA_18_ALL_EN_125_L.gif" width="728" height="90" border="0" className='my-2' onClick={()=>trackAffiliateOffer('CHR106','CR-CAM-STREAM','PPL','crakrevenue','https://t.acam-2.com/226438/1639/28614')}/></a>
         
    </div>

<div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto stream-offer text-center d-md-none'>

{/* <a href="https://t.acam-2.com/226438/1639/28614" target="_blank"><img src="https://www.imglnkd.com/2676/001760B_LIJA_18_ALL_EN_71_L.jpg" width="300" height="250" border="0" className='my-2' onClick={()=>trackAffiliateOffer('CHR107','CR-CAM-STREAM','PPL','crakrevenue','https://t.acam-2.com/226438/1639/28614')}/></a> */}

      <Banner zoneId={5449432}/> 

    </div>

    </React.Fragment>
  );
}
