import React from 'react';
import { Banner } from "exoclick-react";
import useGeoLocation from "react-ipgeolocation";
import {isMobile} from 'react-device-detect';
import {isBrowser} from 'react-device-detect';
import axios from 'axios';

export const StreamTopAd  = () =>{

 //  const banner_desktop = useRef();

   // const banner_mobile = useRef();

//   const atOptions_01 = {
//     key: '9c7065d04ea0b530f76fde7705666943',
//     format: 'iframe',
//     height: 90,
//     width: 728,
//     params: {},
// }

// const atOptions_02 = {
//   key: '18ee5067016c1f95f59d03725628aa7e',
//   format: 'iframe',
//   height: 50,
//   width: 320,
//   params: {},
// }

//  useEffect(()=>{

//   if (!banner_desktop.current.firstChild) {
//     const conf = document.createElement('script')
//     const script = document.createElement('script')
//     script.type = 'text/javascript'
//     script.src = `//www.highperformancedisplayformat.com/9c7065d04ea0b530f76fde7705666943/invoke.js`
//     conf.innerHTML = `atOptions = ${JSON.stringify(atOptions_01)}`

//     if (banner_desktop.current) {
//         banner_desktop.current.append(conf)
//         banner_desktop.current.append(script)
//     }
// }
// },[]);

// useEffect(()=>{

//   if (!banner_mobile.current.firstChild) {
//     const conf = document.createElement('script')
//     const script = document.createElement('script')
//     script.type = 'text/javascript'
//     script.src = `//www.highperformancedisplayformat.com/18ee5067016c1f95f59d03725628aa7e/invoke.js`
//     conf.innerHTML = `atOptions = ${JSON.stringify(atOptions_02)}`

//     if (banner_mobile.current) {
//         banner_mobile.current.append(conf)
//         banner_mobile.current.append(script)
//     }
// }
// },[]);

const location = useGeoLocation().country;

const trackAffiliateOffer= async (id,name,type,provider,link)=>{

  const res = axios.get(`https://hutils.loxal.net/whois`);
  const ip = (await res).data.ip;

  let device="undetected";

  if (isMobile)
   device="mobile";

  if (isBrowser)
   device="desktop";
  

  const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
    affiliateLink:link,deviceType:device,ipAddress:ip,country:location};

    const save_url=process.env.REACT_APP_AFFILIATE;

    axios.post(`${save_url}`,detail,{
        headers:{
            'Content-Type':'application/json',
         //   'Authorization':'bearer '.concat(token)
        }
    });

}


const random =  Math.round(Math.random());

const random_ads=[{id:'ad101',offer:'https://t.acam-2.com/226438/3778/8996',src:'https://i.ibb.co/c1C93DF/006362-A-GDAT-18-ALL-EN-125-L.jpg',provider:'crakrevenue',name:'CR-CAM-STREAM-TOP',type:'PPL'},
         {id:'ad102',offer:'https://landing.spicevids.com/affiliates/?ats=eyJhIjo3ODIwMzIsImMiOjYxMDE0ODAzLCJuIjoxMjAsInMiOjY4MCwiZSI6MTA0MzIsInAiOjExfQ==',src:'https://i.ibb.co/gyt10tH/1060395-banner.png',provider:'adultforce',name:'AF-SPICEVIDS-STREAM-TOP',type:'PPL'}];

  return (
    <React.Fragment>

         <div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto stream-offer text-center d-none d-md-block'>

    
         <a href={random_ads[random].offer} target="_blank"><img src={random_ads[random].src} width="728" height="90" border="0" onClick={()=>trackAffiliateOffer('CHAR108',random_ads[random].name,random_ads[random].type,random_ads[random].provider,random_ads[random].offer)}/></a>


         {/* <Banner zoneId={4827356}/> */}

        </div>

<div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto stream-offer text-center d-md-none'>

    
{/* <div ref={banner_mobile}></div> */}

{/* <iframe src="//a.realsrv.com/iframe.php?idzone=4827358&size=300x100" width="300" height="100" scrolling="no" marginwidth="0" marginheight="0" frameborder="0" className='mt-2'></iframe> */}

<Banner zoneId={4870560}/>

    </div>

    </React.Fragment>
  )
}
