import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

export const PwdField = ({pwd,error,hasError,pwdChangeHandler}) => {
    return (
        <PwdContainer>
            <div className="container">
                <div className="row">
                <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto pwd text-center mt-3">

                <div className="form-group">
                <FontAwesomeIcon className="fa-lock icon" icon={faLock}/>
                <input type="password" className="form-control" placeholder="New Password" name="password"
                value={pwd.password} onChange={(e)=>pwdChangeHandler(e)}/>
               {  (hasError) && <div className="error text-danger my-1">{error.password}</div> }
                </div>

                </div>

                <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto pwd text-center mt-3 mb-4">

                <div className="form-group">
                <FontAwesomeIcon className="fa-lock icon" icon={faLock}/>
 <input type="password" className="form-control" placeholder="Confirmed New Password" name="c_password"
 value={pwd.c_password} onChange={(e)=>pwdChangeHandler(e)}/>
           { (hasError) && <div className="error text-danger my-1">{error.c_password}</div> }

                </div>

                </div>

                </div>
            </div>
        </PwdContainer>
    );
}

const PwdContainer=styled.div`


.pwd .form-control{
    background: transparent;
    box-shadow: none;
    border:none;
    border: 1px solid var(--mainBlue);
    padding-left:2rem;

    &:focus{
        border: 1px solid var(--lightGrey);
    }

    ::placeholder {
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif;
        opacity:0.6;
        font-size:0.9rem;
    }

    ::-ms-input-placeholder { 
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif;
        opacity:0.6;
        font-size:0.9rem;
      }
}

.pwd input{
    color: var(--lightGrey);
    font-family: 'Open Sans', sans-serif;
    font-size:0.9rem;
}

.pwd .form-group{
    position: relative;
}

.pwd .icon{
    position:absolute;
    left:0.6rem;
    top:0.7rem;
    color: var(--lightBlue);
}
.error{
    font-family: 'Open Sans', sans-serif;
    font-size:0.8rem;
}
      


`;