import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styledComponents from 'styled-components'

export const Welcome = () => {

  const navigate = useNavigate();

  const openSignup=()=>{
    return navigate(`/signup`);
  }

  return (
    <WelcomeContainer>
          <div className='container'>
              <div className='row'>
              <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 welcome text-center">
                 
                        <h1 className='w-title text-uppercase d-none d-md-block'>WELCOME TO <label className='site'>Xcelebs</label></h1>
               
                        <h1 className='w-title-sm text-uppercase d-md-none'>WELCOME TO <label className='site'>Xcelebs</label></h1>

                     <p className='w-desc d-none d-md-block'>
                     We have a large, free, exclusive, constantly growing collection of nude celebs from various movies and shows.
                      To upload videos or images, please <Link className='sign' to={`/signup`}>sign up</Link>.
                     </p>

                     <p className='w-desc-sm d-md-none'>
                     We have a large, free, exclusive, constantly growing collection of nude celebs from various movies and shows.
                      To upload videos or images, please <Link className='sign' to={`/signup`}>sign up</Link>.
                     </p>

               </div>
                  </div>
              </div>
    </WelcomeContainer>
  )
}

const WelcomeContainer=styledComponents.div`

background: var(--lightBlack);

  .w-title{
    color: var(--mainWhite);
    font-family: 'Open Sans', sans-serif; 
    font-size:2rem;
    font-weight:bold;
  }

  .w-title-sm{
    color: var(--mainWhite);
    font-family: 'Open Sans', sans-serif; 
    font-size:1.3rem;
    font-weight:bold;
  }

  .site{
      color: var(--lightOrange);
  }

  .w-desc{
    color: var(--lightGrey2);
    font-family: 'Open Sans', sans-serif; 
    font-size:1rem;
  }

  .w-desc-sm{
    color: var(--lightGrey2);
    font-family: 'Open Sans', sans-serif; 
    font-size:0.8rem;
  }

  .sign{
      color: var(--lightOrange);
      opacity:0.8;
      cursor:pointer;
      transition: 0.8s all;
      text-decoration:none;

      &:hover{
        opacity:1;
        transition: 0.8s all;
      }
  }
`;