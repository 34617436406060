import React,{useEffect,useState} from 'react';
import styledComponents from 'styled-components';
import { ConnectionStatus } from '../internet-connection/ConnectionStatus';
import { NotFound } from './NotFound';
import { Helmet } from 'react-helmet';

export const Default = (props) => {

    // const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Page is not found - Xcelebs`);
  const [pageDescription, setPageDescription] = useState(`Sorry, but the page you're looking for couldn't be found. It seems to have gone missing in the digital wilderness. Please double-check the URL or return to our homepage to continue your journey.`);

    useEffect(()=>{
        const title=`Page is not found - Xcelebs`;
        const desc=`Sorry, but the page you're looking for couldn't be found. It seems to have gone missing in the digital wilderness. Please double-check the URL or return to our homepage to continue your journey.`;
        
        setPageTitle(title);
        setPageDescription(desc);
        document.title = title;
        document.body.style.zoom="100%";
       
      },[]);

    return (
       <DefaultContainer>
           <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        {/* <link rel="canonical" href={canonicalUrl} /> */}
          </Helmet>
           <div className="container-fluid">
               <div className="row">
                   <div className="col-12 col-md-12 col-lg-12 col-sm-12 default">
                         <NotFound/>
                   </div>
               </div>
           </div>

           <ConnectionStatus/>

       </DefaultContainer>
    );
}

const DefaultContainer=styledComponents.div`

.default{
    background: var(--lightDark);
    height: 100vh;
}

`;