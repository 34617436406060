import React from 'react';
import styledComponents from 'styled-components';
import { DobFieldForLg } from './DobFieldForLg';
import { DobFieldForSm } from './DobFieldForSm';

export const DobField = ({dob,setDob,error,hasError}) => {

  const months = [
    {
      value: 'Jan',
      label: 'January',
    },
    {
      value: 'Feb',
      label: 'February',
    },
    {
      value: 'Mar',
      label: 'March',
    },
    {
      value: 'Apr',
      label: 'April',
    },
    {
      value: 'May',
      label: 'May',
    },
    {
      value: 'Jun',
      label: 'June',
    },
    {
      value: 'Jul',
      label: 'July',
    },
    {
      value: 'Aug',
      label: 'August',
    },
    {
      value: 'Sep',
      label: 'September',
    },
    {
      value: 'Oct',
      label: 'October',
    },
    {
      value: 'Nov',
      label: 'November',
    },
    {
      value: 'Dec',
      label: 'December',
    }
  ];

  const [month, setMonth] = React.useState('');

  const handleChange = (event) => {
    setMonth(event.target.value);
  };


    return (
        <DobContainer>
            <div className="container">
                <div className="row">

                <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto dob text-start px-2">
                  
                  <div className='dob-for-large-screen d-none d-lg-block'>
                  <DobFieldForLg months={months} month={month} handleChange={handleChange}/>
                  </div>
                     
                  <div className='dob-for-small-screen d-lg-none'>
                  <DobFieldForSm months={months} month={month} handleChange={handleChange}/>
                  </div> 

                </div> {/*dob*/}

                </div>
            </div>
        </DobContainer>
    );
}

const DobContainer=styledComponents.div`


.form-label{
    font-family: 'Open Sans', sans-serif;
    color: var(--lightBlue);
    font-size:0.9rem;
}

@media (max-width: 1024px) {
  .form-label{
    font-size:0.8rem;
  }
}

.dob{
    position:relative;
    padding:0;
    margin:0;
}


.date{
    width: 50%;
    height: 2.5rem;
    background: transparent;
    color: var(--lightGrey);
    border:none;
    border: 1px solid var(--mainBlue);
    font-size:0.9rem;
    padding-left:0.5rem;
    border-radius:0.3rem;

}


.error{
    font-family: 'Open Sans', sans-serif;
    font-size:0.8rem;
}

.input-root {
    border-color: var(--lightGrey);
    height: 40px;
    width: 150px;
  }

  .input-root-2 {
    border-color: var(--lightGrey);
    height: 40px;
    width: 150px;
  }

  .input-root-3 {
    border-color: var(--lightGrey);
    height: 40px;
    width: 150px;
  }

  @media (max-width: 1024px) {

    .input-root {
      width: 130px;
    }
    .input-root-2 {
      width: 130px;
    }
    .input-root-3 {
      width: 130px;
    }
  }

  @media (max-width: 1020px) {

    .input-root {
      width: 140px;
    }

    .input-root-2 {
      width: 70px;
    }

    .input-root-3 {
      width: 80px;
    }
  }

  .input-text{
    text-align:start;
  }

  @media (max-width: 700px) {

    .input-text{
      font-size:0.9rem;
    }

    .input-root {
      width: 150px;
    }

    .input-root-2 {
      width: 70px;
    }

    .input-root-3 {
      width: 80px;
    }
  }

  @media (max-width: 500px) {

    .input-text{
      font-size:0.9rem;
    }

    .input-root {
      width: 140px;
    }
    .input-root-2 {
      width: 60px;
    }

    .input-root-3 {
      width: 70px;
    }
  }

  @media (max-width: 425px) {

    .input-text{
      font-size:0.9rem;
    }

    .input-root {
      width: 136px;
    }
    .input-root-2 {
      width: 60px;
    }

    .input-root-3 {
      width: 70px;
    }
  }

  @media (max-width: 400px) {

    .input-text{
      font-size:0.9rem;
    }

    .input-root {
      width: 128px;
    }
    .input-root-2 {
      width: 60px;
    }

    .input-root-3 {
      width: 65px;
    }
  }

  @media (max-width: 360px) {

    .input-text{
      font-size:0.9rem;
    }

    .input-root {
      width: 122px;
    }

    .input-root-2 {
      width: 60px;
    }

    .input-root-3 {
      width: 65px;
    }
  }
  
  .input-notched-outline {
    border-color: var(--mainBlue) !important;
  }
  
  .input-label {
    color: var(--lightGrey3);
    font-size: 0.9rem;
    margin-top:-0.3rem;
  }

  .input-label.MuiInputLabel-shrink {
    margin-top: 0;
  }

  .input-label-focused{
    font-size: 1rem;
    margin-top:0;
    color: var(--mainWhite) !important;
  }

 
  .input-root-focused .input-notched-outline {
    border-color: var(--mainWhite) !important;
  }

  .input-text:not(:placeholder-shown) {
    color: var(--lightGrey);
  }

  .input-text:not(:placeholder-shown) ~ .input-label {
    margin-top: 0;
  }

  .dob-filed {
    padding:0 !important;
    margin:0 !important;
  }


`;