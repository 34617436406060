import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const ImageSm = ({item,index,viewImageHandler}) => {

  return (
    <React.Fragment>


             <div className="col-6 col-md-3 col-lg-2 col-sm-6 mx-auto image-sm">
 
 <div className='pics-sm px-3'>
    
      { (item.lowResUrl) && <LazyLoadImage className='img-div d-none d-xl-block' srcSet={item.lowResUrl}  alt={(item.celebName) && `${item.celebName} Nude Picture`.concat((item.filmName) && ` in ${item.filmName}`)}
       onClick={()=>viewImageHandler(index,item.imgId)} style={{width: '100%'}} title={(item.celebName) && `${item.celebName} Nude Picture`.concat((item.filmName) && ` in ${item.filmName}`)}/> }


{ (item.lowResUrl) && <LazyLoadImage className='img-sm-div d-xl-none' srcSet={item.lowResUrl}  alt={(item.celebName) && `${item.celebName} Nude Picture`.concat((item.filmName) && ` in ${item.filmName}`)}
       onClick={()=>viewImageHandler(index,item.imgId)} style={{width: '100%'}} title={(item.celebName) && `${item.celebName} Nude Picture`.concat((item.filmName) && ` in ${item.filmName}`)}/> }

 </div>
 
      </div>

     
       </React.Fragment>
  )
}

