import React from 'react'
import styledComponents from 'styled-components'
import { Letter } from './Letter';

export const Alph = ({changeLetter,selectedType}) => {

    const letterrs= ['#','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];

  return (
    <AlphabatContainer>
    <div className='container'>
            <div className='row'>

            <div class="letter-list text-center">

            { letterrs.map((a,index)=> <Letter key={index} changeLetter={changeLetter} selectedType={selectedType} letter={a}/>)}

            </div>

            </div>
        </div>   
    </AlphabatContainer> 
  )
}

const AlphabatContainer=styledComponents.div`

.letter-list{
 //   background: var(--darkBlue);
border-radius:0.3rem;
}


.letter{
    width: auto;
    padding: 0.1rem 0.5rem;
 //   background: linear-gradient(0deg,#343434,#4b4b4b);
    color: var(--mainWhite);
    font-family: 'Karla', sans-serif;
    margin: 0.3rem 0.3rem;
    font-size:1.2rem;
    text-align: center;
    display:inline-block;
    border-radius:0.3rem;
   // transition: 0.5s all;
    font-weight:bold;
    text-decoration: none;
    &:hover{
     
      cursor:pointer;
     // transition: 0.5s all;
      background:  var(--lightOrange);
   }
}

`;