import React from 'react';
import styledComponents from 'styled-components';

export const Exempt = () => {
  return (

    <Container>

    <div className="container">
    <div className="row">

    <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto exemption mt-4 mb-2'>
             <h3 className='header'>  18 U.S.C. Section 2257 EXEMPTION STATEMENT </h3>
    </div>

    <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto statement'>

    <p className='message'>
    The operators of this website are not the "producers" of any depictions of any actual or simulated sexually explicit conduct.
     To the extent that any such content appears on this site, the operators' involvement with respect thereto is limited to the transmission,
      storage, retrieval, hosting and/or formatting of depictions produced by third parties.
     Questions about 2257 issues for sites linked from this website should be directed to those producers.
     </p>

    </div>

      </div>
      </div>

      </Container>
  )
}


const Container = styledComponents.div`

background: var(--lightBlack);

.header{
  color: var(--mainWhite);
  font-family: 'Open Sans', sans-serif; 
  font-weight:bold;
  font-size:1.6rem;
}

.message{
  color: var(--mainWhite);
  font-family: 'Open Sans', sans-serif; 
  font-size:0.9rem;
}


`;