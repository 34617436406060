import React, { useEffect, useRef, useState } from 'react';
import { Banner } from "exoclick-react";
import useGeoLocation from "react-ipgeolocation";
import {isMobile} from 'react-device-detect';
import {isBrowser} from 'react-device-detect';
import axios from 'axios';


export const AdComponent = () => {

  const location = useGeoLocation().country;

  const [lospollos, setLosPollos] = useState({ dating: '', cams: '' });

//   const banner_01 = useRef();

//   const atOptions = {
//     key: 'c4f9eb18af34e498fe906df9cd709a45',
//     format: 'iframe',
//     height: 250,
//     width: 300,
//     params: {},
// }

// useEffect(()=>{

//   if (!banner_01.current.firstChild) {
//     const conf = document.createElement('script')
//     const script = document.createElement('script')
//     script.type = 'text/javascript'
//     script.src = `//www.highperformancedisplayformat.com/c4f9eb18af34e498fe906df9cd709a45/invoke.js`
//     conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`

//     if (banner_01.current) {
//         banner_01.current.append(conf)
//         banner_01.current.append(script)
//     }
// }
// },[]);

useEffect(()=>{

  const script = document.createElement('script');

  script.src = "https://poweredby.jads.co/js/jads.js";
  script.async = true;

  document.body.appendChild(script);

},[]);

useEffect(()=>{
    
  const key_value = '2e2a354a1f5743c5854a38a02a36f162';
  axios.get(`https://domainapi.lospollos.com/actualdomain?key=${key_value}`)
  .then(res=>{
    const responseData = res.data;

    const datingUrl = responseData.Dating[0] && `https://${responseData.Dating[0]}/?u=tnq8hwl&o=z0bkgql`;
    const camsUrl =  responseData.Cams[0] && `https://${responseData.Cams[0]}/?u=tnq8hwl&o=z0wwmc7`;
   
    setLosPollos({
      dating: datingUrl,
      cams: camsUrl,
    });
        
  }).catch(error=>{
     console.log(error);

     setLosPollos({
      dating: 'https://sweetondates.life/?u=tnq8hwl&o=z0bkgql',
      cams: 'https://myhot-cams.life/?u=tnq8hwl&o=z0wwmc7',
    });

  });

},[]);

useEffect(()=>{

  const script = document.createElement('script');

  script.src = "https://a.realsrv.com/nativeads-v2.js";

  document.body.appendChild(script);

},[]);


const trackAffiliateOffer= async (id,name,type,provider,link)=>{

  const res = axios.get(`https://hutils.loxal.net/whois`);
  const ip = (await res).data.ip;

  let device="undetected";

  if (isMobile)
   device="mobile";

  if (isBrowser)
   device="desktop";
  

  const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
    affiliateLink:link,deviceType:device,ipAddress:ip,country:location};

    const save_url=process.env.REACT_APP_AFFILIATE;

    axios.post(`${save_url}`,detail,{
        headers:{
            'Content-Type':'application/json',
         //   'Authorization':'bearer '.concat(token)
        }
    });

}

  
  return (
     
     <React.Fragment>

             <div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto ad mb-2 text-center d-none d-md-block'>

             {/* <ins id="1000106" data-width="728" data-height="90"></ins> */}

             <iframe src="https://creative.xlirdr.com/widgets/wrapper?path=%2Fsignup%2Fuser&userId=11d347350c44824625b893d1b5e6f2eeef888388b45ff2095b02b8dfc212bf84&bb=19b1632c.gif" width="728" height="90" marginwidth="0" marginheight="0" frameborder="0" scrolling="no" onClick={()=>trackAffiliateOffer('CHS132','SC-CAM-INDEX-2','PPL','stipchat','https://creative.xlirdr.com/')}></iframe>
                
           <div>
           <a href="https://t.acam-2.com/226438/2676/5220" target="_blank"><img src="https://www.imglnkd.com/2676/001753A_LIJA_18_ALL_EN_125_L.gif" width="728" height="90" border="0" className='mb-1' onClick={()=>trackAffiliateOffer('CHR131','CR-CAM-INDEX-1','PPL','crakrevenue','https://t.acam-2.com/226438/2676/5220')}/></a>        
           </div>
           
             {/* <Banner zoneId={4827346}/> */}

             <a href="https://t.affenhance.com/226438/8043/27491" target="_blank"><img src="https://i.ibb.co/cxB455W/006357-A-MYFC-18-ALL-EN-125-L.gif" width="728" height="90" border="0" onClick={()=>trackAffiliateOffer('CHR133','CR-CAM-INDEX-3','PPL','crakrevenue','https://t.affenhance.com/226438/8043/27491')}/></a>
            
             </div> {/*desktop*/}

             <div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto ad mb-2 text-center d-md-none'>

             <iframe src="https://creative.xlirdr.com/widgets/wrapper?path=%2Fsignup%2Fuser&userId=11d347350c44824625b893d1b5e6f2eeef888388b45ff2095b02b8dfc212bf84&bb=761faa8b.gif" width="300" height="250" marginwidth="0" marginheight="0" frameborder="0" scrolling="no" onClick={()=>trackAffiliateOffer('CHS135','SC-CAM-INDEX-2','PPL','stripchat','https://creative.xlirdr.com/')}></iframe>
                          
             { (lospollos.dating) && <a href={lospollos.dating} target="_blank"><img src="https://www.imglnkd.com/2086/32837_crak_summer_banner_300x250_02.jpg" width="300" height="250" border="0" className='mb-1' onClick={()=>trackAffiliateOffer('CHL136','LP-CAM-INDEX-3','PPL','lospollos',`${lospollos.dating}`)}/></a> }
            
             { (lospollos.cams) && <a href={lospollos.cams} target="_blank"><img src="https://i.ibb.co/Nr98Mwm/008706-C-ROYA-18-ALL-EN-71-L.gif" width="300" height="250" border="0" className='mb-1' onClick={()=>trackAffiliateOffer('CHL134','LP-CAM-INDEX-1','PPL','lospollos',`${lospollos.cams}`)}/></a> }

             {/* <a href="https://t.acam.link/226438/1639/0?bo=2779,2778,2777,2776,2775&file_id=215481&po=6533" target="_blank"><img src="https://i.ibb.co/HF0CRzS/002807-A-SODA-18-ALL-EN-71-E.jpg" width="300" height="250" border="0" className='mb-1'/></a> */}

             {/* <a href="https://t.acam.link/226438/5170/0?bo=2779,2778,2777,2776,2775&file_id=310251&po=6533" target="_blank"><img src="https://www.imglnkd.com/5170/20180801101542-002804A_SODA_18_ALL_EN_71_E.gif" width="300" height="250" border="0" className='mb-1'/></a> */}

             {/* <a href="https://t.acam-2.com/226438/2934/8990" target="_blank"><img src="https://www.imglnkd.com/2086/005250A_GCAM_18_ALL_EN_71_N.gif" width="300" height="250" border="0" className='mb-1' onClick={()=>trackAffiliateOffer('CHR137','CR-CAM-INDEX-4','PPL','crakrevenue','https://t.acam-2.com/226438/2934/8990')}/></a> */}


             {/* <a title="Live webcam sex! More than 20000 Hot Girls are waiting for you!" href="//apptjmp.com/?siteId=jasmin&cobrandId=&superCategoryName=[--SUPERCATEGORY--]&categoryName=girl&pageName=listpage&prm[psid]=BRKUK89&prm[pstool]=201_26&prm[psprogram]=pps&prm[campaign_id]=&subAffId={SUBAFFID}" align="center" target="_blank">
  <img class="img" alt="Live webcam sex! More than 20000 Hot Girls are waiting for you!" src="//pt-static1.ptlwmstc.com/npt/banner/s1_girl_4/300xx250.jpg?sid=330ce594&psid=BRKUK89&pstool=201_26&psprogram=pps&campaign_id=" border="0" className='mb-1'/>
</a> */}

           {/* <ins id="1000078" data-width="300" data-height="250" className='mb-1'></ins> */}
           
           <Banner zoneId={4870608}/>
                      

</div> {/*mobile*/}

<div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto native-ads px-4'>

<Banner zoneId={4870342}/> 

</div> {/*native ads*/}

</React.Fragment>

    
  );
}

