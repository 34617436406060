import React from 'react'
import styledComponents from 'styled-components'

export const Terms = ()  => {

  const redirectTo=(path)=>{
    return window.location.href=path;
  }
  return (
     <TermContainer>
         <div className='container'>
            <div className='row'>

            <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto main-header my-3'>
              <h3 className='m-header' onClick={()=>redirectTo('communityguidelines')}>Community Guidelines</h3>
            </div>

            <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto relationship'>
                   <h3 className='header'>1. Your relationship with Xcelebs</h3>

                   <p className='message'>
                   1.1 Your use of the Xcelebs website (the "Website") and any Xcelebs products, channels, software, data feeds and services, including the StreamSB embeddable video player provided to you on or from or through the Website by Xcelebs (collectively the "Service") 
                   is subject to the terms of a legal agreement between you and Xcelebs. "Xcelebs" means the website "www.xcelebs.org".
                   </p>

                   <p className='message'>
                   1.2 Your legal agreement with Xcelebs is made up of (A) the terms and conditions set out in this document, (B) Xceleb's Privacy Policy (<span className='link' onClick={()=>redirectTo('privacy')}>http://www.xcelebs.org/privacy</span>) and (C) Xceleb's Community Guidelines (<span className='link' onClick={()=>redirectTo('communityguidelines')}>http://www.xceleb.com/communityguidelines</span>) (collectively called the "Terms"). (D)
                    Our DMCA policy (<span className='link' onClick={()=>redirectTo('dmca')}>http://www.xcelebs.org/dmca</span>).
                   </p>
 
                   <p className='message'>
                   1.3 The Terms form a legally binding agreement between you and Xcelebs in relation to your use of the Service. It is important that you take the time to read them carefully.
                   </p>

                   <p className='message'>
                   1.4 The Terms apply to all users of the Service, including users who are also contributors of Content, on the Service. "Content" includes the text, software, scripts, graphics, photos, sounds, music, videos, audiovisual combinations,
                    interactive features and other materials you may view on, access through or contribute to the Service.
                    </p>

            </div>    

            <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto accepting'>
                   <h3 className='header'>2. Accepting the Terms</h3>

                   <p className='message'>
                   2.1 In order to use the Service, you must firstly agree to the Terms. You may not use the Service if you do not accept the Terms.
                       </p>

                       <p className='message'>
                       2.2 You can accept the Terms by simply using the Service. You understand and agree that Xcelebs will treat your use of the Service as acceptance of the Terms from that point onwards.
                           </p>

                           <p className='message'>
                           2.3 You may not use the Service and may not accept the Terms if (a) you are not of legal age to form a binding contract with Xcelebs, or (b) you are a person who is either barred or otherwise legally prohibited from receiving or using
                            the Service under the laws of the country in which you are resident or from which you access or use the Service.
                               </p>

                               <p className='message'>
                               2.4 You should print off or save a local copy of the Terms for your records.
                               </p>

             </div>   

             <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto change'>
                   <h3 className='header'>3. Changes to the Terms</h3>

                   <p className='message'>
                   3.1 Xcelebs reserves the right to make changes the Terms from time to time, for example to address changes to the law or regulatory changes or changes to functionality offered through the Service. Therefore you must look at the Terms regularly to check for such changes. The modified version of the Terms (the "Modified Terms") will be posted at <span className='link' onClick={()=>redirectTo('terms')}>http://www.xcelebs.org/terms</span> or made available within the Service (for any modified additional terms). If you do not agree to the Modified Terms you must stop using the Service. Your continued use of
                    the Service after the date the Modified Terms are posted will constitute your acceptance of the Modified Terms.
                    </p>

             </div>  

                  <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto account'>
                   <h3 className='header'>4. Xcelebs accounts</h3>

                   <p className='message'>
                   4.1 In order to access some features of the Website or other elements of the Service, you may have to create a Xcelebs account. When creating your account, you must provide accurate and complete information.
                    It is important that you must keep your Xcelebs account password secure and confidential.
                       </p>

                       <p className='message'>
                       4.2 You must notify Xcelebs immediately of any breach of security or unauthorised use of your Xcelebs account that you become aware of.
                           </p>

                           <p className='message'>
                           4.3 You agree that you will be solely responsible (to Xcelebs, and to others) for all activity that occurs under your Xcelebs account.
                               </p>

                   </div> 

                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto copyright'>
                   <h3 className='header'>5. Copyright policy</h3>

                   <p className='message'>
                   5.1 Xcelebs operates a clear copyright policy in relation to any Content that is alleged to infringe the copyright of a third party. Details of that policy can be found here: <span className='link' onClick={()=>redirectTo('dmca')}>http://www.xcelebs.org/dmca</span>
                       </p>

                       <p className='message'>
                       5.2 As part of Xceleb's copyright policy, Xcelebs will terminate user access to a user if a user has been determined to be a repeat infringer. 
                       A repeat infringer is a user who has been notified of infringing activity more than thrice.
</p>

<p className='message'>
5.3 The content on the Website, including without limitation, the text, software, scripts, graphics, files, images, photos, sounds, music, videos, interactive features and the like (collectively "Content") and the trademarks, service marks and logos contained therein ("Marks"), are owned by or licensed to Xcelebs, or used on the Website in accordance with fair use provisions or the U.S. Copyright Act and equivalent exemptions under foreign laws and international conventions. Xcelebs reserves all rights not expressly granted in and to the Website and the Content owned by or licensed to Xcelebs. You agree to not use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell or otherwise exploit for any other purposes whatsoever any Xcelebs, including, but not limited to, image, audio, and visual content, Marks, or other proprietary rights not owned by you, (i) without the express prior written consent of the respective owners, or (ii) in any way that violates any right of any third party. If you download or print a copy
 of the Content for personal use, you must retain all copyright and other proprietary notices contained therein.
</p>

                   </div>

                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto clarifications'>
                   <h3 className='header'>6. Clarifications</h3>

                   <p className='message'>
                   If you have any questions, clarifications about those terms of use, please <span className='link' onClick={()=>redirectTo('contact')}>contact us</span>.
                   </p>

                   </div>

                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto legal'>
                   <h3 className='header'>7. General legal terms</h3>

                   <p className='message'>
                   7.1 The Terms constitute the whole legal agreement between you and Xcelebs and govern your use of the Service and completely replace any prior agreements between you and Xcelebs in relation to the Service. All other terms of service that
                    Xcelebs and any of its subsidiaries may have in place from time to time are expressly excluded from the Terms.
                   </p>

                   <p className='message'>
                   7.2 You agree that Xcelebs may provide you with notices, including those regarding changes to the Terms, by email, regular mail, or postings on the Service.
                   </p>

                   <p className='message'>
                   7.3 You agree that if Xcelebs does not exercise or enforce any legal right or remedy which is contained in the Terms (or which Xcelebs has the benefit of under any applicable law), this will not be taken to be a
                    formal waiver of Xceleb's rights and that those rights or remedies will still be available to Xcelebs.
                     </p>

                     <p className='message'>
                     7.4 If any court of law, having the jurisdiction to decide on this matter, rules that any provision of these Terms is invalid, then that provision will be removed from the Terms without affecting the rest of the Terms.
                      The remaining provisions of the Terms will continue to be valid and enforceable.
                   </p>

                   <p className='message'>
                   7.5 You acknowledge and agree that each member of the group of companies of which Xcelebs is a part shall be third party beneficiaries to the Terms and that such other companies shall be entitled to directly enforce, and rely upon, any provision of the Terms which confers a benefit on (or rights in favour of) them.
                    Other than this, no other person or company shall be a third party beneficiary of the Terms.
                     </p>

                   </div>

                   <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto updated mt-1'>

<h3 className='update'>Last updated: December 2022.</h3>


</div> {/*updated*/}


            </div>
            </div>
     </TermContainer>
  )
}


const TermContainer=styledComponents.div`

background: var(--lightBlack);

.m-header{
    color: var(--lightOrange);
    font-family: 'Open Sans', sans-serif; 
    font-weight:bold;
    font-size:1.4rem;
    cursor:pointer;

    &:hover{
      text-decoration: underline;
  }
}

.header{
    color: var(--mainWhite);
    font-family: 'Open Sans', sans-serif; 
    font-weight:bold;
    font-size:1.4rem;
  }

  .message{
    color: var(--mainWhite);
    font-family: 'Open Sans', sans-serif; 
    font-size:0.9rem;
}

.link{
  color: var(--lightOrange);
  font-family: 'Open Sans', sans-serif; 
  font-size:0.9rem;
  cursor:pointer;

  &:hover{
      text-decoration: underline;
  }
}

.updated .update{
  color: var(--mainWhite);
  font-family: 'Open Sans', sans-serif; 
  font-size:1.1rem;
}

`; 