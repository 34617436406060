import React from 'react';
import styledComponents from 'styled-components';
import { RefreshButton } from './RefreshButton';

export const PlayerError = () => {

    const refreshThePage=()=>{
        window.location.reload();
    };


  return (
    <ErrorConatiner>
         <div className='container'>
             <div className='row'>
                 <div className='col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 player-error-div'>

                     <div className='player-error text-center py-4'>
                            <h3 className='error-message'>Oops! Something went wrong with the player. Please refresh.</h3>
                            <RefreshButton className='refresh-btn mt-2' onClick={refreshThePage}>refresh</RefreshButton>
                     </div>

                 </div>
              </div>
         </div>
    </ErrorConatiner>
  )
}

const ErrorConatiner = styledComponents.div`

position:fixed;
top:0;
bottom:0;
left:0;
right:0;
display:flex;
background: rgba(0,0,0,0.3);
align-items:center;
justify-content:center;
z-index:1111;

.player-error{
    background: var(--mainWhite);
    margin: 0 0;
    border-radius: 0.2rem;
}


@media (min-width: 600px) {
    .player-error{
        margin: 0 3rem;
    }
}

@media (min-width: 1024px) {
    .player-error{
        margin: 0 10rem;
    }
}

@media (min-width: 1400px) {
    .player-error{
        margin: 0 20rem;
    }
}

.player-error .error-message{
    font-family: 'Open Sans', sans-serif; 
    font-size: 1.1rem;
    padding: 0 0.4rem;
}

@media (max-width: 700px) {
    .player-error .error-message{
        padding: 0 0.4rem;
        font-size: 1rem;
        line-height: 1.5;
    }
}

`;