import React, { useState } from 'react'
import styledComponents from 'styled-components'
import {TrendingList} from './Trending/TrendingList'
import {NewList} from './NewAdded/NewList'
import { RecommendList } from './Recommends/RecommendList'
import { Footer } from './footer/Footer'
import { TitleBar } from './Index/TitleBar/TitleBar'
import { TopImg } from './Index/ImgBar/TopImg'
import { TopBar } from './Index/TopBar/TopBar'
import { useEffect } from 'react'
import { Tags } from './stars/Tags'
import { TopBarList } from './Index/TopBar/TopBarList'
import { LiveBoard } from './live/LiveBoard'
import { RecentAds } from './ads/RecentAds'
import { PopularAds } from './ads/PopularAds'
import { ConnectionStatus } from './internet-connection/ConnectionStatus'
import { Helmet } from 'react-helmet'

export const CelebsIndex = () => {

  // const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Xcelebs - Naked Nude Celebrity Videos and Photos`);
  const [pageDescription, setPageDescription] = useState(`Xcelebs is a Celebrity Platform that provides access to Nude Celebrity Videos and Photos, Live Sex Cams, Sex Dating, and the ability to Chat with Models.`);

  const [isShow,setIsShow]=useState(false);

  const removeExpiredWatchedVideoIds = () => {

    const w_key = "w-vids";

    const cachedData = localStorage.getItem(w_key);
    
    if (cachedData) {
        const { expirationTime } = JSON.parse(cachedData);
        const currentTime = new Date().getTime();
        
        if (currentTime >= expirationTime) {
            localStorage.removeItem(w_key);
        }
    }
  };

  useEffect(()=>{
   
    var check = document.getElementById('checkMenu');
    check.checked = false;

     const title=`Xcelebs - Naked Nude Celebrity Videos and Photos`;

     const description = `Xcelebs is a Celebrity Platform that provides access to Nude Celebrity Videos and Photos, Live Sex Cams, Sex Dating, and the ability to Chat with Models.`;

     setPageTitle(title);
     setPageDescription(description);

     document.title=title;
     document.body.style.zoom="100%";
    
   },[]);

   useEffect(()=>{
    removeExpiredWatchedVideoIds();
  },[]);

    const changeCheckHandler=(flag)=>{
        setIsShow(flag);
        var check = document.getElementById('checkMenu');
        check.checked = flag;
    }
   
  
  return (
    <CelebContainer>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        {/* <link rel="canonical" href={canonicalUrl} /> */}
      </Helmet>
        <div className='container-fluid'>
            <div className='row'>

            <input type="checkbox" id="checkMenu"/>

            <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-bar'>
                       <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
            </div>

            <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-bar-list'>
                <TopBarList/>
            </div>

            <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto top-img'>
            <TopImg/>
           </div> {/*top-img*/}

            <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto index-title'>
                     <TitleBar/>
            </div>{/*title-bar*/}

             <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto body'>

                 <div className='trending mb-5'>
                 <TrendingList/>
                 </div>

                 {/* <TrendingAds/> */}
                 <RecentAds/>

                 <div className='new my-5'>
                 <NewList/>
                 </div>

                 {/* <RecentAds/> */}
                 <PopularAds/>

                 <div className='popular my-5'>
                 <RecommendList/>
                 </div>
                 
             </div> {/*body*/}

             {/* <PopularAds/> */}

             <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto live-div'>
                <LiveBoard/>
            </div>

             <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto trending-tags mb-5'>
               <Tags/>
             </div>

             <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                <Footer/>
             </div> {/*footer*/}

            </div>
        </div>

        <ConnectionStatus/>

    </CelebContainer>
  )
}

const CelebContainer=styledComponents.div`

background: var(--lightBlack);

#checkMenu{
  display:none;
}

@media (min-width: 1024px) {
  .top-bar{
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
  // padding:0 !important;
  // margin:0 !important;
}


.top-img{
  padding:0 !important;
  margin:0 !important;
}

.index-title{
  // padding:0 !important;
  // margin:0 !important;
}

.body{
  // padding:0 !important;
  // margin:0 !important;
}

 .trending-tags{
//   padding:0 !important;
//   margin:0 !important;
 }

.footer-div{
  padding:0 !important;
  margin:0 !important;
}

.top-bar-list{
  padding:0 !important;
  margin:0 !important;
  overflow: hidden;
  position:relative;
  max-height: 0;
  transition: max-height 0.5s ease;
}

#checkMenu:checked ~ .top-bar-list{
  max-height: 300px;
  transition: max-height 0.5s ease;
}

`;