import React,{useEffect, useState} from 'react'
import styledComponents from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLarge } from '@fortawesome/free-solid-svg-icons';
import { faFilm } from '@fortawesome/free-solid-svg-icons';

export const SelectHeader = ({selectedHeader,header}) => {

  const [selected,setSelected]=useState(1);

  const headers = ['celebs','pornstars','movies']

  useEffect(()=>{
        changeSelect(header);
  },[header]);

  const changeSelect=(name)=>{
    if(name.toLowerCase()==='celebs'){
      selectedHeader('celebs');
      return setSelected(1);
    }
    if(name.toLowerCase()==='pornstars'){
      selectedHeader('pornstars');
      return setSelected(2);
    }
    if(name.toLowerCase()==='movies'){
      selectedHeader('movies');
      return setSelected(3);
    }
    selectedHeader('celebs');
    return setSelected(1);
  }

  return (
     <SelectContainer>
           <div className='container'>
                     < div className='row'>
                          <div className='col-8 col-md-12 col-lg-10 col-sm-10 mx-auto select-header'>
                            <div className='row'>
                                { headers.map((h,index)=>{
                                  return (<React.Fragment key={index}>
                                    <div className={'col-12 col-md-4 col-lg-4 col-sm-12 mx-auto select text-center text-capitalize '+((index+1==selected) && 'selected')}
                                    onClick={()=>changeSelect(h)}>
                                      {
                                        (h.toLowerCase()==='movies') ? 
                                       <React.Fragment> <FontAwesomeIcon className="fa-film icon px-2" icon={faFilm}/> <span className='name my-2'>{h}</span> </React.Fragment> :
                                       <React.Fragment> <FontAwesomeIcon className="fa-user-large icon px-2" icon={faUserLarge}/> <span className='name my-2'>{h}</span> </React.Fragment>
                                      }
                                    </div>
                                  </React.Fragment>); })
                                }
                              </div>
                          </div>
                     </div>
           </div>
     </SelectContainer>
  )
}

const SelectContainer = styledComponents.div`

background: var(--lightBlack);

.select{
 
  background: linear-gradient(0deg, #343434, #4b4b4b);
  cursor:pointer;
  border-radius:0.3rem;
  transform: scale(0.9);
  transition: opacity 0.8s ease;
  padding: 0.5rem 0;
  margin: 0.3rem 0 0 0!important;

  &:hover{
    background: var(--lightOrange);
    transition: opacity 0.8s ease;
  }
}

.selected{
  background: var(--lightOrange);
  .name{
      color: var(--darkBlue);
    }
    .fa-user-large{
      color: var(--darkBlue);
    }
    .fa-film{
      color: var(--darkBlue);
    }
}

.name{
  color: var(--mainWhite);
  // font-family: 'Karla', sans-serif;
  font-family: 'Ubuntu', sans-serif;
  font-size:1rem;
  letter-spacing:0.02em;
  //  font-weight:bold;
}

@media (max-width: 600px) {
  .name{
    font-size:0.95rem;
  }
  .fa-user-large{
    font-size:0.85rem;
  }
  .fa-film{
    font-size:0.85rem;
  }

}


.fa-user-large{
  color: var(--mainWhite);
  font-size:0.9rem;
}

.fa-film{
  color: var(--mainWhite);
  font-size:0.9rem;
}

`;