import React,{useEffect,useState} from 'react'
import styledComponents from 'styled-components'
import { Alphabat } from '../../category/Alphabat';
import { RecommendedList } from '../../Detail/RecommendedList';
import { TopBar } from '../../Index/TopBar/TopBar';
import { Tags } from '../../stars/Tags';
import { Footer } from '../Footer';
import { Terms } from './Terms';
import { Alph } from '../../test/Alph';
import { TopBarList } from 'src/components/Index/TopBar/TopBarList';
import { ConnectionStatus } from 'src/components/internet-connection/ConnectionStatus';
import { Helmet } from 'react-helmet';

export const TermsPage = () => {

  // const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Terms - Xcelebs`);
  const [pageDescription, setPageDescription] = useState(`Welcome to Xcelebs! This page houses our comprehensive Terms and Conditions, which outline the rules and agreements governing your use of our website. It is essential to understand these terms as they dictate the relationship between you and Xcelebs.`);

  const [isShow,setIsShow]=useState(false);

  useEffect(()=>{
    var check = document.getElementById('checkMenu');
    check.checked = false;
    window.scrollTo(0, 0);
    const title='Terms - Xcelebs';
    const desc='Welcome to Xcelebs! This page houses our comprehensive Terms and Conditions, which outline the rules and agreements governing your use of our website. It is essential to understand these terms as they dictate the relationship between you and Xcelebs.';
   
    setPageTitle(title);
    setPageDescription(desc);
    document.title = title;
    document.body.style.zoom="100%";
},[]);

const changeCheckHandler=(flag)=>{
  setIsShow(flag);
  var check = document.getElementById('checkMenu');
  check.checked = flag;
}

  return (
     <Container>
       <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        {/* <link rel="canonical" href={canonicalUrl} /> */}
      </Helmet>
          <div className='container-fluid'>
            <div className='row'>

            <input type="checkbox" id="checkMenu"/>

            <div className='top-bar'>
            <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
                   </div>

                   <div className='top-bar-list'>
                             <TopBarList/>
                             </div>
                             

                   <div className='alph-header mb-2 mt-1 d-none d-md-block'>
                          <Alph/>
                     </div>

                     <div className='alph-header mb-2 mt-1 d-md-none'>
                         <Alphabat/>
                     </div>
            
                <div className='col-12 col-md-11 col-lg-10 col-sm-12 mx-auto policy-div my-3'>
                       <Terms/>
                 </div>

                 <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto recommended-div mt-5'>
                              <RecommendedList celebName={'Gal Gadot'}/>
                          </div>
                          
                          <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto trending-tags mb-5'>
                            <Tags/>
                          </div>
                          
                          <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                        <Footer/>
                         </div>      


          </div>
          </div>

           <ConnectionStatus/>

     </Container>
  )
}

const Container = styledComponents.div`

background: var(--lightBlack);

@media (min-width: 1024px) {
  .top-bar {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
    // padding:0 !important;
    // margin:0 !important;
  } 

  .recommended-div{
    padding:0 !important;
    //margin:0 !important;
   }
   
//    .trending-tags{
//      padding:0 !important;
//      margin:0 !important;
//    }
   
   .footer-div{
     padding:0 !important;
     margin:0 !important;
   }

   #checkMenu{
    display:none;
  }
  
  .top-bar-list{
    padding:0 !important;
    margin:0 !important;
    overflow: hidden;
    position:relative;
    max-height: 0;
    transition: max-height 0.5s ease;
  }
  
  #checkMenu:checked ~ .top-bar-list{
    max-height: 300px;
    transition: max-height 0.5s ease;
  }

`;
