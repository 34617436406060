import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

export const Item = ({item,type}) =>{

    const navigate = useNavigate();
   
    const redirectTo=item=>{

      if(type==="info"){

        if(item.toLowerCase()==='2257 exempt')    
           return '/2257';
         if(item.toLowerCase()==='contact us')
           return '/contact';
         if(item.toLowerCase()==='dmca')
            return '/dmca';
         if(item.toLowerCase()==='privacy policy')
            return '/privacy-policy'; 
        if(item.toLowerCase()==='terms of service')
            return '/terms';   
        if(item.toLowerCase()==='community guidelines')
            return '/communityguidelines';   
      }  
      
      if(type==='featured'){

        if(item.toLowerCase()==='top 100 videos of 2023')
          return '/top-100-videos';
        if(item.toLowerCase()==='top 100 celebs of all time')
           return '/top-100-celebs';
        if(item.toLowerCase()==='xcelebs models')
           return '/browse/xcelebs/popular';
        if(item.toLowerCase()==='free onlyfans creators')
           return '/free-onlyfans-creators'; 
        if(item.toLowerCase()==='premium onlyfans creators')
           return '/premium-onlyfans-creators';     
        if(item.toLowerCase()==='top onlyfans models')
           return '/top-onlyfans-creators';   

     }  
      

      if(type==='ctg'){
        if(item.toLowerCase()==='celebrities')
         return '/browse/celebs/a';
        if(item.toLowerCase()==='pornstars')
         return `/browse/models/a`;
        if(item.toLowerCase()==='hentai')
         return `/browse/tags/videos/hentai`;
        if(item.toLowerCase()==='milf')
         return `/browse/tags/videos/milf`;
        if(item.toLowerCase()==='live sex')
         return `/live-sex`;
        if(item.toLowerCase()==='live vr sex cams')
         return `/live-vr-sex-cams`;
        if(item.toLowerCase()==='bdsm')
         return `/browse/tags/videos/bdsm`;
      }
             

    }

    return (
        <div className="container">
          <div className="row">
                <div className="col-12 col-md-12 mx-auto col-lg-12 col-sm-12 item">
                    <div className="my-1">
                    <Link to={redirectTo(item)} className="name">{item}</Link>
                    </div>
                </div>
          </div>
        </div>
    );
}

