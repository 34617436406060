import React, { useState,useEffect } from 'react'
import styledComponents from 'styled-components'
import { Footer } from '../footer/Footer'
import { Tags } from '../stars/Tags'
import { Bio } from './Bio'
import { CelebList } from './CelebList'
import { RecommendedList } from './RecommendedList'
import { SelectHeader } from './SelectHeader'
import { Alphabat } from '../category/Alphabat';
import { TopBar } from '../Index/TopBar/TopBar';
import { CelebImgList } from './CelebImgList'
import { useLocation } from 'react-router';
import { Alph } from '../test/Alph';
import { TopBarList } from '../Index/TopBar/TopBarList'
import { LiveBoard } from '../live/LiveBoard'
import { BioAds } from '../ads/BioAds'
import { ConnectionStatus } from '../internet-connection/ConnectionStatus'
import { Helmet } from 'react-helmet'

export const Detail = () => {

  const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Nude Videos and Photos - Xcelebs`);
  const [pageDescription, setPageDescription] = useState(`Nude Videos and Photos, here on xcelebs.org. Discover the growing collection of high quality Most Relevant XXX movies and clips.`);

  const [selected,setSelected] = useState('videos');
  const [celebId,setCelebId]=useState('');
  const [celebName,setCelebName]=useState('');
  const [isShow,setIsShow]=useState(false);

  const location = useLocation();

  const selectedHandler=(header)=>{
       setSelected(header);
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
   }

  useEffect(()=>{

    window.scrollTo(0, 0);

    // const name = (celebName) && celebName.toUpperCase().replaceAll('-',' ');
    const name = (celebName && celebName.replaceAll('-',' ').split(' ').map(capitalizeFirstLetter).join(' '));

    const title=`${name} Nude Videos and Photos - Xcelebs`;
    const desc = `${name} Nude Videos and Photos, here on xcelebs.org. Discover the growing collection of high quality Most Relevant XXX movies and clips.`;
    
    setPageTitle(title);
    setPageDescription(desc);
    document.title = title;
    document.body.style.zoom="100%";
   
  },[celebName]);

  useEffect(()=>{

    if(window.location.href.split("/").length>5){
      const id = window.location.href.split("/")[5];
      const name = window.location.href.split("/")[6];
      
      setCelebId(id);
      setCelebName(name);
    }

 },[location.pathname]);

 useEffect(()=>{

  var check = document.getElementById('checkMenu');
  check.checked = false;

 },[]);

 const changeCheckHandler=(flag)=>{
  setIsShow(flag);
  var check = document.getElementById('checkMenu');
  check.checked = flag;
}


  return (
      <DetailContainer>
        <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    <div className='container-fluid'>
        <div className='row'>

        <input type="checkbox" id="checkMenu"/>

        <div className='top-bar'>
        <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
             </div>

             <div className='top-bar-list'>
                             <TopBarList/>
                 </div>

             <div className='alph-header mb-2 mt-1 d-none d-md-block'>
                          <Alph/>
                     </div>

                     <div className='alph-header mb-2 mt-1 d-md-none'>
                         <Alphabat/>
                     </div>

             <div className='col-12 col-md-12 col-lg-11 col-sm-12 mx-auto bio-div px-xl-4 px-lg-0 px-md-4 px-3'>
               { (celebId && celebName) && <Bio celebId={celebId} celebName={celebName}/> }
             </div>

             <BioAds/>
             
             <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto header-div'>
                 <SelectHeader selectedHandler={selectedHandler}/>
             </div>

            { (selected==='videos') && <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto celeb-div my-4'>
                { (celebName) && <CelebList celebId={celebId} celebName={celebName}/>}
             </div> }

             { (selected==='images') && <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto celeb-img-div my-4'>
               { (celebName) && <CelebImgList celebId={celebId} celebName={celebName}/> }
             </div> }

             <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto recommended-div'>
             { (celebName) && <RecommendedList celebName={celebName}/> }
             </div>

             <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto live-div'>
                <LiveBoard/>
            </div>

             <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto trending-tags mb-5'>
               <Tags/>
             </div>
             
             <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                        <Footer/>
             </div>
        </div>
    </div>

        <ConnectionStatus/>

    </DetailContainer>
  )
}

const DetailContainer=styledComponents.div`

background: var(--lightBlack);

@media (min-width: 1024px) {
  .top-bar {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.top-bar{
    // padding:0 !important;
    // margin:0 !important;
  } 

.celeb-div{
 // padding:0 !important;
 // margin:0 !important;
}  

.celeb-img-div{
  // padding:0 !important;
  // margin:0 !important;
} 

.recommended-div{
  padding:0 !important;
  margin:0 !important;
}

.trending-tags{
 // padding:0 !important;
 // margin:0 !important;
}

.bio-dev{
    padding:0 !important;
    margin:0 !important;
}

.header-div{
    padding:0 !important;
    margin:0 !important;
}

.footer-div{
    padding:0 !important;
    margin:0 !important;
  }
  
  #checkMenu{
    display:none;
  }
  
  .top-bar-list{
    padding:0 !important;
    margin:0 !important;
    overflow: hidden;
    position:relative;
    max-height: 0;
    transition: max-height 0.5s ease;
  }
  
  #checkMenu:checked ~ .top-bar-list{
    max-height: 300px;
    transition: max-height 0.5s ease;
  }

`;
