import styledComponents from "styled-components";

export const HomeButton=styledComponents.button`

border:none;
border-radius:0.3rem;
padding: 0.3rem 1.5rem;
font-size:1rem;
outline:none;
background: var(--mainMaroon);
color: var(--mainWhite);
cursor:pointer;
transition: 1s all;
font-family: 'Karla', sans-serif;

&:hover{
    background: var(--mainMaroon);
    color: var(--lightGrey);
}

`;