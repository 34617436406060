import React from 'react'
import HoverVideoPlayer from 'react-hover-video-player';
import { useRef } from 'react';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Link,useNavigate } from 'react-router-dom';

export const Video = ({item}) => {

  // const sourceUrl = (item.sourceUrl) ? item.sourceUrl.toUpperCase() : item.videoUrl;

  // const sourceUrl = (item.sourceUrl.includes('aznude.com')) ? item.sourceUrl.toUpperCase() : item.sourceUrl;

     const sourceUrl = item.sourceUrl;

    // const hoverVideoRef = useRef();

    // const navigate = useNavigate();

    const watermark="xcelebs.org";

    // useEffect(() => {
    //     const videoElement = hoverVideoRef.current;
    //     videoElement.playbackRate = 5;
    //   }, []);

   const redirectToStream=(id,name)=>{
       return (id && name) && `/stream/${id}/${encodeURIComponent(name.toLowerCase().replaceAll(/[-\s]+/g, '-').replaceAll(',',''))}`;
   }  
   
  //  const redirectToFilm=(name)=>{
  //     return navigate(`/view/movie/${name.replaceAll(' ','-')}`);
  //  }

  
  return (
    <React.Fragment>
        <Link className="col-6 col-md-3 col-lg-3 col-sm-4 mx-auto plays"  to={redirectToStream(item.videoId,item.title)}>
 
        <div className="card">  

        <HoverVideoPlayer className='player d-none d-lg-block'
  videoStyle={{width:'100%',height:'12.5rem'}}
  videoSrc={sourceUrl}
 // videoRef={hoverVideoRef}
  //playbackRangeStart={50}
  pausedOverlay={
    <img
      src={item.imgUrl}
      alt={item.title}
      title={item.title}
      style={{
         width: '100%',
         height: '100%',
        objectFit: 'cover',
      }}
    />
  }
  loadingOverlay={
    <div className="loading-overlay">
      <div className="loading-spinner" />
    </div>
  }
  />

<HoverVideoPlayer className='player d-lg-none'
  videoStyle={{width:'100%',height:'10rem'}}
  videoSrc={sourceUrl}
 // videoRef={hoverVideoRef}
  //playbackRangeStart={50}
  pausedOverlay={
    <img
      src={item.imgUrl}
      alt={item.title}
      title={item.title}
      style={{
         width: '100%',
         height: '100%',
        objectFit: 'cover',
      }}
    />
  }
  loadingOverlay={
    <div className="loading-overlay">
      <div className="loading-spinner" />
    </div>
  }
  />


<div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 time-quality d-flex">

{/* <div className="col-6 mx-auto col-lg-6 col-md-6 col-sm-6 time text-start px-2"> */}

<div className="plays-time d-none d-xl-block">
 {(item.duration) && <span className='duration'>{item.duration}</span> }
</div>

<div className="plays-time-sm d-xl-none">
 {(item.duration) && <span className='duration'>{item.duration}</span> }
</div>

{/* </div> */}

{/* <div className="col-6 mx-auto col-lg-6 col-md-6 col-sm-6 quality text-end px-2"> */}

<div className="plays-quality text-uppercase d-none d-xl-block">
 { (item.quality) && <span className='resolution'>{item.quality}</span> }
</div>

<div className="plays-quality-sm text-uppercase d-xl-none">
 { (item.quality) && <span className='resolution'>{item.quality}</span> }
</div>

{/* </div> */}

</div> {/*time-quality*/}

<div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 video-views text-end">

<div className="view d-none d-xl-block">
<span className='total-views'> <span>{item.views}K</span> <FontAwesomeIcon className="fa-views icon" icon={faEye}/></span> 
</div>

<div className="view-sm d-xl-none">
<span className='total-views'> <span>{item.views}K</span> <FontAwesomeIcon className="fa-views icon" icon={faEye}/></span> 
</div>

</div> {/*video-views*/}
  
<div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 player-watermark text-end px-1 d-none d-xl-block">

<div className="watermark">
<span className='p-wm'>{watermark}</span> 
</div>

</div> {/*player-watermark*/}

<div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 player-watermark-sm text-end px-1 d-xl-none">

<div className="watermark">
<span className='p-wm'>{watermark}</span> 
</div>

</div> {/*player-watermark-sm*/}

       </div>  {/*card*/}

       <div className="card-footer">

{ (item.title) && <h6 className={"title my-2 mx-1 d-none d-xl-block"}>{item.title}</h6> }
{ (item.title) && <h6 className={"title-sm my-2 mx-2 d-xl-none"}>{item.title}</h6> }

        </div>

             </Link>
             </React.Fragment>
  )
}

