import React from 'react'
import styledComponents from 'styled-components'
import { Browse } from '../Trending/Button';
import { CelebsList } from './CelebsList';
import { useState } from 'react';
import Title from './Title';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { VideoLoading } from '../loading/VideoLoading';
import {Video} from '../test/Video';

export const  RecommendList = () => {

  // const popunder_desktop_link = 'https://syndication.realsrv.com/splash.php?cat=&idzone=4851132&type=8&p=&sub=%SUB1%&tags=%KW%';

  // const popunder_mobile_link = 'https://syndication.realsrv.com/splash.php?cat=&idzone=4851130&type=8&p=&sub=%SUB1%&tags=%KW%';

    const popunder_desktop_link = 'https://blackandwhite-temporary.com/bE3.VZ0dPD3npEvOb_mxVoJnZ/DY0/1JMEzcI/y/NCjsMZ1FLITWUczqMwjhI/2wMrz/YW';

    const popunder_mobile_link = 'https://blackandwhite-temporary.com/bf3_V_0/P.3/pmvAbOmBVaJvZBDA0/1yM-zVIJyoNLjeQSzMLSTOUczNMEjGIO2FNhDEQc';

    const [videos,setVideos]=useState([]);
    const [loading,setLoading]=useState(true);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const navigate= useNavigate();

    useEffect(() => {

      const handleOnline = () => {
        setIsOnline(true);
      };
  
      const handleOffline = () => {
        setIsOnline(false);
      };
  
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
  
      return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
      };

    }, []);


  useEffect(() => {

    const fetchData = async () => {
        try {

           // const token = from local storage

            const recommend_url = process.env.REACT_APP_CELEB_VIDEO;

            setLoading(true);

            if (!isOnline)
                return;

            const response = await axios.get(`${recommend_url}/popular`, {
                headers: {
                     // 'Authorization':'bearer '.concat(token)
                }
            });

            setVideos(response.data);
            setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
    };

    fetchData();
}, [isOnline]);


  const detectDevice=()=>{

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile;
  
  }


  const redirectTo=()=>{
     
    if(detectDevice())
      window.open(popunder_mobile_link);
      else
      window.open(popunder_desktop_link);

    // return navigate('/browse/videos/popular');
 }

  return (

   <React.Fragment>

          <Title/>
          <CelebsList/>

    <RecommendContainer>
        
          <div className={`container-fluid ${!loading ? "vid-list mb-4" : "my-3"}`}>
                     <div className="row">
                     
                     { (!loading) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 plays-list plays-list-lg d-none d-lg-block">
                     <div className="row">

                       { (videos) && videos.map((video)=><Video key={video.videoId} item={video}/>)}

                        </div>
                      </div> }

                      { (!loading) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 plays-list plays-list-sm d-lg-none">
                     <div className="row">

                       { (videos) && videos.map((video)=><Video key={video.videoId} item={video}/>)}

                        </div>
                      </div> }

                     </div>
        </div>  

              {
                        (loading) && <VideoLoading/>
                      }
                      
       { (!loading && videos && videos.length>=1) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 browse text-center mt-3">
                          <Link to={'/browse/videos/popular'} onClick={()=>redirectTo()} className="browse-link">browse popular videos</Link>
        </div> }     
    </RecommendContainer>

    </React.Fragment>
  )
}

const RecommendContainer = styledComponents.div`

background: var(--lightBlack);

// .plays-list .plays{
//   position:relative;
//   border: 3px solid transparent;
//   padding:0 !important;
//   margin:0 !important;
//   transform: scale(0.9);
//   transition:0.8s all;

//   &:hover{
//     transition:0.8s all;
//     cursor:pointer;
//     border: 3px solid var(--lightOrange);
//     .title{
//       color: var(--lightOrange);
//     }
//     .video-views{
//       transition:0.8s all;
//       z-index:-1111;
//     }
//     .time-quality{
//       transition:0.8s all;
//       z-index:-1111;
//     }
//   }
// }

// .card{
//   background: transparent;
// }

// .card-footer{
//   padding:0 !important;
//   margin:0 !important;
// }

// .plays .title{

//   // position: absolute;
//   // //top:90%;
//   // z-index:1111;  
//     font-size:1rem;
//     font-family: 'Open Sans', sans-serif; 
//     color: var(--mainWhite);
//     transition:0.8s all;
// }

// .time-quality{
//   position: absolute;
//   top:1%;
//   z-index:1111;

//   .plays-time{

//     .duration{
//       background: var(--lightGrey);
//       padding: 0.2rem 0.5rem 0.2rem 0.5rem;
//       font-size:0.9rem;
//       color: var(--mainDark2);
//       font-family: 'Open Sans', sans-serif; 
//     //  font-weight:bold;
//     }

//   }

//   .plays-time-sm{

//     .duration{
//       background: var(--lightGrey);
//       padding: 0.2rem 0.3rem 0.2rem 0.3rem;
//       font-size:0.7rem;
//       color: var(--mainDark2);
//       font-family: 'Open Sans', sans-serif; 
//     //  font-weight:bold;
//     }
//   }

//     .plays-quality{

//       .resolution{
//         font-family: 'Open Sans', sans-serif; 
//         background: var(--lightGrey);
//         padding: 0.2rem 0.4rem 0.2rem 0.4rem;
//         font-size:0.9rem;
//         color: var(--lightRed);
//         font-weight:bold;
//       }
//     }

//     .plays-quality-sm{

//       .resolution{
//         font-family: 'Open Sans', sans-serif; 
//         background: var(--lightGrey);
//         padding: 0.2rem 0.3rem 0.2rem 0.3rem;
//         font-size:0.7rem;
//         color: var(--lightRed);
//         font-weight:bold;
//       }
//     }

//   }


// .film-rate{
  
// }

// .film{
//   .film-name {
//     color: var(--lightGrey);
//     font-size:0.8rem;
//     font-family: 'Josefin Sans', sans-serif;
//     transition:0.5s all;

//     &:hover{
//       transition:0.5s all;
//       color: var(--mainWhite);
//     }
//   }

// }

// .play-rate{
//   color: var(--lightGreen);
//   .fa-thumb{
//    font-size:1rem;
//   }
//   .percentage{
//     font-size:0.9rem;
//   }
// }

// .video-views{
//   position: absolute;
//   top:1%;
//   z-index:1111;
//   margin:0;
//   padding:0;

//   .view{

//   .total-views{
//       background: rgba(0,0,0,0.7);
//       padding: 0.2rem 0.4rem 0.2rem 0.4rem;
//       color: var(--mainWhite);
//       font-size:0.9rem;
//       font-family: 'Open Sans', sans-serif; 
//   }
//   .fa-views{
//       font-size:1rem;
//   }
// }

// .view-sm{

//   .total-views{
//       background: rgba(0,0,0,0.7);
//       padding: 0.2rem 0.3rem 0.2rem 0.3rem;
//       color: var(--mainWhite);
//       font-size:0.7rem;
//       font-family: 'Open Sans', sans-serif; 
//   }
//   .fa-views{
//       font-size:0.8rem;
//   }
// }

.plays-list-sm{
  padding:0 !important;
  margin:0 !important;
}

.plays-list .plays{
  position:relative;
  border: 3px solid transparent;
  padding:0 !important;
  margin:0 !important;
  transform: scale(1);
  transition:0.8s all;
  text-decoration:none;

  &:hover{
    transition:0.8s all;
    cursor:pointer;
    // border: 3px solid var(--lightOrange);
    .player{
      border: 3px solid var(--lightOrange);
    }
    .title{
      color: var(--lightOrange);
    }
    .title-sm{
      color: var(--lightOrange);
    }
    .video-views{
      // transition:0.8s all;
      z-index:-1111;
    }
    .time-quality{
      transition:0.8s all;
      z-index:-1111;
    }
    .player-watermark{
      // transition:0.8s all;
      z-index:1111;
      opacity: 1;
    }
    .player-watermark-sm{
      // transition:0.8s all;
      z-index:1111;
      opacity: 1;
    }
  }
}

@media (min-width: 768px) {
  .plays-list .plays {
    transform: scale(0.98);
  }
}


.plays-list .player{
  border: 3px solid transparent;
}

.card{
  background: transparent;
}

.card-footer{
  padding:0 !important;
  margin:0 !important;
}

.plays .title{

  // position: absolute;
  // //top:90%;
  // z-index:1111;  
    font-size:1rem;
    font-family: 'Open Sans', sans-serif; 
    color: var(--mainWhite);
    transition:0.8s all;
}

.plays .title-sm{

  // position: absolute;
  // //top:90%;
  // z-index:1111;  
    font-size:0.8rem;
    font-family: 'Open Sans', sans-serif; 
    color: var(--mainWhite);
    transition:0.8s all;
}

  

.time-quality{
  position: absolute;
  // top:-0.1%;
  bottom:4.5%;
  z-index:1111;

  padding-left: 0.4rem;

  .plays-time{

    .duration{
      background: var(--lightGrey);
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      font-size:0.7rem;
      color: var(--mainDark2);
      font-family: 'Open Sans', sans-serif; 
      font-weight:bold;
    }

  }

  .plays-time-sm{

    .duration{
      background: var(--lightGrey);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      font-size:0.6rem;
      color: var(--mainDark2);
      font-family: 'Open Sans', sans-serif; 
      font-weight:bold;
    }
  }

    .plays-quality{

      padding-left: 0.1rem;

      .resolution{
        font-family: 'Open Sans', sans-serif; 
        background: var(--lightGrey);
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;
        font-size:0.7rem;
        color: var(--brightRed);
        font-weight:bold;
      }
    }

    .plays-quality-sm{

      padding-left: 0.1rem;

      .resolution{
        font-family: 'Open Sans', sans-serif; 
        background: var(--lightGrey);
        padding: 0.1rem 0.2rem 0.1rem 0.2rem;
        font-size:0.6rem;
        color: var(--brightRed);
        font-weight:bold;
      }
    }

  }

  @media (min-width: 768px) {
    .time-quality {
      bottom:5%;
      padding-left: 0.5rem;
    }
  }

.film{

  .name{
    text-decoration:none;
  }
  
  .film-name {
    color: var(--lightGrey);
    font-size:0.8rem;
    font-family: 'Josefin Sans', sans-serif;
    transition:0.5s all;

    &:hover{
      transition:0.5s all;
      color: var(--mainWhite);
      text-decoration: underline; 
      color: var(--mainWhite);
      text-decoration-thickness: 1.7px;
    }
  }

  .film-name-sm{
    color: var(--lightGrey);
    font-size:0.7rem;
    font-family: 'Josefin Sans', sans-serif;
    transition:0.5s all;

    &:hover{
      transition:0.5s all;
      color: var(--mainWhite);
      text-decoration: underline; 
      color: var(--mainWhite);
      text-decoration-thickness: 1.5px;
    }
  }

}

.play-rate{
  color: var(--lightGreen);
  .fa-thumb{
   font-size:0.8rem;
  }
  .percentage{
    font-size:0.8rem;
  }
}

.video-views{
  position: absolute;
  // top:-0.1%;
  z-index:1111;
  margin:0;
  padding:0;
  bottom:4.5%;

  padding-right: 0.4rem;

  .view{

  .total-views{
      background: rgba(0,0,0,0.7);
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      color: var(--mainWhite);
      font-size:0.7rem;
      font-family: 'Open Sans', sans-serif; 
  }
  .fa-views{
      font-size:0.8rem;
  }
}

.view-sm{

  .total-views{
      background: rgba(0,0,0,0.7);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      color: var(--mainWhite);
      font-size:0.6rem;
      font-family: 'Open Sans', sans-serif; 
  }
  .fa-views{
      font-size:0.7rem;
  }
}

} 

@media (min-width: 768px) {
  .video-views {
    bottom:5%;
    padding-right: 0.5rem;
  }
}

.player-watermark{
  position: absolute;
  top:1%;
  right:1%;
  z-index:-1111;
  opacity: 0;
  margin:0;
  padding:0;

  .watermark{

    .p-wm{
      background: rgba(0,0,0,0.9);
      padding: 0.1rem 0.4rem 0.1rem 0.4rem;
      color: var(--mainWhite);
      font-size:0.8rem;
      font-family: 'Open Sans', sans-serif; 
  }

  }

}

.player-watermark-sm{
  position: absolute;
  top:-0.1%;
  z-index:-1111;
  opacity: 0;
  margin:0;
  padding:0;

  .watermark{

    .p-wm{
      background: rgba(0,0,0,0.9);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      color: var(--mainWhite);
      font-size:0.6rem;
      font-family: 'Open Sans', sans-serif; 
  }

  }

}

.browse-link{

  text-decoration:none;
  color: var(--mainWhite);
  background-color: var(--lightDark2);
  padding: 0.5rem 1.5rem;
  font-size:1rem;
  text-transform: capitalize;
  border:none;
  outline:none;
  border-radius:0.4rem;
  // font-family: 'Open Sans', sans-serif; 
  // font-weight:bold;
  font-family: 'Montserrat', sans-serif;
  transition:0.5s all;
  letter-spacing: 0.02em;
  
  &:hover{
      background-color: var(--lightOrange);
      transition:0.5s all;
  }
  
  }

  .vid-list{
    margin-top: 1.8rem;
 }
 

`;
