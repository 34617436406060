import React from 'react';
import {isMobile} from 'react-device-detect';
import {isBrowser} from 'react-device-detect';
import axios from 'axios';

export const Video_Premium_Offer_2 = () => {

  const trackAffiliate=(id,name,type,provider,link)=>{

    axios.get('https://ipapi.co/json/')
  .then((response) => {
    const data = response.data;
    const country = data.country_name;
    trackAffiliateOffer(id,name,type,country,provider,link);
    return (link) && window.open(link);
  })
  .catch((error) => {
    console.error('Error fetching country:', error);
    trackAffiliateOffer(id,name,type,'',provider,link);
    return (link) && window.open(link);
  });

    }

  const trackAffiliateOffer= async (id,name,type,location,provider,link)=>{

    const res = axios.get(`https://hutils.loxal.net/whois`);
    const ip = (await res).data.ip;

    let device="undetected";

    if (isMobile)
     device="mobile";

    if (isBrowser)
     device="desktop";
    

    const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
      affiliateLink:link,deviceType:device,ipAddress:ip,country:location};

      const save_url=process.env.REACT_APP_AFFILIATE;

      axios.post(`${save_url}`,detail,{
          headers:{
              'Content-Type':'application/json',
           //   'Authorization':'bearer '.concat(token)
          }
      });

  }

  return (
    <React.Fragment>

    <div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto offer-1 text-center d-none d-md-block'>
      
      <a target="_blank"><img src="https://i.ibb.co/7gZmTDP/Golden-week-728x90.jpg" width="728" height="90" border="0" className='mt-2 mb-3' onClick={()=>trackAffiliate('CHA113','AF-NUTAKU-VIDEO','RevShare','adultforce','https://www.nutaku.net/signup/landing/project-qt/1/?ats=eyJhIjo3ODIwMzIsImMiOjYxMDE0ODAzLCJuIjoxLCJzIjoxLCJlIjo5MjM3LCJwIjoyfQ==')} style={{ cursor: 'pointer' }}/></a>

   </div>

   <div className='col-md-12 col-sm-12 col-lg-12 col-12 mx-auto offer-1 text-center d-md-none'>
   
   <a target="_blank"><img src="https://i.ibb.co/JsHjLYS/300x250-ENG-NSFW.jpg" width="300" height="250" border="0" className='mb-3' onClick={()=>trackAffiliate('CHA114','AF-MyDirtyHobby-VIDEO','RevShare','adultforce','https://lp.mydirtyhobby.com/2/?video=4&lang=de&ats=eyJhIjo3ODIwMzIsImMiOjYxMDE0ODAzLCJuIjoyMSwicyI6MjQxLCJlIjo5NTQyLCJwIjoyfQ==')} style={{ cursor: 'pointer' }}/></a>

</div>

</React.Fragment>
  )
}
