import React,{useEffect, useState} from 'react'
import styledComponents from 'styled-components'
import { SliderButton } from '../Detail/SliderButton2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { VideoJs } from '../player/VideoJs';

export const PlayerModal = ({openPlayerHandler,a_index,plays,currentVideoId}) => {

  const [activeIndex,setActiveIndex]=useState(0);
  const [items,setItems] = useState([]);
  const [item,setItem]=useState({});
  const [loading,setLoading]=useState(false);
  const [openModal,setOpenModal]=useState(false);

  useEffect(()=>{
       setActiveIndex(a_index)
       setItems(plays);
  },[plays]);

  useEffect(() => {
    // Preload video when the component mounts
    if (item){
        const vid = document.createElement('video');
        vid.src = item.videoUrl;
    }
  }, [item]);

  const getVideo = async (id) => {
    const video_url = process.env.REACT_APP_CELEB_VIDEO;
  
    setLoading(true);
  
    try {
      const response = await axios.get(`${video_url}/video/${id}`);
      setItem(response.data);
      setLoading(false);
      setOpenModal(true);
    } catch (error) {
      setLoading(false);
      setOpenModal(true);
      console.log(error);
    }
  };

  // const getVideoList=(id)=>{
   
  //   setLoading(true);

  //   const selectedVideo = plays.find(vid => vid.videoId === id);
  //   setItem(selectedVideo);
  //   setLoading(false);

  // }

  useEffect(()=>{
     getVideo(currentVideoId);
  },[]);


  const goLeft=()=>{
       
    if(activeIndex==0){  
      setActiveIndex(items.length-1);
      getVideo(plays[items.length-1].videoId);
    }else{
      setActiveIndex(prev=>prev-1);
      getVideo(plays[activeIndex-1].videoId);
    }
    
}

const goRight=()=>{
   
    if(activeIndex==items.length-1){
        setActiveIndex(0);
        getVideo(plays[0].videoId);
    }else{
       setActiveIndex(prev=>prev+1);
       getVideo(plays[activeIndex+1].videoId);
    }
    
}


const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft')
      goLeft();
    else if(event.key === 'ArrowRight')
     goRight();
}

useEffect(() => {
  document.addEventListener('keydown', handleKeyDown); 

  return () => {
    document.removeEventListener('keydown', handleKeyDown);
  };
}, [item]);

useEffect(() => {

  if(!openModal)
   return;
  
  const handleOutsideClick = (event) => {

    const excludeLeftFromClose = document.getElementById('leftBtn');
    const excludeRightFromClose = document.getElementById('rightBtn');
    const excludeFromClose = document.getElementById('closeBtn');
    const excludeSmFromClose = document.getElementById('closeBtnSm');
    const excludeNumFromClose = document.getElementById('numItem');
    const excludeNumSmFromClose = document.getElementById('numItemSm');
    const excludePlayerLgFromClose = document.getElementById('playerLg');
    const excludePlayerSmFromClose = document.getElementById('playerSm');

    if ( (excludeLeftFromClose && !excludeLeftFromClose.contains(event.target)) &&  (excludeRightFromClose && !excludeRightFromClose.contains(event.target))
    && (excludeFromClose && !excludeFromClose.contains(event.target)) && (excludeSmFromClose && !excludeSmFromClose.contains(event.target)) && 
    (excludeNumFromClose && !excludeNumFromClose.contains(event.target)) && (excludeNumSmFromClose && !excludeNumSmFromClose.contains(event.target)) &&
    (excludePlayerLgFromClose && !excludePlayerLgFromClose.contains(event.target)) && (excludePlayerSmFromClose && !excludePlayerSmFromClose.contains(event.target))) {
      openPlayerHandler(false);      
    }
  };

  document.addEventListener('click', handleOutsideClick);

  return () => {
    document.removeEventListener('click', handleOutsideClick);
  };
}, [openModal]);

     return ReactDOM.createPortal(<PlayerContainer>

<div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12 mx-auto control-div">

<span id="numItem" className='num px-3 d-none d-lg-block'><span className='current'>{activeIndex+1}</span> / {items.length}</span>

<FontAwesomeIcon id="closeBtn" className='fa-close d-none d-lg-block' icon={faClose} onClick={()=>openPlayerHandler(false)}/>

  </div>

  <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 col-xl-12 control-div">

  <span id="numItemSm" className='num px-3 d-lg-none'><span className='current'>{activeIndex+1}</span> / {items.length}</span>

<FontAwesomeIcon id="closeBtnSm" className='fa-close d-lg-none' icon={faClose} onClick={()=>openPlayerHandler(false)}/>

  </div>

         <div className='container'>
              <div className='row'>

                {/* { ((item.hlsUrl && !loading) || (item.embedUrl && !loading) || (item.videoUrl_02 && !loading)) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 control-div d-none d-lg-block text-center">

                    <FontAwesomeIcon id="closeBtn" className='fa-close' icon={faClose} onClick={()=>openPlayerHandler(false)}/>

                  </div> } */}

          {/* { ((item.hlsUrl && !loading) || (item.embedUrl && !loading) || (item.videoUrl_02 && !loading)) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 control-div d-lg-none text-center">

              <FontAwesomeIcon id="closeBtnSm" className='fa-close' icon={faClose} onClick={()=>openPlayerHandler(false)}/>

            </div> } */}


           {  (loading) &&  <span className='loading text-center'>Loading . . . .</span> }
           
           
 { (!loading && item) && <React.Fragment>

 <div id="playerLg" className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto player text-center d-none d-lg-block'>

{ (item.hlsUrl || item.embedUrl) ? <VideoJs embedVideo={item}/>
:
<iframe className="player-warpper" src={item.videoUrl_02}
frameborder="0" marginwidth="0"  marginheight="0" scrolling="no" width="100%" height="600" allowfullscreen=""/> }

 </div> 
 
<div id="playerSm" className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto player text-center d-lg-none'>

{ (item.hlsUrl || item.embedUrl) ?  <iframe className='player-wrapper' src={`https://xcelebs.org/embed/${encodeURIComponent(JSON.stringify(item))}`}
              frameborder="0" width="100%" height="315" scrolling="no" allowfullscreen=""/>
:
<iframe className="player-warpper" src={item.videoUrl_02}
frameborder="0" marginwidth="0"  marginheight="0" scrolling="no" width="100%" height="315" allowfullscreen=""/> }

 </div>

</React.Fragment> }
                   
                     <SliderButton left id="leftBtn" className="left-btn">
                              <FontAwesomeIcon icon={faCaretLeft} className="arrow-icon" onClick={()=>goLeft()}/>
                    </SliderButton>

            <SliderButton right id="rightBtn" className="right-btn">
                         <FontAwesomeIcon icon={faCaretRight} className="arrow-icon" onClick={()=>goRight()}/>
              </SliderButton>

              </div>
         </div>
     </PlayerContainer>,document.getElementById('player-modal'));
  
}

const PlayerContainer=styledComponents.div`

position:fixed;
top:0;
bottom:0;
left:0;
right:0;
display:flex;
background: rgba(0,0,0,0.9);
align-items:center;
justify-content:center;
z-index:1111;

.left-btn{
  background: transparent;
  // color: var(--lightGrey);
  height:20vh;
}

.right-btn{
 background: transparent;
//  color: var(--lightGrey);
 height:20vh;

}

.arrow-icon{
cursor:pointer;
opacity:0.9;
color: var(--mainWhite);
transition:0.5s all;

&:hover{
 transition:0.5s all;
 opacity: 1;
}

}

.control-div{
    // position:relative;
    // top:-1rem;
    position:fixed;
    top:0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top:0.6rem;
    padding-right:1rem;
 }

.close{
    background: transparent;
    color: var(--lightGrey);
}
  

 .fa-close{
    // position:absolute;
    // top:0;
    // margin: 0 50%;
    // margin-bottom: 1%;
    opacity:0.9;
    transition:0.5s all;
    cursor: pointer;
    font-size:1.5rem;
    color: var(--lightGrey);

    &:hover{
     opacity:1;
     transition:0.5s all;
    }
 }

 
 .loading{
  color: var(--lightGrey);
  font-family: 'Karla', sans-serif;
  font-size:0.9rem;
}

.player-warpper{
  background: rgba(0,0,0,0.9) !important;
}

.num{
  color: var(--lightGrey);
  font-size:0.85rem;
  z-index:1111;
 }


//  .num-sm{
//   color: var(--lightGrey);
//   font-size:0.8rem;
//   z-index:1111;
//  }

 .current{
   color: var(--mainWhite);
 }

 .test{
  color: var(--lightGrey);
  font-size:0.85rem;
  z-index:1111;
 }

`;

