import React,{useState,useEffect} from 'react'
import styledComponents from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShuffle } from '@fortawesome/free-solid-svg-icons';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faFireAlt } from '@fortawesome/free-solid-svg-icons';

export const SelectVideo = ({selectedHaderHandler,selectedHeader}) => {

    const [selected,setSelected]=useState('');

    useEffect(()=>{
      setSelected(selectedHeader);
  },[selectedHeader]);

    const selects=['random sort','sort by recently added','sort by popularity','sort by trending'];
  
    const selectedHandler=(header)=>{
        setSelected(header);
        selectedHaderHandler(header);
    }

  return (
     <SelectVideoContainer>
         <div className='container'>
                 <div className='row'>
                    <div className='col-8 col-md-12 col-xl-10 col-lg-12 col-sm-10 mx-auto buttons'>
                      <div className='row'>
                        {
                          selects.map((s,index)=>{
                           return( <React.Fragment key={index}>
                                 <div className={'col-12 col-md-6 col-sm-12 col-lg-3 mx-auto select-btn text-uppercase text-center '+(selected===s && 'selected')}
                                 onClick={()=>selectedHandler(s.toLowerCase())}>

                                 { (s==='random sort') && <React.Fragment> <FontAwesomeIcon className="fa-shuffle icon px-2" icon={faShuffle}/> <label className='select-name'>{s}</label> </React.Fragment> }
                                  
                                 { (s==='sort by recently added') && <React.Fragment> <FontAwesomeIcon className="fa-clock icon px-2" icon={faClock}/> <label className='select-name'>{s}</label> </React.Fragment> }

                                 { (s==='sort by popularity') && <React.Fragment> <FontAwesomeIcon className="fa-eye icon px-2" icon={faEye}/> <label className='select-name'>{s}</label> </React.Fragment> }

                                 { (s==='sort by trending') && <React.Fragment> <FontAwesomeIcon className="fa-eye icon px-2" icon={faFireAlt}/> <label className='select-name'>{s}</label> </React.Fragment> }
                                  </div> 
                                  </React.Fragment>
                            );
                          })
                        }
                      </div>
                    </div>
                 </div>
        </div>
     </SelectVideoContainer>
  )
}

const SelectVideoContainer=styledComponents.div`


background: var(--lightBlack);


.select-btn{
  background: linear-gradient(0deg, #343434, #4b4b4b);
  cursor:pointer;
  border-radius:0.3rem;
  transition: opacity 0.8s ease;
  padding: 0.5rem 0;
  transform: scale(0.9);
  padding:0 !important;
  margin: 0.3rem 0 0 0!important;

  &:hover{
    background: var(--lightOrange);
    transition: opacity 0.8s ease;
  }
}


.select-name{
  color: var(--mainWhite);
  // font-family: 'Karla', sans-serif;
  font-family: 'Ubuntu', sans-serif;
  font-size:0.8rem;
  cursor:pointer;
  margin: 0.6rem 0;
}

.icon{
  color: var(--mainWhite);
  font-size:0.9rem;
  cursor:pointer;
}

.selected{
  background: var(--lightOrange2);
  transition: opacity 0.8s ease;
  .select-name{
    color: var(--darkBlue);
  }
  .icon{
    color: var(--darkBlue);
  }
  &:hover{
    background: var(--lightOrange2);
    transition: opacity 0.8s ease;
  }
}



`