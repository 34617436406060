import React,{useEffect,useState} from 'react'
import axios from 'axios';
import {isMobile,isBrowser} from 'react-device-detect';

export const LiveCam2 = ({item}) => {

      const openLiveSex = async (url) => {

        try {
          
          const response = await axios.get('https://ipapi.co/json/');
          const data = response.data;
          const country = data.country_name;
      
          await trackAffiliateOffer('CHL103', 'LIVE-SEX-RevShare', 'RevShare', 'bongacams', url, country);
      
          return (url) && window.open(url);

        } catch (error) {

          console.error('Error fetching country:', error);
      
          await trackAffiliateOffer('CHL103', 'LIVE-SEX-RevShare', 'RevShare', 'bongacams', url,'');
      
          return (url) && window.open(url);
        }
      };
      

      const trackAffiliateOffer= async (id,name,type,provider,link,country)=>{

        const res = axios.get(`https://hutils.loxal.net/whois`);
        const ip = (await res).data.ip;
      
        let device="undetected";
      
        if (isMobile)
         device="mobile";
      
        if (isBrowser)
         device="desktop";
        
      
        const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
          affiliateLink:link,deviceType:device,ipAddress:ip,country:country};
      
          const save_url=process.env.REACT_APP_AFFILIATE;
      
          axios.post(`${save_url}`,detail,{
              headers:{
                  'Content-Type':'application/json',
               //   'Authorization':'bearer '.concat(token)
              }
          });
      
      }

      const [cam,setCam]=useState({display_name:'',image_url:'',chat_room_url_revshare:'',age:0});

      useEffect(()=>{
       
        setBongaCams(item);

      },[item]);

      const setBongaCams=(item)=>{
       setCam({display_name:item.display_name,image_url:item.profile_images.profile_image,chat_room_url_revshare:item.chat_url,age:item.display_age});
      }

  return (
    <React.Fragment>
    <div className="col-6 col-lg-2 col-sm-2 col-md-3 mx-auto live" onClick={()=>openLiveSex(cam.chat_room_url_revshare)}>

    {  (cam.display_name) && <div className="card">

    <div className="img-container hoverable-image">
   { (cam.image_url) && <img src={cam.image_url} alt={`${cam.display_name} - Live Sex Cams | BongaCams`} className="card-img-top" style={{width:'100%',height:'11rem'}}/> }
      </div>

      <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 status text-start px-1">
       { (cam.display_name) && <span className='now'>Live</span> }
       </div>

       <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 name-flag d-flex px-2">

       <div className="col-8 mx-auto col-md-8 col-lg-8 col-sm-8 name-div text-start">
         { (cam.display_name && cam.display_name.length<=14) && <label className='name'>{cam.display_name.substring(0,14)}</label> }
         { (cam.display_name && cam.display_name.length>14) && <label className='name'>{`${cam.display_name.substring(0,14)}...`}</label> }
       </div>

       <div className="col-4 mx-auto col-md-4 col-lg-4 col-sm-4 flag-div text-end">
       {/* <img src={item.country_flag} alt={item.name} className="card-img-top" style={{width:'25%',height:'1rem'}}/>  */}
        { (cam.age) && <label className='age'>{cam.age}</label> }
       </div>

       </div>
          
      </div> }
    </div>
    </React.Fragment>
  )
}
