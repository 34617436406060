import React,{useEffect,useState} from 'react'
import axios from 'axios';
import {isMobile,isBrowser} from 'react-device-detect';
import ReactCountryFlag from "react-country-flag";

export const LiveCam3 = ({item}) => {


      const openLiveSex = async (url) => {

        try {

          const response = await axios.get('https://ipapi.co/json/');
          const data = response.data;
          const country = data.country_name;
      
          await trackAffiliateOffer('CHL136', 'LIVE-SEX-RevShare', 'RevShare', 'stripchat', url, country);
      
          return (url) && window.open(url);

        } catch (error) {

          console.error('Error fetching country:', error);
      
          await trackAffiliateOffer('CHL136', 'LIVE-SEX-RevShare', 'RevShare', 'stripchat', url,'');
      
          return (url) && window.open(url);
        }
      };
      

      const trackAffiliateOffer= async (id,name,type,provider,link,country)=>{

        const res = axios.get(`https://hutils.loxal.net/whois`);
        const ip = (await res).data.ip;
      
        let device="undetected";
      
        if (isMobile)
         device="mobile";
      
        if (isBrowser)
         device="desktop";
        
      
        const detail={affiliateId:id,affiliateName:name,affiliateType:type,affiliateProvider:provider,
          affiliateLink:link,deviceType:device,ipAddress:ip,country:country};
      
          const save_url=process.env.REACT_APP_AFFILIATE;
      
          axios.post(`${save_url}`,detail,{
              headers:{
                  'Content-Type':'application/json',
               //   'Authorization':'bearer '.concat(token)
              }
          });
      
      }

      const [cam,setCam]=useState({display_name:'',image_url:'',chat_room_url_revshare:'',countryCode:''});

      useEffect(()=>{
       
        setStripChat(item);

      },[item]);

      const setStripChat=(item)=>{
          const revshare_url = `https://stripchat.com/${item.username}?campaignId=xcelebs-live&sourceId=xcelebs.org&userId=11d347350c44824625b893d1b5e6f2eeef888388b45ff2095b02b8dfc212bf84`;
          setCam({display_name:item.username,image_url:item.snapshotUrl,chat_room_url_revshare:revshare_url,countryCode:item.modelsCountry});
      }

  return (
    <React.Fragment>
    <div className="col-6 col-lg-2 col-sm-2 col-md-3 mx-auto live" onClick={()=>openLiveSex(cam.chat_room_url_revshare)}>

    {  (cam.display_name) && <div className="card">

    <div className="img-container hoverable-image">
   { (cam.image_url) && <img src={cam.image_url} alt={`${cam.display_name} - Live Sex Cams | Stripchat`} className="card-img-top" style={{width:'100%',height:'11rem'}}/> }
      </div>

      <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 status text-start px-1">
       { (cam.display_name) && <span className='now'>Live</span> }
       </div>

       <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 name-flag d-flex px-2">

       <div className="col-8 mx-auto col-md-8 col-lg-8 col-sm-8 name-div text-start">
         { (cam.display_name && cam.display_name.length<=14) && <label className='name'>{cam.display_name.substring(0,14)}</label> }
         { (cam.display_name && cam.display_name.length>14) && <label className='name'>{`${cam.display_name.substring(0,14)}...`}</label> }
       </div>

       <div className="col-4 mx-auto col-md-4 col-lg-4 col-sm-4 flag-div text-end">
        { (cam.countryCode) && <span className='flag-img'>  <ReactCountryFlag
     countryCode={cam.countryCode}
     svg
     style={{
         width: '2em',
         height: '0.8em',
     }}
     title={cam.countryCode}
 />
 </span> }
       </div>

       </div>
          
      </div> }
    </div>
    </React.Fragment>
  )
}
