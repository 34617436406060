import React,{useEffect,useState} from 'react';
import axios from 'axios';
import styledComponents  from 'styled-components';
import { Footer } from '../footer/Footer';
import { TopBar } from '../Index/TopBar/TopBar';
import { TopBarList } from '../Index/TopBar/TopBarList';
import { ConnectionStatus } from '../internet-connection/ConnectionStatus';
import { VideoLoadingList } from '../loading/VideoLoadingList';
import { CamBoard } from './CamBoard';
import { LiveCam } from './LiveCam';
import { LiveCam2 } from './LiveCam2';
import { Helmet } from 'react-helmet';
import { LiveCam3 } from './LiveCam3';

export const LivePage = () => {

  // const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Free Live Adult Sex Cams, Live Sex and Free Sex Chat with Naked Girls | Xcelebs Live`);
  const [pageDescription, setPageDescription] = useState(`Watch Naked Girls in our Adult Live Sex Cams Community. ❤️ No Registration Needed. 5000+ LIVE Cam Girls and Couples are Ready to Chat.`);

    const [lives_1,setLives_1] = useState([]);
    const [lives_2,setLives_2] = useState([]);
    const [lives_3,setLives_3] = useState([]);

    const [ipAddrs,setIpAddrs]=useState('');

    const types = ['girls','couples','guys','trans'];

    const [selectedType,setSelectedType]=useState('girls');

    const [selectedCam,setSelectedCam]=useState('chaturbate v1');

    const [loading,setLoading] = useState(true);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const changeCamHandler=(name)=>{
      setSelectedCam(name);
    }

    const changeCamTypeHandler=(name)=>{
      setSelectedType(name);
    }

    const [isShow,setIsShow]=useState(false);

    const getIpAddress=async ()=>{
        // const res =  axios.get('https://geolocation-db.com/json/');
        // setIpAddrs((await res).data.IPv4);
        // setIpStatus(true);
        const res = axios.get(`https://hutils.loxal.net/whois`);
        setIpAddrs((await res).data.ip);
      }

      const getChaturbateApi_V1=(ip,type)=>{

          var gender='f';

          if(type==='girls')
          gender='f';
          if(type==='couples')
          gender='c';
          if(type==='guys')
          gender='m';
          if(type==='trans')
          gender='t';

          return `https://chaturbate.com/api/public/affiliates/onlinerooms/?wm=zSzgu&client_ip=${ip}&format=json&limit=102&gender=${gender}`;
      }

      const getChaturbateApi_V2=(ip,type)=>{

        var gender='f';

        if(type==='girls')
        gender='f';
        if(type==='couples')
        gender='c';
        if(type==='guys')
        gender='m';
        if(type==='trans')
        gender='t';

        return `https://chaturbate.com/api/public/affiliates/onlinerooms/?wm=zSzgu&client_ip=${ip}&format=json&offset=102&limit=102&gender=${gender}`;
    }

      const getStripchatApi_V1=(type)=>{

        var gender='female';

          if(type==='girls')
          gender='female';
          if(type==='couples')
          gender='maleFemale';
          if(type==='guys')
          gender='male';
          if(type==='trans')
          gender='tranny';

          return `https://go.xlirdr.com/api/models?limit=102&gender=${gender}`;
      }

      const getStripchatApi_V2=(type)=>{

        var gender='female';

          if(type==='girls')
          gender='female';
          if(type==='couples')
          gender='maleFemale';
          if(type==='guys')
          gender='male';
          if(type==='trans')
          gender='tranny';

          return `https://go.xlirdr.com/api/models?offset=102&limit=102&gender=${gender}`;
      }

      const getBongaCamsApi_V1=(ip,type)=>{

        var gender='straight';

        if(type==='girls')
        gender='straight';
        if(type==='couples')
        gender='couples';
        if(type==='guys')
        gender='gay';
        if(type==='trans')
        gender='transsexual';

        return `https://bcprm.com/api/v2/models-online?c=768913&client_ip=${ip}&section=${gender}&limit=102`;

      }

      const getBongaCamsApi_V2=(ip,type)=>{

        var gender='straight';

        if(type==='girls')
        gender='straight';
        if(type==='couples')
        gender='couples';
        if(type==='guys')
        gender='gay';
        if(type==='trans')
        gender='transsexual';

        return `https://bcprm.com/api/v2/models-online?c=768913&client_ip=${ip}&section=${gender}&offset=102&limit=102`;

      }

      useEffect(() => {

        const handleOnline = () => {
          setIsOnline(true);
        };
    
        const handleOffline = () => {
          setIsOnline(false);
        };
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
        };

      }, []);

      useEffect(() => {

    
            getIpAddress();
      
            let live_api=`https://chaturbate.com/api/public/affiliates/onlinerooms/?wm=zSzgu&client_ip=${ipAddrs}&format=json&limit=102&gender=f`;
            
            if (selectedCam.toLowerCase() === 'chaturbate v1')
              live_api = getChaturbateApi_V1(ipAddrs, selectedType.toLowerCase());
            if (selectedCam.toLowerCase() === 'chaturbate v2')
              live_api = getChaturbateApi_V2(ipAddrs, selectedType.toLowerCase());
            if (selectedCam.toLowerCase() === 'bongacams v1')
              live_api = getBongaCamsApi_V1(ipAddrs, selectedType.toLowerCase());
            if (selectedCam.toLowerCase() === 'bongacams v2')
              live_api = getBongaCamsApi_V2(ipAddrs, selectedType.toLowerCase());
            if (selectedCam.toLowerCase() === 'stripchat v1')
              live_api = getStripchatApi_V1(selectedType.toLowerCase());
            if (selectedCam.toLowerCase() === 'stripchat v2')
              live_api = getStripchatApi_V2(selectedType.toLowerCase());
      
            setLoading(true);
      
            if (!isOnline) 
            return;
      
            axios.get(`${live_api}`)
            .then(res=>{
              if((selectedCam.toLowerCase()==='chaturbate v1') || (selectedCam.toLowerCase()==='chaturbate v2'))
               setLives_1(res.data.results);
              if((selectedCam.toLowerCase()==='bongacams v1') || (selectedCam.toLowerCase()==='bongacams v2'))
               setLives_2(res.data.models);
              if((selectedCam.toLowerCase()==='stripchat v1') || (selectedCam.toLowerCase()==='stripchat v2'))
               setLives_3(res.data.models);
    
              setLoading(false);
    
            }).catch(error=>{
               console.log(error);
               setLoading(false);
            });
    
      }, [ipAddrs,selectedCam,selectedType,isOnline]);
      

      const selectedChaturbate=(camType)=>{

        if((camType.toLowerCase()==='chaturbate v1') || (camType.toLowerCase()==='chaturbate v2'))
        return true;
        return false;

      }

      const selectedBongaCam=(camType)=>{
      
        if((camType.toLowerCase()==='bongacams v1') || (camType.toLowerCase()==='bongacams v2'))
        return true;
        return false;

      }

      const selectedStripChat=(camType)=>{
      
        if((camType.toLowerCase()==='stripchat v1') || (camType.toLowerCase()==='stripchat v2'))
        return true;
        return false;

      }

      useEffect(()=>{

        let select = 'Chaturbate';

        select = (selectedCam && selectedCam.includes("chaturbate")) && "Chaturbate";

        select = (selectedCam && selectedCam.includes("bongacams")) && "BongaCams";

        select = (selectedCam && selectedCam.includes("stripchat")) && "Stripchat";

        var check = document.getElementById('checkMenu');
        check.checked = false;
  
        window.scrollTo(0, 0);
        const title=`Free Live Adult Sex Cams, Live Sex and Free Sex Chat with Naked Girls | Xcelebs Live | ${(select) && select}`;
        const desc=`Watch Naked Girls in our Adult Live Sex Cams Community. ❤️ No Registration Needed. 5000+ LIVE Cam Girls and Couples are Ready to Chat.`;

        setPageTitle(title);
        setPageDescription(desc);
        document.title = title;
        document.body.style.zoom="100%";
       
      },[selectedCam]);


      const changeCheckHandler=(flag)=>{
        setIsShow(flag);
        var check = document.getElementById('checkMenu');
        check.checked = flag;
    }

  return (
      <LiveComponent>
         <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        {/* <link rel="canonical" href={canonicalUrl} /> */}
      </Helmet>
      <div className="container-fluid">
          <div className="row">

          <input type="checkbox" id="checkMenu"/>

          <div className='top-bar'>
       <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
       </div>

       <div className='top-bar-list'>
                             <TopBarList/>
                             </div>

          {/* <div className="col-12 col-lg-12 col-sm-12 col-md-12 mx-auto live-title text-start">
              <h1 className='title'>Top Free Live Sex Cams</h1>
            </div> */}

            <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto select-cam mt-3 mb-2'>
              <CamBoard changeCamHandler={changeCamHandler} selected={selectedCam}/>
             </div>

             <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto select-types mb-4 px-4'>
                 <div className="row">
                     {
                         (types) && types.map((type,index)=>{
                            return( <React.Fragment key={index}>

                                    <div className={"type text-center my-2 text-capitalize "+(type.toLowerCase()===selectedType && 'selected-type')} onClick={()=>changeCamTypeHandler(type.toLowerCase())}>
                                  <span className='type-name'>{type}</span>
                                    </div>
                                    
                                    </React.Fragment>);
                         })
                     }
                 </div>
             </div>

            <div className="col-12 col-lg-12 col-sm-12 col-md-12 mx-auto lives px-4 d-none d-lg-block">
              <div className="row">
                {
                 (!loading && lives_1 && selectedChaturbate(selectedCam)) && lives_1.map(item=><LiveCam key={item.username} item={item}/>)
                }
                {
                 (!loading && lives_2 && selectedBongaCam(selectedCam)) && lives_2.map(item=><LiveCam2 key={item.username} item={item}/>)
                }
                 {
                 (!loading && lives_3 && selectedStripChat(selectedCam)) && lives_3.map(item=><LiveCam3 key={item.username} item={item}/>)
                }
              </div>
           </div> {/*lives*/}

           <div className="col-12 col-lg-12 col-sm-12 col-md-12 mx-auto lives px-3 d-lg-none">
              <div className="row">
                {
                 (!loading && lives_1 && selectedChaturbate(selectedCam)) && lives_1.map(item=><LiveCam key={item.username} item={item}/>)
                }
                 {
                 (!loading && lives_2 && selectedBongaCam(selectedCam)) && lives_2.map(item=><LiveCam2 key={item.username} item={item}/>)
                }
                 {
                 (!loading && lives_3 && selectedStripChat(selectedCam)) && lives_3.map(item=><LiveCam3 key={item.username} item={item}/>)
                }
              </div>
           </div> {/*lives-sm*/}

           {/* { ( selectedChaturbate(selectedCam) && lives_1==undefined && !loading) && <Block/>}
           { ( selectedBongaCam(selectedCam) && lives_2==undefined && !loading) && <Block/>} */}

           {
        (loading) && <VideoLoadingList/>
          }

           <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div mt-5'>
                  <Footer/>
       </div>

          </div>
      </div>

      <ConnectionStatus/>

      </LiveComponent>
  )
}

const LiveComponent=styledComponents.div`

background: var(--lightBlack);

.live-title .title{
    color: var(--mainWhite);
    font-size: 1.5rem;
    //font-family: 'Oswald', sans-serif;
    font-family: 'Karla', sans-serif;
  }

  .lives .live{
    position:relative;
    padding:0 !important;
    margin:0 !important;
    transform: scale(0.97);
    cursor:pointer;
  }
  
  
  .live .card{
    background: transparent;
  }
  
  .live .status{
    position: absolute;
    top:-0.5%;
  
    .now{
      background: var(--lightGreen);
      padding: 0.1rem 0.4rem 0.1rem 0.4rem;
      font-family: 'Karla', sans-serif;
      color: var(--mainWhite);
      border-radius:0.2rem;
      font-size:0.8rem;
    }
  }
  
  .live .name-flag{
    position: absolute;
    top:85%;
  
    .name{
      color: var(--mainWhite);
      font-family: 'Karla', sans-serif;
      cursor:pointer;
    }
  
    .age{
      color: var(--mainWhite);
      font-family: 'Karla', sans-serif;
      background: rgba(0,0,0,0.7);
      padding: 0.1rem 0.2rem 0rem 0.2rem;
      font-size:0.8rem;
      cursor:pointer;
    }
  
  }

  .select-cam{
    padding:0 !important;
   // margin:0 !important;
  }
  
  .type{

    width: auto;
    //background: var(--lightDark2);
    background-color: #272727;
    padding:0.2rem 1.3rem;
    transform: scale(0.8);
    cursor:pointer;
    display:inline-block;
    border-radius:0.3rem;
    transition: 0.5s all;
    //  margin: 0.3rem 1.2rem;
     text-align: center;
  
  }

  .selected-type{
    background: var(--lightGreen);
  }
  
  .type .type-name{
   // color: var(--mainWhite);
    color: #fff;
    font-family: 'Open Sans', sans-serif; 
    // font-family: 'Karla', sans-serif;
    //font-family: 'Josefin Sans', sans-serif;
    font-weight:bold;
    font-size:0.9rem;
    letter-spacing: 0.02rem;
  }

  #checkMenu{
    display:none;
  }

  @media (min-width: 1024px) {
    .top-bar {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  .top-bar{
    // padding:0 !important;
    // margin:0 !important;
  }

  .top-bar-list{
    padding:0 !important;
    margin:0 !important;
    overflow: hidden;
    position:relative;
    max-height: 0;
    transition: max-height 0.5s ease;
  }
  
  #checkMenu:checked ~ .top-bar-list{
    max-height: 300px;
    transition: max-height 0.5s ease;
  }

  .footer-div{
    padding:0 !important;
  //  margin:0 !important;
  }

  .hoverable-image {
    position: relative;
  }
  
  .hoverable-image::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 28px; /* Adjust the height as needed */
    background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for transparency */
  }

  @media (max-width: 990px) {
    .select-stripchat{
      margin-right: 1rem;
    }
  }

`;