import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const ForgetEmail = ({setEmailHandler,email,errorMsg,hasError,checkOtp,sentEmail,message}) => {

    // const inputRef = useRef();

    // useEffect(()=>{
       
    //     if(inputRef.current!==null)
    //          inputRef.current.focus();
    // },[])

    return (
        <EmailContainer>
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto email-ctrl text-center">
                      <div className="form-group">
                      <FontAwesomeIcon className="fa-email icon" icon={faEnvelope}/>
                      <input type="text" className="form-control" placeholder="Email" name="email"
                      value={email} onChange={(e)=>setEmailHandler(e)} disabled={checkOtp}/>
                      { (sentEmail) && <div className="msg text-success my-1">{message}</div> }
                     { (hasError) && <div className="error text-danger my-1">{errorMsg}</div> }
                     </div>
                      </div>
                  </div>
              </div>
        </EmailContainer>
    );
}

const EmailContainer=styled.div`

.email-ctrl .form-control{
    background: transparent;
    box-shadow: none;
    border:none;
    border: 1px solid var(--mainBlue);
    padding-left:2rem;

    &:focus{
        border: 1px solid var(--lightGrey);
    }

    ::placeholder {
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif;
        opacity:0.6;
        font-size:0.9rem;
    }

    ::-ms-input-placeholder { 
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif;
        opacity:0.6;
        font-size:0.9rem;
      }
}

.email-ctrl input{
    color: var(--lightGrey);
    font-family: 'Open Sans', sans-serif;
    font-size:0.9rem;
}

.email-ctrl .form-group{
    position: relative;
}

.email-ctrl .icon{
    position:absolute;
    left:0.6rem;
    top:0.7rem;
    color: var(--lightBlue);
}

.error{
    font-family: 'Open Sans', sans-serif;
    font-size:0.8rem;
}

.msg{
    font-family: 'Open Sans', sans-serif;
    font-size:0.9rem;
}


`;