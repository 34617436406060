import React, { useEffect, useState } from 'react';
import styledComponents  from 'styled-components';
import { Live } from './Live';
import axios from 'axios';

export type Item={
  username:string,
  display_name:string
  image_url:string,
  chat_room_url_revshare:string,
  age:number
}

export const LiveBoard = () => {

  const [lives,setLives] = useState<Item[]>();

  const [ipAddrs,setIpAddrs]=useState<string>('');
  // const [ipStatus,setIpStatus]=useState<boolean>(false);

  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {

    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };

  }, []);


  const getIpAddress=async ()=>{
    // const res =  axios.get('https://geolocation-db.com/json/');
    // setIpAddrs((await res).data.IPv4);
    // setIpStatus(true);
    const res = axios.get(`https://hutils.loxal.net/whois`);
    setIpAddrs((await res).data.ip);
  }

  useEffect(() => {

            getIpAddress();

            const live_api=`https://chaturbate.com/api/public/affiliates/onlinerooms/?wm=zSzgu&client_ip=${ipAddrs}&format=json&limit=12&gender=f`;

            axios.get(`${live_api}`)
            .then(res=>{
                 setLives(res.data.results);
                 //setLoading(false);
            }).catch(error=>{
               console.log(error);
              // setLoading(false);
            });

}, [ipAddrs, isOnline]);


  return (
    <React.Fragment>
    { (ipAddrs) && <LiveComponent className='mb-5'>
           <div className="col-12 col-lg-12 col-sm-12 col-md-12 mx-auto live-title text-start mb-3">
             { (ipAddrs) && <h2 className='title'>Live Chat With Girls</h2> }
           </div> {/* lives */}

           <div className="col-12 col-lg-12 col-sm-12 col-md-12 mx-auto lives px-2 d-none d-lg-block">
              <div className="row">
                {
                 (lives) && lives.slice(0,12).map(item=><Live key={item.username} item={item}/>)
                }
              </div>
           </div> {/*lives-lg*/}

           <div className="col-12 col-lg-12 col-sm-12 col-md-12 mx-auto lives px-1 d-md-block d-none d-lg-none">
              <div className="row">
                {
                 (lives) && lives.slice(0,8).map(item=><Live key={item.username} item={item}/>)
                }
              </div>
           </div> {/*lives-md*/}

           <div className="col-12 col-lg-12 col-sm-12 col-md-12 mx-auto lives px-1 d-md-none">
              <div className="row">
                {
                 (lives) && lives.slice(0,10).map(item=><Live key={item.username} item={item}/>)
                }
              </div>
           </div> {/*lives-sm*/}

        
    </LiveComponent> }

    </React.Fragment>
  )
}

const LiveComponent=styledComponents.div`

background: var(--lightBlack);

.live-title .title{
  color: var(--mainWhite);
  font-size: 1.5rem;
  //font-family: 'Oswald', sans-serif;
  font-family: 'Karla', sans-serif;
}

.lives .live{
  position:relative;
  padding:0 !important;
  margin:0 !important;
  transform: scale(0.97);
  cursor:pointer;
}


.live .card{
  background: transparent;
}

.live .status{
  position: absolute;
  top:-0.5%;

  .now{
    background: var(--lightGreen);
    padding: 0.1rem 0.4rem 0.1rem 0.4rem;
    font-family: 'Karla', sans-serif;
    color: var(--mainWhite);
    border-radius:0.2rem;
    font-size:0.8rem;
  }
}

.live .name-flag{
  position: absolute;
  top:85%;

  .name{
    color: var(--mainWhite);
    font-family: 'Karla', sans-serif;
    cursor:pointer;
  }

  .age{
    color: var(--mainWhite);
    font-family: 'Karla', sans-serif;
    background: rgba(0,0,0,0.7);
    padding: 0.1rem 0.2rem 0rem 0.2rem;
    font-size:0.8rem;
    cursor:pointer;
  }

}

.hoverable-image {
  position: relative;
}

.hoverable-image::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 28px; /* Adjust the height as needed */
  background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for transparency */
}

`;