import React,{useState} from 'react';
import styled from 'styled-components';
import { Item } from './Item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faReddit } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';


export const Footer = () => {

    const category = ['celebrities','pornstars','hentai','MILF','live sex','Live VR Sex Cams','BDSM'];
    const featured=['Top 100 Videos of 2023','Top 100 Celebs of all Time','Xcelebs Models','Free OnlyFans Creators','Premium OnlyFans Creators','Top OnlyFans Models'];
    const info = ['2257 Exempt','Contact Us','DMCA','Privacy Policy','Terms of Service','Community Guidelines'];


    return (
       
        <FooterContainer>

        <div className="container-fluid mb-3 px-4">
            <div className="row">

                <div className="col-12 mx-auto col-md-11 col-lg-11 col-sm-11 footer mt-5">

                    <div className="row">

                <div className="col-6 mx-auto col-md-4 col-lg-2 col-sm-6 category text-capitalize">

                <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 header px-2">
                     <h6 className="name">category</h6>
                </div>

                <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 items px-3">
                  
                    {
                        category.map((item,index)=><Item key={index} item={item} type="ctg"/>)
                    }
                </div>   

                </div>    {/*category*/}

                <div className="col-6 mx-auto col-md-4 col-lg-2 col-sm-6 featured d-none d-md-block">

                <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 header px-2">
                     <h6 className="name">Featured</h6>
                </div>

                <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 items px-3">

                    {
                        featured.map((item,index)=><Item key={index} item={item} type="featured"/>)
                    }

               </div>

                </div>  {/*featured*/}

                <div className="col-6 mx-auto col-md-4 col-lg-2 col-sm-6 info">

                <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 header px-2">
                     <h6 className="name">Info</h6>
                </div>

                <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 items px-3">

{
    info.map((item,index)=><Item key={index} item={item} type="info"/>)
}

                  </div>

                </div> {/*info*/}


                <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 featured d-md-none mt-3">

<div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 header px-2">
     <h6 className="name">Featured</h6>
</div>

<div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 items px-3">

    {
        featured.map((item,index)=><Item key={index} item={item} type="featured"/>)
    }

</div>

</div>  {/*featured*/}

                
                <div className="col-12 mx-auto col-md-12 col-lg-6 col-sm-12 about my-4">

                     <div className='rta-logo mb-4'>
                         <img src='https://i.ibb.co/V3LCn5d/rta-1.gif' alt="Xcelebs RTA Logo" width={'90rem'} height={'35rem'}/>
                     </div>

                    <h6 className="title"><label className='x'>X</label>celebs</h6>

                    <p className="disclaimer">Xcelebs has a global mission to organize celebrity nudity from television
                     and make it universally free, accessible, and usable. We have a free collection of nude celebs
                      and movie sex scenes, which include naked celebrities, lesbians, tits, underwear and butt pics,
                     sexy scenes from movies and series, nude and real sex celeb videos.</p>

                    <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 icons text-center">


                       <a href="https://twitter.com/xcelebs_org/"><FontAwesomeIcon icon={faTwitter} className="icon"/></a>
                       <a href="https://www.reddit.com/r/celebmate/"><FontAwesomeIcon icon={faReddit} className="icon"/></a>
                       <a href="https://www.instagram.com/xcelebs_org/"><FontAwesomeIcon icon={faInstagram} className="icon"/></a>
                       <a href="https://www.youtube.com/"><FontAwesomeIcon icon={faYoutube} className="icon"/></a>

                    </div>    


                </div> {/*about*/}

                <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 copyright mt-4 text-end px-5">

                    <p className="value">
                          © 2024 Xcelebs. All Rights Reserved
                    </p>
 
                </div>

              
                </div> {/*footer*/}

                </div>

            </div>
        </div>
        </FooterContainer>

       
    );
}

const FooterContainer = styled.div`

background: var(--mainDark);


.header{
    margin:0;
    padding:0;

    .name{
        color: var(--mainWhite);
        font-family: 'Open Sans', sans-serif; 
        font-size: 1.1rem;
        font-weight:bold;
        letter-spacing:0.1em;
        user-select:none;

    }
}

.footer .category{
    margin:0;
    padding:0;

    .items{
        margin:0;
        padding:0;

    }
}

.item{
    margin:0;
   padding:0;

   .name{
       color: var(--lightGrey);
       font-family: 'Open Sans', sans-serif; 
       cursor:pointer;
       transition: all 0.5s ease;
       font-size:0.9rem;
       text-decoration: none;

       &:hover{
           color: var(--lightOrange);
       }
   }
}

.footer .featured{
    margin:0;
    padding:0;
}

.footer .info{
    margin:0;
    padding:0;
}

.footer .about{

    .title{
        color: var(--mainWhite);
        font-family: 'Open Sans', sans-serif; 
        font-size:1.2rem;
        font-weight:bold;
        letter-spacing:0.1em;

    }

    .x{
        color: var(--lightOrange);
    }

   

    .disclaimer{
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif; 
        font-size:0.9rem;
    }

    .icons{
        margin:0;
        padding:0;

        .brand-icon{
            padding: 0rem;
        }

        .icon{
            font-size:1.5rem;
            color: var(--mainWhite);
            cursor:pointer;
            margin: 1rem;
            padding: 0.4rem;
            transition: 0.5s ease-in-out;

            &:hover{
                background: var(--lightOrange);
                border-radius:50%;
                transition: 0.5s ease-in-out;
            }
        }
    }
}

.copyright{
    .value{
        color: var(--lightGrey);
        font-family: 'Open Sans', sans-serif; 
        font-size:0.8rem;
    }
}
   
`;