
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

export const BgImgCom = ({bgImg}) => {

   return (
    <div
    css={css`
          background-image: 
          linear-gradient(to bottom,rgba(12,13,16,0) 0%,rgba(12,13,16,.34) 34%,rgba(12,13,16,.42) 46%,rgba(12,13,16,.77) 68%,#08090b 100%),
           url('${bgImg}');
          //height:45vh;
         // height: calc(100vh - 60vh);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: relative;
          bottom:0;
          left:0;
          top:0;
          right:0;
          min-width:100%;
          padding:9rem 0;
          filter: blur(2px)
          
    `}
  />

   );
}
