import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { HomeButton } from './HomeButton';

export const NotFound = () => {

    const navigate = useNavigate();

    const redirectToHome=()=>{
        return navigate("/");
    }
    return (
       <ErrorContainer>
           <div className="container">
                 <div className="row">

                     <div className="col-12 col-md-12 col-sm-12 col-lg-12 not-found text-center my-5">
                          <p className="code">404</p>
                          <p className="msg">
                              <span className="h-msg">Oops!, sorry we couldn't find that page!</span>
                                 <br/>
                            <span className="s-msg">Either something went wrong or the page doesn't exist anymore.</span>
                          </p>

                         <div className="home-btn my-4">
                            <HomeButton onClick={()=>redirectToHome()}
                            >Back to Home</HomeButton>
                         </div>

                   </div>{/*not-found*/}

                </div>
           </div>
       </ErrorContainer>
    );
}


const ErrorContainer=styled.div`

background: var(--lightDark);

.not-found{

    .code{
        font-size:5.5rem;
        font-family: 'Open Sans', sans-serif;
        font-weight:bold;
        color: var(--lightGrey);
    }

    .h-msg{
        font-size:1.2rem;
        color: var(--mainWhite);
        font-family: 'Open Sans', sans-serif;
    }

    .s-msg{
        font-size:0.9rem;
        color: var(--mainWhite);
        font-family: 'Open Sans', sans-serif;
    }
}


`;
