import axios from 'axios';
import React,{useState,useEffect} from 'react'
import styledComponents from 'styled-components'
import { VideoLoadingList } from '../loading/VideoLoadingList';
import { Pagination } from './Pagination';
import { PlayerModal } from '../test/PlayerModal';
import { Video } from './Video';

export const CelebList = ({celebId,celebName}) => {

    const [pages,setPages]=useState(0);
    const [currentPage,setCurrentPage]=useState(1);
    const [currentBtn,setCurrentBtn]=useState(1);
    const [openPlayer,setOpenPlayer]=useState(false);
    const [activeIndex,setActiveIndex]=useState(0);

    const [videos,setVideos]=useState([]);
    const [loading,setLoading]=useState(true);
    const [videoId,setVideoId]=useState('');

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const itemPerPage=20;

    useEffect(() => {

      const handleOnline = () => {
        setIsOnline(true);
      };
  
      const handleOffline = () => {
        setIsOnline(false);
      };
  
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
  
      return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
      };

    }, []);

    useEffect(()=>{
           setCurrentBtn(1);
    },[celebId,celebName]);

 
    const currentPageHandler=page=>{
        setCurrentPage(page);
    }
 
    useEffect(()=>{
     document.body.style.zoom="100%";
    //  setPageLink(`/view/celeb/${celebId}/${celebName}`);
    //  navigate(`/view/celeb/${celebId}/${celebName}`);
      setCurrentPage(1);
     
   },[celebId,celebName]);

useEffect(() => {

  const fetchData = async () => {
    if (isNaN(currentBtn))  
      return;   

      if (currentBtn === 1) {
        window.scrollTo(0, 0);
    } else {
        if (window.innerWidth >= 1300) { 
            window.scrollTo(0, 345);
        }  else if (window.innerWidth >= 700) {
            window.scrollTo(0, 410);
        } else
            window.scrollTo(0, 490);
    }

    const video_url = process.env.REACT_APP_CELEB_VIDEO;

    setLoading(true);

    if (!isOnline)
      return;

    const name = (celebName) && celebName.replaceAll("-", " ");

    try {
      const response = await axios.get(`${video_url}/list?name=${encodeURIComponent(name)}&page=${currentPage}`);
      setVideos(response.data.celebVideoList);
      setPageHandler(response.data.numOfItems);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  fetchData();
}, [currentBtn,celebName,currentPage,isOnline]);


   const setPageHandler =length=>{
 
    const numOfItems=length;     
    let page = parseInt(numOfItems/itemPerPage);

    if(parseInt(numOfItems%20)!==0)
     page+=1;

     setPages(page);
   };

   const openPlayerHandler=(flag)=>{
     return setOpenPlayer(flag);
   }

   const activeIndexHandler=(index,id)=>{
     setActiveIndex(index);
     openPlayerHandler(true);
     setVideoId(id);
   }

  return (
     <CelebListContainer>

<div className="container-fluid">
                     <div className="row">

                     { (!loading) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 plays-list  plays-list-lg px-3 d-none d-lg-block">
                     <div className="row">

                      { (videos) && videos.map((video,index)=><Video key={video.videoId} item={video} activeIndexHandler={activeIndexHandler} index={index}/>)}

                        </div>
                      </div> }

                      { (!loading) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 plays-list plays-list-sm d-lg-none">
                     <div className="row">

                      { (videos) && videos.map((video,index)=><Video key={video.videoId} item={video} activeIndexHandler={activeIndexHandler} index={index}/>)}

                        </div>
                      </div> }

                    { (videos && videos.length>=1 && !loading) && <div className="col-12 mx-auto col-md-12 col-lg-12 col-xl-12 col-sm-12 pages mt-3">

<Pagination pages={pages} currentPageHandler={currentPageHandler}
         currentBtn={currentBtn} setCurrentBtn={setCurrentBtn}/> 
                  
                    </div>  }
               
               <div className='player-div-modal'>
               { (openPlayer && videoId) && <PlayerModal openPlayerHandler={openPlayerHandler} a_index={activeIndex} plays={videos} currentVideoId={videoId}/> }
               </div>

                  </div>
                  </div>

                  {
                        (loading) && <VideoLoadingList/>
                      }

     </CelebListContainer>
  )
}

const CelebListContainer=styledComponents.div`


background: var(--lightBlack);
position: relative;


// .plays-list .plays{
//   position:relative;
//   border: 3px solid transparent;
//   padding:0 !important;
//   margin:0 !important;
//   transform: scale(0.95);
//   transition:0.8s all;

//   &:hover{
//     transition:0.8s all;
//     cursor:pointer;
//     border: 3px solid var(--lightOrange);
//     .time-quality{
//       transition:0.8s all;
//       z-index:-1111;
//     }
//   }
// }



// .card{
//   background: transparent;
// }

// .card-footer{
//   padding:0 !important;
//   margin:0 !important;
// }

// .plays .title{

//   // position: absolute;
//   // //top:90%;
//   // z-index:1111;  
//     font-size:1rem;
//     font-family: 'Open Sans', sans-serif; 
//     color: var(--mainWhite);
//     transition:0.8s all;
// }

// .time-quality{
//   position: absolute;
//   top:1%;
//   z-index:1111;

//   .plays-time{

//     .duration{
//       background: var(--lightGrey);
//       padding: 0.2rem 0.5rem 0.2rem 0.5rem;
//       font-size:0.9rem;
//       color: var(--mainDark2);
//       font-family: 'Open Sans', sans-serif; 
//     //  font-weight:bold;
//     }

//   }

//   .plays-time-sm{

//     .duration{
//       background: var(--lightGrey);
//       padding: 0.2rem 0.3rem 0.2rem 0.3rem;
//       font-size:0.7rem;
//       color: var(--mainDark2);
//       font-family: 'Open Sans', sans-serif; 
//     //  font-weight:bold;
//     }
//   }

//     .plays-quality{

//       .resolution{
//         font-family: 'Open Sans', sans-serif; 
//         background: var(--lightGrey);
//         padding: 0.2rem 0.4rem 0.2rem 0.4rem;
//         font-size:0.9rem;
//         color: var(--lightRed);
//         font-weight:bold;
//       }
//     }

//     .plays-quality-sm{

//       .resolution{
//         font-family: 'Open Sans', sans-serif; 
//         background: var(--lightGrey);
//         padding: 0.2rem 0.3rem 0.2rem 0.3rem;
//         font-size:0.7rem;
//         color: var(--lightRed);
//         font-weight:bold;
//       }
//     }

//   }

.plays-list-sm{
  padding:0 !important;
  margin:0 !important;
}

.plays-list .plays{
  position:relative;
  border: 3px solid transparent;
  padding:0 !important;
  margin: 0 0 0 0 !important;
  transform: scale(0.96,0.95);
  transition:0.8s all;

  &:hover{
    transition:0.8s all;
    cursor:pointer;
   // border: 3px solid var(--lightOrange);
    .title{
      color: var(--lightOrange);
    }
    .title-sm{
      color: var(--lightOrange);
    }
    .video-views{
      // transition:0.8s all;
      z-index:-1111;
    }
    .time-quality{
      .time{
          transition:0.8s all;
          z-index:-1111;
      }
      .quality{
          // transition:0.8s all;
          z-index:-1111;
      }
    }
    .player-watermark{
      // transition:0.8s all;
      z-index:1111;
      opacity: 1;
    }
    .player-watermark-sm{
      // transition:0.8s all;
      z-index:1111;
      opacity: 1;
    }
  }
}

.card{
  background: transparent;
}

.card-footer{
  padding:0 !important;
  margin:0 !important;
}

.plays .title{

  // position: absolute;
  // //top:90%;
  // z-index:1111;  
    font-size:1rem;
    font-family: 'Open Sans', sans-serif; 
    color: var(--mainWhite);
    transition:0.8s all;
}

.plays .title-sm{

  // position: absolute;
  // //top:90%;
  // z-index:1111;  
    font-size:0.8rem;
    font-family: 'Open Sans', sans-serif; 
    color: var(--mainWhite);
    transition:0.8s all;
}

  

.time-quality{
  position: absolute;
  // top:-0.1%;
  bottom:4.2%;

  .time{
    z-index:1111;
    padding-left: 0.3rem;
  }

  .quality{
    z-index:1111;
    padding-right: 0.3rem;
  }

  .plays-time{

    .duration{
      background: var(--lightGrey);
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      font-size:0.7rem;
      color: var(--mainDark2);
      font-family: 'Open Sans', sans-serif; 
      font-weight:bold;
    }

  }

  .plays-time-sm{

    .duration{
      background: var(--lightGrey);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      font-size:0.6rem;
      color: var(--mainDark2);
      font-family: 'Open Sans', sans-serif; 
      font-weight:bold;
    }
  }

    .plays-quality{
      
      .resolution{
        font-family: 'Open Sans', sans-serif; 
        background: var(--lightGrey);
        padding: 0.2rem 0.4rem 0.2rem 0.4rem;
        font-size:0.7rem;
        color: var(--brightRed);
        font-weight:bold;
      }
    }

    .plays-quality-sm{

      .resolution{
        font-family: 'Open Sans', sans-serif; 
        background: var(--lightGrey);
        padding: 0.1rem 0.2rem 0.1rem 0.2rem;
        font-size:0.6rem;
        color: var(--brightRed);
        font-weight:bold;
      }
    }

  }

  @media (min-width: 768px) {
    .time-quality {
      bottom:4.5%;
    }
    .time-quality .time {
      padding-left: 0.4rem;
    }
    .time-quality .quality{
      padding-right: 0.4rem;
    }
  }

.film{
  .film-name {
    color: var(--lightGrey);
    font-size:0.8rem;
    font-family: 'Josefin Sans', sans-serif;
    transition:0.5s all;

    &:hover{
      transition:0.5s all;
      color: var(--mainWhite);
    }
  }

  .film-name-sm{
    color: var(--lightGrey);
    font-size:0.7rem;
    font-family: 'Josefin Sans', sans-serif;
    transition:0.5s all;

    &:hover{
      transition:0.5s all;
      color: var(--mainWhite);
    }
  }

}

.play-rate{
  color: var(--lightGreen);
  .fa-thumb{
   font-size:0.8rem;
  }
  .percentage{
    font-size:0.8rem;
  }
}

.video-views{
  position: absolute;
  // top:-0.1%;
  bottom:4.2%;
  z-index:1111;
  margin:0;
  padding:0;

  padding-right: 0.3rem;

  .view{

  .total-views{
      background: rgba(0,0,0,0.7);
      padding: 0.2rem 0.4rem 0.2rem 0.4rem;
      color: var(--mainWhite);
      font-size:0.7rem;
      font-family: 'Open Sans', sans-serif; 
  }
  .fa-views{
      font-size:0.8rem;
  }
}

.view-sm{

  .total-views{
      background: rgba(0,0,0,0.7);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      color: var(--mainWhite);
      font-size:0.6rem;
      font-family: 'Open Sans', sans-serif; 
  }
  .fa-views{
      font-size:0.7rem;
  }
}

} 

@media (min-width: 768px) {
  .video-views {
    bottom:4.5%;
    padding-right: 0.4rem;
  }
}

.player-watermark{
  position: absolute;
  top:1%;
  right:1%;
  z-index:-1111;
  opacity: 0;
  margin:0;
  padding:0;

  .watermark{

    .p-wm{
      background: rgba(0,0,0,0.9);
      padding: 0.1rem 0.4rem 0.1rem 0.4rem;
      color: var(--mainWhite);
      font-size:0.8rem;
      font-family: 'Open Sans', sans-serif; 
  }

  }

}

.player-watermark-sm{
  position: absolute;
  top:-0.1%;
  z-index:-1111;
  opacity: 0;
  margin:0;
  padding:0;

  .watermark{

    .p-wm{
      background: rgba(0,0,0,0.9);
      padding: 0.1rem 0.2rem 0.1rem 0.2rem;
      color: var(--mainWhite);
      font-size:0.6rem;
      font-family: 'Open Sans', sans-serif; 
  }

  }

}

`;
