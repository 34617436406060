import React from 'react';
import styledComponents from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link,useNavigate } from 'react-router-dom';

export const SearchList = () => {

    const searchs= ['Amateur','Anal','Arab','Asian','Ass','British','BTS','BDSM','Big Ass','Big Cock','Big Tits',
   'Black','Blonde','Blowjob','Brunette','Cam Porn','German','Indian','Latina','Mom','Teen','VR Porn','More'];

   const navigate = useNavigate();

  //  const redirectToTag=(tag)=>{
  //      if(tag==='more')
  //        return navigate('/tags');
  //       return navigate(`/browse/tags/videos/${tag}`);
  //  }

   const redirectToTag = (tag) => {
    if (tag === 'more') {
      return '/tags';
    } else {
      return `/browse/tags/videos/${tag}`;
    }
  }

  return (
    <SearchContainer>
          <div className='container'>
            <div className='row'>
                  <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto search-list'>
                  <div className='row'>
                      { searchs.map((s,index)=>{
                    return (<React.Fragment key={index}>
                     <Link to={redirectToTag(s.toLowerCase().replaceAll(' ','-'))} className={'col-1 col-md-2 col-lg-1 col-sm-1 mx-auto search text-center'}>
                         { (s.toLowerCase()==='more') ? <FontAwesomeIcon className='fa-plus icon my-2' icon={faPlus}/> :
                           <label className='name my-2'>{s}</label> }
                     </Link>
                     
                     </React.Fragment>
                       ); })
                      }
                      </div>
                  </div>
            </div>
    </div>
    </SearchContainer>
  )
}

const SearchContainer=styledComponents.div`

background: var(--lightBlack);

.search{
    background: var(--darkBlue);
    margin:0 !important;
    padding:0 !important;
    transform: scale(0.9);
    cursor:pointer;
    transition: 0.5s all;
    text-decoration: none;

    &:hover{
        .name{
        color: var(--lightGrey);
        transition: 0.5s all;
        }
        .icon{
            color: var(--lightGrey);
        transition: 0.5s all; 
        }
    }
}

.search .name{
    color: var(--mainWhite);
    font-family: 'Open Sans', sans-serif; 
    font-size:0.9rem;
    cursor:pointer;
}

.icon{
    color: var(--mainWhite);
    font-size:1.2rem;
}

`;