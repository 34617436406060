import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styledComponents from 'styled-components';
import { Alphabat } from '../category/Alphabat';
import { Footer } from '../footer/Footer';
import { TopBar } from '../Index/TopBar/TopBar';
import { Tags } from '../stars/Tags';
import { ForgetCode } from './ForgetCode';
import { ForgetEmail } from './ForgetEmail';
import { ForgetPwd } from './ForgetPwd';
import {Reset} from './Reset';
import { Alph } from '../test/Alph';
import { TopBarList } from '../Index/TopBar/TopBarList';
import { ConnectionStatus } from '../internet-connection/ConnectionStatus';
import { Helmet } from 'react-helmet';

export const ForgetPage = (props) => {

    // const canonicalUrl = window.location.href;

  const [pageTitle, setPageTitle] = useState(`Reset Password - Xcelebs`);
  const [pageDescription, setPageDescription] = useState(`Forgot password. Enter your email, or username and we'll send you a link to get back into your account.`);

    const [isShow,setIsShow]=useState(false);

    const [message,setMessage]=useState(null);
    const [email,setEmail]=useState('');
    const [sentEmail,setSentEmail]=useState(false);
    const [otp,setOtp]=useState('');
    const [checkOtp,setCheckOtp]=useState(false);
    const [pwd,setPwd]=useState('');
    const [c_pwd,setC_Pwd]=useState('');
    const [error,setError]=useState({email:'',pwd:'',c_pwd:''});
    const [hasError,setHasError]=useState(false);
    const [success,setSuccess]=useState(false);
    const [active,setActive]=useState(false);

    const siteKey = process.env.REACT_APP_SITE_KEY;

    const forget_url=process.env.REACT_APP_FORGET;

    useEffect(()=>{

        var check = document.getElementById('checkMenu');
        check.checked = false;

        window.scrollTo(0, 0);
        const title=`Reset Password - Xcelebs`;
        const description=`Forgot password. Enter your email, or username and we'll send you a link to get back into your account.`;
        document.body.style.zoom="100%";

        setPageTitle(title);
        setPageDescription(description);

        document.title = title;

        // const script = document.createElement('script');
        // script.src=`https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        // document.body.appendChild(script);
       
      },[]);

      const changeCheckHandler=(flag)=>{
        setIsShow(flag);
        var check = document.getElementById('checkMenu');
        check.checked = flag;
    }


    const setEmailHandler=e=>{
       const {value}=e.target;
       setEmail(value);
    }

    const setOtpHandler=e=>{
        const {value}=e.target;

        if(value.trim().length<=6){
        setCheckOtp(false);    
        setOtp(value);
        }

        if(value.trim().length==6)
             checkVerification(email,value);
    }

    const validationCheck=()=>{
         if(email.trim().length<=0){
             setHasError(true);
             setError({...error,email:'**Email is required**',pwd:'',c_pwd:''});
             return true;
         }
         if(!emailRegExp()){
             setHasError(true);
             setError({...error,email:'**Invalid Email**',pwd:'',c_pwd:''});
             return true;
         }
         if((checkOtp && pwd.trim().length<=0)){
             setHasError(true);
             setError({...error,email:'',pwd:'**Password is required**',c_pwd:''});
             return true;
         }
         if((checkOtp && !pwdRegExp())){
             setHasError(true);
             setError({...error,email:'',pwd:'**Strong Password is required**',c_pwd:''});
             return true;
         }
         if((checkOtp && c_pwd!==pwd)){
            setHasError(true);
            setError({...error,email:'',pwd:'',c_pwd:'**Password is not matched**'});
            return true;
         }
         setError({...error,email:'',pwd:'',c_pwd:''});
         return false; 
    }

    const pwdRegExp=()=>{
        if(pwd.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/))
        return true;
    }

    const emailRegExp=()=>{
        if(email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/))
         return true;
    }

    const sendOtpHandler=mail=>{

        if(validationCheck())
           return;

           setActive(true);
           setHasError(false);
        
           window.grecaptcha.ready(()=>{
            window.grecaptcha.execute(siteKey,{action: 'submit'})
            .then(token=>{
      
                const forgetReq={
                   'to':mail,
                   'subject': 'Reset Password',
                   'token':token
                };

                axios.post(`${forget_url}`,forgetReq,{
                    'Content-Type':'application/json'
                }).then(res=>{
                       const response = res.data;
                       setMessage(response.message);
                       setSentEmail(true);
                       setActive(false);
                }).catch(error=>{
                    console.log(error);
                    setSentEmail(false);
                    setActive(false);
                })

                  });

            });
         
    }

    const initializeMode=()=>{
        setEmail('');
        setOtp('');
        setPwd('');
        setC_Pwd('');
        setCheckOtp(false);
        setSentEmail(false);
        setActive(false);
    }


    const checkVerification=(email,code)=>{

        const req={
            email:email,
            code:code
       };


        axios.post(`${forget_url}/verify`,req,{
            headers:{
                'Content-Type':'application/json'
            }
        }).then(res=>{
            setCheckOtp(res.data.status);;
        }).catch(error=>{
            console.log(error);
            setCheckOtp(false);
        });
    }

    const setPwdHandler=(e)=>{
             const {value} = e.target;
             setPwd(value);
    }

    const setC_PwdHandler=(e)=>{
        const {value}=e.target;
        setC_Pwd(value);
    }

    const resetPasswordHandler=()=>{
         
        if(validationCheck())
          return;

          setActive(true);

          setHasError(false);

                const resetReq={
                  email:email,
                  code:otp,
                  password:pwd,
                };

                axios.post(`${forget_url}/reset`,resetReq,{
                    headers:{
                        'Content-Type':'application/json'
                    }
                }).then(res=>{
                    setMessage("Password has been changed");
                    setSuccess(res.data.status);
                    initializeMode();
                }).catch(error=>{
                    console.log(error);
                    setSuccess(false);
                    initializeMode();

                });
      
    }

    return (

        <ForgetContainer>
             <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        {/* <link rel="canonical" href={canonicalUrl} /> */}
             </Helmet>
            <div className="container-fluid">
                <div className="row">

                <input type="checkbox" id="checkMenu"/>

                <div className="col-12 mx-auto col-md-12 col-lg-12 col-sm-12 forget-page">

                <div className='top-bar'>
                <TopBar isShow={isShow} changeCheckHandler={changeCheckHandler}/>
             </div>

             <div className='top-bar-list'>
                             <TopBarList/>
                   </div>

             <div className='alph-header mb-2 mt-1 d-none d-md-block'>
                          <Alph/>
                     </div>

                     <div className='alph-header mb-2 mt-1 d-md-none'>
                         <Alphabat/>
                     </div>
              
            <div id="forget" className="container">

                <div className="row">
                    
                <div className="col-12 col-md-10 col-lg-8 col-sm-12 mx-auto forget-modal text-center">

              { (success) &&
                <div className="col-10 col-md-10 col-lg-10 col-sm-10 mx-auto success text-capitalize text-center my-4">
                     <p className="value my-2">{message}</p>
                </div>
              }

                     <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto title text-center">
                         <h4 className="name text-capitalize">password recovery</h4>
                         <p className="value">An OTP will be sent to your email</p>
                     </div>

                     <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto email-div text-center">
                            <ForgetEmail email={email} setEmailHandler={setEmailHandler}
                            errorMsg={error.email} hasError={hasError} checkOtp={checkOtp} sentEmail={sentEmail} message={message}/>
                     </div>

                     <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto otp-div text-capitalize text-left">
                          { (sentEmail) && <ForgetCode otp={otp} setOtpHandler={setOtpHandler}
                          checkOtp={checkOtp}/> }
                     </div>

                     <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto pwd-div text-capitalize text-left">
                         { (checkOtp) &&  <ForgetPwd setPwdHandler={setPwdHandler}
                         errorMsg_1={error.pwd} hasError={hasError} pwd={pwd} c_pwd={c_pwd} setC_PwdHandler={setC_PwdHandler}
                         errorMsg_2={error.c_pwd}/> }
                     </div>


                     <div className="col-12 col-md-12 col-lg-12 col-sm-12 mx-auto reset text-start mt-3 mb-5">

                     <div className="g-recaptcha"
        date-sitekey={siteKey}
        data-size="invisible"></div>

                           { (!sentEmail || checkOtp) && <Reset className={"active "+(active && 'dis-active')}
                           onClick={()=>{(checkOtp) ? resetPasswordHandler() : sendOtpHandler(email)}}>reset</Reset> }
                     </div>

                 </div> {/*forget-modal*/}

                </div>
            </div>

            <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto trending-tags mt-5 mb-5'>
               <Tags/>
                 </div>

            <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto footer-div'>
                        <Footer/>
                  </div>

           </div>{/*forget-page*/}
        </div>
        </div>

         <ConnectionStatus/>

        </ForgetContainer>

    );
}

const ForgetContainer=styledComponents.div`

background: var(--lightBlack);

.forget-page{
    position:relative;
    margin:0;
    padding:0;
    overflow:hidden;
    box-sizing: border-box;
}

.forget-nav{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    padding:0;
    margin:0;
}

#forget{
    position: relative;
    top:1.5rem;
    bottom:0;
    left:0;
    right:0;
}

.footer{
    margin-top:3rem;
}

.title{
    .name{
        font-family: 'Open Sans', sans-serif;
        font-size:2rem;
        font-weight:bold;
        color: var(--lightGrey);
    }
    .value{
        font-family: 'Open Sans', sans-serif;
        font-size:0.8rem;
        color: var(--lightGrey);
    }
}

.otp-div{
    padding:0;
    margin:0;
}

.reset{
    padding:0 2rem;
    margin:0;
}

.success{
    border: 1px solid var(--lightGreen);
    color: var(--mainWhite);
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    background: var(--lightGreen);
    border-radius:0.2rem;
}

.active{
    cursor:pointer;
    opacity:1;
}

.dis-active{
    cursor:not-allowed;
    opacity:0.7;
}

.footer-div{
    padding:0 !important;
    margin:0 !important;
  }

  @media (min-width: 1024px) {
    .top-bar {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  .top-bar{
    // padding:0 !important;
    // margin:0 !important;
  }   

  .trending-tags{
   // padding:0 !important;
  //  margin:0 !important;
  }

  #checkMenu{
    display:none;
  }
  
  .top-bar-list{
    padding:0 !important;
    margin:0 !important;
    overflow: hidden;
    position:relative;
    max-height: 0;
    transition: max-height 0.5s ease;
  }
  
  #checkMenu:checked ~ .top-bar-list{
    max-height: 300px;
    transition: max-height 0.5s ease;
  }


`;

