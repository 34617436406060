import React, { useState } from 'react'
import styledComponents from 'styled-components'
import { FullpageInterstitial } from "exoclick-react";
import { Link, useNavigate } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/free-solid-svg-icons'
import { faImage } from '@fortawesome/free-solid-svg-icons';

export const SelectHeader = ({header,selectedHandler,tag}) => {

     const [selected,setSelected]=useState(header);

     const navigate = useNavigate();

     const changeSelected=(name)=>{
           setSelected(name);
           selectedHandler(name);
           return navigate(`/browse/tags/${name}/${tag}`);
     }

  return (
    <SelectContainer>
        <div className='container'>
              <div className='row'>
              {/* <div className='col-12 col-md-12 col-lg-12 col-sm-12 mx-auto header-div d-flex'> */}

                   <div className='col-10 col-md-8 col-lg-6 col-sm-10 mx-auto header d-flex'>

                   <Link to={`/browse/tags/videos/${tag}`} className={'col-6 col-md-6 col-lg-6 col-sm-6 mx-auto videos text-capitalize text-center py-1 '+(selected==='videos' && 'selected')} onClick={()=>changeSelected('videos')}>
                          {/* <span className='video'>videos</span> */}
                          <FontAwesomeIcon className="fa-video icon px-2" icon={faVideo}/>
                          <a href="#" onClick={e => e.preventDefault()} className="video">
                          videos
</a>
                    </Link>{/*videos*/}   

                    <Link to={`/browse/tags/images/${tag}`} className={'col-6 col-md-6 col-lg-6 col-sm-6 mx-auto images text-capitalize text-center py-1 '+(selected==='images' && 'selected')} onClick={()=>changeSelected('images')}>
                    {/* <span className='image'>photos</span> */}
                    <FontAwesomeIcon className="fa-image icon px-2" icon={faImage}/>
                    <a href="#" onClick={e => e.preventDefault()} className="image">
                    photos
</a>

                  </Link>{/*images*/}   

                   </div> {/*header*/}

                   {/* <div className='col-0 col-md-4 col-lg-6 col-sm-2 mx-auto header-right'> */}

                   {/* </div> */}

                   {/* </div> */}
              </div>
        </div>

        {/* <FullpageInterstitial
  zoneId={4830474}
  frequencyType="clicks"
  firstTriggerClicks={1}
  nextTriggerClicks={5}
  triggerClass={['image']}
/>

<FullpageInterstitial
  zoneId={4830496}
  frequencyType="clicks"
  firstTriggerClicks={1}
  nextTriggerClicks={5}
  triggerClass={['image']}
/> */}

    </SelectContainer>
  )
}

const SelectContainer=styledComponents.div`

background: var(--lightBlack);

.videos{
  border: 1px solid var(--lightGrey);
  cursor:pointer;
  transition: 0.5s all;
  border-radius: 0.3rem 0 0 0.3rem;
  text-decoration: none;
  &:hover{
    background-color: rgba(200, 200, 200, 0.05);
    opacity:0.9;
   }
}

.images{
    border: 1px solid var(--lightGrey);
    border-left: 1px solid transparent;
    cursor:pointer;
    border-radius: 0 0.3rem 0.3rem 0;
    transition: 0.5s all;
    text-decoration: none;
    &:hover{
      background-color: rgba(200, 200, 200, 0.05);
      opacity:0.9;
    }
}

.selected{
    background: var(--mainBlue);
    transition: 0.5s all;
    &:hover{
       background: var(--mainBlue);
       opacity:1;
   }
}

.video{
  color: var(--mainWhite);
  font-family: 'Karla', sans-serif;
  font-size:1rem;
  text-decoration: none;
}

.image{
    color: var(--mainWhite);
    font-family: 'Karla', sans-serif;
    font-size:1rem;
    text-decoration: none;
}

.icon{
  color: var(--mainWhite);
  font-size:0.9rem;
}

`;