import styledComponents from "styled-components";


export const SliderButton = styledComponents.button`

position:absolute;
top:50%;
left: ${props=>props.left && '0'};
right: ${props=>props.right && '0'};
transform: translateY(-50%);
width:12%;
height:80vh;
outline:none;
border:none;
backgound:none;
font-size:2.5rem;
cursor: default !important;

`;